import { sortBy } from '../../func';

const API_PATH = '/api/provinces';

export const provinces = {
    namespaced: true,
    state: {
        provinces: null,
    },
    mutations: {
        setData(state, value) {
            state.provinces = value;
        },
    },
    actions: {
        clearProvinces({ commit }) {
            commit('setData', null);
        },
        async createProvince({ dispatch, rootGetters }, province) {
            return rootGetters['user/can']('CREATE_PROVINCES')
                ? await dispatch('api/post', { url: API_PATH, data: province }, { root: true })
                : null;
        },
        async deleteProvince({ dispatch, rootGetters }, province) {
            return rootGetters['user/can']('DELETE_PROVINCES')
                ? await dispatch('api/delete', `${API_PATH}/${province.id}`, { root: true })
                : null;
        },
        async getProvinces({ commit, dispatch, rootGetters, state }) {
            if (!state.provinces) {
                commit('setData', []);

                rootGetters['user/can']('READ_PROVINCES') &&
                    commit('setData', (await dispatch('api/get', API_PATH, { root: true })).sort(sortBy('name', 'id')));
            }

            return state.provinces;
        },
        async saveProvince({ dispatch }, province) {
            return await dispatch(province.id ? 'updateProvince' : 'createProvince', province);
        },
        async updateProvince({ dispatch, rootGetters }, province) {
            return rootGetters['user/can']('UPDATE_PROVINCES')
                ? await dispatch('api/put', { url: `${API_PATH}/${province.id}`, data: province }, { root: true })
                : null;
        },
        logout({ dispatch }) {
            dispatch('clearProvinces');
        },
    }
};

export default provinces;