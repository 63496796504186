<template>
  <form
    autocomplete="off"
    class="admin-billing-codes-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Billing Code</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-billing-code-code"
          required
          type="text"
          v-model.trim="code"
        />
      </label>

      <label>
        <span class="required">Description</span>

        <input
          :disabled="disabled"
          id="admin-billing-code-description"
          required
          type="text"
          v-model.trim="description"
        />
      </label>

      <label>
        <span class="required">Rate</span>

        <currency-input
          :disabled="disabled"
          id="admin-billing-code-fee"
          required
          v-model.number="fee"
        />
      </label>

      <label>
        <span class="required">Sage General Ledger</span>

        <input
          :disabled="disabled"
          id="admin-billing-code-account-number"
          required
          type="text"
          v-model="accountNumber"
        />
      </label>
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-billing-code-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && billingCode.id"
      >Delete</button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-billing-code-save"
        type="submit"
        v-if="canSave"
      >Save</button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-billing-code-cancel"
        type="reset"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </form>
</template>

<script>
import { deleteBillingCode, saveBillingCode } from "../../api/BillingCodeAPI";

export default {
  data() {
    return {
      code: undefined,
      description: undefined,
      fee: undefined,
      accountNumber: undefined
    };
  },

  props: {
    billingCode: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_BILLING_CODES");
    },
    canSave() {
      return this.can({ permissions: ["CREATE_BILLING_CODES", "UPDATE_BILLING_CODES"], matchAny: true });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.billingCode?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.billingCode?.id
          ? this.can("UPDATE_BILLING_CODES")
          : this.can("CREATE_BILLING_CODES"))
      );
    }
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (!confirm("Are you sure you want to delete this billing code?")) {
        return;
      }

      await deleteBillingCode(this.billingCode);
      this.$emit("delete", this.billingCode);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveBillingCode({
            ...this.billingCode,
            code: this.code,
            description: this.description,
            fee: this.fee,
            accountNumber: this.accountNumber
          })
        );
      } catch (e) {
        this.showErrors(e, "A billing code");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return (
        this.code !== this.billingCode?.code ||
        this.description !== this.billingCode?.description ||
        this.fee !== this.billingCode?.fee ||
        this.accountNumber !== this.billingCode?.accountNumber
      );
    }
  },

  watch: {
    billingCode: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.code = to?.code;
          this.description = to?.description;
          this.fee = to?.fee;
          this.accountNumber = to?.accountNumber;
        }

        !this.disabled && this.autofocus();
      }
    },
    disabled(to, from) {
      !to && from && this.autofocus();
    }
  }
};
</script>

<style>
.admin-billing-codes-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.admin-billing-codes-form > footer {
  grid-column: span 2;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>