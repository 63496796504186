<template>
  <input
    :pattern="pattern"
    placeholder="(000) 000-0000"
    type="tel"
    :value="formattedValue"
    v-on="listeners"
    @input="handleInput"
    @invalid="handleInvalid"
  />
</template>

<script>
export default {
  props: {
    pattern: {
      type: String,
      default: "^(\\d*\\s*)?\\(\\d{3}\\)\\s*\\d{3}\\s*-\\s*\\d{4}$"
    },
    value: {
      type: String,
      default: ""
    }
  },

  computed: {
    formattedValue() {
      return this.formatPhoneNumber(this.value);
    },
    listeners() {
      const listeners = { ...this.$listeners };
      delete listeners.input;
      delete listeners.invalid;
      return listeners;
    }
  },

  methods: {
    handleInput(event) {
      event.target.setCustomValidity("");
      this.$emit("input", event.target.value.replace(/\D+/g, ""));
    },
    handleInvalid(event) {
      if (!event.target.validity.valid) {
        event.target.setCustomValidity("Please enter a valid phone number");
      } else {
        event.target.setCustomValidity("");
      }

      this.$emit("invalid", event);
    }
  }
};
</script>