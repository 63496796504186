<template>
  <section class="client-page">
    <content-header :prev="previousRoute" :title="title">
      <small v-if="client && client.deceasedOn">(Deceased)</small>
    </content-header>

    <template v-if="!client">
      <search-form class="client-search" :debounce="200" v-model="search" @change="handleSearch">
        <icon-button
          class="client-create-button"
          name="add-client"
          title="Create Client"
          @click="handleCreate"
          v-if="can('CREATE_CLIENTS')"
        />
      </search-form>

      <ClientsTable
        :can-select="can('READ_CLIENTS')"
        :clients="clients.records"
        :page-count="clients.page.last"
        :page-number="clients.page.current"
        :page-size="clients.page.size"
        :selected-id="client && client.id"
        @page-change="handlePageChange"
        @select="handleSelect"
      />
    </template>

    <template v-else>
      <button
        :disabled="Object.keys(client).length === 0"
        class="generate-template-btn"
        @click="handleToggleTemplates(true)"
        v-if="can('READ_TEMPLATES')"
      >
        Templates
      </button>

      <popup
        :modal="true"
        ref="templateModal"
        @close="handleToggleTemplates(false)"
        v-if="templateModal"
      >
        <TemplateSelector
          :templates="filteredTemplates"
          @cancel="handleToggleTemplates(false)"
          @submit="handleSubmitTemplates"
        />
      </popup>

      <ClientsForm
        :billing-codes="billingCodes"
        :client.sync="client"
        :equipment.sync="equipment"
        :facilities="facilities"
        :insurance-providers="insuranceProviders"
        :loan-types="loanTypes"
        :programs="programs"
        :projects="projects"
        :referral-agents="referralAgents"
        @reset="handleCancel"
        @submit="handleSave"
      />
    </template>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ClientsTable from "../../../components/clients/ClientsTable";
import ClientsForm from "../../../components/clients/ClientsForm";
import TemplateSelector from "../../../components/templates/TemplateSelector";

import { getBillingCodes } from "../../../api/BillingCodeAPI";
import { generateDocuments, getClient, getClients, getPaginatedClient } from "../../../api/ClientAPI";
import { getFacilities } from "../../../api/FacilityAPI";
import { getInsuranceProviders } from "../../../api/InsuranceProviderAPI";
import { getLoanTypes } from "../../../api/LoanAPI";
import { getPrograms } from "../../../api/ProgramAPI";
import { getProjects } from "../../../api/ProjectAPI";
import { getReferralAgents } from "../../../api/ReferralAgentAPI";
import { getTemplates } from "../../../api/TemplatesAPI";

import {
  getEquipmentCategories,
  getEquipmentStatuses,
  getEquipmentSubcategories,
  getEquipmentSummaries,
  getEquipmentTypes,
} from "../../../api/EquipmentAPI";

export default {
  data() {
    return {
      _previousRoute: undefined,
      billingCodes: undefined,
      client: undefined,
      clients: {
        totalRecordCount: 0,
        records: [],
        page: {
          size: 20,
          current: 1,
          first: 1,
          last: 1,
        }
      },
      equipment: undefined,
      equipmentCategories: undefined,
      equipmentStatuses: undefined,
      equipmentSubcategories: undefined,
      equipmentTypes: undefined,
      facilities: undefined,
      insuranceProviders: undefined,
      loanTypes: undefined,
      programs: undefined,
      projects: undefined,
      search: "",
      referralAgents: undefined,
      templateModal: false,
      templates: undefined,
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined
    }
  },
  
  computed: {
    ...mapGetters(["cities", "counties", "genders", "languages", "provinces"]),
    filteredClients() {
      if (!/\S/.test(this.search)) {
        return this.clients;
      }

      const regex = this.getFilterRegex(this.search);

      return this.clients.records.filter((client) =>
        regex.test(
          [
            client?.clientNumber ?? "",
            this.getFullName(client),
            client?.medicareNumber ?? "",
            client?.fcsNumber ?? "",
            ...(client?.addresses
              ?.filter((a) => a?.mailing)
              .slice(0, 1)
              .map((a) =>
                [
                  a.address1 ?? "",
                  a.address2 ?? "",
                  a.city?.name ?? "",
                  a.province?.abbrevivation ?? "",
                  a.postalCode ?? "",
                ].join(String.fromCharCode(0x1f))
              ) ?? []),
            ...(client?.phoneNumbers
              ?.filter((p) => p?.type.id === 1)
              .slice(0, 1)
              .map((p) => p.number ?? "") ?? []),
          ].join(String.fromCharCode(0x1f))
        )
      );
    },
    filteredTemplates() {
      return this.templates.filter((t) => t.type.name == "Client Letter");
    },
    previousRoute() {
      if (
        !this.can({
          permissions: [
            "READ_CLIENTS",
            "READ_EQUIPMENT",
            "READ_USERS",
            "READ_INVOICES",
          ],
          matchAny: true,
        })
      ) {
        return null;
      }

      return (
        this.$data._previousRoute ??
        (this.client ? { name: "Clients" } : { name: "Home" })
      );
    },
    title() {
      return this.client?.id
        ? `Edit Client - ${this.client.clientNumber ?? ""}`
        : this.client
        ? "Create Client"
        : "Clients";
    },
  },

  methods: {
    ...mapActions("cities", ["getCities"]),
    ...mapActions("counties", ["getCounties"]),
    ...mapActions("genders", ["getGenders"]),
    ...mapActions("languages", ["getLanguages"]),
    ...mapActions("provinces", ["getProvinces"]),
    handleCancel() {
      this.$router.push({
        ...this.$route,
        params: { ...this.$route.params, id: undefined },
      });
    },
    handleCreate() {
      this.$router.push({
        ...this.$route,
        params: { ...this.$route.params, id: "new" },
      });
    },
    async handleDelete(client) {
      await this.loadClient();

      this.clients = this.clients.records.filter((c) => c?.id !== client?.id);
      this.client?.id === client?.id && (this.client = undefined);
    },
    async handlePageChange(pageNumber) {
      await this.loadClient({ page: pageNumber });
    },
    async handleSave(client) {
      await this.loadClient();

      this.clients.records = [
        ...this.clients.records.filter((c) => c?.id !== client?.id),
        client,
      ].sort(this.sortBy("firstName", "lastName", "clientNumber"));

      const created = !/^\d+$/.test(this.client?.id);
      this.client = { ...client };

      if (created) {
        this.handleSelect(client);
        this.showAlert(`Your client has been created successfully!`);
      } else {
        this.showAlert(`Your client has been updated successfully!`);
      }
    },
    async handleSearch(value) {
      const oldValue = this.search;

      if (value instanceof Event)
        this.search = event.target.value;
      else if (typeof value === 'string' || value == null)
        this.search = value;

      if (oldValue !== this.search)
        await this.loadClient({ page: 1 });
    },
    handleSelect(client) {
      this.$nextTick(() => {
        this.$router.push({
          ...this.$route,
          params: { ...this.$route.params, id: client?.id },
        });
      });
    },
    async handleSubmitTemplates(selectedTemplates) {
      try {
        await generateDocuments({
          clientIds: [this.id],
          templateIds: selectedTemplates,
        });

        this.handleToggleTemplates(false);
      } catch (e) {
        this.showErrors(e);
      }
    },
    handleToggleTemplates(templateModal) {
      this.templateModal = templateModal ?? !this.templateModal;
      this.templateModal &&
        this.$nextTick(() => this.$refs.templateModal.$el.scrollIntoView());
    },
    async loadData() {
      this.getCities();
      this.getCounties();
      this.getGenders();
      this.getLanguages();
      this.getProvinces();

      [
        this.billingCodes,
        this.equipment,
        this.equipmentCategories,
        this.equipmentStatuses,
        this.equipmentSubcategories,
        this.equipmentTypes,
        this.facilities,
        this.insuranceProviders,
        this.loanTypes,
        this.programs,
        this.projects,
        this.referralAgents,
        this.templates,
      ] = await Promise.all([
        this.billingCodes ??
          (this.can("READ_BILLING_CODES") ? getBillingCodes() : []),
        this.equipment ??
          (this.can({
            permissions: ["READ_EQUIPMENT", "READ_AVAILABLE_EQUIPMENT", "READ_PROGRAM_EQUIPMENT", "READ_AVAILABLE_PROGRAM_EQUIPMENT"],
            matchAny: true,
          })
            ? getEquipmentSummaries()
            : []),
        this.equipmentCategories ??
          (this.can("READ_EQUIPMENT_CATEGORIES")
            ? getEquipmentCategories()
            : []),
        this.equipmentStatuses ??
          (this.can("READ_EQUIPMENT_STATUSES") ? getEquipmentStatuses() : []),
        this.equipmentSubcategories ??
          (this.can("READ_EQUIPMENT_SUBCATEGORIES")
            ? getEquipmentSubcategories()
            : []),
        this.equipmentTypes ??
          (this.can("READ_EQUIPMENT_TYPES") ? getEquipmentTypes() : []),
        this.facilities ?? (this.can("READ_FACILITIES") ? getFacilities() : []),
        this.insuranceProviders ??
          (this.can("READ_INSURANCE_PROVIDERS") ? getInsuranceProviders() : []),
        this.loanTypes ?? (this.can("READ_LOAN_TYPES") ? getLoanTypes() : []),
        this.programs ?? (this.can({
            permissions: ["READ_PROGRAMS", "READ_ASSIGNED_PROGRAMS"],
            matchAny: true,
          }) ? getPrograms() : []),
        this.projects ?? (this.can("READ_PROJECTS") ? getProjects() : []),
        this.referralAgents ??
          (this.can({
            permissions: ["READ_REFERRAL_AGENTS", "READ_USERS"],
            matchAny: true,
          })
            ? getReferralAgents()
            : []),
          this.templates ?? (this.can("READ_TEMPLATES") ? getTemplates() : []),
      ]);
    },
    async loadClient({ size, page, phrase, } = {}) {
      this.clients = await getPaginatedClient({
        size: size ?? this.clients.page.size,
        page: page ?? this.clients.page.current,
        phrase: phrase ?? this.search,
      })
    }
  },

  watch: {
    async id(to, from) {
      if (to === from) {
        return;
      }

      if (!to) {
        this.client = undefined;

        await this.loadClient();
      } else {
        if (to !== "new") {
          try {
            this.client = { id: to, ...this.clients?.records?.find((c) => c.id === to) };

            [this.client] = await Promise.all([getClient(to), this.loadData()]);
          } catch (e) {
            this.handleSelect();
          }
        } else {
          this.client = {};
          this.loadData();
        }
      }
    },
  },

  async created() {
    if (this.id) {
      try {
        [this.client] = await Promise.all([
          this.id === "new" ? {} : await getClient(this.id),
          this.loadData(),
        ]);
      } catch (e) {
        this.handleSelect();
      }
    }

    await this.loadClient();
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$data._previousRoute =
        vm.id && from.name === "Home" ? from : undefined;
    });
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ClientsTable,
    ClientsForm,
    TemplateSelector,
  },
};
</script>

<style>
.client-page {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "header search"
    "table table"
    "form form";
}

.client-page > .client-list {
  display: contents;
}

.client-page .content-header {
  align-items: center;
  grid-area: header;
}

.client-page .client-search {
  align-items: flex-end;
  display: flex;
  font-size: 0.8rem;
  font-weight: normal;
  grid-area: search;
  margin: 0 0 0.585rem auto;
  padding: 0;
}

.client-page .client-search label {
  color: var(--primary-red);
  margin-right: 0.33em;
}

.client-page .client-create-button {
  align-self: flex-end;
  font-size: 0.755rem;
  grid-area: add;
}

.client-page .client-table {
  grid-area: table;
}

.client-page .client-form {
  grid-area: form;
}

.client-page .generate-template-btn {
  margin-bottom: 1em;
}
</style>