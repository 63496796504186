import Vue from 'vue';
import Vuex from 'vuex';

import api from './api';
import user from './user';

import cities from './apis/cities';
import counties from './apis/counties';
import genders from './apis/genders';
import languages from './apis/languages';
import roles from './apis/roles';
import provinces from './apis/provinces';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    api,
    cities,
    counties,
    genders,
    languages,
    provinces,
    roles,
    user,
  },
  state: {
    modal: undefined,
  },
  getters: {
    cities: state => state.cities.cities ?? [],
    counties: state => state.counties.counties ?? [],
    genders: state => state.genders.genders ?? [],
    languages: state => state.languages.languages ?? [],
    provinces: state => state.provinces.provinces ?? [],
    roles: state => state.roles.roles ?? [],
    user: state => state.user.user ?? {},
  },
  mutations: {
    setModal(state, modal) {
      state.modal = modal;
    },
  },
  actions: {
    async login({ commit, dispatch }, { username, password, rememberMe }) {
      const res = await dispatch('api/post', {
        url: '/api/login',
        data: { username, password, rememberMe, },
        options: {
          raw: true,
        },
      });

      if (res.ok) {
        const token = res.headers.get('Authorization').replace(/^\S+\s*/, '');
        commit('api/setToken', token, { root: true });

        const passwordExpired = JSON.parse(res.headers.get('X-Password-Expired') ?? 'false');
        commit('api/setPasswordExpired', passwordExpired, { root: true });

        await Promise.all(Object.entries(this._modulesNamespaceMap).map(([namespace, module]) =>
          module._rawModule.actions.login ? dispatch(`${namespace}login`, { passwordExpired, token }) : Promise.resolve()
        ));
      } else {
        await dispatch('logout');
      }

      return res;
    },
    async logout({ dispatch }) {
      const res = await dispatch('api/get', {
        url: '/api/logout',
        options: {
          raw: true,
        },
      });

      Promise.all(Object.entries(this._modulesNamespaceMap).map(([namespace, module]) =>
        module._rawModule.actions.logout ? dispatch(`${namespace}logout`) : Promise.resolve()
      ));

      return res;
    },
    async resetPassword({ dispatch }, { token, password, confirmPassword }) {
      const res = await dispatch('api/put', {
        url: token ? '/api/passwords/forgotten' : '/api/passwords/reset',
        data: { token, password, confirmPassword },
        options: {
          raw: true,
        },
      });

      if (res.ok) {
        await Promise.all(Object.entries(this._modulesNamespaceMap).map(([namespace, module]) =>
          module._rawModule.actions.resetPassword ? dispatch(`${namespace}resetPassword`) : Promise.resolve()
        ));
      }

      return res;
    },
  }

});