<template>
  <table class="striped indirect-services-table">
    <caption v-html="caption" v-if="caption" />
    <colgroup>
      <col width="33%" />
      <col width="33%" />
      <col width="34%" />
    </colgroup>
    <thead>
      <tr>
        <th>User</th>
        <th>Date</th>
        <th>Notes</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="indirectService in paginatedIndirectServices"
        :key="indirectService.id"
        :class="selectedId === indirectService.id ? 'selected' : 'selectable'"
        @click="handleSelect(indirectService)"
      >
        <td>{{ getUserName(indirectService.creator) }}</td>
        <td>
          {{
            formatTimestamp(indirectService.createdAt, {
              format: FORMAT_DEFAULT_DATE_TIME,
            })
          }}
        </td>
        <td>
          {{ indirectService.notes.length }}
          {{ indirectService.notes.length !== 1 ? "notes" : "note" }}
        </td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls" colspan="3">
          <icon-button
            :disabled="!hasPrevPage"
            name="prev"
            @click="goToPrevPage"
          />

          <icon-button
            :disabled="!hasNextPage"
            name="next"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("indirectServices")],

  props: {
    caption: {
      type: String,
      default: undefined,
    },
    indirectServices: {
      type: Array,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
  },

  methods: {
    handleSelect(indirectService) {
      this.$emit("select", indirectService);
    },
  },
};
</script>