import { sortBy } from '../../func';

const API_PATH = '/api/roles';

export const roles = {
    namespaced: true,
    state: {
        roles: null,
    },
    mutations: {
        setData(state, value) {
            state.roles = value;
        },
    },
    actions: {
        clearRoles({ commit }) {
            commit('setData', null);
        },
        async createRole({ dispatch, rootGetters }, role) {
            return rootGetters['user/can']('CREATE_ROLES')
                ? await dispatch('api/post', { url: API_PATH, data: role }, { root: true })
                : null;
        },
        async deleteRole({ dispatch, rootGetters }, role) {
            return rootGetters['user/can']('DELETE_ROLES')
                ? await dispatch('api/delete', `${API_PATH}/${role.id}`, { root: true })
                : null;
        },
        async getRoles({ commit, dispatch, rootGetters, state }) {
            if (!state.roles) {
                commit('setData', []);

                rootGetters['user/can']('READ_ROLES') &&
                    commit('setData', (await dispatch('api/get', API_PATH, { root: true })).sort(sortBy('name', 'id')));
            }

            return state.roles;
        },
        async saveRole({ dispatch }, role) {
            return await dispatch(role.id ? 'updateRole' : 'createRole', role);
        },
        async updateRole({ dispatch, rootGetters }, role) {
            return rootGetters['user/can']('UPDATE_ROLES')
                ? await dispatch('api/put', { url: `${API_PATH}/${role.id}`, data: role }, { root: true })
                : null;
        },
        logout({ dispatch }) {
            dispatch('clearRoles');
        },
    }
};

export default roles;