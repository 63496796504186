<template>
  <SearchCard
    class="client-card"
    :expanded="expanded"
    @update:expanded="$emit('update:expanded', arguments[0])"
  >
    <template slot="tab-icon">
      <img src="../../assets/icons/client.svg" />
    </template>

    <template slot="actions">
      <router-link :to="{'name': 'Clients', 'params': {'id': client.id}}">
        <icon-button name="edit" />
      </router-link>

      <icon-button name="archive" @click="handleArchive" v-if="can('DELETE_CLIENTS')" />
    </template>

    <template slot="info">
      <label>
        Client Number
        <strong>{{ client.clientNumber || 'N/A' }}</strong>
      </label>

      <label>
        Client Name
        <strong>{{ getFullName(client) || 'N/A' }}</strong>
      </label>

      <label class="client-info-address">
        Client Address
        <strong v-if="address.id">
          {{ address.address1 }}
          <br />
          {{ address.address2 }}
          <br v-if="address.address2" />
          {{ `${address.city.name || ''}, ${address.province.abbreviation || ''}`.replace(/^(\s*,|,\s*)$/g, '').trim() }}
          <br />
          {{ formatPostalCode(address.postalCode) }}
        </strong>
        <strong v-else>N/A</strong>
      </label>

      <label>
        Phone Number
        <strong v-if="client.phoneNumbers.length > 0">
          <template v-for="(phoneNumber, index) in client.phoneNumbers">
            {{ formatPhoneNumber(phoneNumber.number) }}
            <br :key="index" v-if="index < client.phoneNumbers.length - 1" />
          </template>
        </strong>
        <strong v-else>N/A</strong>
      </label>

      <label>
        Medicare Number
        <strong>{{ formatMedicareNumber(client.medicareNumber) || 'N/A' }}</strong>
      </label>

      <label>
        Social Development Number
        <strong>{{ client.fcsNumber || 'N/A' }}</strong>
      </label>
    </template>

    <template slot="expanded-info">
      <label></label>

      <label>
        Email
        <strong>{{ emailAddress.address || 'N/A' }}</strong>
      </label>

      <label>
        Language
        <strong>{{ client.language.name || 'N/A' }}</strong>
      </label>

      <label>
        DOB
        <span v-if="client.deceased">(Deceased)</span>
        <strong>{{ formatTimestamp(client.birthDate, { convertTimezone: false }) || 'N/A' }}</strong>
      </label>

      <label>
        Gender
        <strong>{{ client.gender.name || 'N/A' }}</strong>
      </label>

      <label>
        Client Agency Number
        <strong>{{ client.fcsNumber || 'N/A' }}</strong>
      </label>

      <label>
        Special Project Field
        <strong>{{ project.name || 'N/A' }}</strong>
      </label>

      <label>
        Program/Association
        <strong>{{ program.name || 'N/A' }}</strong>
      </label>

      <label>
        Facility
        <strong>{{ facility.name || 'N/A' }}</strong>
      </label>
    </template>
  </SearchCard>
</template>

<script>
import SearchCard from "../search/SearchCard";
import { deleteClient } from "../../api/ClientAPI";

export default {
  components: {
    SearchCard,
  },

  props: {
    client: {
      type: Object,
    },
    expanded: {
      type: Boolean,
      default: null,
    },
  },

  computed: {
    address() {
      return {
        city: {},
        county: {},
        province: {},
        ...(this.client?.facility?.addresses?.slice(-1)?.[0] ??
          this.client?.addresses?.find((a) => a.mailing)),
      };
    },
    emailAddress() {
      return this.client?.emailAddresses?.find((a) => a.primary) ?? {};
    },
    facility() {
      return this.client?.facility ?? {};
    },
    program() {
      return this.client?.program ?? {};
    },
    project() {
      return this.client?.project ?? {};
    },
  },

  methods: {
    async handleArchive() {
      if (!confirm("Are you sure you want to archive this client?")) {
        return;
      }

      try {
        await deleteClient(this.client);
        this.$emit("delete", this.client);
        this.showAlert("The client has been successfully archived!");
      } catch (e) {
        this.showErrors(e);
      }
    },
  },
};
</script>

<style scoped>
.client-info-address {
  grid-row: span 2;
}
</style>