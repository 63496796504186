<template>
  <SearchCard
    class="referral-agent-card"
    :expanded="expanded"
    @update:expanded="$emit('update:expanded', arguments[0])"
  >
    <template slot="tab-icon">
      <img src="../../assets/icons/user.svg" />
    </template>

    <template slot="actions">
      <router-link
        :to="{
          name: 'Admin: Referral Agents',
          params: { id: referralAgent.id },
        }"
      >
        <icon-button name="edit" />
      </router-link>

      <icon-button
        name="archive"
        @click="handleArchive"
        v-if="can('DELETE_REFERRAL_AGENTS')"
      />
    </template>

    <template slot="info">
      <label>
        Name
        <strong>{{ getFullName(referralAgent) || "N/A" }}</strong>
      </label>

      <label>
        Email Address
        <strong>{{ emailAddress.address || "N/A" }}</strong>
      </label>

      <label>
        Phone Number
        <strong v-if="referralAgent.phoneNumbers.length > 0">
          <template v-for="(phoneNumber, index) in referralAgent.phoneNumbers">
            {{ formatPhoneNumber(phoneNumber.number) }}
            {{ phoneNumber.extension ? ` ext. ${phoneNumber.extension}` : "" }}
            <br
              :key="index"
              v-if="index < referralAgent.phoneNumbers.length - 1"
            />
          </template>
        </strong>
        <strong v-else>N/A</strong>
      </label>

      <label class="referral-agent-info-address">
        Address
        <strong v-if="address.id">
          {{ address.address1 }}
          <br />
          {{ address.address2 }}
          <br v-if="address.address2" />
          {{
            `${address.city.name || ""}, ${address.province.abbreviation || ""}`
              .replace(/^(\s*,|,\s*)$/g, "")
              .trim()
          }}
          <br />
          {{ formatPostalCode(address.postalCode) }}
        </strong>
        <strong v-else>N/A</strong>
      </label>
    </template>
  </SearchCard>
</template>

<script>
import SearchCard from "../search/SearchCard";
import { deleteReferralAgent } from "../../api/ReferralAgentAPI";

export default {
  components: {
    SearchCard,
  },

  props: {
    expanded: {
      type: Boolean,
      default: null,
    },
    referralAgent: {
      type: Object,
    },
  },

  computed: {
    address() {
      return {
        city: {},
        county: {},
        province: {},
        ...this.referralAgent?.addresses?.find((a) => a.primary),
      };
    },
    emailAddress() {
      return this.referralAgent?.emailAddresses?.find((a) => a.primary) ?? {};
    },
    phoneNumber() {
      return (
        this.referralAgent?.phoneNumbers?.find((p) => p?.type?.id === 2) ?? {}
      );
    },
  },

  methods: {
    async handleArchive() {
      if (!confirm("Are you sure you want to archive this referral agent?")) {
        return;
      }

      try {
        await deleteReferralAgent(this.referralAgent);
        this.$emit("delete", this.referralAgent);
        this.showAlert("The referral agent has been successfully archived!");
      } catch (e) {
        this.showErrors(e);
      }
    },
  },
};
</script>

<style scoped>
.referral-agent-info-address {
  grid-row: span 2;
}
</style>