<template>
  <header class="header">
    <router-link class="header-logo" :to="{name: 'Home'}">
      <img alt="Easter Seals NB" src="../../assets/logo.svg" />
    </router-link>

    <nav class="header-nav">
      <router-link
        v-for="(link, index) in links"
        :key="index"
        :disabled="link.disabled"
        :to="link.to"
        v-slot="{ disabled, href, isActive, isExactActive, navigate, route }"
      >
        <a
          :class="['header-link', (isActive || isActiveModal(route)) && 'router-link-active', (isExactActive || isExactActiveModal(route)) && 'router-link-exact-active']"
          :disabled="disabled"
          :href="href"
          :title="link.name"
          @click="navigate"
        >
          <img
            :alt="getIcon(link, isActive).replace(/.*?\/([^/]+)$/, '$1')"
            :src="getIcon(link, isActive)"
          />
        </a>
      </router-link>
      <button v-if="this.$store.getters.user.roles.includes('REFERRAL_AGENT')" @click="toggleLanguage">{{this.$store.getters.user.language.id === 1 ? "Fr" : "En"}}</button>
    </nav>
  </header>
</template>

<script>
import sprite from "../../assets/header.svg";
import { mapActions } from "vuex";

export default {
  computed: {
    links() {
      return [
        this.can("READ_INDIRECT_SERVICES") && {
          name: "Indirect Services",
          to: { name: "Indirect Services" },
          icon: `${sprite}#indirect-services`
        },
        this.can("READ_INVOICES") && {
          name: "Bulk Invoicing",
          to: { name: "Bulk Invoicing" },
          icon: `${sprite}#bulk-invoicing`
        },
        (
          this.hasAdmin("USERS") ||
          this.hasAdmin("EQUIPMENT_CATEGORIES") ||
          this.hasAdmin("EQUIPMENT_SUBCATEGORIES") ||
          this.hasAdmin("MANUFACTURERS") ||
          this.hasAdmin("VENDORS") ||
          this.hasAdmin("EQUIPMENT_ITEMS") ||
          this.hasAdmin("EQUIPMENT_PARTS") ||
          this.hasAdmin("EQUIPMENT_ACCESSORIES") ||
          this.hasAdmin("FACILITIES") ||
          this.hasAdmin("PROGRAMS") ||
          this.hasAdmin("PROJECTS") ||
          this.hasAdmin("EQUIPMENT_TYPES") ||
          this.hasAdmin("TEMPLATES") ||
          this.hasAdmin("BILLING_CODES") ||
          this.hasAdmin("LOAN_TYPES") ||
          this.hasAdmin("SERVICE_CATEGORIES") ||
          this.hasAdmin("SERVICE_SUBCATEORIES") ||
          this.hasAdmin("REFERRAL_AGENTS")
        ) && {
          name: "Tools",
          to: { name: "Admin" },
          icon: `${sprite}#tools`,
          activeIcon: `${sprite}#tools-filled`
        },
        {
          name: `${ this.getStaticName(this.$store.getters.user, "Logout", "Déconnexion") }`,
          to: { name: "Logout" },
          icon: `${sprite}#logout`
        }
      ].filter(v => v);
    }
  },

  methods: {
    ...mapActions("user", ["toggleUserLanguage"]),
    toggleLanguage() {
      this.toggleUserLanguage();
    },
    getIcon(link, isActive) {
      return (isActive || this.isActiveModal(link)) && link.activeIcon
        ? link.activeIcon
        : link.icon;
    },
    isActiveModal(route) {
      return (
        this.$store.state.modal?.path
          ?.replace(/\/+$/g, "")
          .indexOf(route?.path?.replace(/\/+$/g, "")) === 0
      );
    },
    isExactActiveModal(route) {
      return (
        this.$store.state.modal?.path.replace(/\/+$/g, "") ===
        route?.path?.replace(/\/+$/g, "")
      );
    }
  }
};
</script>

<style>
.header {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.header .header-logo img {
  cursor: pointer;
  width: 7em;
}

.header .header-nav {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.header .header-link {
  width: 3em;
}

.header .header-link ~ .header-link {
  margin-left: 0.5em;
}

.header .header-link:hover img,
.header .header-link.router-link-active img {
  filter: var(--filter-primary-red);
}


@media print {
  .header {
    display: none;
  }
}
</style>
