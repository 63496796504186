<template>
  <dialog :class="['popup', modal && 'popup-modal']" :open="isOpen" @click="handleClick">
    <section class="popup-inner">
      <article class="popup-content">
        <slot />
      </article>
    </section>
  </dialog>
</template>

<script>
export default {
  props: {
    closeOnEscape: {
      type: Boolean,
      default: null
    },
    closeOnOuterClick: {
      type: Boolean,
      default: null
    },
    modal: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: null
    }
  },

  computed: {
    isCloseOnEscape() {
      return this.modal
        ? this.closeOnEscape ?? true
        : this.closeOnEscape ?? false;
    },
    isCloseOnOuterClick() {
      return this.modal
        ? this.closeOnOuterClick ?? true
        : this.closeOnOuterClick ?? false;
    },
    isOpen() {
      return this.open ?? true;
    }
  },

  methods: {
    handleClick(event) {
      this.$emit("click", event);

      if (
        event.target === this.$el ||
        event.target === this.$el.firstElementChild
      ) {
        this.$emit("outer-click", event);
        this.isCloseOnOuterClick && this.$emit("close");
      }
    },
    handleKeyUp(event) {
      event.key === "Escape" && this.isCloseOnEscape && this.$emit("close");
    }
  },

  mounted() {
    window.addEventListener("keyup", this.handleKeyUp);
  },

  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyUp);
  }
};
</script>

<style>
.popup {
  align-items: flex-start;
  background: rgba(128, 128, 128, 0);
  border: none;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0 auto;
  min-width: 100%;
  overflow: auto;
  padding: 4rem 0 2rem;
  top: 0;
  z-index: 99;
}

.popup .popup-inner {
  display: contents;
}

.popup .popup-content {
  background: var(--primary-white);
  border-radius: 1em;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  padding: 1rem;
}

.popup-modal {
  background: rgba(128, 128, 128, 0.5);
  padding: 0;
}

.popup-modal .popup-inner {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
  padding: 4rem 1rem 2rem;
}

.popup-modal .popup-content {
  width: calc(80% + 1rem);
}
</style>