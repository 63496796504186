<template>
  <table class="striped admin-facilities-table">
    <caption v-html="caption" v-if="caption" />
    <colgroup>
      <col width="30%" />
      <col width="11.6%" />
      <col width="11.6%" />
      <col width="11.6%" />
      <col width="11.6%" />
      <col width="11.6%" />
      <col width="11.6%" />
    </colgroup>
    <thead>
      <tr>
        <th>Facility Name</th>
        <th>Address</th>
        <th>City</th>
        <th>Province</th>
        <th>Postal Code</th>
        <th>Phone Number</th>
        <th>Facility Email</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="facility in paginatedFacilities"
        :key="facility.id"
        :class="selectedId === facility.id ? 'selected' : 'selectable'"
        @click="handleSelect(facility)"
      >
        <td>{{ facility.name }}</td>
        <td>{{ getAddress(facility).address1 }}</td>
        <td>{{ getCity(facility).name }}</td>
        <td>{{ getProvince(facility).abbreviation }}</td>
        <td>{{ formatPostalCode(getAddress(facility).postalCode) }}</td>
        <td>{{ formatPhoneNumber(getPhoneNumber(facility).number) }}</td>
        <td>{{ facility.emailAddress }}</td>
      </tr>
      <tr v-if="facilities.length === 0">
        <td class="no-records" colspan="6">
          There are currently no facilities in the system.
        </td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls" colspan="6">
          <icon-button
            :disabled="!hasPrevPage"
            name="prev"
            @click="goToPrevPage"
          />

          <icon-button
            :disabled="!hasNextPage"
            name="next"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("facilities")],

  props: {
    caption: {
      type: String,
      default: undefined,
    },
    facilities: {
      type: Array,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
  },

  methods: {
    getAddress(facility) {
      return facility?.addresses?.slice(-1)?.[0] ?? {};
    },
    getCity(facility) {
      return this.getAddress(facility)?.city ?? {};
    },
    getPhoneNumber(facility) {
      return facility?.phoneNumbers?.slice(-1)?.[0] ?? {};
    },
    getProvince(facility) {
      return this.getAddress(facility)?.province ?? {};
    },
    handleSelect(facility) {
      this.$emit("select", facility);
    },
  },
};
</script>