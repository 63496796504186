<template>
  <input
    :autocomplete="autocomplete"
    :pattern="pattern"
    placeholder="A0A 0A0"
    type="text"
    :value="formattedValue"
    v-on="listeners"
    @input="handleInput"
    @invalid="handleInvalid"
  />
</template>

<script>
export default {
  props: {
    autocomplete: {
      type: String,
      default: "postal-code"
    },
    pattern: {
      type: String,
      default: "^[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$"
    },
    value: {
      type: String,
      default: ""
    }
  },

  computed: {
    formattedValue() {
      return this.formatPostalCode(this.value);
    },
    listeners() {
      const listeners = { ...this.$listeners };
      delete listeners.input;
      delete listeners.invalid;
      return listeners;
    }
  },

  methods: {
    handleInput(event) {
      event.target.setCustomValidity("");

      this.$emit(
        "input",
        event.target.value.toUpperCase().replace(/[^A-Z0-9]+/gi, "")
      );
    },
    handleInvalid(event) {
      if (!event.target.validity.valid) {
        event.target.setCustomValidity("Please enter a valid postal code");
      } else {
        event.target.setCustomValidity("");
      }

      this.$emit("invalid", event);
    }
  }
};
</script>