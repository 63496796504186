<template>
  <section>
    <header class="login-header">
      <slot name="logo" />

      <section class="login-header-text">
        <h2>Forgot your password?</h2>
        <p>No problem! Fill out the form below and we can send you a link to help you reset it.</p>
      </section>
    </header>

    <ForgotPasswordForm />
  </section>
</template>

<script>
import ForgotPasswordForm from "../../../components/login/ForgotPasswordForm";

export default {
  components: {
    ForgotPasswordForm
  }
};
</script>

<style>
.login-page .forgot-password-form {
  width: 80%;
}
</style>