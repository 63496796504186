import { sortBy } from '../../func';

const API_PATH = '/api/cities';

export const cities = {
    namespaced: true,
    state: {
        cities: null,
    },
    mutations: {
        setData(state, value) {
            state.cities = value;
        },
    },
    actions: {
        clearCities({ commit }) {
            commit('setData', null);
        },
        async createCity({ dispatch, rootGetters }, city) {
            return rootGetters['user/can']('CREATE_CITIES')
                ? await dispatch('api/post', { url: API_PATH, data: city }, { root: true })
                : null;
        },
        async deleteCity({ dispatch, rootGetters }, city) {
            return rootGetters['user/can']('DELETE_CITIES')
                ? await dispatch('api/delete', `${API_PATH}/${city.id}`, { root: true })
                : null;
        },
        async getCities({ commit, dispatch, rootGetters, state }) {
            if (!state.cities) {
                commit('setData', []);

                rootGetters['user/can']('READ_CITIES') &&
                    commit('setData', (await dispatch('api/get', API_PATH, { root: true })).sort(sortBy('name', 'id')));
            }

            return state.cities;
        },
        async saveCity({ dispatch }, city) {
            return await dispatch(city.id ? 'updateCity' : 'createCity', city);
        },
        async updateCity({ dispatch, rootGetters }, city) {
            return rootGetters['user/can']('UPDATE_CITIES')
                ? await dispatch('api/put', { url: `${API_PATH}/${city.id}`, data: city }, { root: true })
                : null;
        },
        logout({ dispatch }) {
            dispatch('clearCities');
        },
    }
};

export default cities;