import { del, get, post, put } from './http';

const API_PATH = '/api/manufacturers';

export const createManufacturer = async manufacturer =>
    await post(API_PATH, manufacturer);

export const deleteManufacturer = async manufacturer =>
    await del(`${API_PATH}/${manufacturer.id}`);

export const getManufacturers = async () =>
    await get(API_PATH);

export const saveManufacturer = async manufacturer =>
    await (manufacturer.id ? updateManufacturer(manufacturer) : createManufacturer(manufacturer));

export const updateManufacturer = async manufacturer =>
    await put(`${API_PATH}/${manufacturer.id}`, manufacturer);