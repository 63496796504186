<template>
  <form
    autocomplete="off"
    class="equipment-form"
    novalidate
    @reset.prevent="handleReset"
    @submit.prevent="handleSubmit"
  >
    <slot />

    <fieldset
      :class="[
        'equipment-general-info',
        !can('READ_EQUIPMENT') && 'equipment-general-info-minimal',
      ]"
    >
      <details open>
        <summary>
          <span>{{ getStaticName($store.getters.user, "General Information", "Renseignements généraux") }}</span>
        </summary>

        <section class="grid">
          <label>
            <span class="required">{{ getStaticName($store.getters.user, "Prefix", "Préfixe") }}</span>

            <select id="equipment-type" required v-model.number="typeId">
              <option disabled></option>
              <option
                v-for="type in filteredTypes"
                :key="type.id"
                :value="type.id"
              >
                {{ [type.code, type.description].filter((v) => v).join(" - ") }}
              </option>
            </select>
          </label>

          <label class="equipment-number">
            <span class="required">{{ getStaticName($store.getters.user, "Equipment Number", "Numéro d'équipement") }}</span>

            <input
              :disabled="!equipment.equipmentNumber"
              id="equipment-number"
              required
              readonly
              type="text"
              :value="
                equipment.equipmentNumber || 'Generated automatically on save'
              "
            />
          </label>

          <label class="equipment-on-hold">
            <span>{{ getStaticName($store.getters.user, "On Hold", "En attente") }}</span>

            <input
              :checked="onHoldForId !== undefined && !equipment.onLoan"
              :disabled="equipment.reserved"
              id="equipment-on-hold"
              type="checkbox"
              @click="handleToggleOnHold"
            />
          </label>

          <label>
            <span class="required">{{ getStaticName($store.getters.user, "Program Assignment", "Affectation au programme") }}</span>

            <select id="program-assignment" required v-model.number="programId">
              <option disabled></option>
              <option
                v-for="program in filteredPrograms"
                :key="program.id"
                :value="program.id"
              >
                {{ program.name }}
              </option>
            </select>
          </label>

          <label v-if="can('READ_EQUIPMENT')">
            <span class="required">Status</span>

            <select id="equipment-status" v-model.number="statusId" :disabled="equipment.reserved">
                <option
                  v-for="status in filteredStatuses"
                  :key="status.id"
                  :value="status.id"
                >
                  {{ status.name }}
                </option>
            </select>
          </label>

          <label class="equipment-item-name">
            <span class="required">{{ getStaticName($store.getters.user, "Item Name", "Nom de l'item") }}</span>

            <select
              id="equipment-item"
              required
              v-model.number="itemId"
              @input="handleItemChange"
            >
              <option disabled></option>
              <option
                v-for="item in filteredItems"
                :key="item.id"
                :value="item.id"
              >
                {{ getName(item, $store.getters.user) }}
              </option>
            </select>
          </label>

          <label>
            <span>{{ getStaticName($store.getters.user, "Manufacturer", "Fabricant") }}</span>

            <select
              id="equipment-manufacturer"
              v-model.number="manufacturerId"
              @input="handleManufacturerChange"
            >
              <option disabled></option>
              <option
                v-for="manufacturer in filteredManufacturers"
                :key="manufacturer.id"
                :value="manufacturer.id"
              >
                {{ manufacturer.name }}
              </option>
            </select>
          </label>

          <label v-if="can('READ_VENDORS')">
            <span>Supplier</span>

            <select
              id="equipment-vendor"
              v-model.number="vendorId"
              @input="handleVendorChange"
            >
              <option disabled></option>
              <option
                v-for="vendor in filteredVendors"
                :key="vendor.id"
                :value="vendor.id"
              >
                {{ vendor.name }}
              </option>
            </select>
          </label>

          <div class="equipment-notes" v-if="can('READ_EQUIPMENT')">
            <span>Notes</span>

            <EquipmentNotes
              :disabled="!equipment.notes"
              id="equipment-notes"
              :notes.sync="notes"
            />
          </div>

          <label>
            <span class="required">{{ getStaticName($store.getters.user, "Category", "Catégorie") }}</span>

            <select
              id="equipment-category"
              required
              v-model.number="categoryId"
              @input="handleCategoryChange"
            >
              <option disabled></option>
              <option
                v-for="category in filteredCategories"
                :key="category.id"
                :value="category.id"
              >
                {{ getName(category, $store.getters.user) }}
              </option>
            </select>
          </label>

          <label>
            <span class="required">{{ getStaticName($store.getters.user, "Sub Category", "Sous-catégorie") }}</span>

            <select
              id="equipment-subcategory"
              required
              v-model.number="subcategoryId"
              @input="handleSubcategoryChange"
            >
              <option disabled></option>
              <option
                v-for="subcategory in filteredSubcategories"
                :key="subcategory.id"
                :value="subcategory.id"
              >
                {{ getName(subcategory, $store.getters.user) }}
              </option>
            </select>
          </label>

          <label class="equipment-model-number">
            <span>{{ getStaticName($store.getters.user, "Model Number", "Numéro de modèle") }}</span>

            <input
              id="equipment-model-number"
              type="text"
              v-model="modelNumber"
            />
          </label>

          <label class="equipment-serial-number">
            <span>{{getStaticName($store.getters.user, "Serial Number", "Numéro de série") }}</span>

            <input
              id="equipment-serial-number"
              type="text"
              v-model="serialNumber"
            />
          </label>

          <label class="equipment-purchase-date">
            <span class="required">{{ getStaticName($store.getters.user, "Purchase Date", "Date d'achat") }}</span>

            <date-input
              id="equipment-purchase-date"
              required
              v-model="purchaseDate"
            />
          </label>

          <label class="equipment-purchase-cost" v-if="can('READ_EQUIPMENT')">
            <span>Purchase Cost</span>

            <currency-input
              id="equipment-purchase-cost"
              v-model.number="purchaseCost"
            />
          </label>
        </section>
      </details>
    </fieldset>

    <fieldset class="equipment-details">
      <details open>
        <summary>
          <span>{{ getStaticName($store.getters.user, "Details", "Détails") }}</span>
        </summary>

        <EquipmentDetails
          :accessories="filteredAccessories"
          :accessories-title="
            categoryId === 1 ? getStaticName($store.getters.user, 'Wheelchair Accessories', 'Accessoires de fauteuil roulant') : undefined
          "
          :accessory-values.sync="accessoryValues"
          :disabled="!subcategoryId"
          :is-wheelchair="categoryId === 1"
          :part-values.sync="partValues"
          :parts="filteredParts"
          v-if="
            (can('READ_EQUIPMENT_ACCESSORIES') ||
              can('READ_EQUIPMENT_PARTS')) &&
            filteredAccessories.length + filteredParts.length > 0
          "
        />

        <section class="equipment-details-description">
          <label for="equipment-details-description">Description</label>
          <textarea
            id="equipment-details-description"
            rows="8"
            cols="50"
            v-model="description"
          ></textarea>
        </section>
      </details>
    </fieldset>

    <fieldset
      class="equipment-picture-attachments"
      v-if="can('READ_EQUIPMENT_PHOTOS')"
    >
      <details open>
        <summary>
          <span>{{ getStaticName($store.getters.user, "Picture Attachments", "Images jointes") }}</span>
        </summary>

        <section class="grid">
          <div class="section-picture-thumbnail">
            <img alt="Equipment" src="../../assets/home/cameraDefault.svg" />
          </div>

          <div class="section-picture-main">
            <div class="no-image-text" v-if="photos.length == 0">
              {{ getStaticName($store.getters.user, "Please upload your first equipment picture", "Veuillez ajouter votre première image d'équipement") }}
            </div>

            <UploadedImage
              v-for="(photo, index) in photos"
              :key="index"
              :image="photo"
              :show-info="true"
              :equipment-number="equipmentNumber"
            >
              <template
                v-slot:controlButton
                v-if="can('DELETE_EQUIPMENT_PHOTOS')"
              >
                <icon-button name="remove" @click="handleDeletePhoto(photo)" />
              </template>
              <template
                v-slot:archiveButton
                v-if="can('DELETE_EQUIPMENT_PHOTOS')"
              >
                <icon-button name="archive" @click="handleDeletePhoto(photo)" />
              </template>
            </UploadedImage>
          </div>

          <div
            class="section-picture-add-new"
            v-if="can('CREATE_EQUIPMENT_PHOTOS')"
          >
            <icon-button name="add-pic" @click="showPhotoUploadCheck" />
          </div>
        </section>
      </details>
    </fieldset>

    <fieldset
      class="equipment-loan-history"
      v-if="can('READ_LOANS') && equipment.loans"
    >
      <details open>
        <summary>
          <span>Loan History</span>
        </summary>

        <LoanHistory
          v-if="can('READ_EQUIPMENT')"
          :clients="clients"
          empty-text="There are no loans for this piece of equipment"
          :equipment.sync="equipmentList"
          :equipment-id="equipment.id"
          :loans.sync="equipment.loans"
          mode="limited"
          :programs="programs"
          :show-equipment="false"
          @invoiced="handleItemInvoiced"
          @update:loans="handleLoanUpdate"
        />
      </details>
    </fieldset>

    <fieldset
      class="equipment-repair-history"
      v-if="can('READ_SERVICES') && equipment.services"
    >
      <details open>
        <summary>
          <span>Repair History</span>
        </summary>

        <RepairHistory
          v-if="can('READ_EQUIPMENT')"
          :client="clients"
          empty-text="There are no repair service records for this piece of equipment"
          :equipment="equipmentList"
          :equipment-id="equipment.id"
          mode="limited"
          :clients="clients"
          :services.sync="equipment.services"
          @invoiced="handleItemInvoiced"
        />
      </details>
    </fieldset>

    <footer>
      <button type="button" v-if="!this.equipment.reserved" @click="handleReserve">{{ getStaticName($store.getters.user, "Reserve", "Réserver") }}</button>
      <button type="button" v-if="this.equipment.reserved" :disabled="isReserveDisabled" @click="handleUnreserve">{{ getStaticName($store.getters.user, "Unreserve", "Dé-réserver") }}</button>
      <button type="submit" :disabled="hasCRUDPermission">{{ getStaticName($store.getters.user, "Save", "Enregistrer") }}</button>
      <button type="reset" :disabled="hasCRUDPermission" >{{ getStaticName($store.getters.user, "Cancel", "Annuler") }}</button>
    </footer>

    <popup
      class="equipment-on-hold-popup"
      :modal="true"
      @close="handleToggleOnHold"
      v-if="showOnHold"
    >
      <EquipmentOnHoldSelector
        :clients="clients"
        :selected-id="onHoldForId"
        @close="handleToggleOnHold"
        @save="handleSetOnHold"
      />
    </popup>

    <AddPictureModal
      input-id="equipment-file-input"
      v-if="showPhotoUpload"
      @closeClick="showPhotoUpload = false"
      @equipmentUploadedImages="handleAddPhotos"
      @showArchivePicModal="showPhotoSelect = true"
      :photos-count="photos.length"
    />

    <PickArchivedPictureModal
      v-if="showPhotoSelect"
      @closeArchivePicModal="showPhotoSelect = false"
      @archivedSelectedPhotos="handleAddPhotos"
      :photos-count="photos.length"
    />
  </form>
</template>

<script>
import EquipmentOnHoldSelector from "./EquipmentOnHoldSelector";
import EquipmentNotes from "./EquipmentNotes";
import EquipmentDetails from "./EquipmentDetails";
import UploadedImage from "../common/UploadedImage";
import AddPictureModal from "../common/AddPictureModal";
import PickArchivedPictureModal from "../equipment/PickArchivedPictureModal";
import LoanHistory from "../loans/LoanHistory";
import RepairHistory from "../repair/RepairHistory";

import { getManufacturers } from "../../api/ManufacturerAPI";
import { getPrograms } from "../../api/ProgramAPI";
import { getVendors } from "../../api/VendorAPI";

import {
  getEquipment,
  getEquipmentAccessories,
  getEquipmentCategories,
  getEquipmentItems,
  getEquipmentParts,
  getEquipmentSpecifications,
  getEquipmentStatuses,
  getEquipmentSubcategories,
  getEquipmentTypes,
  saveEquipment,
  reserveEquipment,
  unreserveEquipment
} from "../../api/EquipmentAPI";

export default {
  components: {
    EquipmentOnHoldSelector,
    EquipmentNotes,
    EquipmentDetails,
    UploadedImage,
    AddPictureModal,
    PickArchivedPictureModal,
    LoanHistory,
    RepairHistory,
  },

  data() {
    return {
      _accessories: [],
      _categories: [],
      _items: [],
      _manufacturers: [],
      _parts: [],
      _programs: [],
      _statuses: [],
      _subcategories: [],
      _types: [],
      _vendors: [],
      accessoryValues: {},
      categoryId: undefined,
      description: undefined,
      equipmentNumber: undefined,
      filters: {
        categories: {
          categoryId: undefined,
        },
        items: {
          categoryId: undefined,
          itemId: undefined,
          subcategoryId: undefined,
        },
        manufacturers: {
          manufacturerId: undefined,
        },
        subcategories: {
          categoryId: undefined,
          subcategoryId: undefined,
        },
        vendors: {
          manufacturerId: undefined,
        },
      },
      itemId: undefined,
      manufacturerId: undefined,
      modelNumber: undefined,
      notes: [],
      onHoldForId: undefined,
      partValues: {},
      photos: [],
      programId: undefined,
      purchaseCost: undefined,
      purchaseDate: undefined,
      serialNumber: undefined,
      showOnHold: false,
      showPhotoSelect: false,
      showPhotoUpload: false,
      statusId: undefined,
      subcategoryId: undefined,
      typeId: undefined,
      vendorId: undefined,
    };
  },

  props: {
    accessories: {
      type: Array,
      default: null,
    },
    categories: {
      type: Array,
      default: null,
    },
    clients: {
      type: Array,
      default: null,
    },
    equipment: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: null,
    },
    manufacturers: {
      type: Array,
      default: null,
    },
    parts: {
      type: Array,
      default: null,
    },
    programs: {
      type: Array,
      default: null,
    },
    statuses: {
      type: Array,
      default: null,
    },
    subcategories: {
      type: Array,
      default: null,
    },
    types: {
      type: Array,
      default: null,
    },
    vendors: {
      type: Array,
      default: null,
    },
  },

  computed: {
    equipmentList() {
      return this.equipment?.id ? [this.equipment] : [];
    },
    filteredAccessories() {
      if (this.can("READ_EQUIPMENT_ACCESSORIES")) {
        return this.$data._accessories
          .filter(
            (accessory) =>
              accessory?.category?.id === this.categoryId &&
              (accessory.assignedToAll ||
                !!accessory?.assignments?.find(
                  (a) => a.subcategory.id === this.subcategoryId
                ))
          )
          .sort((a, b) => {
            a?.assignments?.find(
              (assign) => assign.subcategory?.id === this.subcategoryId
            )?.rank -
              b?.assignments?.find(
                (assign) => assign.subcategory?.id === this.subcategoryId
              )?.rank;
          });
      } else {
        return [];
      }
    },
    filteredCategories() {
      const item = this.$data._items.find((i) => i?.id === this.itemId);

      const subcategory = this.$data._subcategories.find(
        (s) => s?.id === item?.subcategory?.id
      );

      return Object.values(
        [
          ...this.$data._categories,
          subcategory?.category,
          this.equipment?.category,
        ]
          .filter(
            (category) =>
              category &&
              (category.id === this.categoryId ||
                !this.filters?.categories?.categoryId ||
                category.id === this.filters.categories.categoryId)
          )
          .reduce((opts, opt) => ({ ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filteredItems() {
      return Object.values(
        [...this.$data._items, this.equipment?.item]
          .filter(
            (item) =>
              item &&
              (item.id === this.itemId ||
                ((!this.filters?.items?.itemId ||
                  item.id === this.filters.items.itemId) &&
                  (!this.filters?.items?.subcategoryId ||
                    item.subcategory?.id ===
                      this.filters.items.subcategoryId) &&
                  (!this.filters?.items?.categoryId ||
                    this.$data._subcategories.find(
                      (s) => s?.id === item?.subcategory?.id
                    )?.category?.id === this.filters.items.categoryId)))
          )
          .reduce((opts, opt) => ({ ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filteredManufacturers() {
      return Object.values(
        [...this.$data._manufacturers, this.equipment?.manufacturer]
          .filter(
            (manufacturer) =>
              manufacturer &&
              (manufacturer.id === this.manufacturerid ||
                !this.filters?.manufacturers?.manufacturerId ||
                manufacturer?.id === this.filters.manufacturers.manufacturerId)
          )
          .reduce((opts, opt) => ({ ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filteredParts() {
      if (this.can("READ_EQUIPMENT_PARTS")) {
        return this.$data._parts
          .filter(
            (part) =>
              part?.category?.id === this.categoryId &&
              (part.assignedToAll ||
                !!part?.assignments?.find(
                  (a) => a.subcategory?.id === this.subcategoryId
                ))
          )
          .sort((a, b) => {
            a?.assignments?.find(
              (assign) => assign.subcategory?.id === this.subcategoryId
            )?.rank -
              b?.assignments?.find(
                (assign) => assign.subcategory?.id === this.subcategoryId
              )?.rank;
          });
      } else {
        return [];
      }
    },
    filteredPrograms() {
      return Object.values(
        [...this.$data._programs, this.equipment?.program]
          .filter((program) => program)
          .reduce((opts, opt) => ({ ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filteredStatuses() {
      return Object.values(
        [...this.$data._statuses, this.equipment?.status, { id: undefined, name: this.equipment.onLoan ? 'On Loan' : 'Available' }]
          .filter((status) => status)
          .reduce((opts, opt) => ({ ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filteredSubcategories() {
      const item = this.$data._items.find((i) => i?.id === this.itemId);

      const subcategory = this.$data._subcategories.find(
        (s) => s?.id === item?.subcategory?.id
      );

      return Object.values(
        [...this.$data._subcategories, subcategory, this.equipment?.subcategory]
          .filter(
            (subcategory) =>
              subcategory &&
              (subcategory.id === this.subcategoryId ||
                ((!this.filters?.subcategories?.subcategoryId ||
                  subcategory.id ===
                    this.filters.subcategories.subcategoryId) &&
                  (!this.filters?.subcategories?.categoryId ||
                    subcategory.category?.id ===
                      this.filters.subcategories.categoryId)))
          )
          .reduce((opts, opt) => ({ ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filteredTypes() {
      return Object.values(
        [...this.$data._types, this.equipment?.type]
          .filter((type) => type)
          .reduce((opts, opt) => ({ ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("code", "description"));
    },
    filteredVendors() {
      return Object.values(
        [...this.$data._vendors, this.equipment?.vendor, this.vendors]
          .filter(
            (vendor) =>
              vendor &&
              (vendor.id === this.vendorId ||
                ((!this.filters?.vendors?.vendorId ||
                  vendor.id === this.filters.vendors.vendorId) &&
                  (!this.filters?.vendors?.manufacturerId ||
                    vendor.manufacturer?.id ===
                      this.filters.vendors.manufacturerId)))
          )
          .reduce((opts, opt) => ({ ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    isReserveDisabled() {
      if(
        this.$store.getters.user?.roles?.includes("REFERRAL_AGENT") 
        && this.equipment?.reservedById === this.$store.getters.user?.id
      ) {
          return false;
      } else if (this.$store.getters.user?.roles?.includes("REFERRAL_AGENT")) {
          return true;
      } else {
        return false;
      }
    },
    hasCRUDPermission() {
      return (!this.can("UPDATE_EQUIPMENT") || !this.can("CREATE_EQUIPMENT"))
    }
  },
  methods: {
    handleItemInvoiced(invoiceItem) {
      invoiceItem &&
        (this.equipment.invoiceItems = [
          ...this.equipment.invoiceItems,
          invoiceItem,
        ]);
    },
    showPhotoUploadCheck() {
      this.showPhotoUpload = true;
    },

    handleAddPhotos(photos) {
      const photosLeft = this.photos.length - 3;
      if (photosLeft + photos.length > 0) {
        this.showAlert(`You have ${Math.abs(photosLeft)} photos to upload.`);
        return;
      }
      for (const p of photos)
        this.handleUploadPhoto(p);
        this.showPhotoSelect = false;
        this.showPhotoUpload = false;
    },
    handleCategoryChange(event) {
      this.categoryId = isNaN(event?.target?.value)
        ? undefined
        : +event.target.value;

      if (!this.equipment.id) {
        this.filters.subcategories.categoryId = this.categoryId;
        this.filters.items.categoryId = this.categoryId;

        const subcategory = this.filteredSubcategories.find(
          (s) => s.id === this.subcategoryId
        );
        subcategory?.category?.id === this.categoryId ||
          (this.subcategoryId = undefined);

        const item = this.filteredItems.find((i) => i.id === this.itemId);
        this.$data._subcategories.find((s) => s?.id === item?.subcategory?.id)
          ?.category?.id === this.categoryId || (this.itemId = undefined);
      }
    },
    handleDeletePhoto(photo) {
      this.photos = this.photos.filter((p) => p !== photo);
    },
    handleItemChange(event) {
      this.itemId = isNaN(event?.target?.value)
        ? undefined
        : +event.target.value;

      const item = this.filteredItems.find((i) => i.id === this.itemId);

      this.categoryId = this.$data._subcategories.find(
        (s) => s?.id === item?.subcategory?.id
      )?.category?.id;

      this.subcategoryId = item?.subcategory?.id;

      if (this.equipment.id) {
        this.filters.categories.categoryId = this.filters.subcategories.categoryId = this.categoryId;
        this.filters.subcategories.subcategoryId = this.subcategoryId;
      }
    },
    handleLoanUpdate(loans) {
      this.equipment.onLoan = !!loans.find(l => !l.returnDate);
      this.equipment.status = { ...loans[0].equipment.status };
      this.statusId = this.equipment.status?.id;
    },
    handleManufacturerChange(event) {
      this.manufacturerId = isNaN(event?.target?.value)
        ? undefined
        : +event.target.value;

      this.filters.vendors.manufacturerId = this.manufacturerId;

      const vendor = this.filteredVendors.find((v) => v.id === this.vendorId);
      vendor?.manufacturer?.id === this.manufacturerId ||
        (this.vendorId = undefined);
    },
    handleReset(event) {
      event.preventDefault();

      if (this.confirmIfDirty()) {
        this.setFormData(this.equipment);
        this.$emit("reset");
      }
    },
    handleSetOnHold(onHoldFor) {
      this.onHoldForId = onHoldFor?.id;
      this.showOnHold = false;
    },
    handleSubcategoryChange(event) {
      this.subcategoryId = isNaN(event?.target?.value)
        ? undefined
        : +event.target.value;

      this.categoryId = this.$data._subcategories.find(
        (s) => s?.id === this.subcategoryId
      )?.category?.id;

      if (!this.equipment.id) {
        this.filters.categories.categoryId = this.filters.items.categoryId = this.categoryId;
        this.filters.items.subcategoryId = this.subcategoryId;
        this.itemId = undefined;
      }
    },
    async handleSubmit() {
      if (!this.checkValidity(...[].slice.call(this.$el.children, 0, 3))) {
        return;
      }

      try {
        const equipment = {
          ...this.equipment,
          accessories: Object.entries(this.accessoryValues)
            .filter(([id, value]) => value !== undefined && value !== null)
            .map(([id, value]) => ({ id, value })),
          category: this.categoryId ? { id: this.categoryId } : null,
          description: this.description,
          equipmentNumber: this.equipmentNumber,
          item: this.itemId ? { id: this.itemId } : null,
          manufacturer: this.manufacturerId
            ? { id: this.manufacturerId }
            : null,
          modelNumber: this.modelNumber,
          notes: this.notes
            ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
          onHold: this.onHoldForId ? true : false,
          onHoldFor: this.onHoldForId ? { id: this.onHoldForId } : null,
          parts: Object.entries(this.partValues)
            .filter(([id, value]) => value !== undefined && value !== null)
            .map(([id, value]) => ({ id, value })),
          photos: this.photos.filter((p) => p.id),
          program: this.programId ? { id: this.programId } : null,
          purchaseCost: /\d/.test(this.purchaseCost) ? this.purchaseCost : null,
          purchaseDate: this.purchaseDate,
          serialNumber: this.serialNumber,
          status: this.statusId ? { id: this.statusId } : null,
          subcategory: this.subcategoryId ? { id: this.subcategoryId } : null,
          type: this.typeId ? { id: this.typeId } : null,
          vendor: this.vendorId ? { id: this.vendorId } : null,
        };

        this.$emit("submit", {
          ...equipment,
          ...(await saveEquipment(
            equipment,
            this.photos.filter((p) => !p.id)
          )),
        });
      } catch (e) {
        this.showErrors(e, "Equipment");
      }
    },
    handleToggleOnHold(e) {
      e?.preventDefault?.();
      e?.stopPropagation?.();
      this.showOnHold = !this.showOnHold;
    },
    handleVendorChange(event) {
      this.vendorId = isNaN(event?.target?.value)
        ? undefined
        : +event.target.value;

      this.manufacturerId =
        this.$data._vendors.find((v) => v.id === this.vendorId)?.manufacturer
          ?.id ?? this.manufacturerId;
    },
    handleUploadPhoto(photo) {
      photo.creator = {
        ...this.$store.getters.user,
        permissions: undefined,
        roles: undefined,
      };
      this.photos = [...this.photos, photo];
    },
    async handleReserve(e) {
      e.preventDefault();

      if(confirm("Equipment will be Reserved for 24 hours would you like to continue?")) {
        await reserveEquipment(this.equipment.id);
        this.$emit("reserveUpdated");
      };
    },
    async handleUnreserve(e) {
      e.preventDefault();

      if(confirm("Equipment will no longer be Reserved  would you like to continue?")) {
        await unreserveEquipment(this.equipment.id);
        this.$emit("reserveUpdated");
      };
    },
    isDirty() {
      if (
        this.categoryId !== this.equipment?.category?.id ||
        this.equipmentNumber !== this.equipment?.equipmentNumber ||
        this.itemId !== this.equipment?.item?.id ||
        this.manufacturerId !== this.equipment?.manufacturer?.id ||
        this.modelNumber !== this.equipment?.modelNumber ||
        this.onHoldForId !== this.equipment?.onHoldFor?.id ||
        this.programId !== this.equipment?.program?.id ||
        this.purchaseCost !== this.equipment?.purchaseCost ||
        this.purchaseDate !== this.equipment?.purchaseDate ||
        this.serialNumber !== this.equipment?.serialNumber ||
        this.statusId !== this.equipment?.status?.id ||
        this.subcategoryId !== this.equipment?.subcategory?.id ||
        this.typeId !== this.equipment?.type?.id ||
        this.vendorId !== this.equipment?.vendor?.id ||
        this.notes.length !== (this.equipment?.notes?.length ?? 0) ||
        this.photos.length !== (this.equipment?.photos?.length ?? 0) ||
        !!this.notes.find((note) => {
          if (!note?.id) {
            return true;
          }

          const _note = this.equipment?.notes?.find((n) => n.id === note?.id);

          return (
            !_note ||
            note?.author?.id !== _note?.author?.id ||
            note?.message !== _note?.message ||
            note?.loanCheck !== _note?.loanCheck ||
            note?.createdAt !== _note?.createdAt
          );
        }) ||
        !!this.photos.find(
          (photo) =>
            !photo?.id ||
            !this.equipment?.photos?.find((p) => p.id === photo?.id)
        )
      ) {
        return true;
      }

      const accessories = Object.entries(this.accessoryValues);

      if (
        accessories.length !== (this.equipment?.accessories?.length ?? 0) ||
        !!accessories.find(([id, value]) => {
          const accessory = this.equipment?.accessories?.find(
            (a) => a.id === +id
          );
          return !accessory || accessory?.value !== value;
        })
      ) {
        return true;
      }

      const parts = Object.entries(this.partValues);

      return (
        parts.length !== (this.equipment?.parts?.length ?? 0) ||
        !!parts.find(([id, value]) => {
          const part = this.equipment?.parts?.find((p) => p.id === +id);
          return !part || part?.value !== value;
        }) ||
        this.$children?.reduce(
          (dirty, child) => dirty || child.isDirty(),
          false
        )
      );
    },
    setFormData(equipment) {
      this.categoryId = equipment?.category?.id;
      this.equipmentNumber = equipment?.equipmentNumber;
      this.itemId = equipment?.item?.id;
      this.manufacturerId = equipment?.manufacturer?.id;
      this.modelNumber = equipment?.modelNumber;
      this.notes = equipment?.notes ?? [];
      this.onHoldForId = equipment?.onHoldFor?.id;
      this.photos = equipment?.photos ?? [];
      this.programId = equipment?.program?.id;
      this.purchaseCost = equipment?.purchaseCost;
      this.purchaseDate = equipment?.purchaseDate;
      this.serialNumber = equipment?.serialNumber;
      this.statusId = equipment?.status?.id;
      this.subcategoryId = equipment?.subcategory?.id;
      this.typeId = equipment?.type?.id;
      this.vendorId = equipment?.vendor?.id;
      this.description = equipment?.description;

      this.accessoryValues =
        equipment?.accessories?.reduce(
          (vals, val) => ({ ...vals, [val.id]: val.value }),
          {}
        ) ?? {};

      this.partValues =
        equipment?.parts?.reduce(
          (vals, val) => ({ ...vals, [val.id]: val.value }),
          {}
        ) ?? {};

      this.filters = {
        ...this.filters,
        categories: {
          ...this.filters?.categories,
          categoryId: equipment.id ? this.categoryId : undefined,
        },
        items: {
          ...this.filters?.items,
          categoryId: undefined,
          itemId: undefined,
          subcategoryId: undefined,
        },
        subcategories: {
          ...this.filters?.subcategories,
          categoryId: undefined,
          subcategoryId: equipment.id ? this.subcategoryId : undefined,
        },
        vendors: {
          ...this.filters?.vendors,
          manufacturerId: this.manufacturerId,
        },
      };
    },
    toggleOnHold(show) {
      this.showOnHold = show ?? !this.showOnHold;
    },

    dissableAllFields() {
      const tags = ["input", "textarea", "select"];
      tags.forEach((tagName) => {
        const nodes = this.$el.getElementsByTagName(tagName);
        for (let i = 0; i < nodes.length; i++) {
          nodes[i].disabled = true;
          nodes[i].tabIndex = -1;
        }
      });
    },
  },

  mounted() {
    this.$nextTick(() => {
      //TODO: This functionality should be moved into a computed property
      if (!this.can("UPDATE_EQUIPMENT") || !this.can("CREATE_EQUIPMENT")) {
        this.dissableAllFields();
      }
    });
  },

  watch: {
    accessories: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._accessories) {
          this.$data._accessories = to ?? (await getEquipmentAccessories());
          this.$emit("update:accessories", this.$data._accessories);
        }
      },
    },
    categories: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._categories) {
          this.$data._categories = to ?? (await getEquipmentCategories());
          this.$emit("update:categories", this.$data._categories);
        }
      },
    },
    equipment: {
      immediate: true,
      handler(to, from) {
        this.setFormData(to);
      },
    },
    items: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._items) {
          this.$data._items = to ?? (await getEquipmentItems());
          this.$emit("update:items", this.$data._items);
        }
      },
    },
    manufacturers: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._manufacturers) {
          this.$data._manufacturers = to ?? (await getManufacturers());
          this.$emit("update:manufacturers", this.$data._manufacturers);
        }
      },
    },
    parts: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._parts) {
          this.$data._parts = to ?? (await getEquipmentParts());
          this.$emit("update:parts", this.$data._parts);
        }
      },
    },
    programs: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._programs) {
          this.$data._programs = to ?? (await getPrograms());
          this.$emit("update:programs", this.$data._programs);
        }
      },
    },
    statuses: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._statuses) {
          this.$data._statuses = to ?? (await getEquipmentStatuses());
          this.$emit("update:statuses", this.$data._statuses);
        }
      },
    },
    subcategories: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._subcategories) {
          this.$data._subcategories = to ?? (await getEquipmentSubcategories());
          this.$emit("update:subcategories", this.$data._subcategories);
        }
      },
    },
    types: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._types) {
          this.$data._types = to ?? (await getEquipmentTypes());
          this.$emit("update:types", this.$data._types);
        }
      },
    },
    vendors: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._vendors) {
          this.$data._vendors = to ?? (await getVendors());
          this.$emit("update:vendors", this.$data._vendors);
        }
      },
    },
  },
};
</script>

<style>
.equipment-form {
  color: var(--primary-medium-gray);
  font-size: 0.9em;
}

.equipment-form > footer,
.equipment-form details > summary {
  align-items: center;
  background: var(--primary-light-gray);
  color: var(--primary-black);
  display: flex;
  font-size: 1.11em;
  font-weight: bold;
  justify-content: space-between;
  margin: 0 0 0.5em;
  outline: none;
  padding: 0.2em 0.4em;
  text-transform: uppercase;
  width: 100%;
}

.equipment-form > footer {
  justify-content: flex-end;
  margin: 0.5em 0 0;
  padding: 0.4em;
}

.equipment-form details > summary {
  flex-direction: row-reverse;
  list-style: none;
}

.equipment-form details > summary::-webkit-details-marker {
  display: none;
}

.equipment-form details > summary::before {
  background-color: currentColor;
  content: " ";
  cursor: pointer;
  height: 1.6em;
  mask-image: url("../../assets/icons/expand.svg");
  mask-repeat: no-repeat;
  width: 1.6em;
}

.equipment-form details[open] > summary::before {
  mask-image: url("../../assets/icons/collapse.svg");
  mask-repeat: no-repeat;
}

.equipment-form > footer > * ~ * {
  margin-left: 0.67em;
}

.equipment-form .grid {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: 1fr 4em 4em 4em 1fr 4em 4em 4em 1fr 4em 4em 4em 1fr 4em 4em 4em;
  padding: 0.5em 1em;
  width: 100%;
}

.equipment-general-info .grid > *,
.equipment-details .grid > *,
.equipment-loan-history .grid > *,
.equipment-repair-history .grid > * {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column: span 4;
  width: 100%;
}

.equipment-form .grid input[type="checkbox" i] {
  margin: 0.3em 0 0;
}

.equipment-form .grid input:not([type="checkbox" i]):not([type="radio" i]),
.equipment-form .grid select,
.equipment-form .grid textarea {
  width: 100%;
}

.equipment-form .grid select[required] > option:not([value]):disabled {
  display: none;
}

.equipment-form .required::after {
  color: var(--primary-red);
  content: "*";
  display: inline-block;
  font-size: 0.8em;
  margin-left: 0.1em;
  margin-top: -0.2em;
  vertical-align: top;
}

.equipment-form .search-label {
  font-size: 0.9em;
}

.equipment-form .yes-no {
  justify-content: space-evenly;
}

.equipment-form .yes-no label {
  color: var(--primary-black);
}

/* region equipment details */

.equipment-details-description {
  padding: 2em;
}

#equipment-details-description {
  width: 100%;
  resize: none;
}

/* end region equipment details */

/* region equipment styles */
.equipment-form .equipment-number {
  grid-row: 2;
  grid-column: 1 / span 3;
}

.equipment-form .equipment-on-hold {
  grid-row: 2;
  grid-column: 4 / span 1;
}

.equipment-form .equipment-on-hold-popup .popup-content {
  font-style: normal;
  width: calc(50% + 1rem);
}

.equipment-general-info .grid {
  grid-auto-flow: column;
  grid-template-rows: repeat(4, auto);
}

.equipment-general-info-minimal .grid {
  grid-template-rows: repeat(3, auto);
}

/* End region */

/* region Picture attachment */

.equipment-picture-attachments .grid {
  display: grid;
  grid-row-gap: 0.5em;
  padding: 0.5em 1em;
  grid-template-columns: 1fr 20fr;
  width: 100%;
  grid-auto-flow: column;
  grid-column-gap: inherit;
  grid-template-rows: repeat(2, auto);
}

.equipment-picture-attachments .section-picture-thumbnail {
  background-image: radial-gradient(
    circle at 100% 3em,
    var(--primary-black) 3em,
    transparent 3.1em
  );
  cursor: default;
  height: 10em;
  display: flex;
  justify-content: space-around;
}

.section-picture-thumbnail img {
  width: 3em;
  filter: var(--filter-primary-white);
  margin-left: 1em;
  padding: 0em 0em 4em 1em;
}

.no-image-text {
  grid-column: 1 / span 3;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 1.5em;
  font-style: italic;
}

.section-picture-add-new {
  text-align: right;
}

.section-picture-add-new .icon-button {
  padding: 0.1em 0.1em 0.3em 0.5em;
}
/* End region */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .equipment-general-info .grid {
    grid-template-columns: 1fr 4em 4em 4em;
    grid-template-rows: repeat(14, auto);
  }
  .equipment-form .equipment-notes {
    grid-row: 14;
  }
  .equipment-details .grid,
  .equipment-details .equipment-accessories .equipment-options {
    display: block;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .equipment-general-info .grid {
    grid-template-columns: 1fr 4em 4em 4em;
    grid-template-rows: repeat(8, auto);
  }
  .equipment-general-info-minimal .grid {
    grid-template-rows: repeat(6, auto);
  }
  .equipment-form .equipment-notes {
    grid-row: 8;
  }
  .equipment-details .grid,
  .equipment-details .equipment-accessories .equipment-options {
    display: block;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .equipment-general-info .grid {
    grid-template-columns: 1fr 4em 4em 4em 1fr 4em 4em 4em;
    grid-template-rows: repeat(8, auto);
  }
  .equipment-general-info-minimal .grid {
    grid-template-rows: repeat(6, auto);
  }
  .equipment-form .equipment-notes {
    grid-row: 8;
  }
  .equipment-details .grid,
  .equipment-details .equipment-accessories .equipment-options {
    display: block;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .equipment-general-info .grid {
    grid-template-columns: 1fr 4em 4em 4em 1fr 4em 4em 4em 1fr 4em 4em 4em;
    grid-template-rows: repeat(6, auto);
  }
  .equipment-general-info-minimal .grid {
    grid-template-rows: repeat(4, auto);
  }
  .equipment-form .equipment-notes {
    grid-row: 8;
    grid-column: span 12;
  }
  .equipment-details .grid,
  .equipment-details .equipment-accessories .equipment-options {
    display: block;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .equipment-general-info .grid {
    grid-template-columns: 1fr 4em 4em 4em 1fr 4em 4em 4em 1fr 4em 4em 4em 1fr 4em 4em 4em;
    grid-template-rows: repeat(4, auto);
  }
  .equipment-general-info-minimal .grid {
    grid-template-rows: repeat(3, auto);
  }
  .equipment-form .equipment-notes {
    grid-row: 4;
  }
  .equipment-details .grid,
  .equipment-details .equipment-accessories .equipment-options {
    display: grid;
  }
}

@media print {
  button, .equipment-form .search-label {
    display: none;
  }

  .equipment-general-info .grid {
    grid-template-columns: repeat(4, 1fr 4em 4em 4em);
  }

  .equipment-serial-number {
    grid-row: 1;
    grid-column: 4;
  }

  .equipment-loan-history {
    display: inline-block;
    width: 100%;
    break-before: always;
  }

  .equipment-picture-attachments .grid {
    display: block;
  }

  .section-picture-thumbnail {
    display: none !important;
  }
}
</style>