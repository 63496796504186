<template>
  <ContentWrapper
    :create-icon="can('CREATE_USERS') && this.$store.getters.user.roles.includes('ADMIN') ? 'add-user' : null"
    :header-tag="headerTag"
    search-input-id="admin-user-search-phrase"
    :show-back="showBack"
    title="Users"
    @create="handleCreate"
    @search="handleSearch"
  >
    <UsersTable
      class="admin-table"
      :selected-id="user.id"
      :users="filteredUsers"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="user.id ? `Edit - ${getUserName(user)}` : 'Create User'"
      />

      <span class="admin-form-tab">
        <img alt="Users" src="../../../assets/icons/user.svg" />
      </span>

      <UsersForm
        class="admin-form"
        :disabled="user.firstName === undefined"
        :organizations="$data._organizations"
        :roles="$data._roles"
        :user="user"
        :programs="$data._programs"
        :equipmentManagers="$data._equipmentManagers"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import { mapActions } from "vuex";

import ContentWrapper from "./ContentWrapper";
import UsersTable from "../../../components/admin/UsersTable";
import UsersForm from "../../../components/admin/UsersForm";

import { getOrganizations } from "../../../api/OrganizationAPI";
import { getUsers } from "../../../api/UserAPI";
import { getPrograms } from "../../../api/ProgramAPI";
import { getAllEquipmentManagers } from "../../../api/EquipmentManagerAPI";

export default {
  data() {
    return {
      _organizations: [],
      _roles: [],
      search: "",
      user: {},
      _users: [],
      _programs: [],
      _equipmentManagers: [],
    };
  },

  props: {
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    organizations: {
      type: Array,
      default: undefined,
    },
    roles: {
      type: Array,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    users: {
      type: Array,
      default: undefined,
    },
  },

  computed: {
    filteredUsers() {
      const regex = this.getFilterRegex(this.search);

      return this.$data._users.filter((user) =>
        regex.test(
          [
            this.getFullName(user),
            ...(user?.phoneNumbers?.map((p) => p?.number ?? "") ?? []),
            user?.organization?.name ?? "",
            user?.role?.title ?? "",
          ].join(String.fromCharCode(0x1f))
        )
      );
    },
  },

  methods: {
    ...mapActions("roles", ["getRoles"]),
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.user = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.user = { firstName: "" };
      }
    },
    handleDelete(user) {
      this.$data._users = this.$data._users.filter((u) => u?.id !== user?.id);
      this.user = {};
    },
    async handleSave(user) {
      const created = !this.user.id;

      this.$data._users = [
        ...this.$data._users.filter((u) => u?.id !== user?.id),
        user,
      ];

      this.$data._equipmentManagers = await getAllEquipmentManagers();

      this.handleSelect(created ? user : undefined, true);

      this.showAlert(
        `Your user has been ${created ? "created" : "updated"} successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(user, force = false) {
      if (force || this.confirmIfDirty()) {
        this.user = {
          ...this.$data._users.find((u) => u?.id === user?.id),
        };
      }
    },
  },

  watch: {
    "$data._organizations"(to, from) {
      if (to !== from) {
        this.$data._organizations = to.sort(this.sortBy("name", "id"));

        const map = to.reduce(
          (map, organization) => ({ ...map, [organization.id]: organization }),
          {}
        );

        this.$data._users.forEach(
          (user) => (user.organization = map[user.organization?.id] ?? {})
        );
      }
    },
    "$data._users"(to, from) {
      if (to !== from) {
        this.$data._users = to.sort(this.sortBy("firstName", "lastName"));
        this.$emit("update:users", this.$data._users);
      }
    },
    organizations: {
      immediate: true,
      async handler(to, from) {
        this.$data._organizations = to?.slice?.() ?? (await getOrganizations());
      },
    },
    roles: {
      immediate: true,
      async handler(to, from) {
        this.$data._roles = to?.slice?.() ?? (await this.getRoles());
      },
    },
    programs: {
      immediate: true,
      async handler(to, from) {
        this.$data._programs = to?.slice?.() ?? (await getPrograms());
      }
    },
    equipmentManagers : {
      immediate: true,
      async handler(to, from) {
        this.$data._equipmentManagers = to?.slice?.() ?? (await getAllEquipmentManagers());
      }
    },
    "user.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    async users(to, from) {
      to === this.$data._users ||
        (this.$data._users = to?.slice?.() ?? (await getUsers()));
    },
  },

  async created() {
    this.$data._users = this.users?.slice?.() ?? (await getUsers());
    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    UsersTable,
    UsersForm,
  },
};
</script>