<template>
  <PartialView>
    <Manufacturers
      class="admin-content-partial"
      ref="manufacturers"
      header-tag="h3"
      :id="manufacturerId"
      :route-params="{view: 'manufacturers'}"
      :show-back="false"
      @update:manufacturers="handleUpdateManufacturers"
      v-if="hasAdmin('MANUFACTURERS')"
    />

    <Vendors
      class="admin-content-partial"
      ref="vendors"
      header-tag="h3"
      :id="vendorId"
      :manufacturers="manufacturers"
      :route-params="{view: 'suppliers'}"
      :show-back="false"
      v-if="hasAdmin('VENDORS')"
    />
  </PartialView>
</template>

<script>
import PartialView from "./PartialView";
import Manufacturers from "./pages/Manufacturers";
import Vendors from "./pages/Vendors";

export default {
  components: {
    PartialView,
    Manufacturers,
    Vendors,
  },

  data() {
    return {
      manufacturerId: this.view === "manufacturers" ? this.id : undefined,
      manufacturers: undefined,
      vendorId: this.view === "suppliers" ? this.id : undefined,
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined,
    },
    view: {
      type: String,
      default: "manufacturers",
    },
  },

  methods: {
    handleUpdateManufacturers(manufacturers) {
      this.manufacturers = manufacturers;
    },
    isDirty() {
      return false;
    },
  },

  mounted() {
    this.$refs.manufacturers &&
      (this.$refs.manufacturers.$el.id = "admin-manufacturers");

    this.$refs.vendors && (this.$refs.vendors.$el.id = "admin-vendors");
  },

  beforeRouteUpdate(to, from, next) {
    to.params.view === "manufacturers" &&
      (this.manufacturerId = +to.params.id || undefined);

    to.params.view === "suppliers" &&
      (this.vendorId = +to.params.id || undefined);

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (
      !to.params.skipDirtyCheck &&
      Object.values(this.$refs).find((r) => r?.isDirty?.()) &&
      !this.confirmDiscard()
    ) {
      return next(false);
    }

    this.abortRequests();
    next();
  },
};
</script>