<template>
  <ContentWrapper
    :create-icon="can('CREATE_INSURANCE_PROVIDERS') ? 'add-facility' : null"
    :header-tag="headerTag"
    search-input-id="admin-insurance-provider-search-phrase"
    :show-back="showBack"
    title="Insurance Providers"
    @create="handleCreate"
    @search="handleSearch"
  >
    <InsuranceProvidersTable
      class="admin-table"
      :insurance-providers="filteredInsuranceProviders"
      :selected-id="insuranceProvider.id"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="insuranceProvider.id ? `Edit - ${insuranceProvider.name}` : 'Create Insurance Provider'"
      />

      <span class="admin-form-tab">
        <img alt="Insurance Providers" src="../../../assets/icons/facility.svg" />
      </span>

      <InsuranceProvidersForm
        class="admin-form"
        :disabled="insuranceProvider.name == undefined"
        :cities="cities"
        :counties="counties"
        :insurance-provider="insuranceProvider"
        :provinces="provinces"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ContentWrapper from "./ContentWrapper";
import InsuranceProvidersTable from "../../../components/admin/InsuranceProvidersTable";
import InsuranceProvidersForm from "../../../components/admin/InsuranceProvidersForm";

import { getInsuranceProviders } from "../../../api/InsuranceProviderAPI";

export default {
  data() {
    return {
      _insuranceProviders: [],
      insuranceProvider: {},
      search: "",
    };
  },

  props: {
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    insuranceProviders: {
      type: Array,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters(["cities", "counties", "provinces"]),
    filteredInsuranceProviders() {
      const regex = this.getFilterRegex(this.search);

      return this.$data._insuranceProviders.filter((f) =>
        regex.test(
          [
            f?.name ?? "",
            ...(f?.address?.map((a) =>
              [
                a.address1 ?? "",
                a?.city?.name ?? "",
                a?.province?.abbreviation ?? "",
                a?.postalCode ?? "",
              ].join(String.fromCharCode(0x1f))
            ) ?? []),
            ...(f?.phoneNumbers?.map((p) => p?.number ?? "") ?? []),
          ].join(String.fromCharCode(0x1f))
        )
      );
    },
  },

  methods: {
    ...mapActions("cities", ["getCities"]),
    ...mapActions("counties", ["getCounties"]),
    ...mapActions("provinces", ["getProvinces"]),
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.insuranceProvider = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.insuranceProvider = { name: "" };
      }
    },
    handleDelete(insuranceProvider) {
      this.$data._insuranceProviders = this.$data._insuranceProviders.filter(
        (f) => f?.id !== insuranceProvider?.id
      );

      this.insuranceProvider = {};
    },
    handleSave(insuranceProvider) {
      const created = !this.insuranceProvider.id;

      this.$data._insuranceProviders = [
        ...this.$data._insuranceProviders.filter(
          (f) => f?.id !== insuranceProvider?.id
        ),
        insuranceProvider,
      ];

      this.handleSelect(created ? insuranceProvider : undefined, true);

      this.showAlert(
        `Your insurance provider has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(insuranceProvider, force = false) {
      if (force || this.confirmIfDirty()) {
        this.insuranceProvider = {
          ...this.$data._insuranceProviders.find(
            (f) => f?.id === insuranceProvider?.id
          ),
        };
      }
    },
    async loadData() {
      this.getCities();
      this.getCounties();
      this.getProvinces();
    },
  },

  watch: {
    "$data._insuranceProviders"(to, from) {
      if (to !== from) {
        this.$data._insuranceProviders = to.sort(this.sortBy("name", "id"));
        this.$emit("update:insurance-providers", this.$data._insuranceProviders);
      }
    },
    async insuranceProviders(to, from) {
      to === this.$data._insuranceProviders ||
        (this.$data._insuranceProviders =
          to?.slice?.() ?? (await getInsuranceProviders()));
    },
    "insuranceProvider.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
  },

  async created() {
    this.loadData();

    this.$data._insuranceProviders =
      this.insuranceProviders?.slice?.() ?? (await getInsuranceProviders());

    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    InsuranceProvidersTable,
    InsuranceProvidersForm,
  },
};
</script>