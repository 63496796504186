<template>
  <component :is="tag" :class="['content-header', sticky && 'content-header-sticky']">
    <router-link class="icon-button" tag="button" :to="prev" type="button" v-if="prev">
      <icon name="prev" />
    </router-link>

    <span>{{ title }}</span>

    <slot />
  </component>
</template>

<script>
export default {
  props: {
    prev: {
      default: undefined
    },
    sticky: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: "h1"
    },
    title: {
      type: String
    }
  }
};
</script>

<style>
.content-header {
  align-items: flex-end;
  align-self: flex-end;
  background-color: var(--primary-white);
  color: var(--primary-black);
  display: flex;
  height: 2.25em;
  padding: 0.5em 0;
}

.content-header > button {
  align-items: center;
  display: flex;
  font-size: 0.5em;
  height: 2.5em;
  justify-content: center;
  margin: 0 0.67em 0 0;
  padding: 0;
  width: 2.5em;
}

.content-header > small {
  font-size: 0.7em;
  font-weight: normal;
  margin: 0 0 0 0.33em;
}

h1.content-header {
  font-size: 1.5em;
  width: 100%;
}

h2.content-header {
  font-size: 1.33em;
  width: 100%;
}

.content-header-sticky {
  background: var(--primary-white);
  position: sticky;
  top: 0;
}

.content-header-sticky::after {
  background: var(--primary-white);
  content: " ";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}
</style>