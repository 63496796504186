<template>
  <ContentWrapper
    :create-icon="can('CREATE_MANUFACTURERS') ? 'add-manufacturer' : null"
    :header-tag="headerTag"
    search-input-id="admin-manufacturer-search-phrase"
    :show-back="showBack"
    title="Manufacturers"
    @create="handleCreate"
    @search="handleSearch"
  >
    <ManufacturersTable
      class="admin-table"
      :manufacturers="filteredManufacturers"
      :selected-id="manufacturer.id"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="manufacturer.id ? `Edit - ${manufacturer.name}` : 'Create Manufacturer'"
      />

      <span class="admin-form-tab">
        <img alt="Manufacturers" src="../../../assets/icons/manufacturer.svg" />
      </span>

      <ManufacturersForm
        class="admin-form"
        :disabled="manufacturer.name === undefined"
        :manufacturer="manufacturer"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import ManufacturersTable from "../../../components/admin/ManufacturersTable";
import ManufacturersForm from "../../../components/admin/ManufacturersForm";

import { getManufacturers } from "../../../api/ManufacturerAPI";

export default {
  data() {
    return {
      manufacturer: {},
      _manufacturers: [],
      search: "",
    };
  },

  props: {
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    manufacturers: {
      type: Array,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    filteredManufacturers() {
      const regex = this.getFilterRegex(this.search);
      return this.$data._manufacturers.filter((m) => regex.test(m?.name ?? ""));
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.manufacturer = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.manufacturer = { name: "" };
      }
    },
    handleDelete(manufacturer) {
      this.$data._manufacturers = this.$data._manufacturers.filter(
        (m) => m?.id !== manufacturer?.id
      );

      this.manufacturer = {};
    },
    handleSave(manufacturer) {
      const created = !this.manufacturer.id;

      this.$data._manufacturers = [
        ...this.$data._manufacturers.filter((m) => m?.id !== manufacturer?.id),
        manufacturer,
      ];

      this.handleSelect(created ? manufacturer : undefined, true);

      this.showAlert(
        `Your manufacturer has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(manufacturer, force = false) {
      if (force || this.confirmIfDirty()) {
        this.manufacturer = {
          ...this.$data._manufacturers.find((m) => m?.id === manufacturer?.id),
        };
      }
    },
  },

  watch: {
    "$data._manufacturers"(to, from) {
      if (to !== from) {
        this.$data._manufacturers = to.sort(this.sortBy("name", "id"));
        this.$emit("update:manufacturers", this.$data._manufacturers);
      }
    },
    "manufacturer.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    async manufacturers(to, from) {
      to === this.$data._manufacturers ||
        (this.$data._manufacturers =
          to?.slice?.() ?? (await getManufacturers()));
    },
  },

  async created() {
    this.$data._manufacturers =
      this.manufacturers?.slice?.() ?? (await getManufacturers());

    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    ManufacturersTable,
    ManufacturersForm,
  },
};
</script>