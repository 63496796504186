<template>
  <table class="striped admin-insurance-providers-table">
    <caption v-html="caption" v-if="caption" />
    <colgroup>
      <col width="30%" />
      <col width="14%" />
      <col width="14%" />
      <col width="14%" />
      <col width="14%" />
      <col width="14%" />
    </colgroup>
    <thead>
      <tr>
        <th>Insurance Provider Name</th>
        <th>Address</th>
        <th>City</th>
        <th>Province</th>
        <th>Postal Code</th>
        <th>Phone Number</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="insuranceProvider in paginatedInsuranceProviders"
        :key="insuranceProvider.id"
        :class="selectedId === insuranceProvider.id ? 'selected' : 'selectable'"
        @click="handleSelect(insuranceProvider)"
      >
        <td>{{ insuranceProvider.name }}</td>
        <td>{{ getAddress(insuranceProvider).address1 }}</td>
        <td>{{ getCity(insuranceProvider).name }}</td>
        <td>{{ getProvince(insuranceProvider).abbreviation }}</td>
        <td>
          {{ formatPostalCode(getAddress(insuranceProvider).postalCode) }}
        </td>
        <td>
          {{ formatPhoneNumber(getPhoneNumber(insuranceProvider).number) }}
        </td>
      </tr>
      <tr v-if="insuranceProviders.length === 0">
        <td class="no-records" colspan="6">
          There are currently no insurance providers in the system.
        </td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls" colspan="6">
          <icon-button
            :disabled="!hasPrevPage"
            name="prev"
            @click="goToPrevPage"
          />

          <icon-button
            :disabled="!hasNextPage"
            name="next"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("insuranceProviders")],

  props: {
    caption: {
      type: String,
      default: undefined,
    },
    insuranceProviders: {
      type: Array,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
  },

  methods: {
    getAddress(insuranceProvider) {
      return insuranceProvider?.addresses?.slice(-1)?.[0] ?? {};
    },
    getCity(insuranceProvider) {
      return this.getAddress(insuranceProvider)?.city ?? {};
    },
    getPhoneNumber(insuranceProvider) {
      return insuranceProvider?.phoneNumbers?.slice(-1)?.[0] ?? {};
    },
    getProvince(insuranceProvider) {
      return this.getAddress(insuranceProvider)?.province ?? {};
    },
    handleSelect(insuranceProvider) {
      this.$emit("select", insuranceProvider);
    },
  },
};
</script>