<template>
  <section class="main-page">
    <Header class="main-header" />
    <Nav class="main-nav" />
    <router-view class="main-content" />

    <popup id="page-modal" :modal="true" v-if="modal" @close="hideModal">
      <BulkInvoicing
        v-bind="modal.params"
        ref="page"
        :show-back="false"
        v-if="modal.name === 'Bulk Invoicing'"
      />

      <IndirectServices
        v-bind="modal.params"
        ref="page"
        :show-back="false"
        v-if="modal.name === 'Indirect Services'"
      />
    </popup>
  </section>
</template>

<script>
import { mapState } from "vuex";

import Header from "../../components/common/Header";
import Nav from "../../components/common/Nav";
import IndirectServices from "./pages/IndirectServices";
import BulkInvoicing from "./pages/BulkInvoicing";

export default {
  data() {
    return {
      style: undefined,
    };
  },

  computed: {
    ...mapState(["modal"]),
  },

  methods: {
    hideModal() {
      if (this.confirmIfDirty(this.$refs.page)) {
        this.$store.commit("setModal");
      }
    },
  },

  watch: {
    modal: {
      immediate: true,
      handler(to, from) {
        if (!to) {
          this.style
            ? document.body.setAttribute("style", this.style)
            : document.body.removeAttribute("style");

          this.style = undefined;
          this.routeSilent(this.$route.path);
        } else {
          this.style = this.style ?? document.body.getAttribute(this.style);

          document.body.setAttribute(
            "style",
            `${this.style ?? ""};overflow:hidden`
          );

          this.$nextTick(() => {
            Object.defineProperty(this.$refs.page, "$route", {
              configurable: true,
              get() {
                return to;
              },
            });
          });
        }
      },
    },
  },

  components: {
    Header,
    Nav,
    IndirectServices,
    BulkInvoicing,
  },
};
</script>

<style>
.main-page {
  display: contents;
}

.main-header {
  width: 100%;
}

.main-nav {
  width: 80%;
}

.main-content {
  background: var(--primary-white);
  box-shadow: 0 0 0.5em var(--primary-medium-gray);
  margin-bottom: 1em;
  padding: 0.5em 1em;
  width: 80%;
}

.content-header-sticky {
  z-index: 4;
}

.content-header-sticky::after {
  height: 100%;
  top: 0;
  transform: translateX(-1rem);
  width: calc(100 - 2rem);
}

#page-modal .popup-inner {
  max-height: 100vh;
  max-width: 100vw;
  overflow: auto;
  padding: 2rem 1rem;
}

#page-modal .popup-content {
  max-width: 100%;
  min-width: 80%;
  width: auto;
}

@media print {
  .main-content {
    box-shadow: none;
    width: 100%;
  }
}
</style>