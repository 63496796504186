<template>
  <div>
    <ItemsTable
      :id="id"
      caption="Items to be Invoiced"
      table-type="toBeInvoiced"
      status="Pending"
      :items-to-be-invoiced="invoiceItems"
      :items-invoices="invoices"
      :invoice-codes="billingCodes"
      :invoice-programs="programs"
      :client-program="clientProgram"
      :equipment="equipment"
      @update:items-invoices="handleInvoiceUpdate"
      @update:items-to-be-invoiced="handleInvoiceItemsUpdate"
    />

    <InvoiceTable
      caption="Invoices Requiring Approval"
      table-type="invoiceNoAppNumber"
      :items-invoices="invoicesRequiringApproval"
      :invoice-codes="billingCodes"
      :invoice-programs="programs"
      @update:items-invoices="handleInvoiceApprovalUpdate"
    />

    <InvoiceTable
      caption="Invoice History"
      table-type="completedInvoices"
      :items-invoices="invoicesApproved"
      :invoice-codes="billingCodes"
      :invoice-programs="programs"
    />
  </div>
</template>
<script>
import InvoiceTable from "./InvoiceTable";
import ItemsTable from "./ItemsTable";

export default {
  components: {
    ItemsTable,
    InvoiceTable
  },

  props: {
    billingCodes: {
      type: Array,
      default: null
    },
    clientProgram: {
      type: Object,
      default: null
    },
    equipment: {
      type: Array,
      default: null
    },
    id: {
      type: [Number, String],
      default: undefined
    },
    invoiceItems: {
      type: Array,
      default: null
    },
    invoices: {
      type: Array,
      default: null
    },
    programs: {
      type: Array,
      default: null
    }
  },

  computed: {
    invoicesApproved() {
      return this.invoices.filter(
        i => !i.program?.invoiceRequiresApproval || i.approvalNumber
      );
    },
    invoicesRequiringApproval() {
      return this.invoices.filter(
        i => i.program?.invoiceRequiresApproval && !i.approvalNumber
      );
    }
  },

  methods: {
    handleInvoiceApprovalUpdate(updatedInvoices) {
      const invoices = [...this.invoices];

      updatedInvoices.forEach(invoice => {
        invoices.splice(
          invoices.findIndex(inv => inv.id === invoice.id),
          1,
          invoice
        );
      });

      this.$emit("update:invoices", invoices);
    },
    handleInvoiceUpdate(invoices) {
      this.$emit("update:invoices", invoices);
    },
    handleInvoiceItemsUpdate(items) {
      this.$emit("update:invoice-items", items);
    }
  }
};
</script>