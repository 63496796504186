<template>
  <form
    autocomplete="off"
    class="admin-facilities-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Facility Name</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-facility-name"
          required
          type="text"
          v-model.trim="name"
        />
      </label>

      <label>
        <span class="required">Address</span>

        <input
          :disabled="disabled"
          id="admin-facility-address"
          required
          type="text"
          v-model.trim="address.address1"
        />
      </label>

      <label>
        <span class="required">City</span>

        <select
          :disabled="disabled"
          id="admin-facility-city"
          required
          v-model.number="address.city.id"
        >
          <option disabled></option>

          <option
            v-for="city in filterOptions(cities, address.city)"
            :key="city.id"
            :value="city.id"
          >{{ city.name }}</option>
        </select>
      </label>

      <label>
        <span class="required">Province</span>

        <select
          :disabled="disabled"
          id="admin-facility-province"
          required
          v-model.number="address.province.id"
        >
          <option disabled></option>

          <option
            v-for="province in filterOptions(provinces, address.province)"
            :key="province.id"
            :value="province.id"
          >{{ province.abbreviation }} - {{ province.name }}</option>
        </select>
      </label>

      <label>
        <span class="required">County</span>

        <select
          :disabled="disabled"
          id="admin-facility-county"
          required
          v-model.number="address.county.id"
        >
          <option disabled></option>
          <option
            v-for="county in filterOptions(counties, address.county)"
            :key="county.id"
            :value="county.id"
          >{{ county.abbreviation }} - {{ county.name }}</option>
        </select>
      </label>

      <label>
        <span class="required">Postal Code</span>

        <postal-code-input
          :disabled="disabled"
          id="admin-facility-postal-code"
          required
          v-model="address.postalCode"
        />
      </label>

      <label>
        <span class="required">Phone Number</span>

        <phone-number-input
          :disabled="disabled"
          id="admin-facility-phone-number"
          required
          v-model="phoneNumber.number"
        />
      </label>

      <label>
            <span>Facility Email</span>

            <email-address-input
              :disabled="disabled"
              id="admin-facility-email-address"
              placeholder="your@email.com"
              v-model="emailAddress"
            />
          </label>
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-facility-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && facility.id"
      >Delete</button>

      <button :disabled="!isSaveEnabled" id="admin-facility-save" type="submit" v-if="canSave">Save</button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-facility-cancel"
        type="reset"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </form>
</template>

<script>
import { deleteFacility, saveFacility } from "../../api/FacilityAPI";

export default {
  data() {
    return {
      name: undefined,
      emailAddress: undefined,
      address: { city: {}, county: {}, province: {} },
      phoneNumber: { type: { id: 2 } }
    };
  },

  props: {
    cities: {
      type: Array
    },
    counties: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    facility: {
      type: Object
    },
    provinces: {
      type: Array
    }
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_FACILITIES");
    },
    canSave() {
      return this.can({ permissions: ["CREATE_FACILITIES", "UPDATE_FACILITIES"], matchAny: true });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.facility?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.facility?.id
          ? this.can("UPDATE_FACILITIES")
          : this.can("CREATE_FACILITIES"))
      );
    }
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (!this.showConfirm("Are you sure you want to delete this facility?")) {
        return;
      }

      await deleteFacility(this.facility);
      this.$emit("delete", this.facility);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveFacility({
            ...this.facility,
            name: this.name,
            emailAddress: this.emailAddress,
            addresses: [
              this.address,
              ...(this.facility?.addresses?.filter(
                a => a?.id !== this.address.id
              ) ?? [])
            ],
            phoneNumbers: [
              { ...this.phoneNumber, type: { id: 2 } },
              ...(this.facility?.phoneNumbers?.filter(
                p => p?.id !== this.phoneNumber.id
              ) ?? [])
            ]
          })
        );
      } catch (e) {
        this.showErrors(e, "A facility");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      if (this.name !== this.facility?.name) {
        return true;
      }

      if (this.emailAddress !== this.facility?.emailAddress) {
        return true;
      }

      const address = this?.facility?.addresses?.slice(-1)?.[0];

      if (
        this.address.address1 !== address?.address1 ||
        this.address.city?.id !== address?.city?.id ||
        this.address.province?.id !== address?.province?.id ||
        this.address.county?.id !== address?.county?.id ||
        this.address.postalCode !== address?.postalCode
      ) {
        return true;
      }

      return (
        this.phoneNumber.number !==
        this?.facility?.phoneNumbers?.slice(-1)?.[0]?.number
      );
    }
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    facility: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.name = to?.name;
          this.emailAddress = to?.emailAddress;
          this.phoneNumber = { ...to?.phoneNumbers?.slice(-1)?.[0] };

          this.address = {
            city: {},
            county: {},
            province: {},
            ...to?.addresses?.slice(-1)?.[0]
          };
        }

        !this.disabled && this.autofocus();
      }
    }
  }
};
</script>

<style>
.admin-facilities-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.admin-facilities-form > footer {
  grid-column: span 4;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>