<template>
  <form
    autocomplete="off"
    class="admin-equipment-specifications-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Name</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-equipment-specification-name"
          required
          type="text"
          v-model.trim="name"
        />
      </label>

      <label>
        <span>Name Fr</span>

        <input
          :disabled="disabled"
          id="admin-equipment-specification-name-fr"
          type="text"
          v-model.trim="name_fr"
        />
      </label>

      <EquipmentSpecificationOptionsTable
        class="admin-equipment-specification-options"
        :disabled="disabled || !canSave"
        :options.sync="options"
      />
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-equipment-specification-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && specification.id"
      >Delete</button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-equipment-specification-save"
        type="submit"
        v-if="canSave"
      >Save</button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-equipment-specification-cancel"
        type="reset"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </form>
</template>

<script>
import EquipmentSpecificationOptionsTable from "./EquipmentSpecificationOptionsTable";

import {
  deleteEquipmentSpecification,
  saveEquipmentSpecification
} from "../../api/EquipmentAPI";

export default {
  data() {
    return {
      name: undefined,
      name_fr: undefined,
      option: {},
      options: []
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    specification: {
      type: Object
    }
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_EQUIPMENT_PARTS");
    },
    canSave() {
      return this.can({ permissions: ["CREATE_EQUIPMENT_PARTS", "UPDATE_EQUIPMENT_PARTS"], matchAny: true });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return (
        !this.disabled &&
        this.specification?.id &&
        !this.specification?.protected
      );
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.specification?.id
          ? this.can("UPDATE_EQUIPMENT_PARTS")
          : this.can("CREATE_EQUIPMENT_PARTS"))
      );
    }
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (
        !confirm(
          "Are you sure you want to delete this equipment specification?"
        )
      ) {
        return;
      }

      await deleteEquipmentSpecification(this.specification);
      this.$emit("delete", this.specification);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveEquipmentSpecification({
            ...this.specification,
            name: this.name,
            name_fr: this.name_fr,
            options: this.options.map((o, i) => ({ ...o, rank: i + 1 }))
          })
        );
      } catch (e) {
        this.showErrors(e, "An equipment specification");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return (
        this.name !== this.specification?.name ||
        this.name_fr !== this.specification?.name_fr ||
        JSON.stringify(this.options) !==
          JSON.stringify(this.specification?.options ?? [])
      );
    }
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    specification: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.name = to?.name;
          this.name_fr = to?.name_fr;
          this.options = to?.options?.slice?.() ?? [];
          this.option = {};
        }

        !this.disabled && this.autofocus();
      }
    }
  },

  components: {
    EquipmentSpecificationOptionsTable
  }
};
</script>

<style>
.admin-equipment-specifications-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.admin-equipment-specifications-form .admin-equipment-specification-options {
  margin-top: 1.15em;
}

.admin-equipment-specifications-form > footer {
  grid-column: span 2;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>