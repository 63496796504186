var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"striped admin-holidays-table"},[(_vm.caption)?_c('caption',{domProps:{"innerHTML":_vm._s(_vm.caption)}}):_vm._e(),_vm._m(0),_vm._m(1),_c('tbody',[_vm._l((_vm.paginatedHolidays),function(holiday){return _c('tr',{key:holiday.id,class:[
        _vm.selectedId === holiday.id ? 'selected' : 'selectable',
        _vm.compareDates(new Date(holiday.date), Date.now()) > 0
          ? 'past-event'
          : '',
      ],on:{"click":function($event){return _vm.handleSelect(holiday)}}},[_c('td',[_vm._v(_vm._s(_vm.formatTimestamp(holiday.date)))]),_c('td',[_vm._v(_vm._s(holiday.name))]),_c('td',[_vm._v(_vm._s(holiday.description))])])}),(_vm.holidays.length === 0)?_c('tr',[_c('td',{staticClass:"no-records",attrs:{"colspan":"3"}},[_vm._v(" There are currently no holidays in the system. ")])]):_vm._e()],2),(_vm.hasPages)?_c('tfoot',[_c('tr',[_c('td',{staticClass:"page-controls",attrs:{"colspan":"3"}},[_c('icon-button',{attrs:{"name":"prev","disabled":!_vm.hasPrevPage},on:{"click":_vm.goToPrevPage}}),_c('icon-button',{attrs:{"name":"next","disabled":!_vm.hasNextPage},on:{"click":_vm.goToNextPage}})],1)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{attrs:{"width":"25%"}}),_c('col',{attrs:{"width":"25%"}}),_c('col',{attrs:{"width":"50%"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Description")])])])
}]

export { render, staticRenderFns }