<template>
  <tr :class="['invoice-item-wrapper', hidden && 'hidden']">
    <td class="invoice-pick-checkbox-data">
      <input
        type="checkbox"
        @click="checkChange"
        :checked="invoice.selected"
        ref="inputCheckbox"
      />
    </td>
    <td class="invoice-status-data" v-if="tableType == 'bulkInvoicing'">
      {{ ((invoice.loan || invoice.service || {}).client || {}).clientNumber }}
    </td>
    <td class="invoice-status-data" v-if="tableType == 'bulkInvoicing'">
      {{ getFullName((invoice.loan || invoice.service || {}).client || {}) }}
    </td>
    <td>
      <select
        :disabled="invoice.loan || invoice.service"
        id="invoice-equip-number-data"
        required
        :value="_equipment.id"
        @input="setEquipment"
      >
        <template v-if="invoice.loan || invoice.service">
          <option selected :value="_equipment.id">
            {{ getEquipmentNumber(_equipment) }}
          </option>
        </template>
        <template v-else>
          <option v-for="equip in equipment" :key="equip.id" :value="equip.id">
            {{ getEquipmentNumber(equip) }}
          </option>
        </template>
      </select>
    </td>

    <td class="invoice-status-data">{{ equipmentItem }}</td>

    <td>
      <select
        id="invoice-program-select"
        required
        :value="program.id"
        @input="setProgram"
      >
        <option
          v-for="program in programs"
          :key="program.id"
          :value="program.id"
        >
          {{ program.name }}
        </option>
      </select>
    </td>

    <td>
      <select
        id="invoice-code-select"
        required
        :value="billingCode.id"
        @input="setBillingCode"
      >
        <option
          v-for="billingCode in billingCodes"
          :key="billingCode.id"
          :value="billingCode.id"
        >
          {{ billingCode.code }} - {{ billingCode.description }}
        </option>
      </select>
    </td>

    <td id="invoice-billingcode-fee">{{ formatCurrency(fee) || "--" }}</td>

    <td class="invoice-program-data">
      <textarea
        :id="`invoice-note-${index}-message`"
        placeholder="Add note here..."
        required
        :value="description"
        @input="setDescription"
      />
    </td>

    <td class="invoice-status" v-if="tableType != 'bulkInvoicing'">
      {{ status }}
    </td>

    <td class="invoice-remove" v-if="tableType != 'bulkInvoicing'">
      <icon-button
        :id="`invoice-note-${index}-remove`"
        name="remove"
        v-if="can('DELETE_INVOICE_ITEMS')"
        @click="handleRemove(invoice)"
      />
    </td>
    <td v-if="tableType == 'bulkInvoicing'"></td>
  </tr>
</template>

<script>
export default {
  props: {
    billingCodes: {
      type: Array,
    },
    equipment: {
      type: Array,
    },
    programs: {
      type: Array,
    },
    tableType: "",
    itemsToBeInvoiced: {
      type: Array,
    },
    invoice: {
      type: Object,
      default: {},
    },
    index: {
      type: Number,
    },
    status: {
      type: String,
    },
    clientProgram: {
      type: Object,
      default: null,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    billingCode() {
      return (
        this.invoice?.billingCode ?? this.invoice?.service?.billingCode ?? {}
      );
    },
    description() {
      return (
        this.invoice?.description ??
        this.invoice?.service?.description ??
        this.invoice?.billingCode?.description
      );
    },
    _equipment() {
      return (
        this.invoice?.equipment ??
        this.invoice?.loan?.equipment ??
        this.invoice?.service?.equipment ??
        {}
      );
    },
    equipmentItem() {
      return this._equipment?.item?.name ?? "--";
    },
    fee() {
      return (
        this.invoice?.fee ?? this.invoice?.service?.fee ?? this.billingCode?.fee
      );
    },
    program() {
      return this.invoice?.program ?? this.invoice?.loan?.program ?? {};
    },
  },

  methods: {
    handleRemove() {
      this.$emit("updateInvoices", this.invoice);
    },

    checkChange(e) {
      if (!this.checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }
      this.$emit("update:invoice", {
        ...this.invoice,
        selected: e.target.checked,
      });
    },
    setBillingCode(e) {
      this.$emit("update:invoice", {
        ...this.invoice,
        billingCode: this.billingCodes.find(
          (billingCode) => billingCode.id === +e?.target?.value
        ),
      });
    },
    setDescription(e) {
      this.$emit("update:invoice", {
        ...this.invoice,
        description: e?.target?.value?.trim?.(),
      });
    },
    setEquipment(e) {
      this.$emit("update:invoice", {
        ...this.invoice,
        equipment: this.equipment.find(
          (equipment) => equipment.id === +e?.target?.value
        ),
      });
    },
    setProgram(e) {
      this.$emit("update:invoice", {
        ...this.invoice,
        program: this.programs.find(
          (program) => program.id === +e?.target?.value
        ),
      });
    },
  },
};
</script>

<style scoped>
.test-div {
  display: contents;
}

.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.invoice-item-wrapper:not(.hidden) ~ .invoice-item-wrapper:not(.hidden) {
  margin-top: 1em;
}

.hidden {
  height: 0;
  margin: 0;
  padding: 0;
  display: none;
}
</style>