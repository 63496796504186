<template>
  <form
    autocomplete="off"
    class="client-form"
    novalidate
    @reset.prevent="handleReset"
    @submit.prevent="handleSubmit"
  >
    <slot />
    <fieldset class="client-general-info">
      <details open>
        <summary>
          <span>General Information</span>
        </summary>

        <section class="grid">
          <label>
            <span class="required">Client Number</span>

            <input
              :disabled="!client.clientNumber"
              id="client-number"
              required
              readonly
              type="text"
              :value="client.clientNumber || 'Generated automatically on save'"
              tabindex="1"
            />
          </label>

          <div>
            <span>Facility</span>

            <span class="yes-no">
              <label class="radio">
                <input
                  id="client-is-in-facility"
                  name="client-in-facility"
                  type="radio"
                  :value="true"
                  v-model="useFacility"
                  tabindex="10"
                />
                Yes
              </label>

              <label class="radio">
                <input
                  id="client-is-not-in-facility"
                  name="client-in-facility"
                  type="radio"
                  :value="false"
                  v-model="useFacility"
                  tabindex="11"
                />
                No
              </label>
            </span>
          </div>

          <label>
            <span :class="!useFacility ? 'required' : undefined"
              >Postal Code</span
            >

            <postal-code-input
              :disabled="useFacility"
              id="client-mailing-postal-code"
              required
              v-model="mailingAddress.postalCode"
              tabindex="18"
            />
          </label>

          <label>
            <span>Medicare Number</span>

            <medicare-number-input
              id="client-medicare-number"
              v-model="medicareNumber"
            />
          </label>

          <label>
            <span class="required">First Name</span>

            <input
              id="client-first-name"
              required
              type="text"
              v-model.trim="firstName"
              tabindex="2"
            />
          </label>

          <label>
            <span :class="useFacility ? 'required' : undefined"
              >Facility Location</span
            >

            <select
              :disabled="!useFacility"
              id="client-facility"
              required
              v-model.number="facilityId"
              tabindex="12"
            >
              <option disabled></option>
              <option
                v-for="facility in filteredFacilities"
                :key="facility.id"
                :value="facility.id"
              >
                {{ facility.name }}
              </option>
            </select>
          </label>

          <label>
            <span class="required">Language</span>

            <select id="client-language" required v-model.number="languageId" tabindex="19">
              <option disabled></option>
              <option
                v-for="language in filteredLanguages"
                :key="language.id"
                :value="language.id"
              >
                {{ language.name }}
              </option>
            </select>
          </label>

          <label>
            <span class="required">Client Agency Number</span>

            <input
              id="client-agency-number"
              placeholder="A0000"
              required
              type="text"
              maxlength="16"
              v-model.trim="fcsNumber"
            />
          </label>

          <label>
            <span class="required">Last Name</span>

            <input
              id="client-last-name"
              required
              type="text"
              v-model.trim="lastName"
              tabindex="3"
            />
          </label>

          <label>
            <span :class="!useFacility ? 'required' : undefined"
              >Address 1</span
            >

            <input
              :disabled="useFacility"
              id="client-mailing-address-1"
              required
              type="text"
              v-model.trim="mailingAddress.address1"
              tabindex="13"
            />
          </label>

          <label class="client-birthdate">
            <span class="required">Date of Birth</span>

            <date-input id="client-birthdate" required v-model="birthDate" tabindex="20"/>
          </label>

          <label class="client-deceased">
            <span>Deceased</span>

            <input
              id="client-is-deceased"
              type="checkbox"
              v-model="useDeceased"
              tabindex="21"
            />
          </label>

          <label>
            <span>Special Project</span>

            <select id="client-project" v-model.number="projectId">
              <option></option>
              <option
                v-for="project in filteredProjects"
                :key="project.id"
                :value="project.id"
              >
                {{ project.name }}
              </option>
            </select>
          </label>

          <label class="client-home-phone-number">
            <span class="required">Phone Number</span>

            <phone-number-input
              id="client-home-phone-number"
              required
              v-model="homePhone.number"
              tabindex="4"
            />
          </label>

          <label class="client-home-phone-tty">
            <span>TTY</span>

            <input
              id="client-home-phone-tty"
              type="checkbox"
              v-model="homePhone.tty"
              tabindex="5"
            />
          </label>

          <label>
            <span>Address 2</span>

            <input
              :disabled="useFacility"
              id="client-mailing-address-2"
              type="text"
              v-model.trim="mailingAddress.address2"
              tabindex="14"
            />
          </label>

          <label>
            <span :class="useDeceased ? 'required' : undefined"
              >Date of Death</span
            >

            <date-input
              :disabled="!useDeceased"
              id="client-deceased"
              required
              v-model="deceasedOn"
              tabindex="22"
            />
          </label>

          <label>
            <span class="required">Program / Association</span>

            <select id="client-program" required v-model.number="programId">
              <option disabled></option>
              <option
                v-for="program in filteredPrograms"
                :key="program.id"
                :value="program.id"
              >
                {{ program.name }}
              </option>
            </select>
          </label>

          <label class="client-work-phone-number">
            <span>Work Phone</span>

            <phone-number-input
              id="client-work-phone-number"
              v-model="workPhone.number"
              tabindex="6"
            />
          </label>

          <label class="client-work-phone-tty">
            <span>TTY</span>

            <input
              id="client-work-phone-tty"
              type="checkbox"
              v-model="workPhone.tty"
              tabindex="7"
            />
          </label>

          <label>
            <span :class="!useFacility ? 'required' : undefined">City</span>

            <select
              :disabled="useFacility"
              id="client-mailing-city"
              required
              v-model.number="mailingAddress.city.id"
              tabindex="15"
            >
              <option disabled></option>
              <option
                v-for="city in filteredMailingCities"
                :key="city.id"
                :value="city.id"
              >
                {{ city.name }}
              </option>
            </select>
          </label>

          <label>
            <span>Next of Kin</span>

            <input
              id="client-next-of-kin-name"
              type="text"
              v-model.trim="nextOfKin.name"
              tabindex="23"
            />
          </label>

           <label>
            <span>Insurance</span>

            <span class="yes-no">
              <label class="radio">
                <input
                  id="client-is-insured"
                  name="client-is-insured"
                  type="radio"
                  :value="true"
                  v-model="useInsurance"
                />
                Yes
              </label>

              <label class="radio">
                <input
                  id="client-is-not-insured"
                  name="client-is-insured"
                  type="radio"
                  :value="false"
                  v-model="useInsurance"
                />
                No
              </label>
            </span>
          </label>

          <label>
            <span>Email</span>

            <email-address-input
              id="client-email-address"
              placeholder="your@email.com"
              v-model="emailAddress"
              tabindex="8"
            />
          </label>

          <label class="client-province">
            <span :class="!useFacility ? 'required' : undefined">Province</span>

            <select
              :disabled="useFacility"
              id="client-mailing-province"
              required
              v-model.number="mailingAddress.province.id"
              tabindex="16"
            >
              <option disabled></option>
              <option
                v-for="province in filteredMailingProvinces"
                :key="province.id"
                :value="province.id"
              >
                {{ province.abbreviation }} - {{ province.name }}
              </option>
            </select>
          </label>

          <label class="client-county">
            <span :class="!useFacility ? 'required' : undefined">County</span>

            <select
              :disabled="useFacility"
              id="client-mailing-county"
              required
              v-model.number="mailingAddress.county.id"
              tabindex="17"
            >
              <option disabled></option>
              <option
                v-for="county in filiteredMailingCounties"
                :key="county.id"
                :value="county.id"
              >
                {{ county.abbreviation }} - {{ county.name }}
              </option>
            </select>
          </label>

          <label>
            <span>Next of Kin - Phone Number</span>

            <phone-number-input
              id="client-next-of-kin-phone-number"
              v-model="nextOfKin.phoneNumber"
              tabindex="24"
            />
          </label>

          <label>
            <span :class="useInsurance ? 'required' : undefined"
              >Insurance Provider</span
            >

            <select
              :disabled="!useInsurance"
              id="client-insurance-provider"
              required
              v-model.number="insuranceProviderId"
            >
              <option disabled></option>
              <option
                v-for="provider in filteredInsuranceProviders"
                :key="provider.id"
                :value="provider.id"
              >
                {{ provider.name }}
              </option>
            </select>
          </label>


          <label>
            <span class="required">Gender</span>

            <select id="client-gender" required v-model.number="genderId" tabindex="9">
              <option disabled></option>
              <option
                v-for="gender in filteredGenders"
                :key="gender.id"
                :value="gender.id"
              >
                {{ gender.name }}
              </option>
            </select>
          </label>

          <div class="client-notes">
            <span>Notes</span>

            <ClientsNotes
              :disabled="!client.notes"
              id="client-notes"
              :notes.sync="notes"
            />
          </div>

        </section>
      </details>
    </fieldset>

    <fieldset class="client-billing-address">
      <details open>
        <summary>
          <span>Billing Address</span>
        </summary>

        <section class="grid">
          <label class="client-mailing-for-billing-address">
            <span>Billing Address</span>

            <span>
              <input
                id="client-mailing-for-billing-address"
                type="checkbox"
                v-model="useMailingForBilling"
              />
              Same as Mailing Address
            </span>
          </label>

          <label>
            <span :class="!useMailingForBilling ? 'required' : undefined"
              >First Name</span
            >

            <input
              :disabled="useMailingForBilling"
              id="client-billing-first-name"
              required
              type="text"
              v-model.trim="billingAddress.firstName"
            />
          </label>

          <label>
            <span :class="!useMailingForBilling ? 'required' : undefined"
              >Last Name</span
            >

            <input
              :disabled="useMailingForBilling"
              id="client-billing-last-name"
              required
              type="text"
              v-model.trim="billingAddress.lastName"
            />
          </label>

          <label>
            <span :class="!useMailingForBilling ? 'required' : undefined"
              >Phone Number</span
            >

            <phone-number-input
              :disabled="useMailingForBilling"
              required
              v-model="billingAddress.phoneNumber"
            />
          </label>

          <label>
            <span :class="!useMailingForBilling ? 'required' : undefined"
              >Blling Address</span
            >

            <input
              :disabled="useMailingForBilling"
              id="client-billing-address"
              required
              type="text"
              v-model.trim="billingAddress.address1"
            />
          </label>

          <label>
            <span :class="!useMailingForBilling ? 'required' : undefined"
              >City</span
            >

            <select
              :disabled="useMailingForBilling"
              id="client-billing-city"
              required
              v-model.number="billingAddress.city.id"
            >
              <option disabled></option>
              <option
                v-for="city in filiteredBillingCities"
                :key="city.id"
                :value="city.id"
              >
                {{ city.name }}
              </option>
            </select>
          </label>

          <label>
            <span :class="!useMailingForBilling ? 'required' : undefined"
              >Province</span
            >

            <select
              :disabled="useMailingForBilling"
              id="client-billing-province"
              required
              v-model.number="billingAddress.province.id"
            >
              <option disabled></option>
              <option
                v-for="province in filiteredBillingProvinces"
                :key="province.id"
                :value="province.id"
              >
                {{ province.abbreviation }} - {{ province.name }}
              </option>
            </select>
          </label>

          <label>
            <span :class="!useMailingForBilling ? 'required' : undefined"
              >Postal Code</span
            >

            <postal-code-input
              :disabled="useMailingForBilling"
              id="client-billing-postal-code"
              required
              v-model="billingAddress.postalCode"
            />
          </label>
        </section>
      </details>
    </fieldset>

    <fieldset
      class="client-equipment-on-hold"
      v-if="can('READ_EQUIPMENT') && client.equipment"
    >
      <details open>
        <summary>
          <span>Equipment On Hold</span>
        </summary>

        <EquipmentOnHold
          :client-id="client.id"
          :equipment.sync="client.equipment"
          empty-text="This client has no pieces of equipment on hold"
          @loan="handleOnHoldLoan"
        />
      </details>
    </fieldset>

    <fieldset
      class="client-loan-history"
      v-if="can('READ_LOANS') && client.loans"
    >
      <details open>
        <summary>
          <span>Loan History</span>
        </summary>

        <LoanHistory
          :client-id="client.id"
          :clients="clients"
          empty-text="This client has no loans records"
          :equipment.sync="equipment"
          :loans="client.loans"
          :programs="$data._programs"
          ref="loanHistory"
          :referral-agents="$data._referralAgents"
          :show-client="false"
          :types="$data._loanTypes"
          @delete="handleDeleteLoan"
          @invoiced="handleItemInvoiced"
          @update:loans="handleUpdateLoans"
        />
      </details>
    </fieldset>

    <fieldset
      class="client-repair-history"
      v-if="can('READ_SERVICES') && client.services"
    >
      <details open>
        <summary>
          <span>Repair History</span>
        </summary>

        <RepairHistory
          :billing-codes="$data._billingCodes"
          :client-id="client.id"
          :clients="clients"
          empty-text="This client has no repair service records"
          :equipment="loanedEquipment"
          :services.sync="client.services"
          @invoiced="handleItemInvoiced"
        />
      </details>
    </fieldset>

    <fieldset
      class="client-invoices"
      v-if="can('READ_INVOICES') && client.invoiceItems && client.invoices"
    >
      <details open>
        <summary>
          <span>Invoices</span>
        </summary>

        <InvoiceHistory
          :id="id"
          :billing-codes="$data._billingCodes"
          :client-program="client.program"
          :equipment="relatedEquipment"
          :invoice-items.sync="invoiceItems"
          :invoices.sync="client.invoices"
          :programs="$data._programs"
        />
      </details>
    </fieldset>

    <footer>
      <button type="submit">Save</button>
      <button type="reset">Cancel</button>
    </footer>
  </form>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ClientsNotes from "./ClientsNotes";
import EquipmentOnHold from "../equipment/EquipmentOnHold";
import LoanHistory from "../loans/LoanHistory";
import RepairHistory from "../repair/RepairHistory";
import InvoiceHistory from "../invoices/InvoiceHistory";

import { getBillingCodes } from "../../api/BillingCodeAPI";
import { saveClient } from "../../api/ClientAPI";
import { getFacilities } from "../../api/FacilityAPI";
import { getInsuranceProviders } from "../../api/InsuranceProviderAPI";
import { updateInvoiceItem } from "../../api/InvoiceAPI";
import { getLoanTypes } from "../../api/LoanAPI";
import { getPrograms } from "../../api/ProgramAPI";
import { getProjects } from "../../api/ProjectAPI";
import { getReferralAgents } from "../../api/ReferralAgentAPI";

export default {
  components: {
    ClientsNotes,
    EquipmentOnHold,
    LoanHistory,
    RepairHistory,
    InvoiceHistory,
  },

  data() {
    return {
      _billingAddress: { city: {}, county: {}, province: {} },
      _billingCodes: [],
      _client: [],
      _facilities: [],
      _insuranceProviders: [],
      _loanTypes: [],
      _programs: [],
      _projects: [],
      _referralAgents: [],
      _mailingAddress: { city: {}, county: {}, province: {} },
      birthDate: undefined,
      clientNumber: undefined,
      deceasedOn: undefined,
      emailAddress: undefined,
      facilityId: undefined,
      fcsNumber: undefined,
      firstName: undefined,
      genderId: undefined,
      homePhone: {},
      invoiceItems: undefined,
      insuranceProviderId: undefined,
      languageId: undefined,
      lastName: undefined,
      medicareNumber: undefined,
      nextOfKin: {},
      notes: [],
      programId: undefined,
      projectId: undefined,
      useDeceased: false,
      useFacility: false,
      useInsurance: false,
      useMailingForBilling: true,
      workPhone: {},
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined,
    },

    billingCodes: {
      type: Array,
      default: null,
    },
    client: {
      type: Object,
      default: () => ({}),
    },
    equipment: {
      type: Array,
      default: null,
    },
    facilities: {
      type: Array,
      default: null,
    },
    insuranceProviders: {
      type: Array,
      default: null,
    },
    loanTypes: {
      type: Array,
      default: null,
    },
    programs: {
      type: Array,
      default: null,
    },
    projects: {
      type: Array,
      default: null,
    },
    referralAgents: {
      type: Array,
      default: null,
    },
  },

  computed: {
    ...mapGetters(["cities", "counties", "genders", "languages", "provinces"]),
    billingAddress() {
      return (
        (this.useMailingForBilling
          ? {
              ...this.mailingAddress,
              firstName: this.firstName,
              lastName: this.lastName,
              phoneNumber: this.homePhone.number,
            }
          : this.$data._billingAddress) ?? {
          city: {},
          county: {},
          province: {},
        }
      );
    },
    clients() {
      return this.client?.id ? [this.client] : [];
    },
    filiteredBillingCities() {
      return Object.values(
        [
          ...this.cities,
          this.client?.addresses?.find((a) => a.billing)?.city,
        ].reduce((opts, opt) => (!opt ? opts : { ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filiteredBillingProvinces() {
      return Object.values(
        [
          ...this.provinces,
          this.client?.addresses?.find((a) => a.billing)?.province,
        ].reduce((opts, opt) => (!opt ? opts : { ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filteredEquipment() {
      return this.equipment.filter((e) => !e.$creatingLoan);
    },
    filteredFacilities() {
      return Object.values(
        [...this.$data._facilities, this.client?.facility].reduce(
          (opts, opt) => (!opt ? opts : { ...opts, [opt.id]: opt }),
          {}
        )
      ).sort(this.sortBy("name", "id"));
    },
    filteredGenders() {
      return Object.values(
        [...this.genders, this.client?.gender].reduce(
          (opts, opt) => (!opt ? opts : { ...opts, [opt.id]: opt }),
          {}
        )
      ).sort(this.sortBy("name", "id"));
    },
    filteredInsuranceProviders() {
      return Object.values(
        [
          ...this.$data._insuranceProviders,
          this.client?.insuranceProvider,
        ].reduce((opts, opt) => (!opt ? opts : { ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filteredLanguages() {
      return Object.values(
        [...this.languages, this.client?.language].reduce(
          (opts, opt) => (!opt ? opts : { ...opts, [opt.id]: opt }),
          {}
        )
      ).sort(this.sortBy("name", "id"));
    },
    filteredMailingCities() {
      return Object.values(
        [
          ...this.cities,
          this.client?.addresses?.find((a) => a.mailing)?.city,
        ].reduce((opts, opt) => (!opt ? opts : { ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filiteredMailingCounties() {
      return Object.values(
        [
          ...this.counties,
          this.client?.addresses?.find((a) => a.mailing)?.county,
        ].reduce((opts, opt) => (!opt ? opts : { ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filteredMailingProvinces() {
      return Object.values(
        [
          ...this.provinces,
          this.client?.addresses?.find((a) => a.mailing)?.province,
        ].reduce((opts, opt) => (!opt ? opts : { ...opts, [opt.id]: opt }), {})
      ).sort(this.sortBy("name", "id"));
    },
    filteredPrograms() {
      return Object.values(
        [...this.$data._programs, this.client?.program].reduce(
          (opts, opt) => (!opt ? opts : { ...opts, [opt.id]: opt }),
          {}
        )
      ).sort(this.sortBy("name", "id"));
    },
    filteredProjects() {
      return Object.values(
        [...this.$data._projects, this.client?.project].reduce(
          (opts, opt) => (!opt ? opts : { ...opts, [opt.id]: opt }),
          {}
        )
      ).sort(this.sortBy("name", "id"));
    },
    loanedEquipment() {
      return Object.values(
        this.client.loans?.reduce(
          (equipment, loan) => ({
            ...equipment,
            [loan.equipment.id]: loan.equipment,
          }),
          {}
        ) ?? {}
      ).sort(
        this.sortBy("type.code", "-equipmentNumber", "-equipmentNumberSuffix")
      );
    },
    mailingAddress() {
      return (
        (this.useFacility
          ? this.facilityId === this.client?.facility?.id
            ? this.client?.facility?.addresses?.slice(-1)?.[0]
            : this.facilities
                ?.find((f) => f.id === this.facilityId)
                ?.addresses?.slice(-1)?.[0]
          : this.$data._mailingAddress) ?? {
          city: {},
          county: {},
          province: {},
        }
      );
    },
    relatedEquipment() {
      return Object.values(
        [...this.client.loans, ...this.client.services].reduce(
          (equipment, { equipment: equip }) =>
            equip
              ? {
                  ...equipment,
                  [equip.id]: equip,
                }
              : equipment,
          {}
        ) ?? {}
      ).sort(
        this.sortBy("type.code", "-equipmentNumber", "-equipmentNumberSuffix")
      );
    },
  },

  methods: {
    ...mapActions("cities", ["getCities"]),
    ...mapActions("counties", ["getCounties"]),
    ...mapActions("genders", ["getGenders"]),
    ...mapActions("languages", ["getLanguages"]),
    ...mapActions("provinces", ["getProvinces"]),
    handleDeleteLoan(loan) {
      if (loan?.equipment?.$creatingLoan) {
        delete loan.equipment.$creatingLoan;

        this.client.equipment = [...this.client.equipment, loan.equipment].sort(
          this.sortBy("type.code", "-equipmentNumber", "-equipmentNumberSuffix")
        );

        this.$emit("update:client", this.client);
      }
    },
    handleItemInvoiced(invoiceItem) {
      if (invoiceItem) {
        this.invoiceItems = [...this.invoiceItems, invoiceItem];
        this.client.invoiceItems = [...this.client.invoiceItems, invoiceItem];
        this.$emit("update:client", this.client);
      }
    },
    handleOnHoldLoan(equipment) {
      equipment.$creatingLoan = true;
      this.$refs.loanHistory?.handleCreate({ equipment });

      this.client.equipment = this.client.equipment.filter(
        (eq) => eq.id !== equipment.id
      );

      this.$emit("update:client", this.client);
    },
    handleReset(event) {
      event.preventDefault();

      if (this.confirmIfDirty()) {
        this.setFormData(this.client);
        this.$emit("reset");
      }
    },
    async handleSubmit() {
      if (!this.checkValidity(...[].slice.call(this.$el.children, 0, 2))) {
        return;
      }

      try {
        const client = {
          ...this.client,
          addresses: [
            this.useFacility
              ? null
              : {
                  ...this.$data._mailingAddress,
                  mailing: true,
                  billing: this.useMailingForBilling,
                },
            this.useMailingForBilling
              ? null
              : {
                  ...this.$data._billingAddress,
                  county: this.$data._billingAddress?.county?.id
                    ? this.$data._billingAddress.county
                    : this.cities.find(
                        (c) => c.id === this.$data._billingAddress?.city?.id
                      )?.county,
                  billing: true,
                },
            ...(this.client?.addresses
              ?.filter(
                (a) =>
                  a.id !== this.$data._mailingAddress.id &&
                  a.id !== this.$data._billingAddress.id
              )
              ?.map((a) => ({ ...a, mailing: false, billing: false })) ?? []),
          ].filter((v) => v),
          birthDate: this.birthDate,
          clientNumber: this.clientNumber,
          contacts: [
            (this.nextOfKin?.name || this.nextOfKin?.phoneNumber) && {
              ...this.nextOfKin,
              type: "Next of Kin",
            },
            ...(this.contacts
              ?.filter((c) => c.id !== this.nextOfKin.id)
              ?.map((c) => ({ ...c, type: "Contact" })) ?? []),
          ].filter((v) => v),
          deceasedOn: this.useDeceased ? this.deceasedOn : null,
          emailAddresses: [
            this.emailAddress && {
              ...this.client?.emailAddresses?.find((a) => a.primary),
              address: this.emailAddress,
              primary: true,
            },
            ...(this.client?.emailAddresses?.filter((a) => !a.primary) ?? []),
          ].filter((v) => v),
          facility: this.useFacility ? { id: this.facilityId } : null,
          fcsNumber: this.fcsNumber,
          firstName: this.firstName,
          gender: this.genderId ? { id: this.genderId } : null,
          insuranceProvider: this.useInsurance
            ? { id: this.insuranceProviderId }
            : null,
          language: this.languageId ? { id: this.languageId } : null,
          lastName: this.lastName,
          medicareNumber: this.medicareNumber,
          notes: this.notes?.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          ),
          phoneNumbers: [
            { ...this.homePhone, type: { id: 1 } },
            this.workPhone.number && { ...this.workPhone, type: { id: 2 } },
            ...(this.client?.phoneNumbers?.filter(
              (n) => n.id !== this.homePhone.id && n.id !== this.workPhone.id
            ) ?? []),
          ].filter((v) => v),
          program: this.programId ? { id: this.programId } : null,
          project: this.projectId ? { id: this.projectId } : null,
        };

        this.$emit(
          "submit",
          await Promise.all([
            client,
            saveClient(client),
            ...(this.invoiceItems ?? [])
              .filter((invoiceItem) => invoiceItem.id)
              .map((invoiceItem) => {
                const _invoiceItem = client.invoiceItems.find(
                  (i) => i.id === invoiceItem.id
                );

                return _invoiceItem &&
                  (invoiceItem?.billingCode?.id !==
                    _invoiceItem?.billingCode?.id ||
                    invoiceItem?.description !== _invoiceItem?.description ||
                    invoiceItem?.fee !== _invoiceItem?.fee ||
                    invoiceItem?.program?.id !== _invoiceItem?.program?.id)
                  ? updateInvoiceItem(invoiceItem)
                  : invoiceItem;
              }),
          ]).then(([prev, next, ...invoiceItems]) => ({
            ...prev,
            ...next,
            invoiceItems,
          }))
        );
      } catch (e) {
        this.showErrors(e, "A client");
      }
    },
    handleUpdateLoans(loans) {
      loans.forEach((loan) => {
        const _loan = this.client.loans.find((l) => l.id === loan.id);

        if (loan !== _loan) {
          const equipIndex = this.equipment.findIndex(
            (e) => e.id === loan.equipment.id
          );

          if (equipIndex >= 0) {
            this.equipment.splice(equipIndex, 1, {
              ...this.equipment[equipIndex],
              onHold: false,
              onHoldFor: null,
              onLoan: !loan.returnDate,
            });
          }
        }
      });

      this.client.loans.forEach((loan) => {
        if (!loans.find((l) => l.id === loan.id)) {
          const equipIndex = this.equipment.findIndex(
            (e) => e.id === loan.equipment.id
          );

          if (equipIndex >= 0) {
            this.equipment.splice(equipIndex, 1, {
              ...this.equipment[equipIndex],
              onLoan: loan.returnDate
                ? this.equipment[equipIndex].onLoan
                : false,
            });
          }
        }
      });

      this.client.loans = loans;
      this.$emit("update:equipment", this.equipment);
      this.$emit("update:client", this.client);
    },
    isDirty() {
      if (
        this.birthDate !== this.client?.birthDate ||
        this.clientNumber !== this.client?.clientNumber ||
        this.deceasedOn !== this.client?.deceasedOn ||
        this.emailAddress !==
          this.client?.emailAddresses?.find((a) => a.primary)?.address ||
        this.facilityId !== this.client?.facility?.id ||
        this.fcsNumber !== this.client?.fcsNumber ||
        this.firstName !== this.client?.firstName ||
        this.genderId !== this.client?.gender?.id ||
        this.insuranceProviderId !== this.client?.insuranceProvider?.id ||
        this.languageId !== this.client?.language?.id ||
        this.lastName !== this.client?.lastName ||
        this.medicareNumber !== this.client?.medicareNumber ||
        this.programId !== this.client?.program?.id ||
        this.projectId !== this.client?.project?.id ||
        this.useDeceased !== !!this.client?.deceasedOn ||
        this.useFacility !== this.client?.facility?.id > 0 ||
        this.useInsurance !== this.client?.insuranceProvider?.id > 0
      ) {
        return true;
      }

      const nextOfKin = this.client?.contacts?.find(
        (c) => c.type === "Next of Kin"
      );

      if (
        this.nextOfKin.name !== nextOfKin?.name ||
        this.nextOfKin.phoneNumber !== nextOfKin?.phoneNumber
      ) {
        return true;
      }

      const [homePhone, workPhone] = (this.client?.phoneNumbers ?? []).reduce(
        ([home, work], p) => [
          p?.type?.id === 1 ? p : home,
          p?.type?.id === 2 ? p : work,
        ],
        []
      );

      if (
        this.homePhone.number !== homePhone?.number ||
        this.homePhone.tty !== homePhone?.tty ||
        this.workPhone.number !== workPhone?.number ||
        this.workPhone.tty !== workPhone?.tty
      ) {
        return true;
      }

      const [mailingAddress, billingAddress] = (
        this.client?.addresses ?? []
      ).reduce(
        ([mailing, billing], a) => [
          a.mailing ? a : mailing,
          a.billing ? a : billing,
        ],
        []
      );

      if (
        this.$data._mailingAddress.address1 !== mailingAddress?.address1 ||
        this.$data._mailingAddress.address2 !== mailingAddress?.address2 ||
        this.$data._mailingAddress.city.id !== mailingAddress?.city?.id ||
        this.$data._mailingAddress.county.id !== mailingAddress?.county?.id ||
        this.$data._mailingAddress.province.id !==
          mailingAddress?.province?.id ||
        this.$data._mailingAddress.postalCode !== mailingAddress?.postalCode ||
        (this.useMailingForBilling !==
          (mailingAddress?.billing || !billingAddress?.billing) &&
          (this.$data._billingAddress.address1 !== billingAddress?.address1 ||
            this.$data._billingAddress.address2 !== billingAddress?.address2 ||
            this.$data._billingAddress.city.id !== billingAddress?.city?.id ||
            this.$data._billingAddress.county.id !==
              billingAddress?.county?.id ||
            this.$data._billingAddress.province.id !==
              billingAddress?.province?.id ||
            this.$data._billingAddress.postalCode !==
              billingAddress?.postalCode))
      ) {
        return true;
      }

      return (
        this.notes.length !== (this.client?.notes?.length ?? 0) ||
        !!this.notes.find((note) => {
          if (!note?.id) {
            return true;
          }

          const _note = this.client?.notes?.find((n) => n.id === note?.id);

          return (
            !_note ||
            note?.author?.id !== _note?.author?.id ||
            note?.message !== _note?.message ||
            note?.loanCheck !== _note?.loanCheck ||
            note?.createdAt !== _note?.createdAt
          );
        }) ||
        this.invoiceItems?.length !==
          (this.client?.invoiceItems?.length ?? 0) ||
        !!this.invoiceItems?.find((invoiceItem) => {
          if (!invoiceItem?.id) {
            return true;
          }

          const _invoiceItem = this.client?.invoiceItems?.find(
            (i) => i.id === invoiceItem?.id
          );

          return (
            !_invoiceItem ||
            invoiceItem?.billingCode?.id !== _invoiceItem?.billingCode?.id ||
            invoiceItem?.description !== _invoiceItem?.description ||
            invoiceItem?.fee !== _invoiceItem?.fee ||
            invoiceItem?.program?.id !== _invoiceItem?.program?.id
          );
        }) ||
        this.$children?.reduce(
          (dirty, child) => dirty || child.isDirty(),
          false
        )
      );
    },
    setFormData(client, newClient = false) {
      this.birthDate = client?.birthDate;
      this.clientNumber = client?.clientNumber;
      this.deceasedOn = client?.deceasedOn;
      this.emailAddress = client?.emailAddresses?.find(
        (a) => a.primary
      )?.address;
      this.facilityId = client?.facility?.id;
      this.fcsNumber = client?.fcsNumber;
      this.firstName = client?.firstName;
      this.genderId = client?.gender?.id;
      this.insuranceProviderId = client?.insuranceProvider?.id;
      this.languageId = client?.language?.id;
      this.lastName = client?.lastName;
      this.medicareNumber = client?.medicareNumber;
      this.nextOfKin = {
        ...client?.contacts?.find((c) => c.type === "Next of Kin"),
      };
      this.notes = client?.notes ?? [];
      this.programId = client?.program?.id;
      this.projectId = client?.project?.id;

      [this.homePhone, this.workPhone] = (client?.phoneNumbers ?? []).reduce(
        ([home, work], p) => [
          p?.type?.id === 1 ? p : home,
          p?.type?.id === 2 ? p : work,
        ],
        [{}, {}]
      );

      const [mailingAddress, billingAddress] =
        client?.addresses?.reduce(
          ([mailing, billing], a) => [
            a.mailing ? a : mailing,
            a.billing && !a.mailing ? a : billing,
          ],
          [{}, {}]
        ) ?? [];

      this.$data._billingAddress = {
        city: {},
        county: {},
        province: {},
        ...billingAddress,
      };

      this.$data._mailingAddress = {
        city: {},
        county: {},
        province: {},
        ...mailingAddress,
      };

      if (newClient || !this.invoiceItems) {
        this.invoiceItems = client?.invoiceItems;
      }

      this.useDeceased = !!client?.deceasedOn;
      this.useFacility = client?.facility?.id > 0;
      this.useInsurance = client?.insuranceProvider?.id > 0;
      this.useMailingForBilling =
        this.$data._mailingAddress.billing ||
        !this.$data._billingAddress.billing;
    },
  },

  watch: {
    billingCodes: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._billingCodes) {
          this.$data._billingCodes = to ?? (await getBillingCodes());
          this.$emit("update:billing-codes", this.$data._billingCodes);
        }
      },
    },
    client: {
      immediate: true,
      handler(to, from) {
        this.setFormData(to, to?.id !== from?.id);
      },
    },
    facilities: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._facilities) {
          this.$data._facilities = to ?? (await getFacilities());
          this.$emit("update:facilities", this.$data._facilities);
        }
      },
    },
    insuranceProviders: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._insuranceProviders) {
          this.$data._insuranceProviders =
            to ?? (await getInsuranceProviders());
          this.$emit(
            "update:insurance-providers",
            this.$data._insuranceProviders
          );
        }
      },
    },
    loanTypes: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._loanTypes) {
          this.$data._loanTypes = to ?? (await getLoanTypes());
          this.$emit("update:loan-types", this.$data._loanTypes);
        }
      },
    },
    programs: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._programs) {
          this.$data._programs = to ?? (await getPrograms());
          this.$emit("update:programs", this.$data._programs);
        }
      },
    },
    projects: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._projects) {
          this.$data._projects = to ?? (await getProjects());
          this.$emit("update:projects", this.$data._projects);
        }
      },
    },
    referralAgents: {
      immediate: true,
      async handler(to, from) {
        if (to !== this.$data._referralAgents) {
          this.$data._referralAgents = to ?? await getReferralAgents();
          this.$emit("update:referral-agents", this.$data._referralAgents);
        }
      },
    },
  },
};
</script>

<style>
.client-form {
  color: var(--primary-medium-gray);
  font-size: 0.9em;
}

.client-form > footer,
.client-form details > summary {
  align-items: center;
  background: var(--primary-light-gray);
  color: var(--primary-black);
  display: flex;
  font-size: 1.11em;
  font-weight: bold;
  justify-content: space-between;
  margin: 0 0 0.5em;
  outline: none;
  padding: 0.2em 0.4em;
  text-transform: uppercase;
  width: 100%;
}

.client-form > footer {
  justify-content: flex-end;
  margin: 0.5em 0 0;
  padding: 0.4em;
}

.client-form details > summary {
  flex-direction: row-reverse;
  list-style: none;
}

.client-form details > summary::-webkit-details-marker {
  display: none;
}

.client-form details > summary::before {
  background-color: currentColor;
  content: " ";
  cursor: pointer;
  height: 1.6em;
  mask-image: url("../../assets/icons/expand.svg");
  mask-repeat: no-repeat;
  width: 1.6em;
}

.client-form details[open] > summary::before {
  mask-image: url("../../assets/icons/collapse.svg");
  mask-repeat: no-repeat;
}

.client-form > footer > * ~ * {
  margin-left: 0.67em;
}

.client-form .grid {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: 1fr 4em 4em 4em 1fr 4em 4em 4em 1fr 4em 4em 4em 1fr 4em 4em 4em;
  padding: 0.5em 1em;
  width: 100%;
}

.client-form .grid > * {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column: span 4;
  width: 100%;
}

.client-form .grid input[type="checkbox" i] {
  margin: 0.3em 0 0;
}

.client-form .grid input:not([type="checkbox" i]):not([type="radio" i]),
.client-form .grid select,
.client-form .grid textarea {
  width: 100%;
}

.client-form .grid select[required] > option:not([value]):disabled {
  display: none;
}

.client-form .required::after {
  color: var(--primary-red);
  content: "*";
  display: inline-block;
  font-size: 0.8em;
  margin-left: 0.1em;
  margin-top: -0.2em;
  vertical-align: top;
}

.client-form .search-label {
  font-size: 0.9em;
}

.client-form .yes-no {
  justify-content: space-evenly;
}

.client-form .yes-no label {
  color: var(--primary-black);
}

/* region General Info */
.client-general-info .grid {
  grid-template-rows: repeat(7, auto);
}

.client-general-info .client-home-phone-number,
.client-general-info .client-work-phone-number {
  grid-column: 1 / span 3;
}

.client-general-info .client-home-phone-tty,
.client-general-info .client-work-phone-tty {
  align-items: center;
  grid-column: 4;
}

.client-general-info .client-home-phone-number,
.client-general-info .client-home-phone-tty {
  grid-row: 4;
}

.client-general-info .client-work-phone-number,
.client-general-info .client-work-phone-tty {
  grid-row: 5;
}

.client-general-info .client-province {
  grid-column: 5 / span 2;
}

.client-general-info .client-county {
  grid-column: 7 / span 2;
}

.client-general-info .client-province,
.client-general-info .client-county {
  grid-row: 6;
}

.client-general-info .client-notes {
  grid-column: 5 / span 12;
  grid-row: 7;
  justify-content: flex-start;
}

.client-general-info .client-birthdate {
  grid-column: 9 / span 3;
}

.client-general-info .client-deceased {
  align-items: center;
  grid-column: 12;
}

.client-general-info .client-birthdate,
.client-general-info .client-deceased {
  grid-row: 3;
}
/* endregion */

/* region Billing Address */
.client-billing-address
  .client-mailing-for-billing-address
  > span:not(:first-child) {
  align-items: center;
  color: initial;
  display: flex;
  font-size: 0.9em;
  font-weight: normal;
}

.client-billing-address
  .client-mailing-for-billing-address
  > span
  > input[type="checkbox" i] {
  font-size: 1.11em;
  margin: 0 0.33em 0 0;
}
/* endregion */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .client-billing-address .grid,
  .loan-info .loan-history-item {
    grid-template-columns: 1fr 4em 4em 4em;
    grid-template-rows: repeat(15, auto);
  }
  .client-general-info .equipment-notes {
    grid-row: 14;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .client-billing-address .grid,
  .loan-info .loan-history-item {
    grid-template-columns: 1fr 4em 4em 4em;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .client-billing-address .grid,
  .loan-info .loan-history-item {
    grid-template-columns: 1fr 4em 4em 4em 1fr 4em 4em 4em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .client-billing-address .grid,
  .loan-info .loan-history-item {
    grid-template-columns: 1fr 4em 4em 4em 1fr 4em 4em 4em 1fr 4em 4em 4em;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .client-general-info .grid,
  .client-billing-address .grid {
    grid-template-columns: 1fr 4em 4em 4em 1fr 4em 4em 4em 1fr 4em 4em 4em 1fr 4em 4em 4em;
  }
}

@media only screen and (max-width: 1200px) {
  .client-general-info .grid {
    display: block;
  }
}
</style>