<template>
  <table class="striped admin-equipment-statuses-table">
    <caption v-html="caption" v-if="caption" />
    <colgroup>
      <col width="25%" />
      <col width="60%" />
      <col width="15%" />
    </colgroup>
    <thead>
      <tr>
        <th>Status</th>
        <th>Description</th>
        <th>Availability</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="status in paginatedStatuses"
        :key="status.id"
        :class="selectedId === status.id ? 'selected' : 'selectable'"
        @click="handleSelect(status)"
      >
        <td>{{ status.name }}</td>
        <td>{{ status.description }}</td>
        <td>{{ getStatusType(status) }}</td>
      </tr>
      <tr v-if="statuses.length === 0">
        <td class="no-records" colspan="3">
          There are currently no equipment statuses in the system.
        </td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls" colspan="3">
          <icon-button
            :disabled="!hasPrevPage"
            name="prev"
            @click="goToPrevPage"
          />

          <icon-button
            :disabled="!hasNextPage"
            name="next"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";
import { StatusTypes } from "../../consts/StatusTypes";

export default {
  mixins: [pagination("statuses")],

  props: {
    caption: {
      type: String,
      default: undefined,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
    statuses: {
      type: Array,
    },
  },

  methods: {
    getStatusType(status) {
      if (!status.available && status.lost) {
        return StatusTypes.Lost;
      }

      if (status.available && !status.lost) {
        return StatusTypes.Available;
      }

      if (!status.available && !status.lost) {
        return StatusTypes.Unavailable;
      }
    },
    handleSelect(status) {
      this.$emit("select", status);
    },
  },
};
</script>