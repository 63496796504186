<template>
  <form
    autocomplete="off"
    class="admin-insurance-providers-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Insurance Provider Name</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-insurance-provider-name"
          required
          type="text"
          v-model.trim="name"
        />
      </label>

      <label>
        <span :class="isAddressRequired && 'required'">Address</span>

        <input
          :disabled="disabled"
          id="admin-insurance-provider-address"
          :required="isAddressRequired"
          type="text"
          v-model.trim="address.address1"
        />
      </label>

      <label>
        <span :class="isAddressRequired && 'required'">City</span>

        <select
          :disabled="disabled"
          id="admin-insurance-provider-city"
          :required="isAddressRequired"
          v-model.number="address.city.id"
        >
          <option disabled></option>

          <option
            v-for="city in filterOptions(cities, address.city)"
            :key="city.id"
            :value="city.id"
          >{{ city.name }}</option>
        </select>
      </label>

      <label>
        <span :class="isAddressRequired && 'required'">Province</span>

        <select
          :disabled="disabled"
          id="admin-insurance-provider-province"
          :required="isAddressRequired"
          v-model.number="address.province.id"
        >
          <option disabled></option>

          <option
            v-for="province in filterOptions(provinces, address.province)"
            :key="province.id"
            :value="province.id"
          >{{ province.abbreviation }} - {{ province.name }}</option>
        </select>
      </label>

      <label>
        <span :class="isAddressRequired && 'required'">County</span>

        <select
          :disabled="disabled"
          id="admin-insurance-provider-county"
          :required="isAddressRequired"
          v-model.number="address.county.id"
        >
          <option disabled></option>
          <option
            v-for="county in filterOptions(counties, address.county)"
            :key="county.id"
            :value="county.id"
          >{{ county.abbreviation }} - {{ county.name }}</option>
        </select>
      </label>

      <label>
        <span :class="isAddressRequired && 'required'">Postal Code</span>

        <postal-code-input
          :disabled="disabled"
          id="admin-insurance-provider-postal-code"
          :required="isAddressRequired"
          v-model="address.postalCode"
        />
      </label>

      <label>
        <span>Email Address</span>

        <email-address-input
          :disabled="disabled"
          id="admin-insurance-provider-email-address"
          v-model="emailAddress.address"
        />
      </label>

      <label>
        <span>Phone Number</span>

        <phone-number-input
          :disabled="disabled"
          id="admin-insurance-provider-phone-number"
          v-model="phoneNumber.number"
        />
      </label>
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-insurance-provider-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && insuranceProvider.id"
      >Delete</button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-insurance-provider-save"
        type="submit"
        v-if="canSave"
      >Save</button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-insurance-provider-cancel"
        type="reset"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </form>
</template>

<script>
import {
  deleteInsuranceProvider,
  saveInsuranceProvider
} from "../../api/InsuranceProviderAPI";

export default {
  data() {
    return {
      name: undefined,
      address: { city: {}, county: {}, province: {} },
      emailAddress: {},
      phoneNumber: { type: { id: 2 } }
    };
  },

  props: {
    cities: {
      type: Array
    },
    counties: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    insuranceProvider: {
      type: Object
    },
    provinces: {
      type: Array
    }
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_INSURANCE_PROVIDERS");
    },
    canSave() {
      return this.can({
        permissions: [
          "CREATE_INSURANCE_PROVIDERS",
          "UPDATE_INSURANCE_PROVIDERS"
        ],
        matchAny: true
      });
    },
    isAddressRequired() {
      return (
        !!this.address.address1 ||
        !!this.address.city.id ||
        !!this.address.province.id ||
        !!this.address.county.id ||
        !!this.address.postalCode
      );
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.insuranceProvider?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.insuranceProvider?.id
          ? this.can("UPDATE_INSURANCE_PROVIDERS")
          : this.can("CREATE_INSURANCE_PROVIDERS"))
      );
    }
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (
        !this.showConfirm(
          "Are you sure you want to delete this insurance provider?"
        )
      ) {
        return;
      }

      await deleteInsuranceProvider(this.insuranceProvider);
      this.$emit("delete", this.insuranceProvider);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveInsuranceProvider({
            ...this.insuranceProvider,
            name: this.name,
            addresses: [
              this.address.address1 ? this.address : undefined,
              ...(this.insuranceProvider?.addresses?.filter(
                a => a?.id !== this.address.id
              ) ?? [])
            ].filter(v => v),
            emailAddresses: [
              this.emailAddress.address ? {...this.emailAddress}
              :undefined,
              ...(this.insuranceProvider?.emailAddresses?.filter(
                p => p.id !== this.emailAddress.id
              ) ?? [])
            ].filter(v => v),
            phoneNumbers: [
              this.phoneNumber.number
                ? { ...this.phoneNumber, type: { id: 2 } }
                : undefined,
              ...(this.insuranceProvider?.phoneNumbers?.filter(
                p => p?.id !== this.phoneNumber.id
              ) ?? [])
            ].filter(v => v)
          })
        );
      } catch (e) {
        this.showErrors(e, "An insurance provider");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      if (this.name !== this.insuranceProvider?.name) {
        return true;
      }

      const address = this?.insuranceProvider?.addresses?.slice(-1)?.[0];

      if (
        this.address.address1 !== address?.address1 ||
        this.address.city?.id !== address?.city?.id ||
        this.address.province?.id !== address?.province?.id ||
        this.address.county?.id !== address?.county?.id ||
        this.address.postalCode !== address?.postalCode
      ) {
        return true;
      }

      if (
        this.emailAddress.address !==
        this?.insuranceProvider?.emailAddresses?.slice(-1)?.[0]?.address
      ) {
        return true;
      }

      return (
        this.phoneNumber.number !==
        this?.insuranceProvider?.phoneNumbers?.slice(-1)?.[0]?.number
      );
    }
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    insuranceProvider: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.name = to?.name;
          this.emailAddress = { ...to?.emailAddresses?.slice(-1)?.[0] };
          this.phoneNumber = { ...to?.phoneNumbers?.slice(-1)?.[0] };

          this.address = {
            city: {},
            county: {},
            province: {},
            ...to?.addresses?.slice(-1)?.[0]
          };
        }

        !this.disabled && this.autofocus();
      }
    }
  }
};
</script>

<style>
.admin-insurance-providers-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.admin-insurance-providers-form > footer {
  grid-column: span 4;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>