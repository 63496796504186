import { del, get, post, put } from './http';
import { sortBy } from '../func';

const API_PATH = '/api/holidays';

export const createHoliday = async holiday =>
    await post(API_PATH, holiday);

export const deleteHoliday = async holiday =>
    await del(`${API_PATH}/${holiday.id}`);

export const getHolidays = async () =>
    (await get(API_PATH)).sort(sortBy('date', 'name', 'id'));

export const saveHoliday = async holiday =>
    await (holiday.id ? updateHoliday(holiday) : createHoliday(holiday));

export const updateHoliday = async holiday =>
    await put(`${API_PATH}/${holiday.id}`, holiday);