<template>
  <div class="uploaded-image-single-element">
    <div class="image-section">
      <div class="uploaded-image-single-image">
        <img :id="image.id" :alt="src" :src="src" :title="showInfo ? 'Click to open expanded view' : null" @click="showInfo ? toggleExpandedView() : handleImageClick($event)" />
      </div>
      <div class="uploaded-image-single-buttons">
        <slot name="controlButton" />
        <slot name="archiveButton" />
      </div>
    </div>
    <div class="uploaded-image-single-information" v-if="showInfo">
      <label class="uploaded-image-file-number-label">
        <span>{{ getStaticName($store.getters.user, "Equipment Number", "Numéro d'équipement") }}</span>
      </label>
      <label class="uploaded-image-file-number">
        <strong class="uploaded-image-file-details">{{
          equipmentNumber
        }}</strong>
      </label>
      <label class="uploaded-image-file-date-added-label">
        <span>{{ getStaticName($store.getters.user, "Date Added", "Date d'ajout") }}</span>
      </label>
      <label class="uploaded-image-file-date-added">
        <strong
          class="uploaded-image-file-details"
        >{{ formatTimestamp(image.createdAt ? image.createdAt : Date.now()) }}</strong>
      </label>
      <label class="uploaded-image-file-name-label">
        <span>{{ getStaticName($store.getters.user, "File Name", "Nom du ficihier") }}</span>
      </label>
      <label class="uploaded-image-file-name">
        <strong
          class="uploaded-image-file-details"
        >{{ image.filename ? image.filename : image.name }}</strong>
      </label>
      <label class="uploaded-image-file-size-label">
        <span>{{getStaticName($store.getters.user, "File Size", "Taille du fichier") }}</span>
      </label>
      <label class="uploaded-image-file-size">
        <strong class="uploaded-image-file-details">{{ getKiloBytes(image.size) }} Kb</strong>
      </label>
      <label class="uploaded-image-file-user-label">
        <span>User</span>
      </label>
      <label class="uploaded-image-file-user">
        <strong class="uploaded-image-file-details">{{ getUserName(image.creator) }}</strong>
      </label>
    </div>
    <dialog class="uploaded-image-expanded-image" open title="Click to close expanded view" @click="toggleExpandedView()" v-if="expanded">
      <img :alt="src" :src="src" title="" @click.stop.prevent />
    </dialog>
  </div>
</template>

<script>
import { cleanUrl } from "../../func";

export default {
  props: {
    image: {
      type: [Object, File],
      default: null,
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
    equipmentNumber: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      expanded: false,
    };
  },

  computed: {
    src() {
      if (this.image instanceof File) {
        return URL.createObjectURL(this.image);
      }

      return this.image.file
        ? URL.createObjectURL(this.image.file)
        : cleanUrl(this.image.url);
    },
  },

  methods: {
    getKiloBytes(bytes) {
      return Math.round(bytes / 1024, 2);
    },
    handleDelete() {
      this.$emit("delete", this.image);
    },
    toggleExpandedView() {
      this.showInfo && (this.expanded = !this.expanded);
    },
    handleImageClick(e) {
      this.$emit("imageClick", e.target.id);
    }
  },
};
</script>

<style>
.equipment-form .equipment-picture-attachments .section-picture-main {
  grid-row: 1;
  border: 2px solid lightgray;
  display: grid;
  grid-row-gap: 0.5em;
  padding: 0.5em 1em;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  grid-auto-flow: row;
  grid-template-rows: repeat(2, auto);
  grid-column: inherit;
}

.image-section {
  display: grid;
  grid-template-columns: 0.5fr 5em;
  column-gap: 5px;
  grid-template-areas: 
    "picture buttons";
}

.uploaded-image-single-image {
  grid-area: picture;
  justify-self: right;
}

.uploaded-image-single-buttons {
  grid-area: buttons;
}

.uploaded-image-single-buttons input[type="checkbox"]{
  margin: 5px;
  cursor: pointer;
}

.uploaded-image-single-information {
  display: grid;
  grid-row-gap: 0.5em;
  grid-column-gap: 5px;
  padding: 0.5em 1em;
  grid-template-areas: 
    "equip_label equip"
    "date_label date"
    "file_label file"
    "file_size_label file_size"
    "user_label user";
  grid-auto-flow: row;
  grid-column: span 1;
  font-size: 0.9em;
  text-align: left;
  width: 100%;
}

.uploaded-image-file-number-label {
  grid-area: equip_label;
}

.uploaded-image-file-number {
  grid-area: equip;
}

.uploaded-image-file-date-added-label {
  grid-area: date_label;
}

.uploaded-image-file-date-added {
  grid-area: date;
}

.uploaded-image-file-name-label {
  grid-area: file_label;
}

.uploaded-image-file-name {
  grid-area: file;
}

.uploaded-image-file-size-label {
  grid-area: file_size_label;
}

.uploaded-image-file-size {
  grid-area: file_size;
}

.uploaded-image-file-user-label {
  grid-area: user_label;
}

.uploaded-image-file-user {
  grid-area: user;
}

.uploaded-image-file-details {
  font-weight: 900;
  word-break: break-all;
}

.uploaded-image-single-information span {
  color: black;
}

.uploaded-image-add-new {
  display: flex;
  justify-content: flex-end;
}

.uploaded-image-single-image img {
  width: auto;
  max-height: 150px;
  cursor: pointer;
}

.uploaded-image-expanded-image {
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  border: none;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
}

.uploaded-image-expanded-image img {
  max-height: 67%;
  max-width: 67%;
}

@media only screen and (max-width: 1400px) {
  .picture-body .picture-message {
    display: inherit;
  }
  .picture-body .picture-message > button {
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 915px) {
  .picture-wrapper .picture-body {
    padding: 0;
  }
}
</style>