<template>
  <ContentWrapper
    :create-icon="can('CREATE_PROJECTS') ? 'add-project' : null"
    :header-tag="headerTag"
    search-input-id="admin-project-search-phrase"
    :show-back="showBack"
    title="Projects"
    @create="handleCreate"
    @search="handleSearch"
  >
    <ProjectsTable
      class="admin-table"
      :projects="filteredProjects"
      :selected-id="project.id"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="project.id ? `Edit - ${project.name}` : 'Create Project'"
      />

      <span class="admin-form-tab">
        <img alt="Projects" src="../../../assets/icons/program.svg" />
      </span>

      <ProjectsForm
        class="admin-form"
        :disabled="project.name === undefined"
        :project="project"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import ProjectsTable from "../../../components/admin/ProjectsTable";
import ProjectsForm from "../../../components/admin/ProjectsForm";

import { getProjects } from "../../../api/ProjectAPI";

export default {
  data() {
    return {
      project: {},
      _projects: [],
      search: "",
    };
  },

  props: {
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    projects: {
      type: Array,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    filteredProjects() {
      const regex = this.getFilterRegex(this.search);
      return this.$data._projects.filter((p) => regex.test(p?.name ?? ""));
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.project = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.project = { name: "" };
      }
    },
    handleDelete(project) {
      this.$data._projects = this.$data._projects.filter(
        (p) => p?.id !== project?.id
      );

      this.project = {};
    },
    handleSave(project) {
      const created = !this.project.id;

      this.$data._projects = [
        ...this.$data._projects.filter((p) => p?.id !== project?.id),
        project,
      ];

      this.handleSelect(created ? project : undefined, true);

      this.showAlert(
        `Your project has been ${created ? "created" : "updated"} successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(project, force = false) {
      if (force || this.confirmIfDirty()) {
        this.project = {
          ...this.$data._projects.find((p) => p?.id === project?.id),
        };
      }
    },
  },

  watch: {
    "$data._projects"(to, from) {
      if (to !== from) {
        this.$data._projects = to.sort(this.sortBy("name", "id"));
        this.$emit("update:projects", this.$data._projects);
      }
    },
    "project.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    async projects(to, from) {
      to === this.$data._projects ||
        (this.$data._projects = to?.slice?.() ?? (await getProjects()));
    },
  },

  async created() {
    this.$data._projects = this.projects?.slice?.() ?? (await getProjects());
    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    ProjectsTable,
    ProjectsForm,
  },
};
</script>