<template>
  <ContentWrapper
    :create-icon="can('CREATE_FACILITIES') ? 'add-facility' : null"
    :header-tag="headerTag"
    search-input-id="admin-facility-search-phrase"
    :show-back="showBack"
    title="Facilities"
    @create="handleCreate"
    @search="handleSearch"
  >
    <FacilitiesTable
      class="admin-table"
      :facilities="filteredFacilities"
      :selected-id="facility.id"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="facility.id ? `Edit - ${facility.name}` : 'Create Facility'"
      />

      <span class="admin-form-tab">
        <img alt="Facilities" src="../../../assets/icons/facility.svg" />
      </span>

      <FacilitiesForm
        class="admin-form"
        :disabled="facility.name === undefined"
        :cities="cities"
        :counties="counties"
        :facility="facility"
        :provinces="provinces"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ContentWrapper from "./ContentWrapper";
import FacilitiesTable from "../../../components/admin/FacilitiesTable";
import FacilitiesForm from "../../../components/admin/FacilitiesForm";

import { getFacilities } from "../../../api/FacilityAPI";

export default {
  data() {
    return {
      _facilities: [],
      facility: {},
      search: "",
    };
  },

  props: {
    facilities: {
      type: Array,
      default: undefined,
    },
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters(["cities", "counties", "provinces"]),
    filteredFacilities() {
      const regex = this.getFilterRegex(this.search);

      return this.$data._facilities.filter((f) =>
        regex.test(
          [
            f?.name ?? "",
            f?.emailAddress ?? "",
            ...(f?.addresses?.map((a) =>
              [
                a.address1 ?? "",
                a?.city?.name ?? "",
                a?.province?.abbreviation ?? "",
                a?.postalCode ?? "",
              ].join(String.fromCharCode(0x1f))
            ) ?? []),
            ...(f?.phoneNumbers?.map((p) => p?.number ?? "") ?? []),
          ].join(String.fromCharCode(0x1f))
        )
      );
    },
  },

  methods: {
    ...mapActions("cities", ["getCities"]),
    ...mapActions("counties", ["getCounties"]),
    ...mapActions("provinces", ["getProvinces"]),
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.facility = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.facility = { name: "" };
      }
    },
    handleDelete(facility) {
      this.$data._facilities = this.$data._facilities.filter(
        (f) => f?.id !== facility?.id
      );

      this.facility = {};
    },
    handleSave(facility) {
      const created = !this.facility.id;

      this.$data._facilities = [
        ...this.$data._facilities.filter((f) => f?.id !== facility?.id),
        facility,
      ];

      this.handleSelect(created ? facility : undefined, true);

      this.showAlert(
        `Your facility has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(facility, force = false) {
      if (force || this.confirmIfDirty()) {
        this.facility = {
          ...this.$data._facilities.find((f) => f?.id === facility?.id),
        };
      }
    },
    async loadData() {
      this.getCities();
      this.getCounties();
      this.getProvinces();
    },
  },

  watch: {
    "$data._facilities"(to, from) {
      if (to !== from) {
        this.$data._facilities = to.sort(this.sortBy("name", "id"));
        this.$emit("update:facilities", this.$data._facilities);
      }
    },
    async facilities(to, from) {
      to === this.$data._facilities ||
        (this.$data._facilities =
          to?.slice?.() ?? (await this.getFacilities()));
    },
    "facility.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
  },

  async created() {
    this.loadData();

    this.$data._facilities =
      this.facilities?.slice?.() ?? (await getFacilities());

    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    FacilitiesTable,
    FacilitiesForm,
  },
};
</script>