<template>
  <section class="bulk-invoicing-page">
    <InvoiceTableNonGenerated
      caption="Invoices to be generated"
      :items-invoices.sync="nonGeneratedInvoices"
      :invoice-codes="billingCodes"
      :invoice-programs="programs"
      @handleBulkTemplatesUpdate="refreshBulkTemplates"
    />

    <InvoiceTable
      caption="Invoice History"
      table-type="bulkInvoicing"
      :items-invoices.sync="generatedInvoices"
      :invoice-codes="billingCodes"
      :invoice-programs="programs"
      @handleBulkTemplatesUpdate="refreshBulkTemplates"
    />
  </section>
</template>

<script>
import { getBillingCodes } from "../../../api/BillingCodeAPI";
import { getInvoiceSummaries, getInvoiceItems } from "../../../api/InvoiceAPI";
import { getPrograms } from "../../../api/ProgramAPI";

import InvoiceTable from "../../../components/invoices/InvoiceTable";
import InvoiceTableNonGenerated from "../../../components/invoices/InvoiceTableNonGenerated";
import ItemsTable from "../../../components/invoices/ItemsTable";

export default {
  data() {
    return {
      billingCodes: [],
      invoiceItems: [],
      invoices: [],
      generatedInvoices: [],
      nonGeneratedInvoices: [],
      programs: []
    };
  },

  async mounted() {
    [
      this.billingCodes,
      this.programs,
      this.invoiceItems
    ] = await Promise.all([
      getBillingCodes(),
      getPrograms(),
      getInvoiceItems()
    ]);

    this.refreshBulkTemplates();
  },

  methods: {
    async refreshBulkTemplates() {
      this.invoices =  await getInvoiceSummaries();
      const [generated, nonGenerated] = [[], []];
      this.invoices.forEach(invoice => (invoice.templateGenerated ? generated : nonGenerated).push(invoice));

      this.generatedInvoices = generated;
      this.nonGeneratedInvoices = nonGenerated;

      this.sortInvoicesByCreatedAt();
      this.sortInvoicesByGeneratedAt();
    },
    sortInvoicesByCreatedAt() {
      this.nonGeneratedInvoices.sort((first, second) => first.createdAt.localeCompare(second.createdAt)).reverse();
    },
    sortInvoicesByGeneratedAt() {
      const [pass, fail] = this.generatedInvoices.reduce(([p, f], e) => (e.generatedAt !== undefined ? [[...p, e], f] : [p, [...f, e]]), [[], []]);

      pass.sort((a,b) => a.generatedAt.localeCompare(b.generatedAt)).reverse();
      fail.sort((a,b) => a.createdAt.localeCompare(b.createdAt)).reverse();

      this.generatedInvoices = [...pass, ...fail];
    },
  },

  components: {
    ItemsTable,
    InvoiceTable,
    InvoiceTableNonGenerated
  }
};
</script>

<style>
.bulk-invoicing-page .table-space-between:first-child {
  margin-top: 0;
}
</style>