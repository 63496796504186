import { del, get, post, put } from './http';

/** Loans **/
const API_PATH = '/api/loans';

export const createLoan = async loan =>
    await post(API_PATH, loan);

export const deleteLoan = async loan =>
    await del(`${API_PATH}/${loan.id}`);

export const generateDocuments = async ({ loanIds = [], templateIds = [] }) => {
    const file = await post(`${API_PATH}/generate-documents`, { loanIds, templateIds });
    const anchor = document.createElement('a');
    anchor.download = file.headers.get('content-disposition')?.match(/filename=(["'])(.*?)\1/)?.[2];
    anchor.href = window.URL.createObjectURL(await file.blob());
    anchor.click();
    window.URL.revokeObjectURL(anchor.href);
};

export const getLoans = async () =>
    await get(API_PATH);
    
export const saveLoan = async loan =>
    await (loan.id ? updateLoan(loan) : createLoan(loan));

export const updateLoan = async loan =>
    await put(`${API_PATH}/${loan.id}`, loan);



/** Loan Types **/
const TYPE_API_PATH = `${API_PATH}/types`;

export const createLoanType = async type =>
    await post(TYPE_API_PATH, type);

export const deleteLoanType = async type =>
    await del(`${TYPE_API_PATH}/${type.id}`);

export const getLoanTypes = async () =>
    await get(TYPE_API_PATH);

export const saveLoanType = async type =>
    await (type.id ? updateLoanType(type) : createLoanType(type));

export const updateLoanType = async type =>
    await put(`${TYPE_API_PATH}/${type.id}`, type);
