<template>
  <section id="admin-facilities-view">
    <content-header :prev="{name: 'Admin'}" title="Facilities" />

    <PartialView>
      <Facilities
        class="admin-content-partial"
        ref="facilities"
        header-tag="h3"
        :id="facilityId"
        :route-params="{view: 'facilities'}"
        :show-back="false"
        v-if="hasAdmin('PROGRAMS')"
      />

      <InsuranceProviders
        class="admin-content-partial"
        ref="insuranceProviders"
        header-tag="h3"
        :id="insuranceProviderId"
        :route-params="{view: 'insurance-providers'}"
        :show-back="false"
        v-if="hasAdmin('INSURANCE_PROVIDERS')"
      />
    </PartialView>
  </section>
</template>

<script>
import PartialView from "./PartialView";
import Facilities from "./pages/Facilities";
import InsuranceProviders from "./pages/InsuranceProviders";

export default {
  data() {
    return {
      facilityId: this.view === "facilities" ? this.id : undefined,
      insuranceProviderId:
        this.view === "insurance-providers" ? this.id : undefined,
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined,
    },
    view: {
      type: String,
      default: "facilities",
    },
  },

  methods: {
    isDirty() {
      return false;
    },
  },

  mounted() {
    this.$refs.facilities &&
      (this.$refs.facilities.$el.id = "admin-facilities");
    this.$refs.insuranceProviders &&
      (this.$refs.insuranceProviders.$el.id = "admin-insurance-providers");
  },

  beforeRouteUpdate(to, from, next) {
    to.params.view === "facilities" &&
      (this.facilityId = +to.params.id || undefined);

    to.params.view === "insurance-providers" &&
      (this.insuranceProviderId = +to.params.id || undefined);

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (
      !to.params.skipDirtyCheck &&
      Object.values(this.$refs).find((r) => r?.isDirty?.()) &&
      !this.confirmDiscard()
    ) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    PartialView,
    Facilities,
    InsuranceProviders,
  },
};
</script>

<style>
#admin-facilities-view {
  display: flex;
  flex-wrap: wrap;
}

#admin-facilities-view .admin-table {
  grid-area: table;
}

#admin-facilities-view .admin-form-wrapper {
  grid-area: form;
}

#admin-facilities-view .admin-form-tab img {
  max-width: 2em;
}
</style>