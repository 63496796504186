<template>
  <span class="filtered-select">
    <input
      class="filtered-select-toggle"
      :disabled="disabled"
      readonly
      ref="toggle"
      type="text"
      :value="displayValue"
      @blur="handleBlur"
      @click="handleClick"
      @focus="handleFocus"
    />
    <aside
      :class="[
        'filtered-select-menu',
        focused || 'filtered-select-menu-hidden',
      ]"
    >
      <input
        class="filtered-select-filter"
        :disabled="disabled"
        placeholder="Filter..."
        type="text"
        v-model="filterValue"
        @blur="handleBlur"
        @focus="handleFocus"
      />
      <select
        class="filtered-select-menu-options"
        :disabled="disabled"
        ref="options"
        :required="required"
        :value="selectedValue"
        @blur="handleBlur"
        @click="handleInput"
        @focus="handleFocus"
        @input="handleInput"
      >
        <slot />
      </select>
    </aside>
  </span>
</template>

<script>
const getOptions = (options) =>
  options?.reduce(
    (options, child) =>
      child.tag === "optgroup"
        ? [...options, getOptions(child.children)]
        : child.tag === "option"
        ? [...options, child]
        : options,
    []
  ) ?? [];

const getProperty = (option, prop) =>
  option?.data?.attrs?.[prop] ?? option?.data?.domProps?.[prop];

const getValue = (option) =>
  getProperty(option, "value") ?? option?.children?.[0]?.text;

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },

  data() {
    return {
      _selectedValue: null,
      blurOnClick: false,
      blurTimer: null,
      focused: false,
      filterValue: "",
    };
  },

  computed: {
    displayValue() {
      return (
        getOptions(this.$slots.default).find(
          (option) => this.value == getValue(option)
        )?.children[0]?.text ?? ""
      );
    },
    selectedValue() {
      return this.$data._selectedValue ?? this.value;
    },
  },

  methods: {
    applyFilter() {
      const regex = new RegExp(
        this.filterValue
          .trim()
          .split(/\s+/)
          .filter((part) => part.trim().length)
          .map((part) => `(?=.*?${part})`)
          .join(""),
        "i"
      );

      let optionCount = 0;

      this.$refs.options.querySelectorAll("option").forEach((option, index) => {
        if (index === 0 && !option.value && option.disabled) return;

        const visible =
          regex.test(option.value) || regex.test(option.textContent);

        if (visible) {
          option.style.display = "";
          optionCount++;
        } else {
          option.style.display = "none";
        }
      });

      this.$refs.options.querySelectorAll("optgroup").forEach((optgroup) => {
        optgroup.style.display = optgroup.querySelector("option:visible")
          ? ""
          : "none";
      });

      this.$data._selectedValue = optionCount > 1 ? null : "";
      this.$refs.options.size = Math.min(20, optionCount);
      this.$refs.options.multiple = optionCount === 1;

      this.$refs.options.style.overflowY =
        this.$refs.options.scrollHeight < this.$refs.options.offsetHeight
          ? "hidden"
          : "auto";
    },
    blur() {
      this.$refs.toggle.blur();
      this.handleBlur();
    },
    handleBlur(event) {
      clearTimeout(this.blurTimer);

      this.blurTimer = setTimeout(() => {
        this.$data._selectedValue = null;
        this.blurOnClick = false;
        this.blurTimer = null;
        this.focused = false;
        this.filterValue = "";
        event && this.$emit("blur", event);
      }, 1);
    },
    handleClick() {
      this.blurOnClick && this.blur();
    },
    handleFocus(event) {
      clearTimeout(this.blurTimer);
      this.$data._selectedValue = null;
      this.blurTimer = null;
      this.focused = true;
      this.$emit("focus", event);

      this.blurTimer = setTimeout(() => this.blurOnClick = true, 200);
    },
    handleInput(event) {
      this.$emit("input", event.target.value);
      this.blur();
    },
  },

  watch: {
    filterValue: function () {
      this.applyFilter();
    },
    focused: function (newValue) {
      if (!newValue) return;

      const closeOnEscape = (e) => {
        if (e.key !== "Escape") return;
        window.removeEventListener("keydown", closeOnEscape);
        this.blur();
      };

      window.addEventListener("keydown", closeOnEscape);
    },
  },

  mounted() {
    this.applyFilter();
  },
};
</script>

<style scoped>
.filtered-select {
  all: unset;
  cursor: default;
  position: relative;
}

.filtered-select-toggle {
  background-image: url("../../assets/dropdown.svg#default");
  background-position: calc(100% - 0.3em) calc(100% - 0.1em);
  background-size: 1em;
  background-repeat: no-repeat;
  cursor: default;
  padding-right: 1.35em;
  width: 100%;
}

.filtered-select-toggle:hover,
.filtered-select-toggle:focus {
  background-image: url("../../assets/dropdown.svg#hover");
}

.filtered-select-toggle:active {
  background-image: url("../../assets/dropdown.svg#active");
}

.filtered-select-toggle:disabled {
  background-image: url("../../assets/dropdown.svg#disabled");
}

.filtered-select-menu {
  background: #ffffff;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 999;
}

.filtered-select-filter {
  border-bottom: none;
  width: 100%;
}

.filtered-select-menu-options {
  background: transparent;
  padding: 0;
  width: 100%;
}

.filtered-select-menu-options option {
  padding: 2px 4px 4px;
  width: 100%;
}

.filtered-select-menu-options option:checked {
  background-color: transparent;
  color: inherit;
}

.filtered-select-menu-options option:hover {
  background-color: #1e90ff;
  color: #ffffff;
}

.filtered-select-menu-options[required] > option:not([value]):disabled {
  display: none;
}

.filtered-select-menu-hidden {
  display: none;
}
</style>