<template>
  <section class="equipment-on-hold">
    <search-form
      class="equipment-on-hold-search"
      :debounce="200"
      v-model="filter"
      @change="goToFirstPage"
    />

    <section
      class="equipment-on-hold-item-wrapper"
      v-if="!$data._equipment.length && emptyText"
    >
      <section class="tab-left">
        <img alt="Equipment" src="../../assets/icons/equipment.svg" />
      </section>

      <section class="equipment-on-hold-item">
        <span class="equipment-on-hold-search-no-results">{{ emptyText }}</span>
      </section>
    </section>

    <section
      class="equipment-on-hold-item-wrapper"
      v-else-if="$data._equipment.length && !filteredEquipment.length"
    >
      <section class="tab-left">
        <img alt="Loan" src="../../assets/icons/equipment.svg" />
      </section>

      <section class="equipment-on-hold-item">
        <section class="equipment-on-hold-item-actions">
          <icon-button
            :disabled="true"
            name="archive"
            title="Remove On Hold"
            @click.prevent
            v-if="mode !== 'readonly'"
          />

          <icon-button
            :disabled="true"
            name="add-loan"
            title="Create Loan"
            @click.prevent
            v-if="mode !== 'readonly'"
          />
        </section>

        <span class="equipment-on-hold-search-no-results"
          >There are no pieces of on hold equipment matching your search
          criteria</span
        >
      </section>
    </section>

    <section
      v-else
      v-for="equipment in paginatedEquipment"
      :key="equipment.id"
      class="equipment-on-hold-item-wrapper"
    >
      <section class="tab-left">
        <img alt="Loan" src="../../assets/icons/equipment.svg" />
      </section>

      <section class="equipment-on-hold-item">
        <section class="equipment-on-hold-item-actions">
          <icon-button
            :disabled="!can('UPDATE_EQUIPMENT')"
            name="archive"
            title="Remove On Hold"
            @click="handleDelete(equipment)"
            v-if="mode !== 'readonly'"
          />

          <icon-button
            :disabled="!can('CREATE_LOANS')"
            name="add-loan"
            title="Create Loan"
            @click="handleLoan(equipment)"
            v-if="mode !== 'readonly'"
          />
        </section>

        <section class="equipment-on-hold-item-info">
          <label>
            <span>Equipment Number</span>
            <strong>{{ getEquipmentNumber(equipment) }}</strong>
          </label>

          <label>
            <span>Item</span>
            <strong>{{ equipment.item.name }}</strong>
          </label>

          <label>
            <span>Category</span>
            <strong>{{ equipment.category.name }}</strong>
          </label>

          <label>
            <span>Subcategory</span>
            <strong>{{ equipment.subcategory.name }}</strong>
          </label>
        </section>
      </section>
    </section>

    <footer class="page-controls" v-if="hasPages">
      <icon-button :disabled="!hasPrevPage" name="prev" @click="goToPrevPage" />
      <icon-button :disabled="!hasNextPage" name="next" @click="goToNextPage" />
    </footer>
  </section>
</template>

<script>
import { pagination } from "../../mixins/pagination";

import { getClientEquipment } from "../../api/ClientAPI";
import { updateEquipment } from "../../api/EquipmentAPI";

export default {
  mixins: [pagination("filteredEquipment")],

  data() {
    return {
      _equipment: [],
      filter: "",
    };
  },

  props: {
    clientId: {
      type: Number,
    },
    equipment: {
      type: Array,
      default: null,
    },
    emptyText: {
      type: String,
      default: "There are no piece of on hold equipment",
    },
    mode: {
      type: String,
      default: "full",
      validator: (value) => ["full", "readonly"].includes(value),
    },
    pageSize: {
      type: Number,
      default: 5,
    },
  },

  computed: {
    filteredEquipment() {
      if (!/\S/.test(this.search)) {
        return this.$data._equipment;
      }

      const regex = this.getFilterRegex(this.filter);

      return this.$data._equipment.filter(
        (equipment) =>
          equipment.onHold &&
          equipment.onHoldFor.id == this.clientId &&
          regex.test(
            [
              equipment?.equipmentNumber ?? "",
              this.getEquipmentNumber(equipment),
              equipment?.serialNumber ?? "",
              equipment?.modelNumber ?? "",
              equipment?.category?.name ?? "",
              equipment?.subcategory?.name ?? "",
              equipment?.item?.name ?? "",
            ].join(0x1f)
          )
      );
    },
  },

  methods: {
    async handleDelete(equipment) {
      if (
        !confirm(
          "Are you sure you want to remove this piece of on hold equipment?"
        )
      ) {
        return;
      }

      await updateEquipment({ ...equipment, onHoldFor: null });

      this.$emit(
        "update:equipment",
        this.equipment.filter((e) => e?.id !== equipment?.id)
      );
    },
    async handleLoan(equipment) {
      this.$emit("loan", equipment);
    },
  },

  watch: {
    clientId: {
      immediate: true,
      async handler(to, from) {
        this.debounce = setTimeout(async () => {
          this.$data._equipment = await getClientEquipment(this.clientId);
        }, 500);
      },
    },
    equipment: {
      immediate: true,
      async handler(to, from) {
        clearTimeout(this.debounce);
        this.$data._equipment = to ?? (await getClientEquipment(this.clientId));
      },
    },
  },
};
</script>

<style scoped>
.equipment-on-hold {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.equipment-on-hold-search {
  align-items: flex-end;
  display: flex;
  margin-bottom: 1em;
  margin-left: auto;
}

.equipment-on-hold-search .icon-button {
  font-size: 0.755rem;
  margin-left: 0.5em;
}

.equipment-on-hold-item-wrapper {
  display: flex;
  width: 100%;
}

.equipment-on-hold-item-wrapper ~ .equipment-on-hold-item-wrapper {
  margin-top: 1em;
}

.tab-left:not(.tab-left-active) {
  --tab-color: var(--primary-black);
}

.equipment-on-hold-item {
  align-items: center;
  border-color: var(--primary-medium-gray);
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  padding: 1em;
}

.equipment-on-hold-item-actions {
  flex: 0;
  margin-left: 1em;
}

.equipment-on-hold-item-actions button ~ button {
  margin-top: 0.5em;
}

.equipment-on-hold-search-no-results {
  align-self: center;
  flex: 1;
}

.equipment-on-hold-item-info {
  display: grid;
  flex: 1;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.equipment-on-hold-item-info label {
  color: var(--primary-black);
  font-style: normal;
}

.equipment-on-hold-item-info > span {
  grid-column: span 4;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .equipment-on-hold-item-info {
    grid-template-columns: 1fr 1fr;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .equipment-on-hold-item-info {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>