<template>
  <section class="equipment-details">
    <section class="equipment-parts" v-if="parts.length > 0">
      <h4 v-if="partsTitle">{{ partsTitle }}</h4>

      <section class="grid" v-if="selectParts.length > 0">
        <label v-for="part in selectParts" :key="part.id">
          <span>{{ getName(part, $store.getters.user) }}</span>

          <select
            :disabled="partsDisabled"
            :value="partValues[part.id]"
            @input="handlePartInput($event, part)"
          >
            <option></option>
            <option
              v-for="option in part.options"
              :key="option.id"
              :value="option.value"
            >{{ option.value }}</option>
          </select>
        </label>
      </section>

      <section class="equipment-options" v-if="checkboxParts.length > 0">
        <label v-for="(part, index) in checkboxParts" :key="index">
          <input
            :checked="partValues[part.id]"
            :disabled="partsDisabled"
            type="checkbox"
            @input="handlePartInput($event, part)"
          />
          <span>{{ getName(part, $store.getters.user) }}</span>
        </label>
      </section>
    </section>

    <section class="equipment-accessories" v-if="accessories.length > 0">
      <h4 v-if="accessoriesTitle">{{ accessoriesTitle }}</h4>

      <section class="grid equipment-options" v-if="selectAccessories.length > 0">
        <label v-for="accessory in selectAccessories" :key="accessory.id">
          <span>{{ getName(accessory, $store.getters.user) }}</span>

          <select
            :disabled="accessoriesDisabled"
            :value="accessoryValues[accessory.id]"
            @input="handleAccessoryInput($event, accessory)"
          >
            <option></option>
            <option
              v-for="option in accessory.options"
              :key="option.id"
              :value="option.value"
            >{{ option.value }}</option>
          </select>
        </label>
      </section>

      <section class="grid equipment-options" v-if="checkboxAccessories.length > 0">
        <label v-for="(accessory, index) in checkboxAccessories" :key="index">
          <input
            :checked="accessoryValues[accessory.id]"
            :disabled="accessoriesDisabled"
            type="checkbox"
            @input="handleAccessoryInput($event, accessory)"
          />
          <span>{{ getName(accessory, $store.getters.user) }}</span>
        </label>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    accessories: {
      type: Array,
      default: () => [],
    },
    accessoriesTitle: {
      type: String,
      default: "Accessories",
    },
    accessoryValues: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isWheelchair: {
      type: Boolean,
      default: false,
    },
    parts: {
      type: Array,
      default: () => [],
    },
    partsTitle: {
      type: String,
      default: null,
    },
    partValues: {
      type: Object,
    },
  },

  computed: {
    accessoriesDisabled() {
      return this.disabled || !this.can('UPDATE_EQUIPMENT')
    },
    checkboxAccessories() {
      return this.accessories.filter(
        (accessory) => accessory?.type === "CHECKBOX"
      );
    },
    checkboxParts() {
      return this.parts.filter((part) => part?.type === "CHECKBOX");
    },
    partsDisabled() {
      return this.disabled || !this.can('UPDATE_EQUIPMENT')
    },
    selectAccessories() {
      return this.accessories.filter(
        (accessory) => accessory?.type === "SELECT"
      );
    },
    selectParts() {
      return this.parts.filter((part) => part?.type === "SELECT");
    },
  },

  methods: {
    handleAccessoryInput(event, accessory) {
      this.$emit("update:accessory-values", {
        ...this.accessoryValues,
        [accessory.id]:
          event.target.type !== "checkbox"
            ? event.target.value || undefined
            : event.target.checked || undefined,
      });
    },
    handlePartInput(event, part) {
      this.$emit("update:part-values", {
        ...this.partValues,
        [part.id]:
          event.target.type !== "checkbox"
            ? event.target.value || undefined
            : event.target.checked || undefined,
      });
    },
  },
};
</script>

<style>
.equipment-details .grid {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding: 0.5em 1em;
  width: 100%;
}

.equipment-details .equipment-options {
  display: flex;
  border-color: var(--primary-medium-gray);
  border-style: solid;
  border-width: 1px;
  justify-content: space-evenly;
  margin-top: 0.5rem;
  padding: 2em;
}

.equipment-details .equipment-options label {
  align-items: center;
  flex-direction: row;
}

.equipment-details .equipment-options label input[type="checkbox" i] {
  margin: 0 0.3em 0 0;
}

.equipment-details h4 {
  display: block;
  font-weight: 500;
  margin-bottom: -0.4em;
  margin-top: 0.5em;
}

.equipment-details .equipment-accessories .equipment-options {
  border-color: var(--primary-medium-gray);
  border-style: solid;
  border-width: 1px;
  display: grid;
  margin-top: 0.5rem;
  padding: 2em;
}
</style>