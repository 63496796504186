<template>
  <ContentWrapper
    :create-icon="can('CREATE_EQUIPMENT_ITEMS') ? 'add-equipment-item' : null"
    :header-tag="headerTag"
    search-input-id="admin-equipment-item-search-phrase"
    :show-back="showBack"
    title="Equipment Items"
    @create="handleCreate"
    @search="handleSearch"
  >
    <EquipmentItemsTable
      class="admin-table"
      :selected-id="item.id"
      :items="filteredItems"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="item.id ? `Edit - ${item.name}` : 'Create Equipment Item'"
      />

      <span class="admin-form-tab">
        <img alt="Equipment Items" src="../../../assets/icons/equipment-item.svg" />
      </span>

      <EquipmentItemsForm
        class="admin-form"
        :disabled="item.name === undefined"
        :item="item"
        :subcategories="$data._subcategories"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import EquipmentItemsTable from "../../../components/admin/EquipmentItemsTable";
import EquipmentItemsForm from "../../../components/admin/EquipmentItemsForm";

import {
  getEquipmentItems,
  getEquipmentSubcategories,
} from "../../../api/EquipmentAPI";

export default {
  data() {
    return {
      item: {},
      _items: [],
      _subcategories: [],
      search: "",
    };
  },

  props: {
    headerTag: {
      item: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    items: {
      item: Array,
      default: undefined,
    },
    routeParams: {
      item: Object,
      default: undefined,
    },
    showBack: {
      item: Boolean,
      default: true,
    },
    subcategories: {
      item: Array,
      default: undefined,
    },
  },

  computed: {
    filteredItems() {
      const regex = this.getFilterRegex(this.search);

      return this.$data._items.filter((t) =>
        regex.test(
          [t?.name ?? "", t?.subcategory?.name ?? ""].join(
            String.fromCharCode(0x1f)
          )
        )
      );
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.item = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.item = { name: "", name_fr: null };
      }
    },
    handleDelete(item) {
      this.$data._items = this.$data._items.filter((t) => t?.id !== item?.id);
      this.item = {};
    },
    handleSave(item) {
      const created = !this.item.id;

      this.$data._items = [
        ...this.$data._items.filter((t) => t?.id !== item?.id),
        item,
      ];

      this.handleSelect(created ? item : undefined, true);

      this.showAlert(
        `Your equipment item has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(item, force = false) {
      if (force || this.confirmIfDirty()) {
        this.item = {
          ...this.$data._items.find((t) => t?.id === item?.id),
        };
      }
    },
  },

  watch: {
    "$data._items"(to, from) {
      if (to !== from) {
        this.$data._items = to.sort(this.sortBy("name", "id"));
        this.$emit("update:items", this.$data._items);
      }
    },
    "$data._subcategories"(to, from) {
      if (to !== from) {
        this.$data._subcategories = to.sort(this.sortBy("name", "id"));

        const map = to.reduce(
          (map, subcategory) => ({ ...map, [subcategory.id]: subcategory }),
          {}
        );

        this.$data._items.forEach(
          (item) => (item.subcategory = map[item.subcategory?.id] ?? {})
        );
      }
    },
    "item.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    async items(to, from) {
      to === this.$data._items ||
        (this.$data._items = to?.slice?.() ?? (await getEquipmentItems()));
    },
    subcategories: {
      immediate: true,
      async handler(to, from) {
        this.$data._subcategories =
          to?.slice?.() ?? (await getEquipmentSubcategories());
      },
    },
  },

  async created() {
    this.$data._items = this.items?.slice?.() ?? (await getEquipmentItems());
    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    EquipmentItemsTable,
    EquipmentItemsForm,
  },
};
</script>