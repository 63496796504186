<template>
  <input
    :autocomplete="autocomplete"
    placeholder="you@example.com"
    type="email"
    :value="value"
    v-on="listeners"
    @input="handleInput"
  />
</template>

<script>
export default {
  props: {
    autocomplete: {
      type: String,
      default: "email"
    },
    value: {
      type: String,
      default: ""
    }
  },

  computed: {
    listeners() {
      const listeners = { ...this.$listeners };
      delete listeners.input;
      return listeners;
    }
  },

  methods: {
    handleInput(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>