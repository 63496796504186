<template>
  <PartialView>
    <LoanTypes
      class="admin-content-partial"
      ref="loanTypes"
      header-tag="h3"
      :id="loanTypeId"
      :route-params="{view: 'types'}"
      :show-back="false"
      v-if="hasAdmin('LOAN_TYPES')"
    />
  </PartialView>
</template>

<script>
import PartialView from "./PartialView";
import LoanTypes from "./pages/LoanTypes";

export default {
  components: {
    PartialView,
    LoanTypes,
  },

  data() {
    return {
      loanTypeId: this.view === "types" ? this.id : undefined,
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined,
    },
    view: {
      type: String,
      default: "types",
    },
  },

  methods: {
    isDirty() {
      return false;
    },
  },

  mounted() {
    this.$refs.loanTypes && (this.$refs.loanTypes.$el.id = "admin-loan-types");
  },

  beforeRouteUpdate(to, from, next) {
    to.params.view === "types" &&
      (this.loanTypeId = +to.params.id || undefined);

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (
      !to.params.skipDirtyCheck &&
      Object.values(this.$refs).find((r) => r?.isDirty?.()) &&
      !this.confirmDiscard()
    ) {
      return next(false);
    }

    this.abortRequests();
    next();
  },
};
</script>