export const scrollPosition = {
    methods: {
        setScrollPosition() {
            const position = window.scrollY;
            const height = document.body.scrollHeight || document.body.offsetHeight;

            this.$nextTick(() => {
                window.scrollTo({
                    top:
                        position +
                        (document.body.scrollHeight || document.body.offsetHeight) -
                        height,
                });
            });
        },
    },
};

export default scrollPosition;
