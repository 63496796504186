<template>
  <search-form id="search-form" tag="form" v-model="$data._phrase" @submit.prevent="handleSubmit">
    <fieldset>
      <section class="search-types">
        <label class="radio" v-if="can('READ_CLIENTS')">
          <input
            id="search-type-clients"
            name="search-type"
            type="radio"
            value="clients"
            v-model="$data._type"
          />

          <span>Clients</span>
        </label>

        <label class="radio" v-if="can('READ_EQUIPMENT')">
          <input
            id="search-type-equipment"
            name="search-type"
            type="radio"
            value="equipment"
            v-model="$data._type"
          />

          <span>Equipment</span>
        </label>

        <label
          class="radio"
          v-if="can('READ_REFERRAL_AGENTS')"
        >
          <input
            id="search-type-referral-agent"
            name="search-type"
            type="radio"
            value="referral-agents"
            v-model="$data._type"
          />

          <span>Referral Agents</span>
        </label>

        <label class="radio" v-if="can('READ_INVOICES')">
          <input
            id="search-type-invoice"
            name="search-type"
            type="radio"
            value="invoices"
            v-model="$data._type"
          />

          <span>Invoices</span>
        </label>
      </section>
    </fieldset>
  </search-form>
</template>

<script>
import { search } from "../../api/SearchAPI";

export default {
  data() {
    return {
      _phrase: this.phrase,
      _type: this.type,
    };
  },

  props: {
    phrase: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "clients",
    },
  },

  methods: {
    async handleSubmit() {
      this.$emit(
        "search",
        { phrase: this.$data._phrase, type: this.$data._type }
      );
    },
  },

  watch: {
    phrase: {
      immediate: true,
      handler(to, from) {
        this.$data._phrase = to;
      },
    },
    type: {
      immediate: true,
      handler(to, from) {
        this.$data._type = to;
      },
    },
  },

  mounted() {
    this.$el.querySelector('input[name="search-type" i]:checked') ||
      this.$el.querySelector('input[name="search-type" i]')?.click?.();
  },
};
</script>

<style>
#search-form {
  color: inherit;
  font-size: inherit;
}

#search-form label:not(.radio) {
  color: var(--primary-red);
}

#search-form .search-types {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

#search-form .search-types label {
  color: inherit;
  display: flex;
  font-size: inherit;
}
</style>