<template>
  <section>
    <content-header
      :prev="showBack && {name: 'Admin'}"
      :sticky="headerSticky"
      :tag="headerTag"
      :title="title"
    />

    <search-form class="admin-search" :debounce="200" :input-id="searchInputId" v-model="search" />

    <icon-button
      class="admin-create-button"
      :name="createIcon"
      :title="createButtonTitle"
      @click="handleCreate"
      v-if="createIcon"
    />

    <slot />
  </section>
</template>

<script>
export default {
  data() {
    return {
      search: this.initialSearch,
    };
  },

  props: {
    createIcon: {
      type: String,
      default: undefined,
    },
    createTitle: {
      type: String,
      default: undefined,
    },
    headerSticky: {
      type: Boolean,
      default: undefined,
    },
    headerTag: {
      type: String,
      default: undefined,
    },
    initialSearch: {
      type: String,
      default: "",
    },
    searchInputId: {
      type: String,
      default: null,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
    },
  },

  computed: {
    createButtonTitle() {
      return (
        this.createTitle ??
        `Create ${this.title.replace(/(ie)?s$|(es)$/, (a, b) => b ? "y" : "")}`
      );
    },
  },

  methods: {
    handleCreate() {
      this.$emit("create");
    },
  },

  watch: {
    search(to, from) {
      this.$emit("search", to);
    },
  },
};
</script>