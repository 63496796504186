<template>
  <section class="loan-form" @keydown="handleKeypress">
    <label>
      <span class="required">Equipment Number</span>

      <filtered-select
        :disabled="disabled || mode !== 'full'"
        required
        v-model="loan.equipment.id"
        @input="handleEquipmentChange"
      >
        <option v-for="opt in equipmentOptions" :key="opt.id" :value="opt.id">
          {{ getEquipmentNumber(opt) }}
        </option>
      </filtered-select>
    </label>

    <label v-if="showEquipment">
      <span class="required">Item Name</span>

      <select
        :disabled="disabled || mode !== 'full'"
        required
        v-model.number="equipmentItemId"
      >
        <option disabled></option>
        <option
          v-for="equipmentItem in equipmentItemOptions"
          :key="equipmentItem.id"
          :value="equipmentItem.id"
        >
          {{ equipmentItem.name }}
        </option>
      </select>
    </label>

    <label v-if="showEquipment">
      <span class="required">Status</span>
      <select
        :disabled="disabled || mode !== 'full'"
        v-model.number="loan.equipment.status.id"
      >
        <template v-if="loan.equipment.deleted">
          <option :value="loan.equipment.status.id">Deleted</option>
        </template>
        <template v-else>
          <option
            v-for="status in $data._statuses"
            :key="status.id"
            :value="status.id"
          >
            {{ status.name }}
          </option>
        </template>
      </select>
    </label>

    <label v-if="showEquipment">
      <span class="required">Equipment Purchase Date</span>

      <date-input
        :disabled="disabled || mode !== 'full'"
        v-model="loan.equipment.purchaseDate"
      />
    </label>

    <label v-if="showClient">
      <span class="required">Client Number</span>

      <select
        :disabled="disabled || mode !== 'full'"
        required
        v-model.number="loan.client.id"
      >
        <option disabled></option>
        <option
          v-for="client in clientNumberOptions"
          :key="client.id"
          :value="client.id"
        >
          {{ client.clientNumber }}
        </option>
      </select>
    </label>

    <label v-if="showClient">
      <span class="required">Client Name</span>

      <select
        :disabled="disabled || mode !== 'full'"
        required
        v-model.number="loan.client.id"
      >
        <option disabled></option>
        <option
          v-for="client in clientNameOptions"
          :key="client.id"
          :value="client.id"
        >
          {{ getFullName(client) }}
        </option>
      </select>
    </label>

    <label>
      <span class="required">Loan Date</span>

      <date-input
        :disabled="disabled || mode !== 'full'"
        v-model="loan.loanDate"
      />
    </label>

    <label>
      <span>Return Date</span>

      <date-input
        :disabled="disabled || mode === 'readonly'"
        v-model="returnDate"
      />
    </label>

    <label>
      <span class="required">Loan Program</span>

      <select
        :disabled="disabled || mode !== 'full'"
        required
        v-model.number="loan.program.id"
      >
        <option disabled></option>
        <option
          v-for="program in programOptions"
          :key="program.id"
          :value="program.id"
        >
          {{ program.acronym }} - {{ program.name }}
        </option>
      </select>
    </label>

    <label>
      <span class="required">Loan Type</span>

      <select
        :disabled="disabled || mode !== 'full'"
        required
        v-model.number="loan.type.id"
      >
        <option disabled></option>
        <option v-for="type in typeOptions" :key="type.id" :value="type.id">
          {{ type.name }}
        </option>
      </select>
    </label>

    <label>
      <span class="required">Referral Agent</span>

      <filtered-select
        :disabled="disabled || mode !== 'full'"
        required
        v-model.number="loan.referralAgent.id"
      >
        <option disabled></option>
        <option :value="undefined">Unknown</option>
        <option
          v-for="referralAgent in referralAgentOptions"
          :key="referralAgent.id"
          :value="referralAgent.id"
        >
          {{ getFullName(referralAgent) }}
        </option>
      </filtered-select>
    </label>

    <label v-if="displayConfirmationReceived">
      <span>Confirmation Received</span>
      <select :disabled="disabled || mode === 'readonly'" v-model="loan.loanConfirmationReceived">
        <option :key="true" value="true">Yes</option>
        <option :key="false" value="false">No</option>
      </select>
    </label>

    <label v-if="!showEquipment && isReturningLoan">
      <span class="required">Return Status</span>
      <select
        :disabled="disabled || mode === 'readonly' || !isReturningLoan"
        v-model.number="returnStatusId"
      >
        <option
          v-for="status in $data._statuses"
          :key="status.id"
          :value="status.id"
        >
          {{ status.name }}
        </option>
      </select>
    </label>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        type="button"
        @click="handleDelete"
        v-if="canDelete && loan.id"
      >
        Delete
      </button>

      <button
        :disabled="!isSaveEnabled"
        type="button"
        @click="handleSubmit"
        v-if="canSave"
      >
        Save
      </button>

      <button
        :disabled="!isCancelEnabled"
        type="button"
        @click="handleCancel"
        v-if="canCancel"
      >
        Cancel
      </button>
    </footer>
  </section>
</template>

<script>
export default {
  data() {
    return {
      _statuses: [],
      returnDate: null,
      returnStatusId: null,
    };
  },

  props: {
    canCancel: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default() {
        return this.$store.getters.user?.permissions?.includes("DELETE_LOANS");
      },
    },
    canSave: {
      type: Boolean,
      default() {
        return !!this.$store.getters.user?.permissions?.find(
          (p) => p === "CREATE_LOANS" || p === "UPDATE_LOANS"
        );
      },
    },
    clients: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    equipment: {
      type: Array,
    },
    loan: {
      type: Object,
    },
    mode: {
      type: String,
      default: "full",
      validator: (value) => ["full", "limited", "readonly"].includes(value),
    },
    programs: {
      type: Array,
    },
    statuses: {
      type: Array,
    },
    referralAgents: {
      type: Array,
    },
    showClient: {
      type: Boolean,
      default: true,
    },
    showEquipment: {
      type: Boolean,
      default: true,
    },
    types: {
      type: Array,
    },
    displayConfirmationReceived: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    clientNameOptions() {
      if (!this.showClient || this.disabled || this.mode !== "full") {
        return this.loan?.client ? [this.loan.client] : [];
      }

      return this.filterOptions(this.clients, this.loan?.client).sort(
        this.sortBy("firstName", "lastName", "id")
      );
    },
    clientNumberOptions() {
      return this.clientNameOptions
        ?.slice()
        .sort(this.sortBy("clientNumber", "id"));
    },
    equipmentOptions() {
      if (this.disabled || this.mode !== "full") {
        return this.loan?.equipment ? [this.loan.equipment] : [];
      }

      const loanEquipNo = this.getEquipmentNumber(this.loan.equipment);
      const exists = {};

      const equipment = this.equipment
        .slice()
        .filter((equipment, index, array) => {
          if (!equipment || exists[equipment.id]) return false;

          if (loanEquipNo) {
            const currentEquipNo = this.getEquipmentNumber(equipment);
            const nextEquipNo = this.getEquipmentNumber(array[index + 1]);

            if (index === 0 && loanEquipNo < currentEquipNo) {
              array.unshift(this.loan.equipment);
            } else if (
              nextEquipNo &&
              currentEquipNo < loanEquipNo &&
              loanEquipNo < nextEquipNo
            ) {
              array.splice(index + 1, 0, this.loan.equipment);
            } else if (!nextEquipNo && currentEquipNo < loanEquipNo) {
              array.push(this.loan.equipment);
            }
          }

          if (
            equipment.id === this.loan?.equipment?.id ||
            (!equipment.onHold && !equipment.onLoan && !equipment.lost)
          ) {
            return (exists[equipment.id] = true);
          } else {
            return false;
          }
        });

      return equipment.length || !this.loan?.equipment
        ? equipment
        : [this.loan.equipment];
    },
    equipmentItemId() {
      return this.equipmentItemOptions?.[0]?.id;
    },
    equipmentItemOptions() {
      const equipmentItem = (
        this.equipment?.find((e) => e.id === this.loan?.equipment?.id) ??
        this.loan?.equipment
      )?.item;

      return equipmentItem ? [equipmentItem] : [];
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isCreating() {
      return !this.disabled && !this.loan?.id && this.isSaveEnabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.loan?.id;
    },
    isEditing() {
      return !this.disabled && this.loan?.id && this.isSaveEnabled;
    },
    isReturningLoan() {
      return (
        !this.disabled &&
        this.loan?.id &&
        !this.loan.equipment?.deleted &&
        !this.loan.returnDate &&
        this.returnDate
      );
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.loan?.id ? this.can("UPDATE_LOANS") : this.can("CREATE_LOANS"))
      );
    },
    programOptions() {
      if (this.disabled || this.mode !== "full") {
        return this.loan?.program ? [this.loan.program] : [];
      }

      return this.filterOptions(this.programs, this.loan?.program).sort(
        this.sortBy("name", "id")
      );
    },
    referralAgentOptions() {
      if (this.disabled || this.mode !== "full") {
        return this.loan?.referralAgent ? [this.loan.referralAgent] : [];
      }

      return this.filterOptions(
        this.referralAgents,
        this.loan?.referralAgent
      ).sort(this.sortBy("firstName", "lastName", "id"));
    },
    typeOptions() {
      if (this.disabled || this.mode !== "full") {
        return this.loan?.type ? [this.loan.type] : [];
      }

      return this.filterOptions(this.types, this.loan?.type).sort(
        this.sortBy("name", "id")
      );
    },
  },

  methods: {
    handleCancel() {
      this.$emit("cancel", this.loan);
    },
    handleDelete() {
      this.$emit("delete", this.loan);
    },
    handleEquipmentChange(equipmentId) {
      this.loan.equipment = {
        ...this.equipment.find((equipment) => equipment.id === +equipmentId),
      };
    },
    handleKeypress(e) {
      if (e.key === "Enter" && !(e.target instanceof HTMLTextAreaElement)) {
        e.preventDefault();
        e.stopPropagation();
        this.handleSubmit();
      }
    },
    handleSubmit() {
      this.checkValidity() &&
        this.$emit(
          "submit",
          !this.isReturningLoan ? {...this.loan, returnDate: this.returnDate}
            : {
                ...this.loan,
                equipment: {
                  ...this.loan.equipment,
                  status: (this.showEquipment)
                    ? this.loan.equipment.status 
                    : ( this.returnStatusId
                        ? { id: this.returnStatusId }
                        : null
                      ),
                },
                returnDate: this.returnDate,
              }
        );
    },
  },

  watch: {
    loan: {
      immediate: true,
      handler(to, from) {
        if (to) {
          to.client = to.client ?? {};
          to.equipment = to.equipment ?? {};
          to.equipment.status = to.equipment.status ?? {};
          to.program = to.program ?? {};
          to.referralAgent = to.referralAgent ?? {};
          to.type = to.type ?? {};

          this.returnDate = to?.returnDate;
          this.returnStatusId = undefined;
        }
      },
    },
    "loan.equipment": {
      handler(to, from) {
        if (to) {
          to.status = { ...to.status };
        }
      },
    },
    statuses: {
      immediate: true,
      handler(to, from) {
        this.$data._statuses = [
          { id: undefined, name: "Available" },
          ...(to ?? []),
        ].sort(this.sortBy("name", "id"));
      },
    },
  },
};
</script>

<style scoped>
.loan-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.loan-form > footer {
  grid-column: span 4;
  margin: 0;
  padding: 0.5em 0 0 0;
}

@media only screen and (max-width: 768px) {
  .loan-form .loan-history-item-info {
    display: block;
  }
}
</style>