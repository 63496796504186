import Vue from 'vue'
Vue.config.productionTip = false;

import router from './router'
import store from './store'
import App from './App.vue'

require('./mixins');

const requireComponent = require.context('./base-components', true, /.*\.vue$/);

requireComponent.keys().forEach(filename => {
  const config = requireComponent(filename);
  const name = filename.split('/').pop().replace(/\.\w+$/, '').replace(/(^|[a-z])([A-Z])/g, (_, a, b) => (a ? `${a}-${b}` : b).toLowerCase());
  Vue.component((filename.indexOf('./inputs/') === 0) ? `${name}-input` : name, config.default || config);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// Write unhandledrejection to console.debug instead of console.error
window.addEventListener('unhandledrejection', e => {
  if (e?.reason?.constructor?.name === 'APIError') {
    e.preventDefault();
    [401, 403].includes(e?.reason?.status) || console.debug('Uncaught (in promise)', e.reason);
  } else if (typeof (e?.reason ?? false) === 'boolean') {
    e.preventDefault();
  }
});