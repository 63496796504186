<template>
  <section class="repair-form" @keydown="handleKeypress">
    <label>
      <span class="required">Client</span>

      <select :disabled="disabled || mode !== 'full'" required v-model.number="service.client.id">
        <option disabled></option>
        <option
          v-for="client in clientOptions"
          :key="client.id"
          :value="client.id"
        >{{ getFullName(client) }}</option>
      </select>
    </label>

    <label>
      <span>Equipment Number</span>

      <filtered-select 
        :disabled="disabled || mode !== 'full'"
        v-model="service.equipment.id"
      >
        <option
          v-for="opt in equipmentOptions"
          :key="opt.id"
          :value="opt.id"
        >
          {{ getEquipmentNumber(opt) }}
        </option>
      </filtered-select>
    </label>

    <label>
      <span class="required">Service Category</span>

      <select
        :disabled="disabled || mode !== 'full'"
        required
        v-model.number="service.category.id"
        @change="service.subcategory.id = undefined"
      >
        <option disabled></option>
        <option
          v-for="category in categoryOptions"
          :key="category.id"
          :value="category.id"
        >{{ category.name }}</option>
      </select>
    </label>

    <label>
      <span class="required">Service Subcategory</span>

      <select
        :disabled="disabled || mode !== 'full'"
        required
        v-model.number="service.subcategory.id"
      >
        <option disabled></option>
        <option
          v-for="subcategory in subcategoryOptions"
          :key="subcategory.id"
          :value="subcategory.id"
        >{{ subcategory.name }}</option>
      </select>
    </label>

    <label>
      <span class="required">Service Date</span>

      <date-input :disabled="disabled || mode !== 'full'" required v-model="service.serviceDate" />
    </label>

    <label>
      <span class="required">Details</span>

      <textarea
        :disabled="disabled || mode !== 'full'"
        required
        v-model.trim="service.description"
      />
    </label>

    <label>
      <span class="required">Labour Hours</span>

      <input
        :disabled="disabled || mode !== 'full'"
        min="0"
        required
        step="0.01"
        type="number"
        v-model="service.hours"
      />
    </label>

    <label>
      <span class="required">Billing Code</span>

      <select
        :disabled="disabled || mode !== 'full'"
        required
        v-model.number="service.billingCode.id"
      >
        <option disabled></option>
        <option
          v-for="billingCode in billingCodeOptions"
          :key="billingCode.id"
          :value="billingCode.id"
        >{{ billingCode.code }} - {{ billingCode.description }}</option>
      </select>
    </label>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        type="button"
        @click="handleDelete"
        v-if="canDelete && service.id"
      >Delete</button>

      <button :disabled="!isSaveEnabled" type="button" @click="handleSubmit" v-if="canSave">Save</button>

      <button
        :disabled="!isCancelEnabled"
        type="button"
        @click="handleCancel"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </section>
</template>

<script>
export default {
  props: {
    billingCodes: {
      type: Array,
    },
    canCancel: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default() {
        return this.$store.getters.user?.permissions?.includes(
          "DELETE_SERVICES"
        );
      },
    },
    canSave: {
      type: Boolean,
      default() {
        return !!this.$store.getters.user?.permissions?.find(
          (p) => p === "CREATE_SERVICES" || p === "UPDATE_SERVICES"
        );
      },
    },
    categories: {
      type: Array,
    },
    clients: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    equipment: {
      type: Array,
    },
    mode: {
      type: String,
      default: "full",
      validator: (value) => ["full", "limited", "readonly"].includes(value),
    },
    service: {
      type: Object,
    },
    subcategories: {
      type: Array,
    },
  },

  computed: {
    billingCodeOptions() {
      if (this.disabled || this.mode !== "full") {
        return this.service?.billingCode ? [this.service.billingCode] : [];
      }

      return this.filterOptions(
        this.billingCodes,
        this.service?.billingCode
      ).sort(this.sortBy("code", "description"));
    },
    categoryOptions() {
      if (this.disabled || this.mode !== "full") {
        return this.service?.category ? [this.service.category] : [];
      }

      return this.filterOptions(this.categories, this.service?.category).sort(
        this.sortBy("name", "id")
      );
    },
    clientOptions() {
      if (this.disabled || this.mode !== "full") {
        return this.service?.client ? [this.service.client] : [];
      }

      return this.filterOptions(this.clients, this.service?.client).sort(
        this.sortBy("firstName", "lastName", "id")
      );
    },
    equipmentOptions() {
      if (this.disabled || this.mode !== "full") {
        return this.service?.equipment ? [this.service.equipment] : [];
      }

      const exists = {};

      const equipment = this.equipment
        .slice()
        .filter((equipment, index, array) => {
          if (!equipment || exists[equipment.id]) return false;

          return (exists[equipment.id] = true);
        });

      return equipment.length || !this.service?.equipment
        ? equipment
        : [this.service.equipment];
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isCreating() {
      return !this.disabled && !this.service?.id && this.isSaveEnabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.service?.id;
    },
    isEditing() {
      return !this.disabled && this.service?.id && this.isSaveEnabled;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.service?.id
          ? this.can("UPDATE_SERVICES")
          : this.can("CREATE_SERVICES"))
      );
    },
    subcategoryOptions() {
      if (this.disabled || this.mode !== "full") {
        return this.service?.subcategory ? [this.service.subcategory] : [];
      }

      return this.filterOptions(this.subcategories, this.service?.subcategory)
        .filter(
          (subcategory) =>
            this.service?.category?.id &&
            subcategory.category?.id === this.service?.category?.id
        )
        .sort(this.sortBy("name", "id"));
    },
  },

  methods: {
    handleCancel() {
      this.$emit("cancel", this.service);
    },
    handleDelete() {
      this.$emit("delete", this.service);
    },
    handleKeypress(e) {
      if (e.key === "Enter" && !(e.target instanceof HTMLTextAreaElement)) {
        e.preventDefault();
        e.stopPropagation();
        this.handleSubmit();
      }
    },
    handleSubmit() {
      this.checkValidity() && this.$emit("submit", this.service);
    },
  },

  watch: {
    service: {
      immediate: true,
      handler(to, from) {
        if (to) {
          to.billingCode = to.billingCode ?? {};
          to.category = to.category ?? {};
          to.client = to.client ?? {};
          to.equipment = to.equipment ?? {};
          to.subcategory = to.subcategory ?? {};
        }
      },
    },
  },
};
</script>

<style scoped>
.repair-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.repair-form > footer {
  grid-column: span 4;
  margin: 0;
  padding: 0.5em 0 0 0;
}

@media only screen and (max-width: 768px) {
  .repair-form .repair-history-item-info {
    display: block;
  }
}
</style>