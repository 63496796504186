<template>
  <form
    autocomplete="off"
    class="admin-service-categories-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Name</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-service-category-name"
          required
          type="text"
          v-model.trim="name"
        />
      </label>
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-service-category-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && category.id"
      >Delete</button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-service-category-save"
        type="submit"
        v-if="canSave"
      >Save</button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-service-category-cancel"
        type="reset"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </form>
</template>

<script>
import {
  deleteServiceCategory,
  saveServiceCategory
} from "../../api/ServiceAPI";

export default {
  data() {
    return {
      name: undefined
    };
  },

  props: {
    category: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_SERVICE_CATEGORIES");
    },
    canSave() {
      return this.can({
        permissions: [
          "CREATE_SERVICE_CATEGORIES",
          "UPDATE_SERVICE_CATEGORIES"
        ],
        matchAny: true
      });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.category?.id && !this.category?.protected;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.category?.id
          ? this.can("UPDATE_SERVICE_CATEGORIES")
          : this.can("CREATE_SERVICE_CATEGORIES"))
      );
    }
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (
        !confirm("Are you sure you want to delete this service category?")
      ) {
        return;
      }

      await deleteServiceCategory(this.category);
      this.$emit("delete", this.category);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveServiceCategory({
            ...this.category,
            name: this.name
          })
        );
      } catch (e) {
        this.showErrors(e, "An service category");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return this.name !== this.category?.name;
    }
  },

  watch: {
    category: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.name = to?.name;
        }

        !this.disabled && this.autofocus();
      }
    },
    disabled(to, from) {
      !to && from && this.autofocus();
    }
  }
};
</script>

<style>
.admin-service-categories-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.admin-service-categories-form > footer {
  grid-column: span 2;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>