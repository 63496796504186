<template>
  <form
    autocomplete="off"
    class="admin-equipment-items-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Equipment Item</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-equipment-item-name"
          required
          type="text"
          v-model.trim="name"
        />
      </label>

      <label>
        <span>Name Fr</span>

        <input
          :disabled="disabled"
          id="admin-equipment-subcategory-item-fr"
          type="text"
          v-model.trim="name_fr"
        />
      </label>

      <label>
        <span>Subcategory</span>

        <select
          :disabled="disabled"
          id="admin-equipment-item-subcategory"
          v-model.number="subcategoryId"
        >
          <option></option>

          <option
            v-for="subcategory in filterOptions(subcategories, item.subcategory)"
            :key="subcategory.id"
            :value="subcategory.id"
          >{{ subcategory.name }}</option>
        </select>
      </label>
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-equipment-item-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && item.id"
      >Delete</button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-equipment-item-save"
        type="submit"
        v-if="canSave"
      >Save</button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-equipment-item-cancel"
        type="reset"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </form>
</template>

<script>
import { deleteEquipmentItem, saveEquipmentItem } from "../../api/EquipmentAPI";

export default {
  data() {
    return {
      name: undefined,
      name_fr: undefined,
      subcategoryId: undefined
    };
  },

  props: {
    disabled: {
      item: Boolean,
      default: false
    },
    item: {
      item: Object
    },
    subcategories: {
      item: Array
    }
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_EQUIPMENT_ITEMS");
    },
    canSave() {
      return this.can({ permissions: ["CREATE_EQUIPMENT_ITEMS", "UPDATE_EQUIPMENT_ITEMS"], matchAny: true });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.item?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.item?.id
          ? this.can("UPDATE_EQUIPMENT_ITEMS")
          : this.can("CREATE_EQUIPMENT_ITEMS"))
      );
    }
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (!confirm("Are you sure you want to delete this equipment item?")) {
        return;
      }

      await deleteEquipmentItem(this.item);
      this.$emit("delete", this.item);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveEquipmentItem({
            ...this.item,
            name: this.name,
            name_fr: this.name_fr,
            subcategory: this.subcategoryId
              ? { id: this.subcategoryId }
              : null
          })
        );
      } catch (e) {
        this.showErrors(e, "An equipment item");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return (
        this.name !== this.item?.name ||
        this.name_fr !== this.item?.name_fr ||
        this.subcategoryId !== this.item?.subcategory?.id
      );
    }
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    item: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.name = to?.name;
          this.name_fr = to?.name_fr;
          this.subcategoryId = to?.subcategory?.id;
        }

        !this.disabled && this.autofocus();
      }
    }
  }
};
</script>

<style>
.admin-equipment-items-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.admin-equipment-items-form > footer {
  grid-column: span 2;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>