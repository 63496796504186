import { del, get, post, put } from './http';

const API_PATH = '/api/clients';

export const createClient = async client =>
    await post(API_PATH, client);

export const deleteClient = async client =>
    await del(`${API_PATH}/${client.id}`);

export const generateDocuments = async ({ clientIds = [], templateIds = [] }) => {
    const file = await post(`${API_PATH}/generate-documents`, { clientIds, templateIds });
    const anchor = document.createElement('a');
    anchor.download = file.headers.get('content-disposition')?.match(/filename=(["'])(.*?)\1/)?.[2];
    anchor.href = window.URL.createObjectURL(await file.blob());
    anchor.click();
    window.URL.revokeObjectURL(anchor.href);
};

export const getClient = async id =>
    await Promise.all([
        get(`${API_PATH}/${id}`),
        get(`${API_PATH}/${id}/equipment/on-hold`).catch(handleForbidden(`${API_PATH}/${id}/equipment/on-hold`)),
        get(`${API_PATH}/${id}/invoices`).catch(handleForbidden(`${API_PATH}/${id}/invoices`)),
        get(`${API_PATH}/${id}/invoices/items`).catch(handleForbidden(`${API_PATH}/${id}/invoices/items`)),
        get(`${API_PATH}/${id}/loans`).catch(handleForbidden(`${API_PATH}/${id}/loans`)),
        get(`${API_PATH}/${id}/services`).catch(handleForbidden(`${API_PATH}/${id}/services`)),
    ]).then(([client, equipment, invoices, invoiceItems, loans, services]) => ({ ...client, equipment, invoices, invoiceItems, loans, services }));

const handleForbidden = url => err => {
    if (err.status === 404) {
        return get(url);
    } else if (err.status === 403) {
        return null;
    } else {
        throw err;
    }
};

export const getPaginatedClient = async ({ size, page, phrase, }) => {
    const params = new URLSearchParams();

    if (size != null)
        params.set('size', size);

    if (page != null)
        params.set('page', page);

    if (phrase != null)
        params.set('phrase', phrase.trim());

    return await get(`${API_PATH}/paginated?${params.toString()}`);

}

export const getClientEquipment = async (id, { onHold = true, onLoan = false }) =>
    await get(`${API_PATH}/${id}/equipment${onHold ^ onLoan ? (onHold ? '/on-hold' : '/on-loan') : ''}`);

export const getClients = async () =>
    await get(API_PATH);

export const getClientSummaries = async () =>
    await get(`${API_PATH}/summaries`);

export const saveClient = async client =>
    await (client.id ? updateClient(client) : createClient(client));

export const updateClient = async client =>
    await put(`${API_PATH}/${client.id}`, client);

export const getMostRecentLoan = async (clientId) => 
    await get(`${API_PATH}/${clientId}/loans/most-recent`)