<template>
  <section class="repair-info">
    <label>
      <span>Service Date</span>
      <strong>{{ formatTimestamp(service.serviceDate) }}</strong>
    </label>

    <label>
      <span>Labour Hours</span>
      <strong>{{ formattedHours }}</strong>
    </label>

    <label>
      <span>Details</span>
      <strong>{{ service.description }}</strong>
    </label>

    <label>
      <span>Client</span>
      <strong>{{ getFullName(service.client) }}</strong>
    </label>
  </section>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
    },
  },

  computed: {
    formattedHours() {
      return /\d/.test(this.service?.hours) ? Number(this.service.hours).toFixed(2) : "";
    },
  },
};
</script>

<style scoped>
.repair-info {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.repair-info label {
  color: var(--primary-black);
  font-style: normal;
}

.repair-info strong {
  max-height: 6em;
  overflow: auto;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .repair-info {
    grid-template-columns: 1fr 1fr;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .repair-info {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>