<template>
  <form
    autocomplete="off"
    class="admin-holidays-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Date</span>

        <date-input
          :disabled="disabled"
          id="admin-holiday-date"
          required
          v-model="date"
        />
      </label>

      <label>
        <span class="required">Name</span>

        <input
          :disabled="disabled"
          id="admin-holiday-name"
          required
          type="text"
          v-model.trim="name"
        />
      </label>

      <label>
        Description

        <input
          :disabled="disabled"
          id="admin-holiday-description"
          type="text"
          v-model.trim="description"
        />
      </label>
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-holiday-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && holiday.id"
      >
        Delete
      </button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-holiday-save"
        type="submit"
        v-if="canSave"
      >
        Save
      </button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-holiday-cancel"
        type="reset"
        v-if="canCancel"
      >
        Cancel
      </button>
    </footer>
  </form>
</template>

<script>
import { deleteHoliday, saveHoliday } from "../../api/HolidayAPI";

export default {
  data() {
    return {
      date: undefined,
      description: undefined,
      name: undefined,
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    holiday: {
      type: Object,
    },
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_HOLIDAYS");
    },
    canSave() {
      return this.can({
        permissions: ["CREATE_HOLIDAYS", "UPDATE_HOLIDAYS"],
        matchAny: true,
      });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.holiday?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.holiday?.id
          ? this.can("UPDATE_HOLIDAYS")
          : this.can("CREATE_HOLIDAYS"))
      );
    },
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (!confirm("Are you sure you want to delete this holiday?")) {
        return;
      }

      await deleteHoliday(this.holiday);
      this.$emit("delete", this.holiday);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveHoliday({
            ...this.holiday,
            date: this.date,
            name: this.name,
            description: this.description,
          })
        );
      } catch (e) {
        this.showErrors(e, "A holiday");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

        console.log(this.date, this.holiday?.date, this.date !== this.holiday?.date);
        console.log(this.name, this.holiday?.name, this.name !== this.holiday?.name);
        console.log(this.description, this.holiday?.description, this.lastName !== this.holiday?.description);
      return (
        this.date !== this.holiday?.date ||
        this.name !== this.holiday?.name ||
        this.description !== this.holiday?.description
      );
    },
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    holiday: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.date = to?.date;
          this.name = to?.name;
          this.description = to?.description;
        }

        !this.disabled && this.autofocus();
      },
    },
  },
};
</script>

<style>
.admin-holidays-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.admin-holidays-form .admin-holiday-referral-agent-label {
  align-items: center;
}

.admin-holidays-form > footer {
  grid-column: span 4;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>