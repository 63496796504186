<template>
  <form class="login-form" @submit.prevent="handleSubmit">
    <fieldset>
      <label>
        <img alt="Username" src="../../assets/common/user-default.svg" />

        <span>
          <span>Email Address</span>

          <router-link
            :disabled="submitting"
            tabindex="5"
            :to="{name: 'Forgot Email'}"
          >Forgot my email?</router-link>
        </span>

        <email-address-input
          autofocus
          id="login-username"
          required
          tabindex="1"
          v-model="$data._username"
        />
      </label>

      <label>
        <img alt="Password" src="../../assets/common/password-default.svg" />

        <span>
          <span>Password</span>

          <router-link
            :disabled="submitting"
            tabindex="6"
            :to="{name: 'Forgot Password'}"
          >Forgot my password?</router-link>
        </span>

        <input
          autocomplete="current-password"
          id="login-password"
          required
          tabindex="2"
          type="password"
          v-model="$data._password"
        />
      </label>

      <label class="remember-me">
        <input id="login-remember-me" tabindex="3" type="checkbox" v-model="$data._rememberMe" />

        <span>Remember Me</span>
      </label>
    </fieldset>

    <footer>
      <button :disabled="submitting" tabindex="4" type="submit">Log In</button>
    </footer>
  </form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      _username: this.username,
      _password: this.password,
      _rememberMe: this.rememberMe,
      submitting: false,
    };
  },

  props: {
    username: {
      type: String,
      default: "",
    },
    password: {
      type: String,
      default: "",
    },
    rememberMe: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ...mapActions(["login"]),
    async handleSubmit() {
      try {
        this.submitting = true;

        this.$emit(
          "login",
          await this.login({
            username: this.$data._username,
            password: this.$data._password,
            rememberMe: this.$data._rememberMe,
          })
        );
      } catch (e) {
        this.showError(e, "Login");
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style>
.login-form {
  --grid-column-gap: 1em;
  --grid-column-template: 3.5em 1fr;
  color: inherit;
  display: grid;
  font-size: inherit;
  grid-column-gap: var(--grid-column-gap);
  grid-template-areas:
    "email-icon      email-input"
    "password-icon   password-input"
    "remember-icon   remember-input"
    "footer          footer";
  grid-template-columns: var(--grid-column-template);
  padding: 1em;
}

.login-form img {
  align-self: flex-end;
}

.login-form label {
  color: inherit;
  display: grid;
  grid-column: span 2;
  grid-column-gap: var(--grid-column-gap);
  grid-template-areas:
    ".      label"
    "icon   input";
  grid-template-columns: var(--grid-column-template);
}

.login-form label a {
  color: var(--primary-red);
  font-size: 0.9em;
}

.login-form label img {
  grid-area: icon;
}

.login-form label input {
  align-self: center;
  font: inherit;
  font-style: initial;
  grid-area: input;
}

.login-form label span {
  align-items: flex-end;
  display: flex;
  grid-area: label;
  justify-content: space-between;
}

.login-form label ~ label {
  margin-top: 2em;
}

.login-form label.remember-me {
  align-items: center;
  align-self: flex-start;
  display: flex;
  grid-area: remember-input;
  flex-direction: row;
  margin-top: 0;
}

.login-form .remember-me input {
  margin-right: 0.33em;
}

.login-form > footer {
  grid-area: remember-input;
  margin: 0;
  padding: 0;
}
</style>