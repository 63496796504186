import { del, get, post, put } from './http';

/** Equipment **/
const API_PATH = '/api/equipment';

export const createEquipment = async (equipment, photos = []) =>
    await post(API_PATH, formatDataFormation(equipment, photos));

export const deleteEquipment = async equipment =>
    await del(`${API_PATH}/${equipment.id}`.replace(/\/+$/, ''));

export const getEquipment = async (id, args = { invoices: true, items: true, loans: true, services: true }) =>
    await (!id
        ? get(API_PATH)
        : Promise.all([
            get(`${API_PATH}/${id}`),
            args.invoices ? get(`${API_PATH}/${id}/invoices`).catch(handleForbidden(`${API_PATH}/${id}/invoices`)) : null,
            args.items ? get(`${API_PATH}/${id}/invoices/items`).catch(handleForbidden(`${API_PATH}/${id}/invoices/items`)) : null,
            args.loans ? get(`${API_PATH}/${id}/loans`).catch(handleForbidden(`${API_PATH}/${id}/loans`)) : null,
            args.services ? get(`${API_PATH}/${id}/services`).catch(handleForbidden(`${API_PATH}/${id}/services`)) : null,
        ]).then(([equipment, invoices, invoiceItems, loans, services]) => ({ ...equipment, invoices, invoiceItems, loans, services })));

export const getEquipmentSummaries = async () =>
    await get(`${API_PATH}/summaries`);

const handleForbidden = url => err => {
    if (err.status === 404) {
        return get(url);
    } else if (err.status === 403) {
        return null;
    } else {
        throw err;
    }
};

export const getPaginatedEquipment = async ({ size, page, phrase, programId, searchStatus, }) => {
    const params = new URLSearchParams();

    if (size != null)
        params.set('size', size);

    if (page != null)
        params.set('page', page);

    if (phrase != null)
        params.set('phrase', phrase.trim());

    if (programId != null)
        params.set('programId', programId);

    if (searchStatus != null) {
        if (typeof (searchStatus) == "number") {
            params.set('statusId', searchStatus);
        } else {
            params.set('statusString', searchStatus);
        }
    }

    return await get(`${API_PATH}/paginated?${params.toString()}`);
}

export const saveEquipment = async (equipment, photos = []) =>
    await (equipment.id ? updateEquipment(equipment, photos) : createEquipment(equipment, photos));


export const updateEquipment = async (equipment, photos = []) =>
    await put(`${API_PATH}/${equipment.id}`, formatDataFormation(equipment, photos));


function formatDataFormation(equipment, photos) {
    const data = new FormData();
    photos.forEach(file => data.append('photo', file, file.name));
    data.set('equipment', new Blob([JSON.stringify(equipment)], {
        type: 'application/json'
    }));

    return data;
}


/** Equipment Accessories **/
const ACCESSORY_API_PATH = `${API_PATH}/accessories`;

export const createEquipmentAccessory = async accessory =>
    await post(ACCESSORY_API_PATH, accessory);

export const deleteEquipmentAccessory = async accessory =>
    await del(`${ACCESSORY_API_PATH}/${accessory.id}`);

export const getEquipmentAccessories = async () =>
    await get(ACCESSORY_API_PATH);

export const saveEquipmentAccessory = async accessory =>
    await (accessory.id ? updateEquipmentAccessory(accessory) : createEquipmentAccessory(accessory));

export const updateEquipmentAccessory = async accessory =>
    await put(`${ACCESSORY_API_PATH}/${accessory.id}`, accessory);



/** Equipment Categories **/
const CATEGORY_API_PATH = `${API_PATH}/categories`;

export const createEquipmentCategory = async category =>
    await post(CATEGORY_API_PATH, category);

export const deleteEquipmentCategory = async category =>
    await del(`${CATEGORY_API_PATH}/${category.id}`);

export const getEquipmentCategories = async () =>
    await get(CATEGORY_API_PATH);

export const saveEquipmentCategory = async category =>
    await (category.id ? updateEquipmentCategory(category) : createEquipmentCategory(category));

export const updateEquipmentCategory = async category =>
    await put(`${CATEGORY_API_PATH}/${category.id}`, category);



/** Equipment Items **/
const ITEM_API_PATH = `${API_PATH}/items`;

export const createEquipmentItem = async item =>
    await post(ITEM_API_PATH, item);

export const deleteEquipmentItem = async item =>
    await del(`${ITEM_API_PATH}/${item.id}`);

export const getEquipmentItems = async () =>
    await get(ITEM_API_PATH);

export const saveEquipmentItem = async item =>
    await (item.id ? updateEquipmentItem(item) : createEquipmentItem(item));

export const updateEquipmentItem = async item =>
    await put(`${ITEM_API_PATH}/${item.id}`, item);



/** Equipment Parts **/
const PART_API_PATH = `${API_PATH}/parts`;

export const createEquipmentPart = async part =>
    await post(PART_API_PATH, part);

export const deleteEquipmentPart = async part =>
    await del(`${PART_API_PATH}/${part.id}`);

export const getEquipmentParts = async () =>
    await get(PART_API_PATH);

export const saveEquipmentPart = async part =>
    await (part.id ? updateEquipmentPart(part) : createEquipmentPart(part));

export const updateEquipmentPart = async part =>
    await put(`${PART_API_PATH}/${part.id}`, part);



/** Equipment Specifications (Wheelchair category) **/
export const createEquipmentSpecification = createEquipmentPart;

export const deleteEquipmentSpecification = deleteEquipmentPart;

export const getEquipmentSpecifications = async () =>
    (await getEquipmentParts()).reduce((acc, specification) => specification.category?.id === 1 ? [...acc, specification] : acc, []);

export const saveEquipmentSpecification = saveEquipmentPart;

export const updateEquipmentSpecification = updateEquipmentPart;



/** Equipment Statuses **/
const STATUS_API_PATH = `${API_PATH}/statuses`;

export const createEquipmentStatus = async status =>
    await post(STATUS_API_PATH, status);

export const deleteEquipmentStatus = async status =>
    await del(`${STATUS_API_PATH}/${status.id}`);

export const getEquipmentStatuses = async () =>
    await get(STATUS_API_PATH);

export const saveEquipmentStatus = async status =>
    await (status.id ? updateEquipmentStatus(status) : createEquipmentStatus(status));

export const updateEquipmentStatus = async status =>
    await put(`${STATUS_API_PATH}/${status.id}`, status);



/** Equipment Subcategories **/
const SUBCATEGORY_API_PATH = `${API_PATH}/subcategories`;

export const createEquipmentSubcategory = async subcategory =>
    await post(SUBCATEGORY_API_PATH, subcategory);

export const deleteEquipmentSubcategory = async subcategory =>
    await del(`${SUBCATEGORY_API_PATH}/${subcategory.id}`);

export const getEquipmentSubcategories = async () =>
    await get(SUBCATEGORY_API_PATH);

export const saveEquipmentSubcategory = async subcategory =>
    await (subcategory.id ? updateEquipmentSubcategory(subcategory) : createEquipmentSubcategory(subcategory));

export const updateEquipmentSubcategory = async subcategory =>
    await put(`${SUBCATEGORY_API_PATH}/${subcategory.id}`, subcategory);



/** Equipment Types **/
const TYPE_API_PATH = `${API_PATH}/types`;

export const createEquipmentType = async type =>
    await post(TYPE_API_PATH, type);

export const deleteEquipmentType = async type =>
    await del(`${TYPE_API_PATH}/${type.id}`);

export const getEquipmentTypes = async () =>
    await get(TYPE_API_PATH);

export const saveEquipmentType = async type =>
    await (type.id ? updateEquipmentType(type) : createEquipmentType(type));

export const updateEquipmentType = async type =>
    await put(`${TYPE_API_PATH}/${type.id}`, type);



/** Wheelchair Accessories **/
export const createWheelchairAccessory = createEquipmentPart;

export const deleteWheelchairAccessory = deleteEquipmentPart;

export const getWheelchairAccessories = async () =>
    (await getEquipmentParts()).reduce((acc, specification) => specification.category?.id === 2 ? [...acc, specification] : acc, []);

export const saveWheelchairAccessory = saveEquipmentPart;

export const updateWheelchairAccessory = updateEquipmentPart;

// all photos retrieving
const PHOTOS_API_PATH = '/api/equipment/photos'

export const getAllPhotos = async () =>
    await get(PHOTOS_API_PATH);


// equipment reservations

export const reserveEquipment = async (equipmentId) =>
    await post(`${API_PATH}/${equipmentId}/reservation`);

export const unreserveEquipment = async (equipmentId) =>
    await del(`${API_PATH}/${equipmentId}/reservation`);
