<template>
  <div class="table-space-between">
    <search-form v-model="search" class="invoice-search" />
    <div class="table-overflow">
      <table class="striped invoice-items-table">
        <caption v-html="caption" v-if="caption" />
        <colgroup>
          <col width="1%" />
          <template v-if="tableType != 'bulkInvoicing'">
            <col width="15%" />
            <col width="18%" />
            <col width="14%" />
          </template>
          <template v-else>
            <col width="13%" />
            <col width="15%" />
            <col width="12%" />
          </template>
          <col width="14%" />
          <col width="12%" />
          <col width="15%" />
          <col width="10%" />
          <template v-if="tableType == 'bulkInvoicing'">
            <col width="7%" />
          </template>
          <col width="1%" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                :checked="isAllSelected"
                @click="handleSelectAll"
              />
            </th>
            <th
              class="invoice-table-header invoice-status-header"
              v-if="tableType == 'bulkInvoicing'"
            >
              Client Number
            </th>
            <th
              class="invoice-table-header invoice-status-header"
              v-if="tableType == 'bulkInvoicing'"
            >
              Client Name
            </th>
            <th class="invoice-table-header invoice-equip-number-header">
              equipment no
            </th>

            <th class="invoice-table-header invoice-item-header">item</th>
            <th class="invoice-table-header invoice-program-header">program</th>
            <th class="invoice-table-header invoice-code-header">code</th>
            <th class="invoice-table-header invoice-fee-header">fee</th>
            <th class="invoice-table-header invoice-note-header">note</th>
            <th
              class="invoice-table-header invoice-status-header"
              v-if="tableType == 'toBeInvoiced'"
            >
              status
            </th>

            <th
              v-if="tableType == 'toBeInvoiced' || tableType == 'bulkInvoicing'"
            ></th>
          </tr>
        </thead>
        <tbody ref="items">
          <ItemsRow
            v-for="(invoiceItem, index) in paginatedInvoiceItems"
            :key="index"
            :billing-codes="invoiceCodes"
            :equipment="equipment"
            :invoice="invoiceItem"
            :programs="invoicePrograms"
            :table-type="tableType"
            :items-to-be-invoiced="equipment"
            :index="index"
            :status="status"
            :client-program="clientProgram"
            @update:invoice="handleInvoiceItemUpdate($event, index)"
            @updateInvoices="updateInvoices"
          />
        </tbody>
        <tfoot>
          <tr>
            <td
              class="page-controls invoice-items-controls"
              :colspan="
                8 +
                (tableType == 'toBeInvoiced') +
                3 * (tableType === 'bulkInvoicing')
              "
            >
              <button
                class="invoice-table-header"
                :disabled="!itemsArray.find((item) => item.selected)"
                id="invoice-generate"
                type="button"
                v-if="
                  (tableType == 'toBeInvoiced' && can('CREATE_INVOICES')) ||
                  tableType == 'bulkInvoicing'
                "
                @click="addItemsToInvoice"
              >
                Generate Invoice
              </button>

              <icon-button
                class="invoice-items-add"
                :disabled="disabled"
                id="invoice-items-add"
                name="add"
                v-if="
                  tableType == 'toBeInvoiced' &&
                  can({
                    permissions: ['CREATE_INVOICES', 'CREATE_INVOICE_ITEMS'],
                  })
                "
                @click="handleAdd"
              />

              <icon-button
                class="invoice-page-prev"
                :disabled="!hasPrevPage"
                id="invoice-page-prev"
                name="prev"
                @click="goToPrevPage"
              />

              <icon-button
                :disabled="!hasNextPage"
                id="invoice-page-next"
                name="next"
                @click="goToNextPage"
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { pagination } from "../../mixins/pagination";

import {
  createInvoiceItems,
  createInvoice,
  updateInvoiceItems,
  deleteInvoiceItem,
} from "../../api/InvoiceAPI";

import ItemsRow from "./ItemsRow";

export default {
  mixins: [pagination("filteredInvoiceItems")],

  data() {
    return {
      // we will use this array to store data we need to display and will fill it depend on the type of the table we are at
      itemsArray: [],
      search: "",
    };
  },

  props: {
    canAdd: {
      type: Boolean,
      default: true,
    },
    canRemove: {
      type: Boolean,
      default: true,
    },
    caption: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
    itemsToBeInvoiced: {
      type: Array,
      default: null,
    },
    invoicePrograms: {
      type: Array,
    },
    invoiceCodes: {
      type: Array,
    },
    tableType: {
      type: String,
      default: undefined,
    },
    status: {
      type: String,
      default: undefined,
    },
    clientProgram: {
      type: Object,
      default: null,
    },
    itemsInvoices: {
      type: Array,
      default: null,
    },
    equipment: {
      type: Array,
      default: null,
    },
  },

  computed: {
    filteredInvoiceItems() {
      if (!/\S/.test(this.search)) {
        return this.itemsArray;
      }

      const regex = this.getFilterRegex(this.search);

      return this.itemsArray.filter((invoiceItem) =>
        regex.test(
          [
            (this.tableType === "bulkInvoicing" &&
              this.getFullName(
                invoiceItem?.loan?.client ?? invoiceItem?.service?.client
              )) ||
              "",
            this.getEquipmentNumber(
              invoiceItem?.equipment ??
                invoiceItem?.loan?.equipment ??
                invoiceItem?.service?.equipment
            ) ?? "",
            (
              invoiceItem?.equipment ??
              invoiceItem?.loan?.equipment ??
              invoiceItem?.service?.equipment
            )?.item?.name ?? "",
            invoiceItem?.program?.name,
            invoiceItem?.billingCode?.code ?? "",
            invoiceItem?.billingCode?.description ?? "",
          ].join(String.fromCharCode(0x1f))
        )
      );
    },
    // Checking if we selected ALL invoices that user curently search for and if so check the master checkbox
    isAllSelected() {
      return this.filteredInvoiceItems.length != 0
        ? this.itemsArray.filter((i) => i.selected).length ==
            this.filteredInvoiceItems.length
        : false;
    },
  },

  methods: {
    groupBy(array, f) {
      var groups = {};
      array.forEach((o) => {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map((group) => {
        return groups[group];
      });
    },

    async addItemsToInvoice() {
      try {
        let invoices = [...this.itemsInvoices];
        let items = [...this.itemsToBeInvoiced];
        let clientId = this.$route.params.id;

        // grouping items to be invoiced by client and by program
        var result = this.groupBy(
          this.itemsArray.filter((item) => item.selected),
          (item) => {
            item.billingCode = item?.billingCode ?? item?.service?.billingCode;
            item.description =
              item?.description ??
              item?.service?.description ??
              item?.billingCode?.description;
            item.fee =
              item?.fee ?? item?.service?.fee ?? item?.billingCode?.fee;
            item.program = item?.program ?? item?.loan?.program;

            item.clientId =
              clientId ?? item.loan?.client.id ?? item.service?.client.id;
            return [item.clientId, item.program.id];
          }
        );

        let results = await Promise.allSettled(
          result.map(async (item) => {
            return {
              invoice: await createInvoice({
                client: { id: item[0].clientId },
                program: { id: item[0].program.id },
                items: item,
              }),
              item,
            };
          })
        );

        results.forEach((res) => {
          if (res.status !== "fulfilled") {
            return;
          }

          invoices = [...invoices, res.value.invoice];

          items = items.filter(
            (item) => !res.value.item.find((i) => i.id === item.id)
          );

          this.itemsArray = this.itemsArray.filter(
            (item) => !res.value.item.find((i) => i.id === item.id)
          );
        });

        this.$emit("update:items-invoices", invoices);
        this.$emit("update:items-to-be-invoiced", items);
      } catch (e) {
        this.showErrors(e, "Invoice");
      }
    },

    handleSelectAll(e) {
      if (!this.checkValidity(this.$refs.items)) {
        if (!this.validateFields(this.search)) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      this.itemsArray.forEach((i) => (i.selected = e.target.checked));
      this.$emit("update:items-to-be-invoiced", this.itemsArray);
    },

    validateFields(array, e) {
      if (
        (!array.loan && !array.service && !array.equipment) ||
        !array.billingCode ||
        !array.program
      ) {
        alert(
          "Please double check items you want to select to make sure they are all filled with data"
        );
        return false;
      }
    },

    handleAdd() {
      this.$emit("update:items-to-be-invoiced", [
        ...this.itemsArray,
        {
          billingCode: {},
          description: null,
          equipment: {},
          fee: null,
          program: {},
        },
      ]);
    },
    handleInvoiceItemUpdate(item, index) {
      const targetIndex = this.itemsArray.findIndex(
        (item) => item === this.paginatedInvoiceItems[index]
      );

      if (targetIndex >= 0) {
        this.itemsArray.splice(targetIndex, 1, item);
        this.$emit("update:items-to-be-invoiced", this.itemsArray);
      }
    },

    async updateInvoices(invoice) {
      try {
        invoice.id && (await deleteInvoiceItem(invoice.id));

        this.$emit(
          "update:items-to-be-invoiced",
          this.itemsArray.filter((item) => item.id != invoice.id)
        );

        this.showAlert("Invoice item was deleted successfully");
      } catch (error) {
        this.showAlert("Something went wrong please check with administrator");
      }
    },
  },

  watch: {
    itemsToBeInvoiced: {
      immediate: true,
      deep: true,
      handler(to, from) {
        const selected =
          this.itemsArray?.reduce(
            (map, item) => ({ ...map, [item.id]: item.selected }),
            {}
          ) ?? {};

        this.itemsArray =
          JSON.parse(JSON.stringify(to))?.map((i) => ({
            ...i,
            selected: selected[i.id] ?? false,
          })) ?? [];
      },
    },
  },
  components: {
    ItemsRow,
  },
};
</script>

<style>
.invoice-items-table input:not([type="checkbox" i]),
.invoice-items-table select {
  width: 100%;
}

.invoice-items-table textarea {
  resize: none;
}

.table-overflow {
  width: 100%;
  overflow-y: hidden;
}
</style>