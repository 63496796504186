<template>
    <ContentWrapper
        :create-icon="can('CREATE_EQUIPMENT_STATUSES') ? 'add-equipment-statuses' : null"
        :header-tag="headerTag"
        search-input-id="admin-equipment-status-search-phrase"
        :show-back="showBack"
        title="Equipment Statuses"
        @create="handleCreate"
        @search="handleSearch"
    >
        <EquipmentStatusesTable
            class="admin-table"
            :statuses="filteredEquipmentStatuses"
            :selected-id="status.id"
            @select="handleSelect"
        />

        <section class="admin-form-wrapper">
            <content-header
                class="admin-form-header"
                tag="h2"
                :title="status.id ? `Edit - ${status.name}` : 'Create Status'"
            />

            <span class="admin-form-tab">
                <img alt="Equipment Statuses" src="../../../assets/icons/equipment-status.svg" />
            </span>

            <EquipmentStatusForm
                class="admin-form"
                :disabled="status.name === undefined"
                :status="status"
                @cancel="handleCancel"
                @delete="handleDelete"
                @save="handleSave"
            />
        </section>
    </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import EquipmentStatusesTable from "../../../components/admin/EquipmentStatusesTable";
import EquipmentStatusForm from "../../../components/admin/EquipmentStatusForm";

import { getEquipmentStatuses } from "../../../api/EquipmentAPI";

export default {
    data() {
        return {
            status: {},
            _statuses: [],
            search: "",
        };
    },

    props: {
        headerTag: {
            type: String,
            default: undefined,
        },
        id: {
            type: [Number, String],
            default: undefined,
        },
        routeParams: {
            type: Object,
            default: undefined,
        },
        showBack: {
            type: Boolean,
            default: false,
        },
        statuses: {
            type: Array,
            default: undefined,
        },
    },

    computed: {
        filteredEquipmentStatuses() {
            const regex = this.getFilterRegex(this.search);

            return this.$data._statuses.filter((s) =>
                regex.test(s?.name ?? "")
            );
        },
    },

    methods: {
        handleCancel() {
            if (this.confirmIfDirty()) {
                this.status = {};
            }
        },
        handleCreate() {
            if (this.confirmIfDirty()) {
                this.status = {
                    name: "",
                    description: "",
                    available: false,
                    lost: false
                };
            }
        },
        handleDelete(status) {
            this.$data._statuses = this.$data._statuses.filter(
                (c) => c?.id !== status?.id
            )

            this.status = {};
        },
        handleSave(status) {
            const created = !this.status.id;

            this.$data._statuses = [
                ...this.$data._statuses.filter((c) => c?.id !== status?.id),
                status,
            ];

            this.handleSelect(created ? status : undefined, true);

            this.showAlert(
                `Your equipment status has been ${
                created ? "created" : "updated"
                } successfully!`
            );
        },
        handleSearch(phrase) {
            this.search = phrase;
        },
        handleSelect(status, force = false) {
            if(force || this.confirmIfDirty()) {
                this.status = {
                    ...this.$data._statuses.find(
                        (s) => s?.id === status?.id
                    ),
                };
            }
        },
    },

    watch: {
        "$data._statuses"(to, from) {
            if(to !== from) {
                    this.$data._statuses = to.sort(this.sortBy("id", "name"));
                    this.$emit("update:statuses", this.$data._statuses);
            }
        },
        async statuses(to, from) {
            to === this.$data._statuses ||
                (this.$data._statuses =
                    to?.slice?.() ?? (await getEquipmentStatuses()));
        },
        "statuses.id"(to, from) {
            to === this.id ||
            this.$nextTick(() => {
                this.$router.push({
                    ...this.$route,
                    params: { ...this.$route.params, ...this.routeParams, id: to },
                });
            });
        }
    },

    async created() {
        this.$data._statuses = 
            this.statuses?.slice?.() ?? (await getEquipmentStatuses());
    },

    beforeRouteUpdate(to, from, next) {
        if(!(this?.confirmIfDirty?.() ?? true)) {
            return next(false);
        }

        this.abortRequests();
        next();
    },

    beforeRouteLeave(to, from, next) {
        if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
            next(false);
        }

        this.abortRequests();
        next();
    },

    components: {
        ContentWrapper,
        EquipmentStatusesTable,
        EquipmentStatusForm
    }
}
</script>

