<template>
  <form
    autocomplete="off"
    class="admin-projects-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Project / Association</span>

        <input
          :disabled="disabled"
          id="admin-project-name"
          required
          type="text"
          v-model.trim="name"
        />
      </label>
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-project-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && project.id"
      >Delete</button>

      <button :disabled="!isSaveEnabled" id="admin-project-save" type="submit" v-if="canSave">Save</button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-project-cancel"
        type="reset"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </form>
</template>

<script>
import { deleteProject, saveProject } from "../../api/ProjectAPI";

export default {
  data() {
    return {
      name: undefined
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object
    }
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_PROJECTS");
    },
    canSave() {
      return this.can({ permissions: ["CREATE_PROJECTS", "UPDATE_PROJECTS"], matchAny: true });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.project?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.project?.id
          ? this.can("UPDATE_PROJECTS")
          : this.can("CREATE_PROJECTS"))
      );
    }
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (!confirm("Are you sure you want to delete this project?")) {
        return;
      }

      await deleteProject(this.project);
      this.$emit("delete", this.project);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveProject({
            ...this.project,
            name: this.name
          })
        );
      } catch (e) {
        this.showErrors(e, "A project");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return this.name !== this.project?.name;
    }
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    project: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.name = to?.name;
        }

        !this.disabled && this.autofocus();
      }
    }
  }
};
</script>

<style>
.admin-projects-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.admin-projects-form > footer {
  grid-column: span 2;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>