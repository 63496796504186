<template>
  <section class="repair-history">
    <search-form
      class="repair-history-search"
      :debounce="200"
      v-model="filter"
      @change="goToFirstPage"
    >
      <icon-button
        name="add-repair"
        @click="handleCreate"
        v-if="mode === 'full'"
      />
    </search-form>

    <section
      class="repair-history-record-wrapper"
      v-if="!$data._services.length && emptyText"
    >
      <section class="tab-left">
        <img alt="Repair" src="../../assets/icons/service.svg" />
      </section>

      <section class="repair-history-record">
        <span class="repair-search-no-results">{{ emptyText }}</span>
      </section>
    </section>

    <section
      class="repair-history-record-wrapper"
      v-else-if="$data._services.length && !filteredServices.length"
    >
      <section class="tab-left">
        <img alt="Repair" src="../../assets/icons/service.svg" />
      </section>

      <section class="repair-history-record">
        <section class="repair-history-record-actions">
          <icon-button
            :disabled="true"
            name="edit"
            title="Edit Repair"
            @click.prevent
            v-if="mode !== 'readonly'"
          />

          <icon-button
            :disabled="true"
            name="archive"
            title="Archive Repair"
            @click.prevent
            v-if="mode !== 'readonly'"
          />

          <icon-button
            :disabled="true"
            name="invoice"
            title="Invoice Repair"
            @click.prevent
            v-if="mode === 'full'"
          />
        </section>

        <span class="repair-search-no-results"
          >There are no repairs matching your search criteria</span
        >
      </section>
    </section>

    <section
      v-for="(service, index) in paginatedServices"
      :key="service.$key"
      class="repair-history-record-wrapper"
    >
      <section
        :class="['tab-left', editing[service.$key] && 'tab-left-active']"
      >
        <img alt="Repair" src="../../assets/icons/service.svg" />
      </section>

      <section class="repair-history-record">
        <section class="repair-history-record-actions">
          <icon-button
            :class="editing[service.$key] && 'active'"
            :disabled="!can('UPDATE_SERVICES')"
            name="edit"
            title="Edit Repair"
            @click="handleEdit(service)"
            v-if="mode === 'full' && service.id"
          />

          <icon-button
            :disabled="!can('DELETE_SERVICES')"
            name="archive"
            title="Archive Repair"
            @click="handleArchive(service)"
            v-if="mode !== 'readonly' && service.id"
          />

          <icon-button
            :disabled="!can('CREATE_INVOICE_ITEMS')"
            name="invoice"
            title="Invoice Repair"
            @click="handleInvoice(service)"
            v-if="mode === 'full' && service.id && !service.invoiced"
          />
        </section>

        <RepairInfo
          class="repair-history-record-info"
          :service="service"
          v-if="service.id && !editing[service.$key]"
        />

        <RepairForm
          class="repair-history-record-info"
          :billing-codes="$data._billingCodes"
          :can-delete="false"
          :categories="$data._categories"
          :clients="$data._clients"
          :equipment="$data._equipment"
          :service.sync="paginatedServices[index]"
          :subcategories="$data._subcategories"
          @cancel="handleCancel"
          @delete="handleDelete"
          @submit="handleSave"
          v-if="!service.id || editing[service.$key]"
        />
      </section>
    </section>

    <footer class="page-controls" v-if="hasPages">
      <icon-button :disabled="!hasPrevPage" name="prev" @click="goToPrevPage" />
      <icon-button :disabled="!hasNextPage" name="next" @click="goToNextPage" />
    </footer>
  </section>
</template>

<script>
import { pagination } from "../../mixins/pagination";

import RepairInfo from "./RepairInfo";
import RepairForm from "./RepairForm";

import { getBillingCodes } from "../../api/BillingCodeAPI";
import { getClientSummaries } from "../../api/ClientAPI";
import { getEquipmentSummaries } from "../../api/EquipmentAPI";
import { createInvoiceItem } from "../../api/InvoiceAPI";

import {
  deleteService,
  getServiceCategories,
  getServiceSubcategories,
  saveService,
} from "../../api/ServiceAPI";

export default {
  components: {
    RepairInfo,
    RepairForm,
  },

  mixins: [pagination("filteredServices")],

  data() {
    return {
      _billingCodes: [],
      _categories: [],
      _clients: [],
      _equipment: [],
      _services: [],
      _subcategories: [],
      editing: {},
      filter: "",
    };
  },

  props: {
    billingCodes: {
      type: Array,
      default: null,
    },
    categories: {
      type: Number,
      default: null,
    },
    clientId: {
      type: Number,
      default: null,
    },
    clients: {
      type: Array,
      default: null,
    },
    equipment: {
      type: Array,
      default: null,
    },
    equipmentId: {
      type: Number,
      default: null,
    },
    emptyText: {
      type: String,
      default: "There are no repair service records",
    },
    invoiceItems: {
      type: Array,
    },
    mode: {
      type: String,
      default: "full",
      validator: (value) => ["full", "limited", "readonly"].includes(value),
    },
    pageSize: {
      type: Number,
      default: 5,
    },
    services: {
      type: Array,
      default: () => [],
    },
    subcategories: {
      type: Number,
      default: null,
    },
  },

  computed: {
    filteredServices() {
      if (!/\S/.test(this.search)) {
        return this.$data._services;
      }

      const regex = this.getFilterRegex(this.filter);

      return this.$data._services.filter((service) =>
        regex.test(
          [
            this.getEquipmentNumber(service?.equipment),
            service?.equipment?.item?.name ?? "",
            service?.client?.clientNumber ?? "",
            this.getFullName(service?.client),
            service?.serviceDate ?? "",
            this.formatTimestamp(service?.serviceDate) ?? "",
            service?.billingCode?.code ?? "",
            service?.fee ?? "",
            service?.description ?? "",
          ].join(String.fromCharCode(0x1f))
        )
      );
    },
  },

  methods: {
    deleteService(service) {
      this.$data._services = this.$data._services.filter(
        (s) => s.$key !== service.$key
      );

      service.id &&
        this.$emit(
          "update:services",
          this.services.filter((s) => s?.id !== service?.id)
        );
    },
    async handleArchive(service) {
      if (
        !confirm("Are you sure you want to archive this repair service record?")
      ) {
        return;
      }

      try {
        if (service?.id) {
          await deleteService(service);

          this.showAlert(
            "The repair service record has been successfully archived!"
          );
        }

        this.deleteService(service);
      } catch (e) {
        this.showErrors(e, "A repair service record");
      }
    },
    handleCancel(service) {
      if (this.isDirty(service) && !this.confirmDiscard()) {
        return;
      }

      if (service.id) {
        this.$data._services.forEach((svc, i) => {
          if (svc.id === service.id) {
            this.$data._services.splice(i, 1, {
              ...this.$data._services[i],
              ...this.services.find((s) => s.id === svc.id),
            });
          }
        });
      } else {
        this.deleteService(service);
      }

      delete this.editing[service.$key];
    },
    handleCreate() {
      this.$data._services = [
        {
          $key: Date.now(),
          client: {
            id: undefined,
            ...(this.clientId &&
              this.$data._clients.find((c) => c.id === this.clientId)),
          },
          equipment: {
            id: undefined,
            ...(this.equipmentId &&
              this.$data._equipment.find((c) => c.id === this.equipmentId)),
          },
          billingCode: { id: undefined },
          category: { id: undefined },
          subcategory: { id: undefined },
          serviceDate: new Date().toJSON(),
        },
        ...this.$data._services,
      ];

      this.goToFirstPage();
    },
    async handleDelete(service) {
      if (
        !confirm("Are you sure you want to delete this repair service record?")
      ) {
        return;
      }

      await deleteService(service);
      this.deleteService(service);
    },
    handleEdit(service) {
      this.editing = { ...this.editing, [service.$key]: true };
    },
    async handleInvoice(service) {
      try {
        this.$emit(
          "invoiced",
          await createInvoiceItem({
            service,
            billingCode: service?.billingCode,
            fee: service?.hours * service?.billingCode?.fee,
            description: service?.description,
          })
        );

        this.updateService({ ...service, invoiced: true });
      } catch (e) {
        this.showErrors(e, "An invoice item");
      }
    },
    async handleSave(service) {
      clearTimeout(this.debounce?.save);

      this.debounce = {
        ...this.debounce,
        save: setTimeout(async () => {
          try {
            const res = await saveService(service);
            this.editing = { ...this.editing, [service.$key]: false };
            this.updateService({ ...service, ...res });
          } catch (e) {
            this.showErrors(e, "A repair service record");
          }
        }, 400),
      };
    },
    isDirty(service) {
      if (service) {
        const s = this.services.find((s) => s.id === service.id);

        return (
          s?.billingCode?.id !== service?.billingCode?.id ||
          s?.category?.id !== service?.category?.id ||
          s?.client?.id !== service?.client?.id ||
          s?.description !== service?.description ||
          s?.equipment?.id !== service?.equipment?.id ||
          s?.hours !== service?.hours ||
          s?.serviceDate !== service?.serviceDate ||
          s?.subcategory?.id !== service?.subcategory?.id
        );
      }

      return this.$data._services.reduce(
        (dirty, service) => dirty || this.isDirty(service),
        false
      );
    },
    updateService(service) {
      this.$emit(
        "update:services",
        [...this.services.filter((s) => s?.id !== service.id), service].sort(
          this.sortBy("-serviceDate", "-id")
        )
      );
    },
  },

  watch: {
    billingCodes: {
      immediate: true,
      async handler(to) {
        this.$data._billingCodes = to ?? (await getBillingCodes());
      },
    },
    categories: {
      immediate: true,
      async handler(to) {
        this.$data._categories = to ?? (await getServiceCategories());
      },
    },
    clients: {
      immediate: true,
      async handler(to) {
        this.$data._clients = to ?? (await getClientSummaries());
      },
    },
    equipment: {
      immediate: true,
      async handler(to) {
        this.$data._equipment = to ?? (await getEquipmentSummaries());
      },
    },
    services: {
      immediate: true,
      handler(to, from) {
        this.$data._services = JSON.parse(JSON.stringify(to)).map(
          (service) => ({ $key: service.id, ...service })
        );
      },
    },
    subcategories: {
      immediate: true,
      async handler(to) {
        this.$data._subcategories = to ?? (await getServiceSubcategories());
      },
    },
  },
};
</script>

<style scoped>
.repair-history {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.repair-history-search {
  align-items: flex-end;
  display: flex;
  margin-bottom: 1em;
  margin-left: auto;
}

.repair-history-search .icon-button {
  font-size: 0.755rem;
  margin-left: 0.5em;
}

.repair-history-record-wrapper {
  display: flex;
  width: 100%;
}

.repair-history-record-wrapper ~ .repair-history-record-wrapper {
  margin-top: 1em;
}

.tab-left:not(.tab-left-active) {
  --tab-color: var(--primary-black);
}

.repair-history-record {
  align-items: flex-start;
  border-color: var(--primary-medium-gray);
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  padding: 1em;
}

.repair-history-record-actions {
  flex: 0;
  margin-left: 1em;
}

.repair-history-record-actions button ~ button {
  margin-top: 0.5em;
}

.repair-history-record-info,
.repair-search-no-results {
  align-self: center;
  flex: 1;
}

@media print {
  .tab-left {
    display: none;
  }
}
</style>
