<template>
  <section id="admin-programs-view">
    <content-header :prev="{name: 'Admin'}" title="Programs" />

    <PartialView>
      <Programs
        class="admin-content-partial"
        ref="programs"
        header-tag="h3"
        :id="programId"
        :route-params="{view: 'programs'}"
        :show-back="false"
        @update:programs="handleUpdate"
        v-if="hasAdmin('PROGRAMS')"
      />

      <Projects
        class="admin-content-partial"
        ref="projects"
        header-tag="h3"
        :id="projectId"
        :route-params="{view: 'projects'}"
        :show-back="false"
        v-if="hasAdmin('PROJECTS')"
      />

      <EquipmentTypes
        class="admin-content-partial"
        ref="types"
        header-tag="h3"
        :id="typeId"
        :programs="programs"
        :route-params="{view: 'types'}"
        :show-back="false"
        v-if="hasAdmin('EQUIPMENT_TYPES')"
      />
    </PartialView>
  </section>
</template>

<script>
import PartialView from "./PartialView";
import Programs from "./pages/Programs";
import Projects from "./pages/Projects";
import EquipmentTypes from "./pages/EquipmentTypes";

export default {
  data() {
    return {
      programId: this.view === "programs" ? this.id : undefined,
      programs: undefined,
      projectId: this.view === "projects" ? this.id : undefined,
      typeId: this.view === "types" ? this.id : undefined,
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined,
    },
    view: {
      type: String,
      default: "programs",
    },
  },

  methods: {
    handleUpdate(programs) {
      this.programs = programs;
    },
    isDirty() {
      return false;
    },
  },

  mounted() {
    this.$refs.programs && (this.$refs.programs.$el.id = "admin-programs");
    this.$refs.projects && (this.$refs.projects.$el.id = "admin-projects");
    this.$refs.types && (this.$refs.types.$el.id = "admin-equipment-types");
  },

  beforeRouteUpdate(to, from, next) {
    // This will ensure that the paths are always: /tools/programs, /tools/projects, or /tools/equipment/types
    if (to.params.view === "types" && to.params.slug !== "equipment") {
      next({ ...to, params: { ...to.params, slug: "equipment" } });
    } else if (to.params.view !== "types" && to.params.slug) {
      next({ ...to, params: { ...to.params, slug: undefined } });
    }

    to.params.view === "programs" &&
      (this.programId = +to.params.id || undefined);

    to.params.view === "projects" &&
      (this.projectId = +to.params.id || undefined);

    to.params.view === "types" && (this.typeId = +to.params.id || undefined);

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (
      !to.params.skipDirtyCheck &&
      Object.values(this.$refs).find((r) => r?.isDirty?.()) &&
      !this.confirmDiscard()
    ) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    PartialView,
    Programs,
    Projects,
    EquipmentTypes,
  },
};
</script>

<style>
#admin-programs-view {
  display: flex;
  flex-wrap: wrap;
}

#admin-programs-view .admin-table {
  grid-area: table;
}

#admin-programs-view .admin-form-wrapper {
  grid-area: form;
}

#admin-programs-view .admin-form-tab img {
  max-width: 2em;
}
</style>