<template>
  <section>
    <header class="login-header">
      <slot name="logo" />
    </header>

    <LoginForm @login="handleLogin" />
  </section>
</template>

<script>
import LoginForm from "../../../components/login/LoginForm";

export default {
  methods: {
    handleLogin(res) {
      res.ok && this.$router.push(this.$route.query.next ?? { name: "Home" });
    }
  },

  components: {
    LoginForm
  }
};
</script>

<style>
.login-page .login-form {
  width: 100%;
}
</style>