<template>
  <section class="password-with-strength">
    <input type="password" :value="value" v-bind="$attrs" v-on="_listeners" @input="handleInput" />

    <div class="password-strength">
      <progress class="password-strength-meter" max="5" :value="value ? strength.score + 1 : 0" />

      <aside
        class="password-strength-tooltip"
        :style="{left: value && `${100 * (strength.score + 1) / 10}%`}"
        v-if="value && (strength.feedback.warning || strength.feedback.suggestions.length > 0)"
      >
        <section class="password-strength-warning" v-if="strength.feedback.warning">
          <strong>WARNING!</strong>
          {{ strength.feedback.warning }}
        </section>

        <section
          class="password-strength-suggestions"
          v-if="strength.feedback.suggestions.length > 0"
        >
          <strong>Suggestions:</strong>
          <ul>
            <li
              v-for="(suggestion, index) in strength.feedback.suggestions"
              :key="index"
            >{{ suggestion }}</li>
          </ul>
        </section>
      </aside>
    </div>
  </section>
</template>

<script>
import zxcvbn from "zxcvbn";

export default {
  props: {
    value: ""
  },

  computed: {
    _attrs() {
      const _attrs = { ...this.$attrs };
      delete _attrs.value;
      return _attrs;
    },
    _listeners() {
      const _listeners = { ...this.$listeners };
      delete _listeners.input;
      return _listeners;
    },
    strength() {
      return zxcvbn(this.value);
    }
  },

  methods: {
    handleInput(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>

<style>
.password-with-strength {
  display: inline-flex;
  flex-direction: column;
}

.password-with-strength input {
  width: 100%;
}

.password-strength {
  position: relative;
}

.password-strength-meter {
  appearance: none;
  height: 1em;
  width: 100%;
}

.password-strength-meter::-webkit-progress-bar {
  background: #efefef;
  border-color: #b2b2b2;
  border-radius: 1em;
  border-style: solid;
  border-width: 1px;
  height: 50%;
  overflow: hidden;
  transform: translateY(50%);
}

.password-strength-meter::-webkit-progress-value {
  background: #aa0000;
  transition: width 200ms ease, background 200ms ease;
}

.password-strength-meter[value="2" i]::-webkit-progress-value {
  background: #aa2200;
}

.password-strength-meter[value="3" i]::-webkit-progress-value {
  background: #aa4400;
}

.password-strength-meter[value="4" i]::-webkit-progress-value {
  background: #44aa00;
}

.password-strength-meter[value="5" i]::-webkit-progress-value {
  background: #008000;
}

.password-strength-meter::-moz-progress-bar {
  background: #efefef;
  border-color: #b2b2b2;
  border-radius: 1em;
  border-style: solid;
  border-width: 1px;
  height: 50%;
  overflow: hidden;
  transform: translateY(50%);
}

.password-strength-meter::-moz-progress-value {
  background: #aa0000;
  transition: width 200ms ease, background 200ms ease;
}

.password-strength-meter[value="2" i]::-moz-progress-value {
  background: #aa2200;
}

.password-strength-meter[value="3" i]::-moz-progress-value {
  background: #aa4400;
}

.password-strength-meter[value="4" i]::-moz-progress-value {
  background: #44aa00;
}

.password-strength-meter[value="5" i]::-moz-progress-value {
  background: #008000;
}

.password-strength-tooltip {
  background: var(--primary-black);
  border-radius: 1em;
  box-shadow: 0 0 0.5em var(--primary-black);
  color: var(--primary-white);
  display: none;
  font-size: 0.75em;
  left: 50%;
  margin-top: 0.5em;
  min-width: 60%;
  padding: 1em;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: auto;
}

.password-strength-tooltip::before {
  border-bottom: 1em solid var(--primary-black);
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  content: " ";
  display: block;
  height: 0;
  left: 50%;
  position: absolute;
  top: 1px;
  transform: translate(-50%, -100%);
  width: 0;
}

.password-strength-tooltip .password-strength-warning {
  background: var(--primary-red);
  border-radius: 1em;
  padding: 1em;
  text-align: center;
  transform: translate(-1em, -1em);
  width: calc(100% + 2em);
}

.password-strength-tooltip .password-strength-warning::before {
  border-bottom: 1em solid var(--primary-red);
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  content: " ";
  display: block;
  height: 0;
  left: 50%;
  position: absolute;
  top: 1px;
  transform: translate(-50%, -100%);
  width: 0;
}

.password-strength-tooltip .password-strength-warning:not(:last-child) {
  background: var(--primary-red);
  border-radius: 1em 1em 0 0;
  padding: 1em;
  text-align: center;
  transform: translate(-1em, -1em);
  width: calc(100% + 2em);
}

.password-strength-tooltip ul {
  list-style: none;
  margin-top: 0.75em;
  padding-left: 0.25em;
}

.password-strength-tooltip ul li {
  display: flex;
  margin-top: 0.25em;
}

.password-strength-tooltip ul li::before {
  content: "\2022";
  display: inline-block;
  height: 100%;
  margin-right: 0.5em;
}

.password-with-strength
  input:hover
  + .password-strength
  .password-strength-tooltip,
.password-strength:hover .password-strength-tooltip {
  display: inline-block;
}
</style>