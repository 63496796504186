<template>
  <input
    v-bind="$attrs"
    :max="max"
    :min="min"
    type="datetime-local"
    :value="formattedValue"
    v-on="listeners"
    @blur="handleBlur"
    @input="handleInput"
  />
</template>

<script>
export default {
  data() {
    return {
      applyFormatting: true,
    };
  },

  props: {
    convertTimezone: {
      type: Boolean,
      default: false,
    },
    max: {
      type: String,
      default: '2999-12-31T23:59:59',
    },
    min: {
      type: String,
      default: '1900-01-01T00:00:00'
    },
    value: {
      type: [Date, String, Number],
      default: "",
    },
  },

  computed: {
    formattedValue() {
      return this.applyFormatting
        ? this.formatTimestamp(this.value, {
            convertTZ: this.convertTimezone,
            format: "yyyy-MM-ddTHH:mm:ss",
          })
        : this.value;
    },
    listeners() {
      const listeners = { ...this.$listeners };
      delete listeners.blur;
      delete listeners.input;
      return listeners;
    },
  },

  methods: {
    handleBlur(event) {
      this.$emit("input", new Date(event.target.value).toJSON());
      this.$emit("blur", event);
      this.applyFormatting = true;
    },
    handleInput(event) {
      this.applyFormatting = false;
      this.$emit("input", event.target.value);
    },
  },
};
</script>