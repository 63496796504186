<template>
  <ContentWrapper
    :create-icon="can('CREATE_REFERRAL_AGENTS') ? 'add-referral-agent' : null"
    :header-tag="headerTag"
    search-input-id="admin-referral-agent-search-phrase"
    :show-back="showBack"
    title="Referral Agents"
    @create="handleCreate"
    @search="handleSearch"
  >
    <ReferralAgentsTable
      class="admin-table"
      :selected-id="referralAgent.id"
      :referral-agents="filteredReferralAgents"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="
          referralAgent.id
            ? `Edit - ${getFullName(referralAgent)}`
            : 'Create Referral Agent'
        "
      />

      <span class="admin-form-tab">
        <img alt="Referral Agents" src="../../../assets/icons/user.svg" />
      </span>

      <ReferralAgentsForm
        class="admin-form"
        :disabled="loading || referralAgent.firstName === undefined"
        :cities="cities"
        :counties="counties"
        :organizations="$data._organizations"
        :programs="$data._programs"
        :provinces="provinces"
        :referral-agent="referralAgent"
        @cancel="handleCancel"
        @create-user="handleCreateUser"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ContentWrapper from "./ContentWrapper";
import ReferralAgentsTable from "../../../components/admin/ReferralAgentsTable";
import ReferralAgentsForm from "../../../components/admin/ReferralAgentsForm";

import { getOrganizations } from "../../../api/OrganizationAPI";
import { getPrograms } from "../../../api/ProgramAPI";
import {
  getReferralAgent,
  getReferralAgents,
} from "../../../api/ReferralAgentAPI";

export default {
  data() {
    return {
      _organizations: [],
      _programs: [],
      _referralAgents: [],
      loading: false,
      referralAgent: {},
      search: "",
    };
  },

  props: {
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    organizations: {
      type: Array,
      default: undefined,
    },
    programs: {
      type: Array,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    referralAgents: {
      type: Array,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters(["cities", "counties", "provinces"]),
    filteredReferralAgents() {
      const regex = this.getFilterRegex(this.search);

      return this.$data._referralAgents.filter((referralAgent) =>
        regex.test(
          [
            this.getFullName(referralAgent),
            ...(referralAgent?.emailAddresses?.map((a) => a?.address ?? "") ??
              []),
            ...(referralAgent?.phoneNumbers?.map((p) => p?.number ?? "") ?? []),
          ].join(String.fromCharCode(0x1f))
        )
      );
    },
  },

  methods: {
    ...mapActions("cities", ["getCities"]),
    ...mapActions("counties", ["getCounties"]),
    ...mapActions("provinces", ["getProvinces"]),
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.referralAgent = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.referralAgent = { firstName: "" };
      }
    },
    async handleCreateUser() {
      this.loading = true;

      try {
        const referralAgent = await getReferralAgent(this.referralAgent.id);

        this.$data._referralAgents = [
          ...this.$data._referralAgents.filter(
            (r) => r?.id !== referralAgent?.id
          ),
          referralAgent,
        ];

        this.handleSelect(referralAgent, true);

        this.showAlert(
          `Your referral agent user has been created successfully!`
        );
      } finally {
        this.loading = false;
      }
    },
    handleDelete(referralAgent) {
      this.$data._referralAgents = this.$data._referralAgents.filter(
        (r) => r?.id !== referralAgent?.id
      );
      this.referralAgent = {};
    },
    handleSave(referralAgent) {
      const created = !this.referralAgent.id;

      this.$data._referralAgents = [
        ...this.$data._referralAgents.filter(
          (r) => r?.id !== referralAgent?.id
        ),
        referralAgent,
      ];

      this.handleSelect(created ? referralAgent : undefined, true);

      this.showAlert(
        `Your referral agent has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(referralAgent, force = false) {
      if (force || this.confirmIfDirty()) {
        this.referralAgent = {
          ...this.$data._referralAgents.find(
            (r) => r?.id === referralAgent?.id
          ),
        };
      }
    },
    async loadData() {
      this.getCities();
      this.getCounties();
      this.getProvinces();
    },
  },

  watch: {
    "$data._organizations"(to, from) {
      if (to !== from) {
        this.$data._organizations = to.sort(this.sortBy("name", "id"));
      }
    },
    "$data._programs"(to, from) {
      if (to !== from) {
        this.$data._programs = to.sort(this.sortBy("acronym", "name", "id"));
      }
    },
    "$data._referralAgents"(to, from) {
      if (to !== from) {
        this.$data._referralAgents = to.sort(
          this.sortBy("firstName", "lastName")
        );
        this.$emit("update:referral-agents", this.$data._referralAgents);
      }
    },
    organizations: {
      immediate: true,
      async handler(to, from) {
        this.$data._organizations = to?.slice?.() ?? (await getOrganizations());
      },
    },
    programs: {
      immediate: true,
      async handler(to, from) {
        this.$data._programs = to?.slice?.() ?? (await getPrograms());
      },
    },
    "referralAgent.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    async referralAgents(to, from) {
      to === this.$data._referralAgents ||
        (this.$data._referralAgents =
          to?.slice?.() ?? (await getReferralAgents()));
    },
  },

  async created() {
    this.loadData();

    this.$data._referralAgents =
      this.referralAgents?.slice?.() ?? (await getReferralAgents());

    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    ReferralAgentsTable,
    ReferralAgentsForm,
  },
};
</script>