import md5 from "md5";

export function cleanUrl(url) {
    if (/^[^;/]*:?\/\//.test(url)) {
        return url;
    }

    return `${process.env.VUE_APP_API_PATH?.replace(/(.)\/+$/, '$1') ?? ''}${url}`.replace(/\/+/g, '/');
}

export function formatTimestamp(value, {
    convertTZ = true,
    format = formatTimestamp.FORMAT_DEFAULT_DATE,
} = {}) {
    const date = new Date(convertTZ || typeof value !== 'string' ? value : value?.replace(/\s*((GMT)?[+-]\d{1,2}:?\d{2}|\D+)$/i, ''));

    if ((value ?? false) === false || !(date instanceof Date) || isNaN(date)) {
        return value;
    }

    return format.replace(/([AaDdHhMmSsYyZz])\1*/g, (full, type) => {
        switch (type) {
            // AM/PM
            case 'A':
            case 'a':
                return date.toLocaleString('default', { hour12: true, hour: 'numeric' }).split(/\s+/).splice(1).join(' ')[(type === 'a') ? 'toLowerCase' : 'toUpperCase']();

            // Day
            case 'D':
            case 'd':
                switch (full.length) {
                    case 1: return date.toLocaleString('default', { day: 'numeric' });
                    case 2: return date.toLocaleString('default', { day: '2-digit' }).padStart(2, '0');
                    case 3: return date.toLocaleString('default', { weekday: 'short' });
                    default: return date.toLocaleString('default', { weekday: 'long' });
                }

            // Hours
            case 'H':
            case 'h':
                switch (full.length) {
                    case 1: return date.toLocaleString('default', { hour12: (type === 'h'), hour: 'numeric' }).split(/\s+/)[0];
                    default: return date.toLocaleString('default', { hour12: (type === 'h'), hour: '2-digit' }).split(/\s+/)[0].padStart(2, '0');
                }

            // Month
            case 'M':
                switch (full.length) {
                    case 1: return (date.getMonth() + 1).toString();
                    case 2: return (date.getMonth() + 1).toString().padStart(2, '0');
                    case 3: return date.toLocaleString('default', { month: 'short' });
                    default: return date.toLocaleString('default', { month: 'long' });
                }

            // Minutes
            case 'm':
                switch (full.length) {
                    case 1: return date.toLocaleString('default', { minute: 'numeric' });
                    default: return date.toLocaleString('default', { minute: '2-digit' }).padStart(2, '0');
                }

            // Seconds
            case 'S':
            case 's':
                switch (full.length) {
                    case 1: return date.toLocaleString('default', { second: 'numeric' });
                    default: return date.toLocaleString('default', { second: '2-digit' }).padStart(2, '0');
                }

            // Year
            case 'Y':
            case 'y':
                switch (full.length) {
                    case 2: return date.toLocaleString('default', { year: '2-digit' }).padStart(2, '0');
                    default: return date.toLocaleString('default', { year: 'numeric' });
                }

            // Timezone
            case 'Z':
            case 'z':
            default: return date.toLocaleString('default', { timeZoneName: (type === 'z') ? 'short' : 'long' }).split(/\s+/).slice(3).join(' ')
        }
    });
}

formatTimestamp.FORMAT_DEFAULT_DATE = 'dd/MM/yyyy';
formatTimestamp.FORMAT_DEFAULT_TIME = 'hh:mm A';
formatTimestamp.FORMAT_DEFAULT_DATE_TIME = `${formatTimestamp.FORMAT_DEFAULT_DATE} ${formatTimestamp.FORMAT_DEFAULT_TIME}`;

function defaultKeyGenerator(...args) {
    if (args.length > 1) {
        return md5(JSON.stringify(args));
    } else if (typeof args[0] === 'object') {
        return md5(JSON.stringify(args[0]));
    } else {
        return args[0];
    }
}

export function memoize(fn, keyGenerator = defaultKeyGenerator) {
    const cached = {};

    return (...args) => {
        const key = keyGenerator(...args);
        return cached[key] ?? (cached[key] = fn(...args));
    };
}

export function routeSilent(url) {
    history.pushState({}, null, cleanUrl(url));
}

export const sortBy = memoize((...keys) => (a, b) =>
    keys.reduce((val, key) => {
        if (val !== 0) return val;
        const [_, reverse, prop] = (key ?? '').match(/^(-)?(.*)$/);
        const valA = prop.split('.').reduce((obj, key) => obj?.[key], a);
        const valB = prop.split('.').reduce((obj, key) => obj?.[key], b);

        if (typeof valA === 'number') {
            return (reverse ? -1 : 1) * ((valA ?? 'Infinity') - (valB ?? 'Infinity'));
        } else {
            return (reverse ? -1 : 1) * (valA ?? '').toString().localeCompare((valB ?? '').toString(), undefined, { sensitivity: "base" });
        }
    }, 0));