import store from '../store';

export const del = async (url, options, opts) =>
    await store.dispatch('api/delete', { ...opts, url, options }, { root: true });

export const get = async (url, options, opts) =>
    await store.dispatch('api/get', { ...opts, url, options }, { root: true });

export const post = async (url, data = null, options, opts) =>
    await store.dispatch('api/post', { ...opts, url, data, options }, { root: true });

export const put = async (url, data = null, options, opts) =>
    await store.dispatch('api/put', { ...opts, url, data, options }, { root: true });