<template>
  <section id="admin-users-view">
    <content-header :prev="{name: 'Admin'}" title="Users" />

    <PartialView>
      <Users
        class="admin-content-partial"
        ref="users"
        header-tag="h3"
        :id="userId"
        :organizations="organizations"
        :route-params="{view: 'users'}"
        :show-back="false"
        v-if="hasAdmin('USERS')"
      />

      <Organizations
        class="admin-content-partial"
        ref="organizations"
        header-tag="h3"
        :id="organizationsId"
        :route-params="{view: 'organizations'}"
        :show-back="false"
        @update:organizations="handleUpdateOrganizations"
        v-if="hasAdmin('ORGANIZATIONS')"
      />
    </PartialView>
  </section>
</template>

<script>
import PartialView from "./PartialView";
import Organizations from "./pages/Organizations";
import Users from "./pages/Users";

export default {
  data() {
    return {
      organizationsId: this.view === "organizations" ? this.id : undefined,
      organizations: undefined,
      userId: this.view === "users" ? this.id : undefined,
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined,
    },
    view: {
      type: String,
      default: "users",
    },
  },

  methods: {
    handleUpdateOrganizations(organizations) {
      this.organizations = organizations;
    },
    isDirty() {
      return false;
    },
  },

  mounted() {
    this.$refs.users && (this.$refs.users.$el.id = "admin-users");
    this.$refs.organizations &&
      (this.$refs.organizations.$el.id = "admin-organizations");
  },

  beforeRouteUpdate(to, from, next) {
    to.params.view === "users" && (this.userId = +to.params.id || undefined);

    to.params.view === "organizations" &&
      (this.organizationsId = +to.params.id || undefined);

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (
      !to.params.skipDirtyCheck &&
      Object.values(this.$refs).find((r) => r?.isDirty?.()) &&
      !this.confirmDiscard()
    ) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    PartialView,
    Users,
    Organizations,
  },
};
</script>

<style>
#admin-users-view {
  display: flex;
  flex-wrap: wrap;
}

#admin-users-view .admin-table {
  grid-area: table;
}

#admin-users-view .admin-form-wrapper {
  grid-area: form;
}

#admin-users-view .admin-form-tab img {
  max-width: 2em;
}
</style>