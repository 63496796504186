import md5 from 'md5';
import router from '../router';

class APIError extends Error {
    constructor(response) {
        super(`${response.status} ${response.statusText}`.trim());

        Object.assign(this, {
            error: undefined,
            name: 'APIError',
            response,
            status: response.status
        });
    }

    async getError() {
        return this.error ?? (this.error = await this.response.clone().json());
    }

    async getFieldErrors() {
        return (await this.getError())?.message?.match(/of '(.*?)' already exists$/)?.[1]?.split('; ').reduce((errors, error) => {
            const [key, value] = error.split('=');
            return { ...errors, [key]: value };
        }, {});
    }
}

const getContentType = data => {
    switch (true) {
        case data instanceof FormData:
            return '';

        case typeof (data ?? {}) === 'object':
            return 'application/json';

        case typeof (data ?? {}) === 'string':
        default:
            return 'text/plain';
    }
};

const getRequestBody = (data, type = 'application/json') => {
    const [contentType] = type.split(';');

    switch (true) {
        case data instanceof FormData:
            return data;

        case contentType === 'application/javascript':
        case contentType === 'application/json':
        case typeof (data ?? {}) === 'object':
            return JSON.stringify(data ?? {});

        case contentType === 'application/x-www-form-url-encoded':
            return Object.entries(data ?? {}).reduce((qs, [key, value]) => [...qs, `${encodeURIComponent(key)}=${encodeURIComponent(value)}`], []).join('&');

        case contentType === 'text/plain':
            return data ?? '';

        case typeof (data ?? {}) === 'object':
            return JSON.stringify(data ?? {});

        default:
            return encodeURIComponent(data);
    }
};

const cache = new Map();

export const api = {
    namespaced: true,
    state: {
        controller: undefined,
        passwordExpired: !!localStorage.getItem('easter-seals.passwordExpired'),
        requests: {
            open: 0,
            total: 0
        },
        token: localStorage.getItem('easter-seals.token')
    },
    getters: {
        isFetchingData: state => state.requests.total > 0,
        percentComplete: state => state.requests.total && (100 * (state.requests.total - state.requests.open) / state.requests.total),
    },
    mutations: {
        endRequest(state) {
            state.requests.open = Math.max(state.requests.open - 1, 0);
            state.requests.open || (state.requests.total = 0);
        },
        setController(state, controller) {
            state.controller = controller;
        },
        setPasswordExpired(state, passwordExpired) {
            state.passwordExpired = !!passwordExpired;
            passwordExpired ? localStorage.setItem('easter-seals.passwordExpired', 1) : localStorage.removeItem('easter-seals.passwordExpired');
        },
        setToken(state, token) {
            state.token = token;
            token ? localStorage.setItem('easter-seals.token', token) : localStorage.removeItem('easter-seals.token');
        },
        startRequest(state) {
            state.requests.total++;
            state.requests.open++;
        }
    },
    actions: {
        abort({ commit, state }) {
            const { controller } = state;
            commit('setController');

            cache.clear();
            controller?.abort?.();
        },
        async fetch({ commit, state }, url) {
            try {
                state.controller || commit('setController', new AbortController());
                commit('startRequest');

                const { url: _url, options: _options = {} } = typeof url === 'object' ? url : { url };
                const { raw = false, ...options } = _options;

                if (_url === '/api/logout' && !state.token) {
                    return new Response(null, { status: 204, statusText: 'No Content' })
                }

                const headers = {
                    Authorization: state.token && `Bearer ${state.token}`,
                    ...options?.headers,
                };

                headers['Authorization']?.trim?.() || delete headers['Authorization'];
                headers['Content-Type']?.trim?.() || delete headers['Content-Type'];

                const res = await fetch(`${process.env.VUE_APP_API_PATH.replace(/(.)?\/+$/g, '$1')}${_url}`, {
                    cache: 'no-cache',              // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin',     // include, *same-origin, omit
                    mode: 'cors',                   // no-cors, *cors, same-origin
                    redirect: 'follow',             // manual, *follow, error
                    referrerPolicy: 'no-referrer',  // no-referrer, *client,
                    signal: (options.method === 'GET') ? state.controller.signal : undefined,
                    ...options,
                    headers
                });

                if (res.headers.has('Authorization')) {
                    commit('setToken', res.headers.get('Authorization').replace(/^\S+\s*/, ''));
                    commit('setPasswordExpired', false);
                }

                if (res.headers.has('X-Password-Expired')) {
                    commit('setPasswordExpired', JSON.parse(res.headers.get('X-Password-Expired')));
                }

                if (res.status === 401) {
                    router.replace('/logout');
                }

                if (raw) {
                    return res;
                }

                if (!res.ok) {
                    throw new APIError(res);
                }

                switch (true) {
                    default:
                        return res;

                    case /^application\/(javascript|json)/i.test(res.headers.get('Content-Type')):
                        return await res.json();

                    case res.status === 204:
                        return;
                }
            } catch (ex) {
                if (ex.message !== 'The user aborted a request.') {
                    throw ex;
                }

                return new Promise(() => { });
            } finally {
                commit('endRequest');
            }
        },
        async delete({ dispatch }, url) {
            const { url: _url, options, ...opts } = typeof url === 'object' ? url : { url };

            return await dispatch('fetch', {
                ...opts,
                url: _url,
                options: {
                    ...options,
                    method: 'DELETE'
                },
            });
        },
        async get({ dispatch }, url) {
            const { url: _url, options, useCache = true, ...opts } = typeof url === 'object' ? url : { url };

            if (useCache) {
                const key = md5(JSON.stringify({ url: _url, options, ...opts }));

                if (!cache.has(key)) {
                    cache.set(key, dispatch('get', { url: _url, options, ...opts, useCache: false }));
                    cache.get(key).finally(() => cache.delete(key))
                }

                return await cache.get(key);
            } else {
                return await dispatch('fetch', {
                    ...opts,
                    url: _url,
                    options: {
                        ...options,
                        method: 'GET'
                    },
                });
            }
        },
        async post({ dispatch }, url) {
            const { url: _url, data, options, ...opts } = typeof url === 'object' ? url : { url };

            return await dispatch('fetch', {
                ...opts,
                url: _url,
                options: {
                    ...options,
                    method: 'POST',
                    headers: {
                        'Content-Type': getContentType(data),
                        ...options?.headers,
                    },
                    body: getRequestBody(data, options?.headers?.['Content-Type'])
                },
            });
        },
        async put({ dispatch }, url) {
            const { url: _url, data, options, ...opts } = typeof url === 'object' ? url : { url };

            return await dispatch('fetch', {
                ...opts,
                url: _url,
                options: {
                    ...options,
                    method: 'PUT',
                    headers: {
                        'Content-Type': getContentType(data),
                        ...options?.headers,
                    },
                    body: getRequestBody(data, options?.headers?.['Content-Type'])
                },
            });
        },
        login({ commit }, { token, passwordExpired }) {
            commit('setToken', token);
            commit('setPasswordExpired', passwordExpired);
        },
        logout({ commit, dispatch }) {
            dispatch('abort');
            commit('setToken');
            commit('setPasswordExpired');
        },
        resetPassword({ commit }) {
            commit('setPasswordExpired');
        }
    }
};

export default api;