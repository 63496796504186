<template>
  <TemplateSelector
    :templates="this.templates"
    @cancel="handleCancel"
    @submit="handleSubmit"
  >
    <section slot="embedded">
      <content-header>Loans</content-header>
      <search-form :debounce="200" v-model="filter" />

      <table class="striped">
        <colgroup>
          <col width="4%" />
          <template v-if="clientId">
            <col width="10.14%" />
            <col width="14.14%" />
            <col width="14.14%" />
            <col width="14.14%" />
            <col width="14.14%" />
            <col width="14.14%" />
            <col width="14.14%" />
          </template>
          <template v-else>
            <col width="12.5%" />
            <col width="16.5%" />
            <col width="16.5%" />
            <col width="16.5%" />
            <col width="16.5%" />
            <col width="16.5%" />
          </template>
        </colgroup>
        <thead>
          <tr>
            <th>
              <input
                :checked="isAllSelected"
                type="checkbox"
                @click="handleSelectAll"
              />
            </th>
            <th>Loan Date</th>
            <th>Equipment Number</th>
            <th>Item</th>
            <th>Program</th>
            <th>Type</th>
            <th>Referral Agent</th>
            <th v-if="clientId">Equipment Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="loan in paginatedLoans"
            :key="loan.id"
            :class="selectedLoans.includes(loan.id) ? 'selected' : 'selectable'"
            @click="handleSelect(loan)"
          >
            <td>
              <input
                :checked="selectedLoans.includes(loan.id)"
                type="checkbox"
              />
            </td>
            <td>{{ formatTimestamp(loan.loanDate) }}</td>
            <td>{{ getEquipmentNumber(loan.equipment) }}</td>
            <td>{{ ((loan.equipment || {}).item || {}).name }}</td>
            <td>{{ (loan.program || {}).name }}</td>
            <td>{{ (loan.type || {}).name }}</td>
            <td>{{ getFullName(loan.referralAgent) || "Unknown" }}</td>
            <td v-if="clientId">{{ getStatus(loan.equipment) }}</td>
          </tr>
        </tbody>
        <tfoot v-if="hasPages">
          <tr>
            <td class="page-controls" :colspan="clientId ? 8 : 4">
              <icon-button
                :disabled="!hasPrevPage"
                name="prev"
                @click="goToPrevPage"
              />

              <icon-button
                :disabled="!hasNextPage"
                name="next"
                @click="goToNextPage"
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </section>
  </TemplateSelector>
</template>

<script>
import { pagination } from "../../mixins/pagination";

import TemplateSelector from "../templates/TemplateSelector";

import { generateDocuments } from "../../api/LoanAPI";

export default {
  mixins: [pagination("filteredLoans")],

  props: {
    clientId: {
      type: Number,
      default: null
    },
    loans: {
      type: Array,
    },
    templates: {
      type: Array,
    },
  },

  data() {
    return {
      filter: "",
      selectedLoans: [],
    };
  },

  computed: {
    filteredLoans() {
      const regex = this.getFilterRegex(this.filter);

      return (
        !/\S/.test(this.filter)
          ? this.loans.slice()
          : this.loans.filter(
              (loan) =>
                loan?.id &&
                regex.test(
                  [
                    this.getEquipmentNumber(loan?.equipment),
                    loan?.equipment?.item?.name ?? "",
                    loan?.client?.clientNumber ?? "",
                    this.getFullName(loan?.client),
                    loan?.loanDate ?? "",
                    this.formatTimestamp(loan?.loanDate) ?? "",
                    loan?.returnDate ?? "",
                    this.formatTimestamp(loan?.returnDate) ?? "",
                    loan?.program?.acronym ?? "",
                    loan?.program?.name ?? "",
                    loan?.type?.name ?? "",
                    this.getFullName(loan?.referralAgent),
                  ].join(String.fromCharCode(0x1f))
                )
            )
      ).sort(this.sortBy("-equipment.onLoan", "-loanDate", "-id"));
    },
    isAllSelected() {
      return (
        this.selectedLoans.length &&
        !this.paginatedLoans.find(
          (template) => !this.selectedLoans.includes(template.id)
        )
      );
    },
  },

  methods: {
    handleCancel() {
      this.filter = "";
      this.selectedLoans = [];
      this.$emit("cancel");
    },
    handleSelectAll() {
      this.selectedLoans = this.isAllSelected
        ? this.selectedLoans.filter(
            (id) => !this.paginatedLoans.find((template) => template.id === id)
          )
        : [
            ...this.selectedLoans,
            ...this.paginatedLoans.map((template) => template.id),
          ].filter((v, i, a) => a.indexOf(v) === i);
    },
    handleSelect(template) {
      this.selectedLoans = this.selectedLoans.includes(template.id)
        ? this.selectedLoans.filter((id) => id != template.id)
        : [...this.selectedLoans, template.id].filter(
            (v, i, a) => a.indexOf(v) === i
          );
    },
    async handleSubmit(selectedTemplates) {
      try {
        await generateDocuments({
          loanIds: this.selectedLoans,
          templateIds: selectedTemplates,
        });

        this.handleCancel();
      } catch (e) {
        this.showErrors(e);
      }
    },
    getStatus(equipment) {
      const status = this.getEquipmentStatus(equipment);

      if(status === "On Loan" && this.clientId && equipment.onLoanFor?.id === this.clientId) {
        return `${status} For ${equipment.onLoanFor.firstName} ${equipment.onLoanFor.lastName}`;
      } else {
        return status;
      }
      
    },
  },

  components: {
    TemplateSelector,
  },
};
</script>