<template>
  <form class="password-reset-form" @submit.prevent="handleSubmit">
    <fieldset>
      <p
        v-if="!withToken"
      >Before you continue to the application, you must reset your password by filling in the fields below and clicking "Save".</p>

      <p
        v-if="withToken"
      >Enter your new password below and click "Save" to reset your password. Upon completion, you will be taken back to the login page.</p>

      <label v-if="withToken">
        <img alt="Username" src="../../assets/common/user-default.svg" />
        <span class="required">Token</span>

        <input
          autocomplete="one-time-code"
          autofocus
          :disabled="!!token"
          id="reset-password-token"
          required
          type="text"
          v-model="$data._token"
        />
      </label>

      <label>
        <img alt="Password" src="../../assets/common/password-default.svg" />
        <span class="required">New Password</span>

        <password-with-strength
          autocomplete="new-password"
          autofocus
          id="reset-password-new-password"
          required
          v-model="$data._password"
        />
      </label>

      <label>
        <img alt="Password" src="../../assets/common/password-default.svg" />
        <span class="required">Confirm New Password</span>

        <input
          autocomplete="new-password"
          id="reset-password-confirm-password"
          required
          type="password"
          v-model="$data._confirmPassword"
        />
      </label>
    </fieldset>

    <footer>
      <button :disabled="submitting" type="submit">Save</button>
      <router-link :disabled="submitting" tag="button" :to="{name: 'Logout'}" type="button">Cancel</router-link>
    </footer>
  </form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      _confirmPassword: this.confirmPassword,
      _password: this.newPassword,
      _token: this.token,
      submitting: false,
    };
  },

  props: {
    confirmPassword: {
      type: String,
      default: "",
    },
    newPassword: {
      type: String,
      default: "",
    },
    token: {
      type: String,
      default: "",
    },
    withToken: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    ...mapActions(["resetPassword"]),
    async handleSubmit() {
      try {
        this.submitting = true;

        this.$emit(
          "login",
          await this.resetPassword({
            token: this.withToken ? this.$data._token : undefined,
            password: this.$data._password,
            confirmPassword: this.$data._confirmPassword,
          })
        );
      } catch (e) {
        this.showError(e, "Login");
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style>
.password-reset-form {
  color: inherit;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  justify-items: center;
  padding: 1em;
}

.password-reset-form label {
  align-items: center;
  color: inherit;
  display: grid;
  grid-column-gap: 1em;
  grid-template-areas:
    ". label"
    "icon input"
    ". strength";
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 3.5em;
}

.password-reset-form label img {
  grid-area: icon;
  height: 3.5em;
}

.password-reset-form label input,
.password-reset-form label .password-with-strength {
  align-self: center;
  font: inherit;
  font-style: initial;
  grid-area: input;
}

.password-reset-form label .password-with-strength {
  display: contents;
}

.password-reset-form label .password-strength {
  align-self: flex-end;
  grid-area: input;
  transform: translateY(0.2em);
}

.password-reset-form label span {
  grid-area: label;
}

.password-reset-form label ~ label {
  margin-top: 1em;
}

.password-reset-form p {
  padding-bottom: 2em;
  padding-left: 4.5em;
}
</style>