<template>
  <form
    autocomplete="off"
    class="admin-referral-agents-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">First Name</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-referral-agent-first-name"
          required
          type="text"
          v-model.trim="firstName"
        />
      </label>

      <label>
        <span class="required">Last Name</span>

        <input
          :disabled="disabled"
          id="admin-referral-agent-last-name"
          required
          type="text"
          v-model.trim="lastName"
        />
      </label>

      <label>
        <span class="required">Email</span>

        <email-address-input
          :disabled="disabled || referralAgent.isUser"
          id="admin-referral-agent-email-address"
          required
          :title="
            referralAgent.isUser
              ? 'This field must be edited through the user management interface'
              : undefined
            "
          v-model.trim="emailAddress.address"
        />
      </label>

      <label>
        <span>Phone Number</span>

        <phone-number-input
          :disabled="disabled"
          id="admin-referral-agent-phone-number"
          v-model="phoneNumber.number"
        />
      </label>

      <label>
        <span>Extension</span>

        <input
          :disabled="disabled"
          id="admin-referral-agent-phone-extension"
          pattern="^\d*$"
          type="text"
          v-model.number="phoneNumber.extension"
        />
      </label>

      <label>
        <span>Address</span>

        <input
          :disabled="disabled"
          id="admin-referral-agent-address"
          type="text"
          v-model.trim="address.address1"
        />
      </label>

      <label>
        <span>City</span>

        <select
          :disabled="disabled"
          id="admin-referral-agent-city"
          v-model.number="address.city.id"
        >
          <option disabled></option>

          <option
            v-for="city in filterOptions(cities, address.city)"
            :key="city.id"
            :value="city.id"
          >
            {{ city.name }}
          </option>
        </select>
      </label>

      <label>
        <span>Province</span>

        <select
          :disabled="disabled"
          id="admin-referral-agent-province"
          v-model.number="address.province.id"
        >
          <option disabled></option>

          <option
            v-for="province in filterOptions(provinces, address.province)"
            :key="province.id"
            :value="province.id"
          >
            {{ province.abbreviation }} - {{ province.name }}
          </option>
        </select>
      </label>

      <label>
        <span>County</span>

        <select
          :disabled="disabled"
          id="admin-referral-agent-county"
          v-model.number="address.county.id"
        >
          <option disabled></option>
          <option
            v-for="county in filterOptions(counties, address.county)"
            :key="county.id"
            :value="county.id"
          >
            {{ county.abbreviation }} - {{ county.name }}
          </option>
        </select>
      </label>

      <label>
        <span>Postal Code</span>

        <postal-code-input
          :disabled="disabled"
          id="admin-referral-agent-postal-code"
          v-model="address.postalCode"
        />
      </label>
    </fieldset>

    <fieldset class="admin-referral-agent-programs" v-if="referralAgent.isUser && can('MODIFY_REFERAL_AGENT_PROGRAM_ASSIGNMENTS')">
      <table class="striped admin-referral-agent-programs-table">
        <caption>
          <section>
            Program Assignments

            <search-form
              :debounce="200"
              v-model="programFilter"
              @change="goToFirstPage"
            />
          </section>
        </caption>
        <colgroup>
          <col width="3%" />
          <col width="26%" />
          <col width="71%" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <input
                :checked="isAllProgramsSelected"
                :disabled="disabled"
                type="checkbox"
                @click="handleSelectAllPrograms"
              />
            </th>
            <th>Acronym</th>
            <th>Program / Association</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="program in paginatedPrograms"
            :key="program.id"
            :class="disabled ? '' : 'selectable'"
            @click="handleSelectProgram(program)"
          >
            <td>
              <input
                :checked="selectedPrograms.includes(program.id)"
                :disabled="disabled"
                type="checkbox"
              />
            </td>
            <td>{{ program.acronym }}</td>
            <td>{{ program.name }}</td>
          </tr>
        </tbody>
        <tfoot v-if="hasPages">
          <tr>
            <td class="page-controls" colspan="3">
              <icon-button
                :disabled="!hasPrevPage"
                name="prev"
                @click="goToPrevPage"
              />

              <icon-button
                :disabled="!hasNextPage"
                name="next"
                @click="goToNextPage"
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </fieldset>

    <footer>
      <button
        :disabled="!isCreateUserEnabled"
        id="admin-referral-agent-create-user"
        type="button"
        @click="handleToggleCreation"
        v-if="canCreateUser"
      >
        Create User
      </button>

      <button
        :disabled="!isDeleteEnabled"
        id="admin-referral-agent-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete"
      >
        Delete
      </button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-referral-agent-save"
        type="submit"
        v-if="canSave"
      >
        Save
      </button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-referral-agent-cancel"
        type="reset"
        v-if="canCancel"
      >
        Cancel
      </button>
    </footer>

    <popup
      class="referral-agent-user-creation-popup"
      :modal="true"
      @close="handleToggleCreation"
      v-if="showUserCreation"
    >
      <ReferralAgentUserForm
        :disabled="!showUserCreation"
        :email="emailAddress.address"
        :organizations="organizations"
        @cancel="handleToggleCreation"
        @create="handleCreateUser"
      />
    </popup>
  </form>
</template>

<script>
import { pagination } from "../../mixins/pagination";
import { UserCreationMode } from "../../consts/UserCreationMode";

import ReferralAgentUserForm from "./ReferralAgentUserForm";

import {
  createReferralAgentUser,
  deleteReferralAgent,
  getReferralAgent,
  saveReferralAgent,
} from "../../api/ReferralAgentAPI";

export default {
  components: {
    ReferralAgentUserForm,
  },

  mixins: [pagination("filteredPrograms")],

  data() {
    return {
      firstName: undefined,
      lastName: undefined,
      emailAddress: {},
      phoneNumber: {},
      address: {
        city: {},
        county: {},
        province: {},
      },
      selectedPrograms: [],
      programFilter: "",
      showUserCreation: false,
    };
  },

  props: {
    cities: {
      type: Array,
    },
    counties: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    organizations: {
      type: Array,
    },
    programs: {
      type: Array,
    },
    provinces: {
      type: Array,
    },
    referralAgent: {
      type: Object,
    },
  },

  computed: {
    canCancel() {
      return true;
    },
    canCreateUser() {
      return (
        this.referralAgent?.id &&
        !this.referralAgent?.isUser &&
        this.can(["UPDATE_REFERRAL_AGENTS", "CREATE_USERS"])
      );
    },
    canDelete() {
      return this.referralAgent?.id && this.can("DELETE_REFERRAL_AGENTS");
    },
    canSave() {
      return this.can({
        permissions: ["CREATE_REFERRAL_AGENTS", "UPDATE_REFERRAL_AGENTS"],
        matchAny: true,
      });
    },
    filteredPrograms() {
      const regex = this.getFilterRegex(this.programFilter);

      return this.programs.filter((p) =>
        regex.test(
          [p?.acronym ?? "", p?.name ?? "", p.vendorNumber ?? ""].join(
            String.fromCharCode(0x1f)
          )
        )
      );
    },
    isAllProgramsSelected() {
      return (
        this.selectedPrograms.length &&
        !this.paginatedPrograms.find(
          (program) => !this.selectedPrograms.includes(program.id)
        )
      );
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isCreateUserEnabled() {
      return (
        !this.disabled && this.referralAgent?.id && !this.referralAgent?.isUser
      );
    },
    isDeleteEnabled() {
      return !this.disabled && this.referralAgent?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.referralAgent?.id
          ? this.can("UPDATE_REFERRAL_AGENTS")
          : this.can("CREATE_REFERRAL_AGENTS"))
      );
    },
  },

  methods: {
    async doSave() {
      return await saveReferralAgent({
        ...this.referralAgent,
        firstName: this.firstName,
        lastName: this.lastName,
        emailAddresses: this.referralAgent?.isUser
          ? undefined
          : this.emailAddress.address
          ? [{ ...this.emailAddress, primary: true }]
          : [],
        phoneNumbers: this.phoneNumber.number || this.phoneNumber.extension
          ? [{ ...this.phoneNumber, type: { id: 2 } }]
          : [],
        addresses:
          this.address.address1 ||
          this.address.city.id ||
          this.address.province.id ||
          this.address.county.id ||
          this.address.postalCode
            ? [{ ...this.address, primary: true }]
            : [],
        programs: 
        !this.can("MODIFY_REFERAL_AGENT_PROGRAM_ASSIGNMENTS")
          ? undefined :
        this.referralAgent?.isUser
          ? this.selectedPrograms
              .filter((id) => this.programs.find((p) => p.id === id))
              .map((id) => ({ id }))
          : [],
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    async handleCreateUser([{ email, organizationId }, mode]) {
      if (
        !this.checkValidity() ||
        !confirm(
          "Are you sure you want to create a user for this referral agent? They will remain available as a referral agent, but will be sent a welcome email and granted access to the system."
        )
      ) {
        return;
      }

      try {
        const user = await createReferralAgentUser(this.referralAgent, {
          email,
          organizationId,
        });

        switch (mode) {
          case UserCreationMode.STAY:
            this.showUserCreation = false;
            this.$emit("create-user");
            break;

          case UserCreationMode.CONTINUE:
          default:
            this.$nextTick(() => {
              this.$router.push({
                name: "Admin: Users",
                params: { id: user.id },
              });
            });

            break;
        }
      } catch (e) {
        this.showErrors(e, "A user");
      }
    },
    async handleDelete() {
      if (
        !confirm(
          this.referralAgent.isUser
            ? "Are you sure you want to delete this referral agent? This will only remove their referral agent record; they will still be granted access to the system as a user. To remove them completely, delete the user record via the user management interface."
            : "Are you sure you want to delete this referral agent?"
        )
      ) {
        return;
      }

      await deleteReferralAgent(this.referralAgent);
      this.$emit("delete", this.referralAgent);
    },
    handleSelectAllPrograms() {
      if (this.disabled) {
        return;
      }

      this.selectedPrograms = this.isAllProgramsSelected
        ? this.selectedPrograms.filter(
            (id) => !this.paginatedPrograms.find((program) => program.id === id)
          )
        : [
            ...this.selectedPrograms,
            ...this.paginatedPrograms.map((program) => program.id),
          ].filter((v, i, a) => a.indexOf(v) === i);
    },
    handleSelectProgram(program) {
      if (this.disabled) {
        return;
      }

      this.selectedPrograms = this.selectedPrograms.includes(program.id)
        ? this.selectedPrograms.filter((id) => id != program.id)
        : [...this.selectedPrograms, program.id].filter(
            (v, i, a) => a.indexOf(v) === i
          );
    },
    handleToggleCreation() {
      this.user = this.showUserCreation
        ? { email: undefined, organizationId: undefined }
        : { email: this.emailAddress.address, organizationId: undefined };
      this.showUserCreation = !this.showUserCreation;
    },
    async handleSave() {
      try {
        this.$emit("save", await this.doSave());
      } catch (e) {
        this.showErrors(e, "A referral agent");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      if (
        this.firstName !== this.referralAgent?.firstName ||
        this.lastName !== this.referralAgent?.lastName ||
        this.emailAddress.address !==
          this.referralAgent?.emailAddresses?.find((a) => a.primary)?.address
      ) {
        return true;
      }

      const address = this?.referralAgent?.addresses?.find((a) => a.primary);

      if (
        this.address.address1 !== address?.address1 ||
        this.address.city?.id !== address?.city?.id ||
        this.address.province?.id !== address?.province?.id ||
        this.address.county?.id !== address?.county?.id ||
        this.address.postalCode !== address?.postalCode
      ) {
        return true;
      }

      const phoneNumber = this.referralAgent?.phoneNumbers?.find(
        (p) => p?.type?.id === 2
      );

      if (
        this.phoneNumber.number !== phoneNumber?.number ||
        this.phoneNumber.extension !== phoneNumber?.extension
      ) {
        return true;
      }

      return (
        JSON.stringify(this.selectedPrograms.sort((a, b) => +a - +b)) !==
        JSON.stringify(
          this.referralAgent?.programs
            ?.map((p) => +p.id)
            ?.sort((a, b) => +a - +b) ?? []
        )
      );
    },
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    referralAgent: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.firstName = to?.firstName;
          this.lastName = to?.lastName;
          this.emailAddress = { ...to?.emailAddresses?.find((a) => a.primary) };

          this.phoneNumber = {
            ...to?.phoneNumbers?.find((p) => p?.type?.id === 2),
          };

          this.address = { ...to?.addresses?.find((a) => a.primary) };
          this.address.city = this.address.city ?? {};
          this.address.county = this.address.county ?? {};
          this.address.province = this.address.province ?? {};
          this.selectedPrograms = to.programs?.map((p) => p?.id) ?? [];
        }

        !this.disabled && this.autofocus();
      },
    },
  },
};
</script>

<style>
.admin-referral-agents-form,
.referral-agent-user-creation-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.admin-referral-agents-form .admin-referral-agent-referral-agent-label {
  align-items: center;
}

.admin-referral-agents-form .admin-referral-agent-programs::before {
  display: none;
}

.admin-referral-agents-form .admin-referral-agent-programs-table {
  grid-column: 1 / span 4;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.admin-referral-agents-form
  .admin-referral-agent-programs-table
  caption
  section {
  align-items: flex-end;
  display: grid;
  grid-template-columns: 1fr auto;
}

.admin-referral-agents-form
  .admin-referral-agent-programs-table
  caption
  .search-form {
  font-weight: normal;
}

.admin-referral-agents-form > footer,
.referral-agent-user-creation-form > footer {
  grid-column: span 4;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>