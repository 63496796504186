<template>
  <div class="picture-mask">
    <div class="picture-wrapper">
      <div class="picture-body">
        <div class="picture-close">
          <span v-on:click="onCloseClick">X</span>
        </div>
        <div class="picture-header">
          <h2>Add image(s)</h2>
        </div>
        <div class="remaining-count">
          <h3>{{3 - photosCount}} uploads remaining (Max 3)</h3>
        </div>
        <div class="picture-message">
          <button type="button" @click="getAllImages()" :disabled="!(photosCount < 3)">FROM LIBRARY</button>
          <label :class="photosCount >= 3 ? 'upload-file-disabled' : 'custom-file-upload'">
            Upload File
            <input
              type="file"
              accept="image/*"
              :id="inputId"
              ref="file"
              multiple
              @change="handleFileUpload"
              :disabled="!(photosCount < 3)"
            />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllPhotos } from "../../api/EquipmentAPI";

export default {
  data() {
    return {
      archivedPhotos: []
    };
  },
  methods: {
    onCloseClick() {
      this.$emit("closeClick", true);
    },
    handleFileUpload() {
      this.$emit("equipmentUploadedImages", this.$refs.file.files);
    },
    getAllImages() {
      this.$emit("showArchivePicModal", true);
    }
  },
  props: {
    inputId: "",
    photosCount: 0
  }
};
</script>

<style>
.picture-close {
  grid-area: close;
  justify-self: end;
  cursor: pointer;
}

.picture-header {
  grid-area: header;
  display: flex;
  justify-content: center;
  font-size: 1.3em;
}

.remaining-count {
  grid-area: remaining;
  margin: auto;
}

.picture-message {
  grid-area: body;
  display: flex;
  justify-content: space-evenly;
}

.picture-body {
  width: 30%;
  display: grid;
  grid-auto-rows: 1rem auto auto 1fr;
  grid-template-areas:
    "close"
    "header"
    "remaining"
    "body";
  position: absolute;
  top: 30%;
  left: 35%;
  z-index: 9999;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  padding: 2em;
  grid-row-gap: 1em;
}

.picture-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.picture-message > label {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 0.8rem;
  font-weight: bold;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: none;
  padding: 0.6rem 2rem;
  text-transform: uppercase;
  font-style: inherit;
}

.picture-message .custom-file-upload {
  background: var(--primary-black);
  border-color: var(--primary-black);
  color: var(--primary-white);
  cursor: pointer;
}

.picture-message > .custom-file-upload:hover {
  background: var(--primary-white);
  border-color: var(--primary-red);
  color: var(--primary-red);
}

.picture-message > .custom-file-upload:active {
  background: var(--primary-red);
  border-color: var(--primary-red);
  color: var(--primary-white);
}

.picture-mask input[type="file" i] {
  display: none;
}

.upload-file-disabled {
  background: var(--disabled-background-color);
  border-color: var(--disabled-background-color);
  color: var(--disabled-font-color);
  cursor: default;
}
</style>
