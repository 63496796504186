<template>
  <form class="forgot-email-form" @submit.prevent="handleSubmit">
    <h3
      v-if="submitted"
    >Your request has been submitted and will be reviewed by the system administrator.</h3>

    <fieldset v-if="!submitted">
      <label>
        <span class="required">First Name</span>

        <input
          autocomplete="given-name"
          autofocus
          id="forgot-email-first-name"
          required
          type="text"
          v-model="$data._firstName"
        />
      </label>

      <label>
        <span class="required">Last Name</span>

        <input
          autocomplete="family-name"
          id="forgot-email-last-name"
          required
          type="text"
          v-model="$data._lastName"
        />
      </label>

      <label class="organization">
        <span class="required">Organization</span>

        <input
          autocomplete="organization"
          id="forgot-email-organization"
          required
          type="text"
          v-model="$data._organization"
        />
      </label>

      <label>
        <span class="required">Phone Number</span>

        <phone-number-input
          autocomplete="tel"
          id="forgot-email-phone-number-number"
          required
          v-model="$data._phoneNumber"
        />
      </label>

      <label>
        <span>Extension</span>

        <input
          autocomplete="tel-extension"
          id="forgot-email-phone-number-extension"
          type="text"
          v-model="$data._extension"
        />
      </label>
    </fieldset>

    <footer>
      <button :disabled="submitting" type="submit" v-if="!submitted">Submit</button>

      <router-link
        :disabled="submitting"
        tag="button"
        :to="{name: 'Logout'}"
        type="button"
      >{{ submitted ? 'Return to Login Page' : 'Cancel' }}</router-link>
    </footer>
  </form>
</template>

<script>
import { recoverUsername } from "../../api/SecurityAPI";

export default {
  data() {
    return {
      _extension: this.extension,
      _firstName: this.firstName,
      _lastName: this.lastName,
      _organization: this.organization,
      _phoneNumber: this.phoneNumber,
      submitted: false,
      submitting: false,
    };
  },

  props: {
    extension: {
      type: String,
      default: "",
    },
    firstName: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    organization: {
      type: String,
      default: "",
    },
    phoneNumber: {
      type: String,
      default: "",
    },
  },

  methods: {
    async handleSubmit() {
      try {
        this.submitting = true;

        const res = await recoverUsername({
          firstName: this.$data._firstName,
          lastName: this.$data._lastName,
          organization: this.$data._organization,
          phoneNumber: this.$data._phoneNumber,
          extension: this.$data._extension,
        });

        this.submitted = res.ok;

        res.ok ||
          this.showAlert(
            "An unexpected error has occurred. Please contact the system administrator for assistance."
          );
      } catch (e) {
        this.showError(e);
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style>
.forgot-email-form {
  display: grid;
  grid-column-gap: 1.5em;
  grid-row-gap: 1.5em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.forgot-email-form label {
  color: inherit;
}

.forgot-email-form label input {
  font: inherit;
  font-style: normal;
  margin-top: 0.5em;
}

.forgot-email-form > h3,
.forgot-email-form .organization,
.forgot-email-form footer {
  grid-column: span 2;
}
</style>