<template>
  <table class="striped admin-equipment-specification-options-table">
    <caption v-html="caption" v-if="caption" />
    <colgroup>
      <col width="2.639em" />
      <col width="auto" />
      <col width="2.361em" />
    </colgroup>
    <thead>
      <tr>
        <th></th>
        <th>Drop Down Options</th>
        <th></th>
      </tr>
    </thead>
    <draggable handle=".icon-sort" :list="options" tag="tbody" v-if="!disabled && canSave">
      <tr v-for="option in options" :key="option.id">
        <td>
          <icon name="sort" />
        </td>
        <td v-if="!disabled">
          <input
            class="equipment-specification-option-value"
            :disabled="false"
            type="text"
            v-model="option.value"
          />
        </td>
        <td>
          <icon-button
            class="equipment-specification-option-remove"
            name="remove"
            @click="handleRemove(option)"
          />
        </td>
      </tr>
    </draggable>
    <tbody v-if="disabled">
      <tr v-for="option in options" :key="option.id">
        <td></td>
        <td v-if="disabled">{{ option.value }}</td>
        <td></td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td class="page-next" colspan="3">
          <icon-button :disabled="disabled" name="add" @click="handleAdd" v-if="canSave" />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import draggable from "vuedraggable";

export default {
  props: {
    caption: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array
    }
  },

  computed: {
    canSave() {
      return this.can({ permissions: ["CREATE_EQUIPMENT_ITEMS", "UPDATE_EQUIPMENT_ITEMS"], matchAny: true });
    }
  },

  methods: {
    handleAdd() {
      this.$emit("update:options", [...this.options, { value: "" }]);

      this.$nextTick(() => {
        [...this.$el?.querySelectorAll?.("input")]
          .slice(-1)
          .pop()
          ?.focus?.();
      });
    },
    handleRemove(option) {
      this.$emit(
        "update:options",
        this.options.filter(o => o !== option)
      );
    }
  },

  components: {
    draggable
  }
};
</script>

<style>
.admin-equipment-specification-options-table :not(tfoot) tr > *:first-child {
  text-align: center;
  vertical-align: middle;
  width: 2.639em;
}

.admin-equipment-specification-options-table :not(tfoot) tr > *:last-child {
  vertical-align: middle;
  width: 2.361em;
}

.admin-equipment-specification-options-table
  .equipment-specification-option-value {
  appearance: none;
  background: none;
  border: none;
  width: 100%;
}

.admin-equipment-specification-options-table .icon-sort {
  cursor: move;
  filter: var(--filter-primary-light-gray);
}

.admin-equipment-specification-options-table .icon-sort:hover {
  cursor: move;
  filter: var(--filter-primary-medium-gray);
}

.admin-equipment-specification-options-table .icon-sort:active,
.admin-equipment-specification-options-table .icon-sort:focus {
  filter: var(--filter-primary-black);
}

.admin-equipment-specification-options-table
  .equipment-specification-option-remove {
  height: 1.33em;
  width: 1.33em;
}

.admin-equipment-specification-options-table
  .equipment-specification-option-remove
  .icon-remove {
  font-size: 0.33em;
}
</style>