<template>
  <PartialView>
    <EquipmentCategories
      ref="categories"
      class="admin-content-partial"
      header-tag="h3"
      :id="categoryId"
      :route-params="{view: 'categories'}"
      :show-back="false"
      @update:categories="handleUpdateCategories"
      v-if="hasAdmin('EQUIPMENT_CATEGORIES')"
    />

    <EquipmentSubcategories
      ref="subcategories"
      class="admin-content-partial"
      :categories="categories"
      header-tag="h3"
      :id="subcategoryId"
      :route-params="{view: 'subcategories'}"
      :show-back="false"
      @update:subcategories="handleUpdateSubcategories"
      v-if="hasAdmin('EQUIPMENT_SUBCATEGORIES')"
    />

    <EquipmentItems
      ref="items"
      class="admin-content-partial"
      header-tag="h3"
      :id="itemId"
      :subcategories="subcategories"
      :route-params="{view: 'items'}"
      :show-back="false"
      v-if="hasAdmin('EQUIPMENT_ITEMS')"
    />
  </PartialView>
</template>

<script>
import PartialView from "./PartialView";
import EquipmentCategories from "./pages/EquipmentCategories";
import EquipmentSubcategories from "./pages/EquipmentSubcategories";
import EquipmentItems from "./pages/EquipmentItems";

export default {
  components: {
    PartialView,
    EquipmentCategories,
    EquipmentSubcategories,
    EquipmentItems,
  },

  data() {
    return {
      categoryId: this.view === "categories" ? this.id : undefined,
      categories: undefined,
      itemId: undefined,
      subcategoryId: this.view === "subcategories" ? this.id : undefined,
      subcategories: undefined,
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined,
    },
    view: {
      type: String,
      default: "categories",
    },
  },

  methods: {
    handleUpdateCategories(categories) {
      this.categories = categories;
    },
    handleUpdateSubcategories(subcategories) {
      this.subcategories = subcategories;
    },
    isDirty() {
      return false;
    },
  },

  mounted() {
    this.$refs.categories &&
      (this.$refs.categories.$el.id = "admin-equipment-categories");

    this.$refs.subcategories &&
      (this.$refs.subcategories.$el.id = "admin-equipment-subcategories");

    this.$refs.items && (this.$refs.items.$el.id = "admin-equipment-items");
  },

  beforeRouteUpdate(to, from, next) {
    to.params.view === "categories" &&
      (this.categoryId = +to.params.id || undefined);

    to.params.view === "subcategories" &&
      (this.subcategoryId = +to.params.id || undefined);

    to.params.view === "items" && (this.itemId = +to.params.id || undefined);

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (
      !to.params.skipDirtyCheck &&
      Object.values(this.$refs).find((r) => r?.isDirty?.()) &&
      !this.confirmDiscard()
    ) {
      return next(false);
    }

    this.abortRequests();
    next();
  },
};
</script>