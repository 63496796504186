<template>
  <form
    autocomplete="off"
    class="admin-equipment-status-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Name</span>
        <input
          autofocus
          :disabled="disabled"
          id="admin-equipment-status-name"
          required
          type="text"
          v-model.trim="name"
        />
      </label>

      <label>
        <span>Description</span>
        <input
          autofocus
          :disabled="disabled"
          id="admin-equipment-status-description"
          type="text"
          v-model.trim="description"
        />
      </label>

      <label>
        <span class="required">Availability</span>
        <select
          :disabled="disabled"
          id="admin-equipment-status-type"
          required
          v-model.number="availability"
        >
          <option
            v-for="status in statusTypes"
            :key="status"
            :value="status"
          >{{ status }}</option>
        </select>
      </label>
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-equipment-status-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && status.id"
      >
        Delete
      </button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-equipment-status-save"
        type="submit"
        v-if="canSave"
      >
        Save
      </button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-equipment-status-cancel"
        type="reset"
        v-if="canCancel"
      >
        Cancel
      </button>
    </footer>
  </form>
</template>

<script>
import { StatusTypes } from "../../consts/StatusTypes";

import {
  deleteEquipmentStatus,
  saveEquipmentStatus
} from "../../api/EquipmentAPI";

export default {
  data() {
    return {
      name: undefined,
      description: undefined,
      available: undefined,
      lost: undefined,
      statusTypes: StatusTypes
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Object,
    },
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_EQUIPMENT_STATUSES");
    },
    canSave() {
      return this.can({
        permissions: ["CREATE_EQUIPMENT_STATUSES", "UPDATE_EQUIPMENT_STATUSES"],
        matchAny: true,
      });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return (
        !this.disabled &&
        this.status?.id &&
        !this.status?.protected
      );
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.status?.id
          ? this.can("UPDATE_EQUIPMENT_STATUSES")
          : this.can("CREATE_EQUIPMENT_STATUSES"))
      );
    },
    availability: {
      get() {
        return this.status?.lost
          ? StatusTypes.Lost
          : this.status?.available
          ? StatusTypes.Available
          : StatusTypes.Unavailable
      },
      set(value) {
        this.available = (value === StatusTypes.Available);
        this.lost = (value === StatusTypes.Lost);
      }
    }
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (
        !confirm(
          "Are you sure you want to delete this equipment status?"
        )
      ) {
        return;
      }

      await deleteEquipmentStatus(this.status);
      this.$emit("delete", this.status);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveEquipmentStatus({
            ...this.status,
            name: this.name,
            description: this.description.trim() === "" ? null : this.description,
            available: this.available,
            lost: this.lost
          })
        );
      } catch (e) {
        this.showErrors(e, "An equipment status");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return (
        this.name !== this.status?.name ||
        this.description !== this.status?.description ||
        this.available !== this.status?.available ||
        this.lost !== this.status?.lost
      );
    }
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    status: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.name = to?.name;
          this.description = to?.description;
          this.lost = to?.lost;
          this.available = to?.available;
        }

        !this.disabled && this.autofocus();
      },
    },
  }
};
</script>

<style>
.admin-equipment-status-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.admin-equipment-status-form> footer {
  grid-column: span 3;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>