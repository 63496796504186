import { del, get, post, put } from './http';

const API_PATH = '/api/insurance-providers';

export const createInsuranceProvider = async insuranceProvider =>
    await post(API_PATH, insuranceProvider);

export const deleteInsuranceProvider = async insuranceProvider =>
    await del(`${API_PATH}/${insuranceProvider.id}`);

export const getInsuranceProviders = async () =>
    await get(API_PATH);

export const saveInsuranceProvider = async insuranceProvider =>
    await (insuranceProvider.id ? updateInsuranceProvider(insuranceProvider) : createInsuranceProvider(insuranceProvider));

export const updateInsuranceProvider = async insuranceProvider =>
    await put(`${API_PATH}/${insuranceProvider.id}`, insuranceProvider);