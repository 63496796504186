<template>
  <section>
    <header class="login-header">
      <slot name="logo" />

      <section class="login-header-text">
        <h2>Forgot your email?</h2>
        <p>No problem! Send us your information and we will contact you with your next steps.</p>
      </section>
    </header>

    <ForgotEmailForm />
  </section>
</template>

<script>
import ForgotEmailForm from "../../../components/login/ForgotEmailForm";

export default {
  components: {
    ForgotEmailForm
  }
};
</script>

<style>
.login-page .forgot-email-form {
  width: 80%;
}
</style>