import { del, get, post, put } from './http';

const API_PATH = '/api/organizations';

export const createOrganization = async organization =>
    await post(API_PATH, organization);

export const deleteOrganization = async organization =>
    await del(`${API_PATH}/${organization.id}`);

export const getOrganizations = async () =>
    await get(API_PATH);

export const saveOrganization = async organization =>
    await (organization.id ? updateOrganization(organization) : createOrganization(organization));

export const updateOrganization = async organization =>
    await put(`${API_PATH}/${organization.id}`, organization);