import { del, get, post, put } from './http';

const API_PATH = '/api/users';

export const createUser = async user =>
    await post(API_PATH, { url: new URL(process.env.BASE_URL, window.location.origin), ...user })

export const deleteUser = async user =>
    await del(`${API_PATH}/${user.id}`);

export const getUsers = async () =>
    await get(API_PATH);

export const saveUser = async user =>
    await (user.id ? updateUser(user) : createUser(user));

export const updateUser = async user =>
    await put(`${API_PATH}/${user.id}`, user);

export const toggleUserLanguage = async (languageId) =>
    await put(`${API_PATH}/language/${languageId}`);