<template>
  <ContentWrapper
    :create-icon="can('CREATE_LOAN_TYPES') ? 'add-loan' : null"
    :header-tag="headerTag"
    search-input-id="admin-loan-type-search-phrase"
    :show-back="showBack"
    title="Loan Types"
    @create="handleCreate"
    @search="handleSearch"
  >
    <LoanTypesTable
      class="admin-table"
      :loan-types="filteredLoanTypes"
      ref="table"
      :selected-id="loanType.id"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="loanType.id ? `Edit - ${loanType.name}` : 'Create Loan Type'"
      />

      <span class="admin-form-tab">
        <img alt="Loan Types" src="../../../assets/icons/loan.svg" />
      </span>

      <LoanTypesForm
        class="admin-form"
        :disabled="loanType.name === undefined"
        :loan-type="loanType"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import LoanTypesTable from "../../../components/admin/LoanTypesTable";
import LoanTypesForm from "../../../components/admin/LoanTypesForm";

import { getLoanTypes } from "../../../api/LoanAPI";

export default {
  data() {
    return {
      _loanTypes: [],
      loanType: {},
      search: "",
    };
  },

  props: {
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    loanTypes: {
      type: Array,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    filteredLoanTypes() {
      const regex = this.getFilterRegex(this.search);
      return this.$data._loanTypes.filter((p) => regex.test(p?.name ?? ""));
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.loanType = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.loanType = { name: "" };
      }
    },
    handleDelete(loanType) {
      this.$data._loanTypes = this.$data._loanTypes.filter(
        (p) => p?.id !== loanType?.id
      );

      this.loanType = {};
    },
    handleSave(loanType) {
      const created = !this.loanType.id;

      this.$data._loanTypes = [
        ...this.$data._loanTypes.filter((p) => p?.id !== loanType?.id),
        loanType,
      ];

      this.handleSelect(created ? loanType : undefined, true);

      this.showAlert(
        `Your loan type has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
      this.$refs.table.goToFirstPage();
    },
    handleSelect(loanType, force = false) {
      if (force || this.confirmIfDirty()) {
        this.loanType = {
          ...this.$data._loanTypes.find((p) => p?.id === loanType?.id),
        };
      }
    },
  },

  watch: {
    "$data._loanTypes"(to, from) {
      if (to !== from) {
        this.$data._loanTypes = to.sort(this.sortBy("name", "id"));
        this.$emit("update:loan-types", this.$data._loanTypes);
      }
    },
    "loanType.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    async loanTypes(to, from) {
      to === this.$data._loanTypes ||
        (this.$data._loanTypes = to?.slice?.() ?? (await getLoanTypes()));
    },
  },

  async created() {
    this.$data._loanTypes = this.loanTypes?.slice?.() ?? (await getLoanTypes());
    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    LoanTypesTable,
    LoanTypesForm,
  },
};
</script>