<template>
  <section>
    <header class="login-header">
      <slot name="logo" />
    </header>

    <ResetPasswordForm :token="token" :with-token="withToken" @login="handleLogin" />
  </section>
</template>

<script>
import ResetPasswordForm from "../../../components/login/ResetPasswordForm";

export default {
  props: {
    token: {
      type: String,
      default: undefined,
    },
    withToken: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async handleLogin(res) {
      if (!res.ok) {
        const error = await res.json();
        const errors = error?.errors ?? [error.message];

        if (errors.includes("invalid password")) {
          this.showError(
            "The password you have entered is not strong enough. Please enter a new one and try again."
          );
        } else if (errors.includes("password cannot match old password")) {
          this.showError("Your new password cannot match your old one.");
        } else if (errors.includes("token is no longer valid")) {
          this.showError(
            "Your password reset token has expired. Please request a new one and try again."
          );
          this.$router.push({ name: "Forgot Password" });
        } else {
          this.showError(
            "An unexpected error has occurred. Please contact your system administrator."
          );
        }
      } else {
        this.withToken &&
          this.showAlert(
            "Your password has been changed. You will now be taken back to the login form."
          );

        this.$router.push(this.$route.query.next ?? { name: "Home" });
      }
    },
  },

  components: {
    ResetPasswordForm,
  },
};
</script>