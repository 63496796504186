<template>
  <router-view :class="['admin-page', full && 'admin-page-full']" />
</template>

<script>
export default {
  computed: {
    full() {
      return this.$route.meta.full === true;
    },
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (
      !to.params.skipDirtyCheck &&
      Object.values(this.$refs).find((r) => r?.isDirty?.()) &&
      !this.confirmDiscard()
    ) {
      return next(false);
    }

    this.abortRequests();
    next();
  },
};
</script>

<style>
.admin-page {
  display: grid;
  grid-template-columns: 3.1em 1fr auto auto;
  grid-template-areas:
    "header   header   search   add"
    "nav      table    table    table"
    "form     form     form     form";
}

.admin-page .content-header {
  grid-area: header;
}

.admin-page .admin-search {
  align-items: flex-end;
  display: flex;
  font-size: 0.8rem;
  font-weight: normal;
  grid-area: search;
  margin: 0 0 0.585rem auto;
  padding: 0;
}

.admin-page .admin-search label {
  color: var(--primary-red);
  margin-right: 0.33em;
}

.admin-page .admin-create-button {
  align-self: flex-end;
  font-size: 0.755rem;
  grid-area: add;
  margin-bottom: 0.585rem;
}

.admin-page .admin-content {
  display: contents;
}

.admin-page .admin-content-full {
  grid-area: header / header / form / form;
}

.admin-page .admin-table {
  grid-column: span 4;
}

.admin-page .admin-form-wrapper {
  align-items: flex-start;
  display: grid;
  grid-column: nav / form;
  grid-template-areas:
    ".     header"
    "tab   form";
  grid-template-columns: 4em 1fr;
  margin-top: 2em;
}

.admin-page .admin-form-header {
  grid-area: header;
  margin: 0;
  position: initial;
  top: initial;
}

.admin-page .admin-form-tab {
  align-items: center;
  background-image: radial-gradient(
    circle at 100% 3em,
    var(--primary-red) 3em,
    transparent 3.1em
  );
  background-repeat: no-repeat;
  display: flex;
  grid-area: tab;
  height: 6em;
  justify-content: center;
  justify-self: flex-end;
  padding-left: 12%;
  width: 3.1em;
}

.admin-page .admin-form-tab img {
  filter: var(--filter-primary-white);
  max-height: 3em;
  max-width: 2.5em;
}

.admin-page .admin-form {
  border-color: var(--primary-medium-gray);
  border-style: solid;
  border-width: 1px;
  grid-area: form;
  padding: 1em;
}

.admin-page-full {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.admin-page-full > * {
  width: 100%;
}
</style>