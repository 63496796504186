<template>
  <SearchCard
    class="equipment-card"
    :expanded="expanded"
    @update:expanded="$emit('update:expanded', arguments[0])"
  >
    <template slot="tab-icon">
      <img src="../../assets/icons/equipment.svg" />
    </template>

    <template slot="actions">
      <router-link :to="{'name': 'Equipment', 'params': {'id': equipment.id}}">
        <icon-button name="edit" />
      </router-link>

      <icon-button name="archive" @click="handleArchive" v-if="can('DELETE_EQUIPMENT')" />
    </template>

    <template slot="info">
      <label>
        Equipment Number
        <strong>{{ getEquipmentNumber(equipment) }}</strong>
      </label>

      <label>
        Serial Name
        <strong>{{ equipment.serialNumber || 'N/A' }}</strong>
      </label>

      <label>
        Model Name
        <strong>{{ equipment.modelNumber || 'N/A' }}</strong>
      </label>

      <label>
        Equipment Category
        <strong>{{ category.name || 'N/A' }}</strong>
      </label>

      <label>
        Item
        <strong>{{ item.name || 'N/A' }}</strong>
      </label>
    </template>

    <template slot="expanded-info">
      <label>
        Status
        <strong>{{ getEquipmentStatus(equipment) }}</strong>
      </label>

      <label>
        Purchase Date
        <strong>{{ formatTimestamp(equipment.purchaseDate, { convertTimezone: false }) || 'N/A' }}</strong>
      </label>

      <label>
        Manufacturer
        <strong>{{ manufacturer.name || 'N/A' }}</strong>
      </label>

      <label>
        Equipment Subcategory
        <strong>{{ subcategory.name || 'N/A' }}</strong>
      </label>

      <label></label>

      <label>
        Program Assignment
        <strong>{{ program.name || 'N/A' }}</strong>
      </label>

      <label>
        Purchase Cost
        <strong>{{ (/\d/.test(equipment.purchaseCost) && formatCurrency(equipment.purchaseCost)) || 'N/A' }}</strong>
      </label>

      <label>
        Supplier
        <strong>{{ vendor.name || 'N/A' }}</strong>
      </label>

      <label>
        Return Date
        <strong>{{ formatTimestamp(equipment.returnDate, { convertTimezone: false }) || 'N/A' }}</strong>
      </label>

      <section class="equipment-photos" v-if="equipment.photos.length > 0">
        <img v-for="photo in equipment.photos" :key="photo.id" :src="cleanUrl(photo.url)" />
      </section>
    </template>
  </SearchCard>
</template>

<script>
import SearchCard from "../search/SearchCard";
import { deleteEquipment } from "../../api/EquipmentAPI";

export default {
  components: {
    SearchCard,
  },

  props: {
    equipment: {
      item: Object,
    },
    expanded: {
      type: Boolean,
      default: null,
    },
  },

  computed: {
    category() {
      return this.equipment?.category ?? {};
    },
    item() {
      return this.equipment?.item ?? {};
    },
    manufacturer() {
      return this.equipment?.manufacturer ?? {};
    },
    program() {
      return this.equipment?.program ?? {};
    },
    subcategory() {
      return this.equipment?.subcategory ?? {};
    },
    vendor() {
      return this.equipment?.vendor ?? {};
    },
  },

  methods: {
    async handleArchive() {
      if (
        !confirm("Are you sure you want to archive this piece of equipment?")
      ) {
        return;
      }

      try {
        await deleteEquipment(this.equipment);
        this.$emit("delete", this.equipment);
        this.showAlert("The equipment has been successfully archived!");
      } catch (e) {
        this.showErrors(e);
      }
    },
  },
};
</script>

<style>
.equipment-card .search-card-inner .search-info {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
</style>

<style scoped>
.equipment-photos {
  display: flex;
  justify-content: space-evenly;
  grid-column: 1 / 6;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  width: 100%;
}

.equipment-photos img {
  height: 10em;
  width: auto;
}
</style>
