<template>
  <table class="striped admin-programs-table">
    <caption v-html="caption" v-if="caption" />
    <colgroup>
      <col width="33%" />
      <col width="33%" />
      <col width="33%" />
    </colgroup>
    <thead>
      <tr>
        <th>Acronym</th>
        <th>Program / Association</th>
        <th>Vendor Number</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="program in paginatedPrograms"
        :key="program.id"
        :class="selectedId === program.id ? 'selected' : 'selectable'"
        @click="handleSelect(program)"
      >
        <td>{{ program.acronym }}</td>
        <td>{{ program.name }}</td>
        <td>{{ program.vendorNumber }}</td>
      </tr>
      <tr v-if="programs.length === 0">
        <td class="no-records" colspan="3">
          There are currently no programs in the system.
        </td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls" colspan="3">
          <icon-button
            :disabled="!hasPrevPage"
            name="prev"
            @click="goToPrevPage"
          />

          <icon-button
            :disabled="!hasNextPage"
            name="next"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("programs")],

  props: {
    caption: {
      type: String,
      default: undefined,
    },
    programs: {
      type: Array,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
  },

  methods: {
    handleSelect(program) {
      this.$emit("select", program);
    },
  },
};
</script>