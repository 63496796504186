<template>
  <component :is="tag" v-bind="$attrs" v-on="$listeners">
    <h3 class="admin-form-header content-header">User Details</h3>

    <section class="referral-agent-user-creation-form admin-form">
      <fieldset>
        <label>
          <span class="required">Email</span>

          <email-address-input
            :disabled="disabled"
            id="admin-referral-agent-user-email"
            name="username"
            required
            v-model.trim="$data._email"
          />
        </label>

        <label>
          <span class="required">Organization</span>

          <select
            :disabled="disabled"
            id="admin-referral-agent-user-organization"
            required
            v-model.number="organizationId"
          >
            <option disabled></option>

            <option
              v-for="organization in organizations"
              :key="organization.id"
              :value="organization.id"
            >
              {{ organization.name }}
            </option>
          </select>
        </label>
      </fieldset>

      <footer>
        <button
          class="create-button"
          id="admin-referral-agent-user-create-and-continue"
          title="Create the user, and continue to the user record"
          type="button"
          @click="handleCreateContinue"
        >
          Create and Continue
        </button>

        <button
          class="create-button"
          id="admin-referral-agent-user-create-and-stay"
          title="Create the user, and stay on the referral agent record"
          type="button"
          @click="handleCreateStay"
        >
          Create and Stay
        </button>

        <button
          id="admin-referral-agent-user-cancel"
          type="button"
          @click="handleCancel"
        >
          Cancel
        </button>
      </footer>
    </section>
  </component>
</template>

<script>
import { UserCreationMode } from "../../consts/UserCreationMode";

export default {
  data() {
    return {
      _email: this.email,
      organizationId: 0,
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    email: {
      type: String,
      default: "",
    },
    organizations: {
      type: Array,
    },
    tag: {
      type: String,
      default: "section",
    },
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    handleCreateContinue() {
      this.$emit("create:continue", {
        email: this.email,
        organizationId: this.organizationId,
      });

      this.$emit("create", [
        {
          email: this.$data._email,
          organizationId: this.organizationId,
        },
        UserCreationMode.CONTINUE,
      ]);
    },
    handleCreateStay() {
      this.$emit("create:stay", {
        email: this.email,
        organizationId: this.organizationId,
      });

      this.$emit("create", [
        {
          email: this.email,
          organizationId: this.organizationId,
        },
        UserCreationMode.STAY,
      ]);
    },
  },
};
</script>

<style scoped>
.create-button {
  align-self: stretch;
  font-size: 0.67em;
}
</style>