<template>
  <table class="striped admin-equipment-categories-table">
    <caption v-html="caption" v-if="caption" />
    <thead>
      <tr>
        <th>Category</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="category in paginatedCategories"
        :key="category.id"
        :class="selectedId === category.id ? 'selected' : 'selectable'"
        @click="handleSelect(category)"
      >
        <td>{{ category.name }}</td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls">
          <icon-button
            :disabled="!hasPrevPage"
            name="prev"
            @click="goToPrevPage"
          />

          <icon-button
            :disabled="!hasNextPage"
            name="next"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("categories")],

  props: {
    categories: {
      type: Array,
    },
    caption: {
      type: String,
      default: undefined,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
  },

  methods: {
    handleSelect(category) {
      this.$emit("select", category);
    },
  },
};
</script>