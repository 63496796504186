<template>
  <ContentWrapper
    :create-icon="can('CREATE_EQUIPMENT_PARTS') ? 'add-equipment-accessory' : null"
    :header-tag="headerTag"
    search-input-id="admin-wheelchair-accessory-search-phrase"
    :show-back="showBack"
    title="Wheelchair Accessories"
    @create="handleCreate"
    @search="handleSearch"
  >
    <WheelchairAccessoriesTable
      class="admin-table"
      :accessories="filteredAccessories"
      :selected-id="accessory.id"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="accessory.id ? `Edit - ${accessory.name}` : 'Create Wheelchair Accessory'"
      />

      <span class="admin-form-tab">
        <img alt="Wheelchair Accessories" src="../../../assets/icons/equipment-specification.svg" />
      </span>

      <WheelchairAccessoriesForm
        class="admin-form"
        :disabled="accessory.name === undefined"
        :accessory="accessory"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import WheelchairAccessoriesTable from "../../../components/admin/WheelchairAccessoriesTable";
import WheelchairAccessoriesForm from "../../../components/admin/WheelchairAccessoriesForm";

import { getWheelchairAccessories } from "../../../api/EquipmentAPI";

export default {
  data() {
    return {
      _accessories: [],
      accessory: {},
      search: "",
    };
  },

  props: {
    accessories: {
      type: Array,
      default: undefined,
    },
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    filteredAccessories() {
      const regex = this.getFilterRegex(this.search);

      return this.$data._accessories.filter((a) =>
        regex.test(a?.name ?? "")
      );
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.accessory = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        const ranks = this.$data._accessories.reduce(
          (map, spec) => ({
            ...map,
            ...spec.assignments.reduce(
              (map, assign) => ({
                ...map,
                [assign.subcategory?.id]: Math.max(
                  map[assign.subcategory?.id] ?? 0,
                  assign.rank
                ),
              }),
              {}
            ),
          }),
          {}
        );

        const tpl = this.$data._accessories[0];

        this.accessory = {
          ...tpl,
          assignments: [...tpl.assignments].map((a) => ({
            ...a,
            rank: ranks[a.subcategory?.id] + 1,
          })),
          id: undefined,
          name: "",
          name_fr: null,
          options: [],
          protected: false,
        };
      }
    },
    handleDelete(accessory) {
      this.$data._accessories = this.$data._accessories.filter(
        (a) => a?.id !== accessory?.id
      );

      this.accessory = {};
    },
    handleSave(accessory) {
      const created = !this.accessory.id;

      this.$data._accessories = [
        ...this.$data._accessories.filter((a) => a?.id !== accessory?.id),
        accessory,
      ];

      this.handleSelect(created ? accessory : undefined, true);

      this.showAlert(
        `Your equipment accessory has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(accessory, force = false) {
      if (force || this.confirmIfDirty()) {
        this.accessory = {
          ...this.$data._accessories.find((a) => a?.id === accessory?.id),
        };
      }
    },
  },

  watch: {
    async accessories(to, from) {
      to === this.$data._accessories ||
        (this.$data._accessories =
          to?.slice?.() ?? (await getWheelchairAccessories()));
    },
    "accessory.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    "$data._accessories"(to, from) {
      if (to !== from) {
        this.$data._accessories = to.sort(this.sortBy("id", "name"));
        this.$emit("update:accessories", this.$data._accessories);
      }
    },
  },

  async created() {
    this.$data._accessories =
      this.accessories?.slice?.() ?? (await getWheelchairAccessories());

    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    WheelchairAccessoriesTable,
    WheelchairAccessoriesForm,
  },
};
</script>