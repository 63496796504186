import { del, get, post, put } from './http';

const API_PATH = '/api/programs';

export const createProgram = async program =>
    await post(API_PATH, program);

export const deleteProgram = async program =>
    await del(`${API_PATH}/${program.id}`);

export const getPrograms = async () =>
    await get(API_PATH);

export const saveProgram = async program =>
    await (program.id ? updateProgram(program) : createProgram(program));

export const updateProgram = async program =>
    await put(`${API_PATH}/${program.id}`, program);