import { del, get, post, put } from './http';
import { formatTimestamp } from '../func';

const API_PATH = '/api/templates';

export const deleteTemplate = async template =>
    await del(`${API_PATH}/${template.id}`);

export const downloadTemplate = async id => {
    const file = await get(`${API_PATH}/${id}/download`);
    const anchor = document.createElement("a");
    anchor.href = window.URL.createObjectURL(await file.blob());
    anchor.download = file.headers.get("content-disposition")?.match(/filename=(["'])(.*?)\1/)?.[2] ||
        `invoice-export-${formatTimestamp(Date.now(), { format: "yyyyMMddHHmmss" })}.docx`;
    anchor.click();
    window.URL.revokeObjectURL(anchor.href);
};

export const getTemplates = async () =>
    await get(API_PATH);

export const getTemplate = async id =>
    await get(`${API_PATH}/${id}`);

export const updateTemplate = async (template, file) =>
    await put(`${API_PATH}/${template.id}`, formatDataFormation(template, file));

export const createTemplate = async (template, file) =>
    await post(API_PATH, formatDataFormation(template, file))

export const saveTemplate = async (template, file) =>
    await (template.id ? updateTemplate(template, file) : createTemplate(template, file));

function formatDataFormation(template, file) {
    const data = new FormData();
    file && data.append('document', file, file.name);
    data.set('template', new Blob([JSON.stringify(template)], {
        type: 'application/json'
    }));

    return data;
}



/** Template Types **/
const TYPE_API_PATH = '/api/templates/types';

export const getTemplateTypes = async () =>
    await get(`${TYPE_API_PATH}`);
