<template>
  <section id="admin-loans-services-view">
    <content-header :prev="{name: 'Admin'}" :sticky="true" title="Loans" />

    <nav class="admin-loans-services-nav">
      <router-link
        :to="{name: 'Admin: Loan Types'}"
        v-slot="{ href, route, navigate }"
        v-if="hasAdmin('LOAN_TYPES')"
      >
        <a
          :class="['admin-loans-services-nav-link', isExactActive(route) && 'router-link-exact-active', isActive(route) && 'router-link-active']"
          :href="href"
          @click="navigate"
        >
          <img alt="Loans" src="../../assets/icons/loan.svg" />
        </a>
      </router-link>

      <router-link
        :to="{name: 'Admin: Service Categories'}"
        v-slot="{ href, route, navigate }"
        v-if="hasAdmin('SERVICE_CATEGORIES') || hasAdmin('SERVICE_SUBCATEGORIES')"
      >
        <a
          :class="['admin-loans-services-nav-link', isExactActive(route) && 'router-link-exact-active', isActive(route) && 'router-link-active']"
          :href="href"
          @click="navigate"
        >
          <img alt="Services" src="../../assets/icons/equipment-category.svg" />
        </a>
      </router-link>
    </nav>

    <router-view />
  </section>
</template>

<script>
import PartialView from "./PartialView";

export default {
  data() {
    return {
      view: "",
    };
  },

  methods: {
    isActive(route) {
      return (
        route.name === this.$route.name &&
        route.matched[route.matched.length - 1].regex.test(this.$route.path)
      );
    },
    isExactActive(route) {
      return (
        this.isActive(route) && route.params.view === this.$route.params.view
      );
    },
  },

  created() {
    this.view = this.$route.params.view ?? "";
  },

  beforeRouteUpdate(to, from, next) {
    this.abortRequests();

    next((vm) => {
      vm.view = to.params.view ?? "";
    });
  },

  beforeRouteLeave(to, from, next) {
    this.abortRequests();
    next();
  },

  components: {
    PartialView,
  },
};
</script>

<style>
#admin-loans-services-view {
  display: flex;
  flex-wrap: wrap;
}

#admin-loans-services-view .admin-loans-services-nav {
  align-self: flex-start;
  grid-area: nav;
  position: sticky;
  top: 3.375rem;
  transform: translateX(-1rem);
  width: auto;
}

#admin-loans-services-view .admin-loans-services-nav-link {
  align-items: center;
  background-image: radial-gradient(
    circle at 0 3em,
    var(--primary-medium-gray) 3em,
    transparent 3.1em
  );
  background-repeat: no-repeat;
  display: flex;
  grid-area: tab;
  height: 6em;
  justify-content: center;
  justify-self: flex-start;
  padding-right: 12%;
  width: 3.1em;
}

#admin-loans-services-view .admin-loans-services-nav-link img {
  filter: var(--filter-primary-white);
  max-height: 3em;
  max-width: 2em;
}

#admin-loans-services-view .admin-loans-services-nav-link:hover {
  background-image: radial-gradient(
    circle at 0 3em,
    var(--primary-dark-gray) 3em,
    transparent 3.1em
  );
}

#admin-loans-services-view .admin-loans-services-nav-link.router-link-active {
  background-image: radial-gradient(
    circle at 0 3em,
    var(--primary-black) 3em,
    transparent 3.1em
  );
}

#admin-loans-services-view .admin-table {
  grid-area: table;
}

#admin-loans-services-view .admin-form-wrapper {
  grid-area: form;
}

#admin-loans-services-view
  .admin-content-partial:not(#admin-loans-services-items)
  .admin-form-tab
  img {
  max-width: 2em;
}
</style>