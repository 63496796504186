import { del, get, post, put } from './http';

const API_PATH = '/api/vendors';

export const createVendor = async vendor =>
    await post(API_PATH, vendor);

export const deleteVendor = async vendor =>
    await del(`${API_PATH}/${vendor.id}`);

export const getVendors = async () =>
    await get(API_PATH);

export const saveVendor = async vendor =>
    await (vendor.id ? updateVendor(vendor) : createVendor(vendor));

export const updateVendor = async vendor =>
    await put(`${API_PATH}/${vendor.id}`, vendor);