<template>
  <ContentWrapper
    :create-icon="can('CREATE_PROGRAMS') ? 'add-program' : null"
    :header-tag="headerTag"
    search-input-id="admin-program-search-phrase"
    :show-back="showBack"
    title="Programs"
    @create="handleCreate"
    @search="handleSearch"
  >
    <ProgramsTable
      class="admin-table"
      :programs="filteredPrograms"
      :selected-id="program.id"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="program.id ? `Edit - ${program.acronym}` : 'Create Program'"
      />

      <span class="admin-form-tab">
        <img alt="Programs" src="../../../assets/icons/program.svg" />
      </span>

      <ProgramsForm
        class="admin-form"
        :disabled="program.acronym === undefined"
        :program="program"
        :users="$data._users"
        :equipmentManagers="$data._equipmentManagers"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import ProgramsTable from "../../../components/admin/ProgramsTable";
import ProgramsForm from "../../../components/admin/ProgramsForm";

import { getPrograms } from "../../../api/ProgramAPI";
import { getUsers } from "../../../api/UserAPI";
import { getAllEquipmentManagers } from "../../../api/EquipmentManagerAPI";

export default {
  data() {
    return {
      program: {},
      _programs: [],
      search: "",
      _users: [],
      _equipmentManagers: [],
    };
  },

  props: {
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    programs: {
      type: Array,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    filteredPrograms() {
      const regex = this.getFilterRegex(this.search);

      return this.$data._programs.filter((p) =>
        regex.test(
          [p?.acronym ?? "", p?.name ?? "", p.vendorNumber ?? ""].join(String.fromCharCode(0x1f))
        )
      );
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.program = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.program = { acronym: "" };
      }
    },
    handleDelete(program) {
      this.$data._programs = this.$data._programs.filter(
        (p) => p?.id !== program?.id
      );

      this.program = {};
    },
    async handleSave(program) {
      const created = !this.program.id;

      this.$data._programs = [
        ...this.$data._programs.filter((p) => p?.id !== program?.id),
        program,
      ];

      this.$data._equipmentManagers = await getAllEquipmentManagers();

      this.handleSelect(created ? program : undefined, true);

      this.showAlert(
        `Your program has been ${created ? "created" : "updated"} successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(program, force = false) {
      if (force || this.confirmIfDirty()) {
        this.program = {
          ...this.$data._programs.find((p) => p?.id === program?.id),
        };
      }
    },
  },

  watch: {
    "$data._programs"(to, from) {
      if (to !== from) {
        this.$data._programs = to.sort(this.sortBy("name", "id"));
        this.$emit("update:programs", this.$data._programs);
      }
    },
    "program.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    async programs(to, from) {
      to === this.$data._programs ||
        (this.$data._programs = to?.slice?.() ?? (await getPrograms()));
    },
    users: {
      immediate: true,
      async handler(to, from) {
        this.$data._users = to?.slice?.() ?? (await getUsers()).filter(user => !user.isReferralAgent);
      }
    },
    equipmentManagers : {
      immediate: true,
      async handler(to, from) {
        this.$data._equipmentManagers = to?.slice?.() ?? (await getAllEquipmentManagers());
      }
    },
  },

  async created() {
    this.$data._programs = this.programs?.slice?.() ?? (await getPrograms());
    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    ProgramsTable,
    ProgramsForm,
  },
};
</script>