<template>
  <ContentWrapper
    :create-icon="can('CREATE_VENDORS') ? 'add-vendor' : null"
    :header-tag="headerTag"
    search-input-id="admin-vendor-search-phrase"
    :show-back="showBack"
    title="Suppliers"
    @create="handleCreate"
    @search="handleSearch"
  >
    <VendorsTable
      class="admin-table"
      :selected-id="vendor.id"
      :vendors="filteredVendors"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="vendor.id ? `Edit - ${vendor.name}` : 'Create Supplier'"
      />

      <span class="admin-form-tab">
        <img alt="Suppliers" src="../../../assets/icons/manufacturer.svg" />
      </span>

      <VendorsForm
        class="admin-form"
        :disabled="vendor.name === undefined"
        :manufacturers="manufacturers"
        :vendor="vendor"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import VendorsTable from "../../../components/admin/VendorsTable";
import VendorsForm from "../../../components/admin/VendorsForm";

import { getManufacturers } from "../../../api/ManufacturerAPI";
import { getVendors } from "../../../api/VendorAPI";

export default {
  data() {
    return {
      _manufacturers: [],
      search: "",
      vendor: {},
      _vendors: [],
    };
  },

  props: {
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    manufacturers: {
      item: Array,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    vendors: {
      type: Array,
      default: undefined,
    },
  },

  computed: {
    filteredVendors() {
      const regex = this.getFilterRegex(this.search);
      return this.$data._vendors.filter((v) => regex.test(v?.name ?? ""));
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.vendor = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.vendor = { name: "" };
      }
    },
    handleDelete(vendor) {
      this.$data._vendors = this.$data._vendors.filter(
        (v) => v?.id !== vendor?.id
      );

      this.vendor = {};
    },
    handleSave(vendor) {
      const created = !this.vendor.id;

      this.$data._vendors = [
        ...this.$data._vendors.filter((v) => v?.id !== vendor?.id),
        vendor,
      ];

      this.handleSelect(created ? vendor : undefined, true);

      this.showAlert(
        `Your vendor has been ${created ? "created" : "updated"} successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(vendor, force = false) {
      if (force || this.confirmIfDirty()) {
        this.vendor = {
          ...this.$data._vendors.find((v) => v?.id === vendor?.id),
        };
      }
    },
  },

  watch: {
    "$data._manufacturers"(to, from) {
      if (to !== from) {
        this.$data._manufacturers = to.sort(this.sortBy("name", "id"));

        const map = to.reduce(
          (map, manufacturer) => ({ ...map, [manufacturer.id]: manufacturer }),
          {}
        );

        this.$data._vendors.forEach(
          (vendor) => (vendor.manufacturer = map[vendor.manufacturer?.id] ?? {})
        );
      }
    },
    "$data._vendors"(to, from) {
      if (to !== from) {
        this.$data._vendors = to.sort(this.sortBy("name", "id"));
        this.$emit("update:vendors", this.$data._vendors);
      }
    },
    manufacturers: {
      immediate: true,
      async handler(to, from) {
        this.$data._manufacturers = to?.slice?.() ?? (await getManufacturers());
      },
    },
    "vendor.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    async vendors(to, from) {
      to === this.$data._vendors ||
        (this.$data._vendors = to?.slice?.() ?? (await getVendors()));
    },
  },

  async created() {
    this.$data._vendors = this.vendors?.slice?.() ?? (await getVendors());
    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    VendorsTable,
    VendorsForm,
  },
};
</script>