<template>
  <section class="loan-info">
    <label>
      <router-link
        class="equipment-link"
        target="_blank"
        :to="{ name: 'Equipment', params: { id: loan.equipment.id } }"
        v-if="
          showEquipment &&
          can({
            permissions: ['READ_EQUIPMENT', 'READ_AVAILABLE_EQUIPMENT', 'READ_PROGRAM_EQUIPMENT', 'READ_AVAILABLE_PROGRAM_EQUIPMENT'],
            matchAny: true,
          })
        "
      >
        <span
          >Equipment Number
          <small :title="`View Equipment ${equipmentNumber}`"
            ><icon name="launch" /></small
        ></span>
        <strong>{{ equipmentNumber }}</strong>
      </router-link>
      <template v-else>
        <span>Equipment Number</span>
        <strong>{{ equipmentNumber }}</strong>
      </template>
    </label>

    <label v-if="showEquipment">
      <span>Item</span>
      <strong>{{ ((loan.equipment || {}).item || {}).name }}</strong>
    </label>

    <label v-if="showEquipment">
      <span>Equipment Status</span>
      <strong>{{ getEquipmentStatus(loan.equipment) }}</strong>
    </label>

    <label v-if="showEquipment">
      <span>Equipment Purchase Date</span>
      <strong>{{ formatTimestamp(loan.equipment.purchaseDate) }}</strong>
    </label>

    <label v-if="showClient">
      <span>Client Number</span>
      <strong>{{ (loan.client || {}).clientNumber }}</strong>
    </label>

    <label v-if="showClient">
      <span>Client Name</span>
      <strong>{{ getFullName(loan.client) }}</strong>
    </label>

    <label>
      <span>Loan Date</span>
      <strong>{{ formatTimestamp(loan.loanDate) }}</strong>
    </label>

    <label>
      <span>Return Date</span>
      <strong>{{ formatTimestamp(loan.returnDate) || "-- -- ----" }}</strong>
    </label>

    <label>
      <span>Loan Program</span>
      <strong>{{ (loan.program || {}).name }}</strong>
    </label>

    <label>
      <span>Loan Type</span>
      <strong>{{ (loan.type || {}).name }}</strong>
    </label>

    <label>
      <span>Referral Agent</span>
      <strong>{{ getFullName(loan.referralAgent) || "Unknown" }}</strong>
    </label>

    <label v-if="displayConfirmationReceived">
      <span>Confirmation Received</span>
      <strong>{{ loan.loanConfirmationReceived ? "Yes" : "No"}}</strong>
    </label>
  </section>
</template>

<script>
export default {
  props: {
    loan: {
      type: Object,
    },
    showClient: {
      type: Boolean,
      default: true,
    },
    showEquipment: {
      type: Boolean,
      default: true,
    },
    displayConfirmationReceived: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    equipmentNumber() {
      return this.getEquipmentNumber(this.loan?.equipment);
    },
  },
};
</script>

<style scoped>
.loan-info {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.loan-info label {
  color: var(--primary-black);
  font-style: normal;
}

.loan-info .equipment-link {
  all: unset;
  cursor: pointer;
  display: contents;
}

.loan-info .equipment-link small {
  vertical-align: middle;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .loan-info {
    grid-template-columns: 1fr 1fr;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .loan-info {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>