import { get, put } from './http';

const API_PATH = '/api/equipment/managers';

export const updateByClientId = async (clientId, programs) => 
    await put(`${API_PATH}/client/${clientId}`, programs);

export const updateByProgramId = async (programId, clients) => 
    await put(`${API_PATH}/program/${programId}`, clients);

export const getAllEquipmentManagers = async () => 
    await get(`${API_PATH}`);