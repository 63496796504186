<template>
  <ContentWrapper
    :create-icon="can('CREATE_SERVICE_CATEGORIES') ? 'add-equipment-category' : null"
    :header-tag="headerTag"
    search-input-id="admin-service-category-search-phrase"
    :show-back="showBack"
    title="Service Categories"
    @create="handleCreate"
    @search="handleSearch"
  >
    <ServiceCategoriesTable
      class="admin-table"
      :categories="filteredServiceCategories"
      ref="table"
      :selected-id="category.id"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="category.id ? `Edit - ${category.name}` : 'Create Service Category'"
      />

      <span class="admin-form-tab">
        <img alt="Service Categories" src="../../../assets/icons/equipment-category.svg" />
      </span>

      <ServiceCategoriesForm
        class="admin-form"
        :disabled="category.name === undefined"
        :category="category"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import ServiceCategoriesTable from "../../../components/admin/ServiceCategoriesTable";
import ServiceCategoriesForm from "../../../components/admin/ServiceCategoriesForm";

import { getServiceCategories } from "../../../api/ServiceAPI";

export default {
  data() {
    return {
      category: {},
      _categories: [],
      search: "",
    };
  },

  props: {
    categories: {
      type: Array,
      default: undefined,
    },
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    filteredServiceCategories() {
      const regex = this.getFilterRegex(this.search);
      return this.$data._categories.filter((c) => regex.test(c?.name ?? ""));
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.category = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.category = {
          name: "",
        };
      }
    },
    handleDelete(category) {
      this.$data._categories = this.$data._categories.filter(
        (c) => c?.id !== category?.id
      );

      this.category = {};
    },
    handleSave(category) {
      const created = !this.category.id;

      this.$data._categories = [
        ...this.$data._categories.filter((c) => c?.id !== category?.id),
        category,
      ];

      this.handleSelect(created ? category : undefined, true);

      this.showAlert(
        `Your service category has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
      this.$refs.table.goToFirstPage();
    },
    handleSelect(category, force = false) {
      if (force || this.confirmIfDirty()) {
        this.category = {
          ...this.$data._categories.find((c) => c?.id === category?.id),
        };
      }
    },
  },

  watch: {
    async categories(to, from) {
      to === this.$data._categories ||
        (this.$data._categories =
          to?.slice?.() ?? (await getServiceCategories()));
    },
    "category.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    "$data._categories"(to, from) {
      if (to !== from) {
        this.$data._categories = to.sort(this.sortBy("name", "id"));
        this.$emit("update:categories", this.$data._categories);
      }
    },
  },

  async created() {
    this.$data._categories =
      this.categories?.slice?.() ?? (await getServiceCategories());

    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    ServiceCategoriesTable,
    ServiceCategoriesForm,
  },
};
</script>