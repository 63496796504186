import { del, get, post, put } from './http';

const API_PATH = '/api/invoices';

export const createInvoice = async invoice =>
    await post(API_PATH, invoice);

export const deleteInvoice = async invoice =>
    await del(`${API_PATH}/${invoice.id}`);

export const generateDocuments = async ({ invoiceIds = [], templateIds = [] }) => {
    const file = await post(`${API_PATH}/generate-documents`, { invoiceIds, templateIds });
    const anchor = document.createElement('a');
    anchor.download = file.headers.get('content-disposition')?.match(/filename=(["'])(.*?)\1/)?.[2];
    anchor.href = window.URL.createObjectURL(await file.blob());
    anchor.click();
    window.URL.revokeObjectURL(anchor.href);
};

export const getInvoices = async () =>
    await get(API_PATH);

export const getInvoiceSummaries = async () =>
    await get(`${API_PATH}/summaries`);

export const updateInvoice = async invoice =>
    await put(`${API_PATH}/${invoice.id}`, invoice);

export const updateGeneratedInvoices = async (invoiceIds = []) => 
    await put(`${API_PATH}/update-generated`, invoiceIds)




/** Invoice Export **/
const EXPORT_API_PATH = '/api/invoices/export';

export const postCsvInvoiceExport = async invoiceArray =>
    await post(`${EXPORT_API_PATH}/csv`, invoiceArray);

export const postSage50InvoiceExport = async invoiceArray =>
    await post(`${EXPORT_API_PATH}/sage50`, invoiceArray);



/** Invoice Items **/
const ITEMS_API_PATH = '/api/invoices/items';

export const createInvoiceItem = async item =>
    await post(ITEMS_API_PATH, item);

export const getInvoiceItems = async invoiceId =>
    await get(invoiceId ? `${API_PATH}/${invoiceId}/items` : ITEMS_API_PATH);

export const deleteInvoiceItem = async id =>
    await del(`${ITEMS_API_PATH}/${id}`);

export const updateInvoiceItem = async item =>
    await put(`${ITEMS_API_PATH}/${item.id}`, item);
