<template>
  <section class="loan-history">
    <search-form
      class="loan-history-search"
      :debounce="200"
      v-model="filter"
      @change="goToFirstPage"
    >
      <icon-button
        name="add-loan"
        @click="handleCreate"
        v-if="mode === 'full'"
      />
    </search-form>

    <section
      class="loan-history-record-wrapper"
      v-if="!$data._loans.length && emptyText"
    >
      <section class="tab-left">
        <img alt="Loan" src="../../assets/icons/loan.svg" />
      </section>

      <section class="loan-history-record">
        <span class="loan-search-no-results">{{ emptyText }}</span>
      </section>
    </section>

    <section
      class="loan-history-record-wrapper"
      v-else-if="$data._loans.length && !filteredLoans.length"
    >
      <section class="tab-left">
        <img alt="Loan" src="../../assets/icons/loan.svg" />
      </section>

      <section class="loan-history-record">
        <section class="loan-history-record-actions">
          <icon-button
            :disabled="true"
            name="edit"
            title="Edit Loan"
            @click.prevent
            v-if="mode !== 'readonly'"
          />

          <icon-button
            :disabled="true"
            name="archive"
            title="Archive Loan"
            @click.prevent
            v-if="mode !== 'readonly'"
          />

          <icon-button
            :disabled="true"
            name="invoice"
            title="Invoice Loan"
            @click.prevent
            v-if="mode === 'full'"
          />
        </section>

        <span class="loan-search-no-results"
          >There are no loans matching your search criteria</span
        >
      </section>
    </section>

    <section
      v-else
      v-for="(loan, index) in paginatedLoans"
      :key="loan.$key"
      class="loan-history-record-wrapper"
    >
      <section :class="['tab-left', editing[loan.$key] && 'tab-left-active']">
        <img alt="Loan" src="../../assets/icons/loan.svg" />
      </section>

      <section class="loan-history-record">
        <section class="loan-history-record-actions">
          <icon-button
            :class="editing[loan.$key] && 'active'"
            :disabled="!can('UPDATE_LOANS')"
            name="edit"
            title="Edit Loan"
            @click="handleEdit(loan)"
            v-if="mode !== 'readonly' && loan.id"
          />

          <icon-button
            :disabled="!can('DELETE_LOANS')"
            name="archive"
            title="Archive Loan"
            @click="handleArchive(loan)"
            v-if="mode !== 'readonly' && loan.id"
          />

          <icon-button
            :disabled="!can('CREATE_INVOICE_ITEMS')"
            name="invoice"
            title="Invoice Loan"
            @click="handleInvoice(loan)"
            v-if="mode === 'full' && loan.id && !loan.invoiced"
          />
        </section>

        <LoanInfo
          class="loan-history-record-info"
          :loan="loan"
          :show-client="showClient"
          :show-equipment="showEquipment"
          :display-confirmation-received="displayConfirmationReceived(loan)"
          v-if="loan.id && !editing[loan.$key]"
        />

        <LoanForm
          class="loan-history-record-info"
          :can-delete="false"
          :clients="$data._clients"
          :equipment="$data._equipment"
          :loan.sync="paginatedLoans[index]"
          :mode="mode"
          :programs="$data._programs"
          :referral-agents="$data._referralAgents"
          :show-client="showClient"
          :show-equipment="showEquipment"
          :statuses="$data._statuses"
          :types="$data._types"
          :display-confirmation-received="displayConfirmationReceived(loan)"
          @cancel="handleCancel"
          @delete="handleDelete"
          @submit="handleSave"
          v-if="!loan.id || editing[loan.$key]"
        />
      </section>
    </section>

    <footer class="page-controls">
      <button
        type="button"
        @click="handleToggleTemplates(true)"
        v-if="$data._loans.length && $data._templates.length"
      >
        Templates
      </button>

      <icon-button
        :disabled="!hasPrevPage"
        name="prev"
        @click="goToPrevPage"
        v-if="hasPages"
      />

      <icon-button
        :disabled="!hasNextPage"
        name="next"
        @click="goToNextPage"
        v-if="hasPages"
      />
    </footer>

    <popup
      :modal="true"
      ref="templateModal"
      @close="handleToggleTemplates(false)"
      v-if="templateModal"
    >
      <LoanTemplateSelector
        :client-id="clientId"
        :loans="$data._loans"
        :templates="$data._templates"
        @cancel="handleToggleTemplates(false)"
      />
    </popup>
  </section>
</template>

<script>
import { pagination } from "../../mixins/pagination";

import LoanInfo from "./LoanInfo";
import LoanForm from "./LoanForm";
import LoanTemplateSelector from "./LoanTemplateSelector";

import { getClientSummaries, getMostRecentLoan } from "../../api/ClientAPI";
import {
  getEquipmentSummaries,
  getEquipmentStatuses
} from "../../api/EquipmentAPI";
import { createInvoiceItem } from "../../api/InvoiceAPI";
import { deleteLoan, getLoanTypes, saveLoan } from "../../api/LoanAPI";
import { getPrograms } from "../../api/ProgramAPI";
import { getTemplates } from "../../api/TemplatesAPI";
import { getReferralAgents } from "../../api/ReferralAgentAPI";

export default {
  components: {
    LoanInfo,
    LoanForm,
    LoanTemplateSelector,
  },

  mixins: [pagination("filteredLoans")],

  data() {
    return {
      _clients: [],
      _equipment: [],
      _loans: [],
      _programs: [],
      _referralAgents: [],
      _templates: [],
      _types: [],
      _statuses: [],
      editing: {},
      filter: "",
      templateModal: false,
    };
  },

  props: {
    clientId: {
      type: Number,
      default: null,
    },
    clients: {
      type: Array,
      default: null,
    },
    equipment: {
      type: Array,
      default: null,
    },
    equipmentId: {
      type: Number,
      default: null,
    },
    emptyText: {
      type: String,
      default: "There are no loans",
    },
    invoiceItems: {
      type: Array,
    },
    loans: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: "full",
      validator: (value) => ["full", "limited", "readonly"].includes(value),
    },
    pageSize: {
      type: Number,
      default: 5,
    },
    programs: {
      type: Array,
      default: null,
    },
    referralAgents: {
      type: Array,
      default: null,
    },
    showClient: {
      type: Boolean,
      default: undefined,
    },
    showEquipment: {
      type: Boolean,
      default: undefined,
    },
    templates: {
      type: Array,
      default: null,
    },
    types: {
      type: Array,
      default: null,
    },
  },

  computed: {
    filteredLoans() {
      if (!/\S/.test(this.filter)) {
        return this.$data._loans;
      }

      const regex = this.getFilterRegex(this.filter);

      return this.$data._loans.filter(
        (loan) =>
          !loan.id ||
          regex.test(
            [
              this.getEquipmentNumber(loan?.equipment),
              loan?.equipment?.item?.name ?? "",
              loan?.client?.clientNumber ?? "",
              this.getFullName(loan?.client),
              loan?.loanDate ?? "",
              this.formatTimestamp(loan?.loanDate) ?? "",
              loan?.returnDate ?? "",
              this.formatTimestamp(loan?.returnDate) ?? "",
              loan?.program?.acronym ?? "",
              loan?.program?.name ?? "",
              loan?.type?.name ?? "",
              this.getUserName(loan?.referralAgent),
            ].join(String.fromCharCode(0x1f))
          )
      );
    },
  },

  methods: {
    deleteLoan(loan) {
      this.$data._loans = this.$data._loans.filter((l) => l.$key !== loan.$key);
      this.$emit("delete", loan);

      loan.id &&
        this.$emit(
          "update:loans",
          this.loans.filter((l) => l?.id !== loan?.id)
        );
    },
    displayConfirmationReceived(loan) {
      console.log(loan.id, )
      return (
        loan?.program?.id === 1 &&
        loan?.type?.id === 2
      )
    },
    async handleArchive(loan) {
      if (!confirm("Are you sure you want to archive this loan?")) {
        return;
      }

      try {
        if (loan?.id) {
          await deleteLoan(loan);

          this.showAlert("The loan has been successfully archived!");
        }

        this.deleteLoan(loan);
      } catch (e) {
        this.showErrors(e, "A loan");
      }
    },
    handleCancel(loan) {
      if (this.isDirty(loan) && !this.confirmDiscard()) {
        return;
      }

      if (loan.id) {
        this.$data._loans.forEach((svc, i) => {
          if (svc.id === loan.id) {
            this.$data._loans.splice(i, 1, {
              ...this.$data._loans[i],
              ...this.loans.find((s) => s.id === svc.id),
            });
          }
        });
      } else {
        this.deleteLoan(loan);
      }

      delete this.editing[loan.$key];
    },
    async handleCreate({ client, equipment } = {}) {
      const recentLoan = await getMostRecentLoan(this.clientId);

      this.$data._loans = [
        {
          $key: Date.now(),
          client:
            client ??
            (this.clientId && {
              ...this.$data._clients.find((c) => c.id === this.clientId),
            }),
          equipment: {
            status: {},
            ...(equipment ??
              (this.equipmentId && {
                ...this.$data._equipment.find((c) => c.id === this.equipmentId),
              })),
          },
          loanDate: new Date().toJSON(),
          program: recentLoan?.program ? recentLoan.program : {},
          referralAgent: recentLoan?.referralAgent ? recentLoan.referralAgent : {},
          type: recentLoan?.type ? recentLoan.type : {},
        },
        ...this.$data._loans,
      ];

      this.goToFirstPage();
    },
    async handleDelete(loan) {
      if (!confirm("Are you sure you want to delete this loan?")) {
        return;
      }

      await deleteLoan(loan);
      this.deleteLoan(loan);
    },
    handleEdit(loan) {
      this.editing = { ...this.editing, [loan.$key]: true };
    },
    async handleInvoice(loan) {
      try {
        this.$emit(
          "invoiced",
          await createInvoiceItem({ loan, program: loan.program })
        );
        this.updateLoan({ ...loan, invoiced: true });
      } catch (e) {
        this.showErrors(e, "An invoice item");
      }
    },
    handleSave(loan) {
      clearTimeout(this.debounce?.save);

      this.debounce = {
        ...this.debounce,
        save: setTimeout(async () => {
          try {
            const res = await saveLoan({
              ...loan,
              client: loan?.client?.id ? loan.client : null,
              equipment: loan?.equipment?.id ? loan.equipment : null,
              program: loan?.program?.id ? loan.program : null,
              referralAgent: loan?.referralAgent?.id
                ? loan.referralAgent
                : null,
              type: loan?.type?.id ? loan.type : null,
            });

            if (res?.equipment?.id) {
              for (let i = 0; i < this.loans.length; i++) {
                if (this.loans[i].equipment?.id !== res.equipment.id) continue;

                this.loans[i].equipment.purchaseDate =
                  res.equipment.purchaseDate;
                this.loans[i].equipment.status = res.equipment.status;
              }
            }

            this.editing = { ...this.editing, [loan.$key]: false };
            this.updateLoan({ ...loan, ...res });
          } catch (e) {
            this.showErrors(e, "A loan");
          }
        }, 400),
      };
    },
    handleToggleTemplates(templateModal) {
      this.templateModal = templateModal ?? !this.templateModal;
      this.templateModal &&
        this.$nextTick(() => this.$refs.templateModal.$el.scrollIntoView());
    },
    isDirty(loan) {
      if (loan) {
        const l = this.loans.find((l) => l.id === loan.id);

        return (
          l?.client?.id !== loan?.client?.id ||
          l?.equipment?.id !== loan?.equipment?.id ||
          l?.equipment?.purchaseDate !== loan?.equipment?.purchaseDate ||
          l?.equipment?.status?.id !== loan?.equipment?.status?.id ||
          l?.loanDate !== loan?.loanDate ||
          l?.program?.id !== loan?.program?.id ||
          l?.referralAgent?.id !== loan?.referralAgent?.id ||
          l?.returnDate !== loan?.returnDate ||
          l?.type?.id !== loan?.type?.id
        );
      }

      return this.$data._loans.reduce(
        (dirty, loan) => dirty || this.isDirty(loan),
        false
      );
    },
    updateLoan(loan) {
      this.$emit(
        "update:loans",
        [...this.loans.filter((l) => l?.id !== loan.id), loan].sort(
          this.sortBy("-loanDate", "id")
        )
      );
    },
  },

  watch: {
    clients: {
      immediate: true,
      async handler(to, from) {
        this.$data._clients = to ?? (await getClientSummaries());
      },
    },
    equipment: {
      immediate: true,
      async handler(to, from) {
        this.$data._equipment = to ?? (await getEquipmentSummaries());
      },
    },
    loans: {
      immediate: true,
      handler(to, from) {
        this.$data._loans = JSON.parse(JSON.stringify(to)).map((loan) => ({
          $key: loan.id,
          ...loan,
        }));
      },
    },
    programs: {
      immediate: true,
      async handler(to, from) {
        this.$data._programs = to ?? (await getPrograms());
      },
    },
    referralAgents: {
      immediate: true,
      async handler(to, from) {
        this.$data._referralAgents = to ?? (await getReferralAgents());
      },
    },
    templates: {
      immediate: true,
      async handler(to, from) {
        this.$data._templates = (to ?? (await getTemplates())).filter(
          (template) => template?.type?.name === "Client Loan Letter"
        );
      },
    },
    types: {
      immediate: true,
      async handler(to, from) {
        this.$data._types = to ?? (await getLoanTypes());
      },
    },
    statuses: {
      immediate: true,
      async handler(to, from) {
        this.$data._statuses = to ?? (await getEquipmentStatuses());
      },
    },
  },
};
</script>

<style scoped>
.loan-history {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.loan-history-search {
  align-items: flex-end;
  display: flex;
  margin-bottom: 1em;
  margin-left: auto;
}

.loan-history-search .icon-button {
  font-size: 0.755rem;
  margin-left: 0.5em;
}

.loan-history-record-wrapper {
  display: flex;
  width: 100%;
}

@media print {
  .tab-left {
    display: none;
  }
}

.loan-history-record-wrapper ~ .loan-history-record-wrapper {
  margin-top: 1em;
}

.tab-left:not(.tab-left-active) {
  --tab-color: var(--primary-black);
}

.loan-history-record {
  border-color: var(--primary-medium-gray);
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  padding: 1em;
}

.loan-history-record-actions {
  flex: 0;
  margin-left: 1em;
}

.loan-history-record-actions button ~ button {
  margin-top: 0.5em;
}

.loan-history-record-info {
  flex: 1;
}

.loan-search-no-results {
  align-self: center;
  flex: 1;
}
</style>