<template>
  <form
    :class="['forgot-password-form', submitted && 'forgot-password-submitted']"
    @submit.prevent="handleSubmit"
  >
    <h3
      v-if="submitted"
    >Your request has been submitted and an email is on its way with instructions on how to reset your password.</h3>

    <fieldset v-if="!submitted">
      <label>
        <span>Email Address</span>
        <email-address-input
          autofocus
          id="forgot-password-username"
          required
          v-model="$data._username"
        />
      </label>
    </fieldset>

    <footer>
      <button :disabled="submitting" type="submit" v-if="!submitted">Submit</button>

      <router-link
        :disabled="submitting"
        tag="button"
        :to="{name: 'Logout'}"
        type="button"
      >{{ submitted ? 'Return to Login Page' : 'Cancel' }}</router-link>
    </footer>
  </form>
</template>

<script>
import { requestReset } from "../../api/SecurityAPI";

export default {
  data() {
    return {
      _username: this.username,
      submitted: false,
      submitting: false,
    };
  },

  props: {
    username: {
      type: String,
      default: "",
    },
  },

  methods: {
    async handleSubmit() {
      try {
        this.submitting = true;

        const res = await requestReset({ username: this.$data._username });
        this.submitted = res.ok;

        res.ok ||
          this.showAlert(
            "An unexpected error has occurred. Please contact the system administrator for assistance."
          );
      } catch (e) {
        this.showError(e);
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>

<style>
.forgot-password-form label {
  color: inherit;
}

.forgot-password-form label input {
  font: inherit;
  font-style: normal;
  margin-top: 0.5em;
}

.forgot-password-form footer button:only-child {
  margin-left: auto;
  margin-right: auto;
}
</style>