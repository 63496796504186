<template>
  <section class="equipment-page">
    <content-header :prev="previousRoute" :title="title">
      <small v-if="isOnHold($data._equipment) && onHoldFor && onHoldFor.clientNumber">
        (On Hold: {{ getFullName(onHoldFor) }} -
        {{ onHoldFor.clientNumber }} -
        {{ formatTimestamp($data._equipment.onHoldDate) }}
        )
      </small>
    </content-header>

    <section v-if="$data._equipment && isAvailable($data._equipment) && $data._equipment.lastReturnDate" class="last-returned">
      {{ getStaticName($store.getters.user, "Last Returned:", "Date de retour:") }} {{ formatTimestamp($data._equipment.lastReturnDate) }}
    </section>

    <template v-if="!$data._equipment">
      <search-form class="equipment-search" :debounce="400" v-model="search" @change="handleSearch" @keydown.enter="handleSearch">
        <fieldset class="equipment-search-status">
          <label>
            {{  getStaticName($store.getters.user, "Status", "Statut") }}
            <select v-model="searchStatus" @change.stop="loadEquipment({ page: 1 })">
              <option></option>
              <option>Available</option>
              <option v-if="can({ permissions: ['READ_EQUIPMENT_STATUSES'], matchAny: true})">Lost</option>
              <option v-if="can({ permissions: ['READ_EQUIPMENT_STATUSES'], matchAny: true})">On Hold</option>
              <option v-if="can({ permissions: ['READ_EQUIPMENT_STATUSES'], matchAny: true})">On Loan</option>
              <option>Reserved</option>
              <option
                v-for="status in statuses"
                :key="status.id"
                :value="status.id">{{ status.name }}</option>
            </select>
          </label>
        </fieldset>
        <fieldset class="equipment-search-program" v-if="can({ permissions: ['READ_PROGRAMS', 'READ_ASSIGNED_PROGRAMS'], matchAny: true })">
          <label>
            {{ getStaticName($store.getters.user, "Program", "Programme") }}
            <select v-model.number="programId" @change.stop="loadEquipment({ page: 1 })">
              <option></option>
              <option
                v-for="program in programs"
                :key="program.id"
                :value="program.id"
              >{{ program.name }}</option>
            </select>
          </label>
        </fieldset>

        <icon-button
          class="equipment-create-button"
          name="add-equipment"
          title="Create Equipment"
          @click="handleCreate"
          v-if="can('CREATE_EQUIPMENT')"
        />
      </search-form>

      <EquipmentTable
        :equipment="equipment.records"
        :page-count="equipment.page.last"
        :page-number="equipment.page.current"
        :page-size="equipment.page.size"
        :selected-id="$data._equipment && $data._equipment.id"
        @page-change="handlePageChange"
        @select="handleSelect"
      />
    </template>

    <EquipmentForm
      :accessories="accessories"
      :categories="categories"
      :clients="clients"
      :equipment="$data._equipment"
      :items="items"
      :manufacturers="manufacturers"
      :parts="parts"
      :programs="programs"
      :statuses="statuses"
      :subcategories="subcategories"
      :types="types"
      :vendors="vendors"
      @reset="handleCancel"
      @submit="handleSave"
      @reserveUpdated="handleReserveUpdate"
      v-else
    />
  </section>
</template>

<script>
import EquipmentTable from "../../../components/equipment/EquipmentTable";
import EquipmentForm from "../../../components/equipment/EquipmentForm";

import { getClientSummaries } from "../../../api/ClientAPI";
import { getManufacturers } from "../../../api/ManufacturerAPI";
import { getPrograms } from "../../../api/ProgramAPI";
import { getVendors } from "../../../api/VendorAPI";

import {
  getEquipment,
  getEquipmentAccessories,
  getEquipmentCategories,
  getEquipmentItems,
  getEquipmentParts,
  getEquipmentStatuses,
  getEquipmentSubcategories,
  getEquipmentTypes,
  getPaginatedEquipment,
} from "../../../api/EquipmentAPI";

export default {
  data() {
    return {
      _equipment: undefined,
      _previousRoute: undefined,
      accessories: undefined,
      categories: undefined,
      clients: undefined,
      equipment: {
        totalRecordCount: 0,
        records: [],
        page: {
          size: 20,
          current: 1,
          first: 1,
          last: 1,
        }
      },
      items: undefined,
      manufacturers: undefined,
      parts: undefined,
      searchStatus: undefined,
      programId: undefined,
      programs: undefined,
      search: "",
      statuses: undefined,
      subcategories: undefined,
      types: undefined,
      vendors: undefined
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined
    }
  },

  computed: {
    filteredEquipment() {
      const regex = this.getFilterRegex(this.search);

      return this.equipment.records.filter(
        equipment =>
          (!this.programId || equipment?.program?.id === this.programId) &&
          regex.test(
            [
              equipment?.equipmentNumber ?? "",
              this.getEquipmentNumber(equipment),
              equipment?.serialNumber ?? "",
              equipment?.modelNumber ?? "",
              this.getName(equipment?.category, this.$store.getters.user) ?? "",
              this.getName(equipment?.subcategory, this.$store.getters.user)?? "",
              this.getName(equipment?.item, this.$store.getters.user) ?? "",
              this.getEquipmentStatus(equipment) ?? ""
            ].join(0x1f)
          )
      );
    },
    onHoldFor() {
      return this.$data._equipment?.onHoldFor;
    },
    previousRoute() {
      return (
        this.$data._previousRoute ??
        (this.$data._equipment ? { name: "Equipment" } : { name: "Home" })
      );
    },
    title() {
      return this.$data._equipment?.id
        ? `${this.getStaticName(this.$store.getters.user, "Edit Equipment", "Modifier l'équipement")} - ${this.getEquipmentNumber(this.$data._equipment)}`
        : this.$data._equipment
        ? "Create Equipment"
        : this.getStaticName(this.$store.getters.user, "Equipment", "Équipement");
    }
  },

  methods: {
    async handleReserveUpdate() {
      await getEquipment(this.$data._equipment.id, {
        invoices: this.can("READ_INVOICES"),
        items: this.can("READ_INVOICE_ITEMS"),
        loans: this.can("READ_LOANS"),
        services: this.can("READ_SERVICES"),
      }).then(equipment => this.$data._equipment = {...equipment});
    },
    handleCancel() {
      this.$router.push({
        ...this.$route,
        params: { ...this.$route.params, id: undefined }
      });
    },
    handleCreate() {
      this.$router.push({
        ...this.$route,
        params: { ...this.$route.params, id: "new" }
      });
    },
    async handleDelete(equipment) {
      await this.loadEquipment();
      this.$data._equipment?.id === equipment?.id &&
        (this.$data._equipment = undefined);
    },
    async handlePageChange(pageNumber) {
      await this.loadEquipment({ page: pageNumber });
    },
    async handleSave(equipment) {
      await this.loadEquipment();

      const created = !/^\d+$/.test(this.$data._equipment?.id);
      this.$data._equipment = { ...equipment };

      if (created) {
        this.handleSelect(equipment);
        this.showAlert(`Your equipment has been created successfully!`);
      } else {
        this.showAlert(`Your equipment has been updated successfully!`);
      }
    },
    async handleSearch(value) {
      const oldValue = this.search;

      if (value instanceof Event)
        this.search = event.target.value;
      else if (typeof value === 'string' || value == null)
        this.search = value;

      if (oldValue !== this.search)
        await this.loadEquipment({ page: 1 });
    },
    handleSelect(equipment) {
      this.$nextTick(() => {
        this.$router.push({
          ...this.$route,
          params: { ...this.$route.params, id: equipment?.id }
        });
      });
    },
    async loadData() {
      [
        this.accessories,
        this.categories,
        this.clients,
        this.items,
        this.manufacturers,
        this.parts,
        this.programs,
        this.statuses,
        this.subcategories,
        this.types,
        this.vendors
      ] = await Promise.all([
        this.accessories ??
          (this.can("READ_EQUIPMENT_ACCESSORIES")
            ? getEquipmentAccessories()
            : []),
        this.categories ??
          (this.can("READ_EQUIPMENT_CATEGORIES")
            ? getEquipmentCategories()
            : []),
        this.clients ?? (this.can("READ_CLIENTS") ? getClientSummaries() : []),
        this.items ??
          (this.can("READ_EQUIPMENT_ITEMS") ? getEquipmentItems() : []),
        this.manufacturers ??
          (this.can("READ_MANUFACTURERS") ? getManufacturers() : []),
        this.parts ??
          (this.can("READ_EQUIPMENT_PARTS") ? getEquipmentParts() : []),
        this.programs ?? (this.can({
            permissions: ["READ_PROGRAMS", "READ_ASSIGNED_PROGRAMS"],
            matchAny: true,
          }) ? getPrograms() : []),
        this.statuses ??
          (this.can("READ_EQUIPMENT_STATUSES") ? getEquipmentStatuses() : []),
        this.subcategories ??
          (this.can("READ_EQUIPMENT_SUBCATEGORIES")
            ? getEquipmentSubcategories()
            : []),
        this.types ??
          (this.can("READ_EQUIPMENT_TYPES") ? getEquipmentTypes() : []),
        this.vendors ?? (this.can("READ_VENDORS") ? getVendors() : [])
      ]);
    },
    async loadEquipment({ size, page, phrase, programId, searchStatus } = {}) {
      this.equipment = await getPaginatedEquipment({
        size: size ?? this.equipment.page.size,
        page: page ?? this.equipment.page.current,
        phrase: phrase ?? this.search,
        programId: programId ?? this.programId,
        searchStatus: searchStatus ?? this.searchStatus
      });
    },
    async loadPrograms() {
      this.programs =
        this.programs ?? (this.can({
            permissions: ["READ_PROGRAMS", "READ_ASSIGNED_PROGRAMS"],
            matchAny: true,
          }) ? await getPrograms() : []);
    },
    async loadStatuses() {
      this.statuses = await getEquipmentStatuses();
    }
  },

  watch: {
    async id(to, from) {
      if (to === from) {
        return;
      }

      if (!to) {
        this.$data._equipment = undefined;

        await Promise.all([
          this.loadEquipment(),
          this.loadPrograms(),
          this.loadStatuses(),
        ]);
      } else {
        if (to !== "new") {
          try {
            const getExtraData =
              this.can("READ_INVOICES") &&
              this.can("READ_INVOICE_ITEMS") &&
              this.can("READ_LOANS") &&
              this.can("READ_SERVICES");

            this.$data._equipment = {
              id: to,
              ...this.equipment?.records?.find(e => e.id === to),
            };

            [this.$data._equipment] = await Promise.all([
              getEquipment(to, {
                invoices: this.can("READ_INVOICES"),
                items: this.can("READ_INVOICE_ITEMS"),
                loans: this.can("READ_LOANS"),
                services: this.can("READ_SERVICES"),
              }),
              this.loadData(),
            ]);
          } catch (e) {
            this.handleSelect();
          }
        } else {
          this.$data._equipment = {};
          this.loadData();
        }
      }
    }
  },

  async created() {
    if (this.id) {
      try {
        [this.$data._equipment] = await Promise.all([
          this.id === "new" ? {} : await getEquipment(this.id),
          this.loadData(),
        ]);
      } catch (e) {
        this.handleSelect();
      }
    }

    await Promise.all([
      this.loadEquipment(),
      this.loadPrograms(),
      this.loadStatuses(),
    ]);
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$data._previousRoute =
        vm.id && from.name === "Home" ? from : undefined;
    });
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    EquipmentTable,
    EquipmentForm
  }
};
</script>

<style>
.equipment-page {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "header search"
    "table table"
    "form form";
}

.equipment-page > .equipment-list {
  display: contents;
}

.equipment-page .content-header {
  align-items: center;
  grid-area: header;
}

.equipment-page > .last-returned {
  align-self: center;
  grid-area: search;
}

.table {
  grid-area: table;
}

.equipment-page .equipment-search {
  align-items: flex-end;
  display: flex;
  font-size: 0.8rem;
  font-weight: normal;
  grid-area: search;
  margin: 0 0 0.585rem auto;
  padding: 0;
}

.equipment-page .equipment-search label {
  color: var(--primary-red);
  display: flex;
  flex-direction: column;
  font-style: italic;
  margin-right: 0.33em;
}

.equipment-page .equipment-search .equipment-search-program label,
.equipment-page .equipment-search .equipment-search-status label  {
  order: -1;
}

.equipment-page .equipment-create-button {
  align-self: flex-end;
  font-size: 0.755rem;
  grid-area: add;
}

.equipment-page .equipment-table {
  grid-area: table;
}

.equipment-page .equipment-form {
  grid-area: form;
}
</style>
