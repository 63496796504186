<template>
  <button class="icon-button" :type="type" v-on="$listeners">
    <icon :name="name" :tag="tag" />
  </button>
</template>

<script>
export default {
  props: {
    name: {
      type: String
    },
    tag: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: "button"
    }
  }
};
</script>

<style>
button.icon-button {
  padding: 0.1em;
}

button.icon-button .icon-add {
  transform: translateX(0.05em);
}

button.icon-button .icon-remove {
  transform: translate(0.04em, 0.05em);
}

@media print {
  button.icon-button {
    display: none;
  }
}
</style>