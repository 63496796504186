<template>
<section class="admin-equipment-manager-tool" :disabled="disabled" v-if="selectedId">
    <span>Reservation Email Notification</span>
    <div class="admin-equipment-manager-dropdown">
        <section>
            <input type="checkbox" class="admin-equipment-manager-checkbox-all" @click="handleSelectAllClick" v-model="selectAll" />
            <label class="admin-equipment-manager-label">Select All</label>
        </section>
        <section v-for="item in selectedCollection">
            <input class="admin-equipment-manager-checkbox" @click="handleCollectionItemClick" type="checkbox" :key="item.id" :value="item.id" :checked="item.selected" />
            <label class="admin-equipment-manager-label">{{item.name ? item.name : `${item.firstName} ${item.lastName}` }}</label>
        </section>
    </div>
</section>
</template>

<script>
export default {
    data() {
        return {
            selectedCollection: [],
            selectAll: false,
        };
    },
    props: {
        collection: {
            type: Array
        },
        equipmentManagers: {
            type: Array
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        mappingPrograms: {
            type: Boolean,
            default: false,
        },
        selectedId: {
            type: Number
        }
    },
    watch: {
        selectedId(to, from) {
            if( to !== from ) {
                this.selectAll = false;
                this.$data.selectedCollection = this.buildSelected();
                this.createMappedManagersList();
            }
        }
    },
    methods: {
        handleCollectionItemClick(e) {
            this.$data.selectedCollection.find(r => r.id == e.target.value)["selected"] = !this.$data.selectedCollection.find(r => r.id == e.target.value)["selected"];
            this.createMappedManagersList();
        },
        handleSelectAllClick(e) {
            if (e.target.checked) {
                this.$data.selectedCollection = this.buildSelected(true, true);
            } else {
                this.$data.selectedCollection = this.buildSelected(true, false);
            }
            this.createMappedManagersList();
        },
        buildSelected(selectAll = false, selectionStatus = false) {
                return this.collection.map(item => {
                return {
                    ...item,
                    selected: selectAll ? selectionStatus : this.checkIfSelected(item),
                }
            })
        },
        checkIfSelected(item) {
            if(this.mappingPrograms) {
                return this.equipmentManagers.filter(i => i["programId"] === item.id && i["userId"] === this.selectedId).length > 0;
            } else {
                return this.equipmentManagers.filter(i => i["userId"] === item.id && i["programId"] === this.selectedId).length > 0;
            }
        },
        createMappedManagersList() {
            this.$emit("handleManagerUpdate", this.selectedCollection.filter(item => item.selected).map(filtered_item => filtered_item.id));
        },
    }
}
</script>

<style>
.admin-equipment-manager-input {
    width: 100%;
}

.admin-equipment-manager-dropdown {
    outline: 1px solid black;
    padding: 10px;
    max-height: 200px;
    overflow: scroll;
}

.admin-equipment-manager-label {
    display: inline;
}

.admin-equipment-manager-checkbox-all {
    padding-bottom: 1em;
}
</style>
