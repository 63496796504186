<template>
  <form
    autocomplete="off"
    class="indirect-services-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span>Created By</span>

        <input
          disabled
          type="text"
          :value="getUserName(indirectService.creator)"
        />
      </label>

      <label>
        <span>Created At</span>

        <input
          disabled
          type="text"
          :value="
            formatTimestamp(indirectService.createdAt, { format: FORMAT_DEFAULT_DATE_TIME })
          "
        />
      </label>

      <IndirectServicesNotes
        :disabled="!indirectService.notes"
        :notes.sync="notes"
      />
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="indirect-service-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && indirectService.id"
      >
        Delete
      </button>

      <button
        :disabled="!isSaveEnabled"
        id="indirect-service-save"
        type="submit"
        v-if="canSave"
      >
        Save
      </button>

      <button
        :disabled="!isCancelEnabled"
        id="indirect-service-cancel"
        type="reset"
        v-if="canCancel"
      >
        Cancel
      </button>
    </footer>
  </form>
</template>

<script>
import IndirectServicesNotes from "./IndirectServicesNotes";

import {
  deleteIndirectService,
  saveIndirectService,
} from "../../api/IndirectServiceAPI";

export default {
  data() {
    return {
      notes: [],
    };
  },

  props: {
    canCancel: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default() {
        return this.$store.getters.user?.permissions?.includes(
          "DELETE_INDIRECT_SERVICES"
        );
      },
    },
    canSave: {
      type: Boolean,
      default() {
        return !!this.$store.getters.user?.permissions?.find(
          (p) =>
            p === "CREATE_INDIRECT_SERVICES" || p === "UPDATE_INDIRECT_SERVICES"
        );
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    indirectService: {
      type: Object,
    },
  },

  computed: {
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.indirectService?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.indirectService?.id
          ? this.can("UPDATE_INDIRECT_SERVICES")
          : this.can("CREATE_INDIRECT_SERVICES"))
      );
    },
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (!confirm("Are you sure you want to delete this indirect service?")) {
        return;
      }

      await deleteIndirectService(this.indirectService);
      this.$emit("delete", this.indirectService);
    },
    async handleSave() {
      try {
        if (this.notes.length > 0) {
          this.$emit(
            "save",
            await saveIndirectService({
              ...this.indirectService,
              notes: this.notes
                ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
            })
          );
        } else {
          alert("At least one note is required to create an indirect service.");
        }
      } catch (e) {
        this.showErrors(e, "An indirect service");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return (
        this.notes.length !== this.indirectService?.notes?.length ||
        !!this.notes.find((note) => {
          if (!note.id) {
            return true;
          }

          const _note = this.indirectService?.notes?.find(
            (n) => n.id === note.id
          );

          return (
            !_note ||
            note?.author?.id !== _note?.author?.id ||
            note?.message !== _note?.message ||
            note?.createdAt !== _note?.createdAt
          );
        })
      );
    },
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    indirectService: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.notes = to?.notes ?? [];
        }

        !this.disabled && this.autofocus();
      },
    },
  },

  components: {
    IndirectServicesNotes,
  },
};
</script>

<style>
.indirect-services-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.indirect-services-form .indirect-services-notes {
  grid-column: span 2;
}

.indirect-services-form > footer {
  grid-column: span 2;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>