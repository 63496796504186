import { del, get, post, put } from './http';

const API_PATH = '/api/facilities';

export const createFacility = async facility =>
    await post(API_PATH, facility);

export const deleteFacility = async facility =>
    await del(`${API_PATH}/${facility.id}`);

export const getFacilities = async () =>
    await get(API_PATH);

export const saveFacility = async facility =>
    await (facility.id ? updateFacility(facility) : createFacility(facility));

export const updateFacility = async facility =>
    await put(`${API_PATH}/${facility.id}`, facility);