<template>
  <form
    autocomplete="off"
    class="admin-templates-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSubmit"
  >
    <fieldset>
      <label>
        <span class="required">Template Name:</span>

        <input
          :disabled="disabled"
          id="admin-template-name"
          required
          type="text"
          v-model="name"
        />
      </label>

      <label>
        <span class="required">Template Type:</span>

        <select
          :disabled="disabled"
          id="admin-template-type"
          required
          v-model="typeId"
        >
          <option disabled></option>
          <option v-for="type in types" :key="type.id" :value="type.id">
            {{ type.name }}
          </option>
        </select>
      </label>

      <section class="attachment">
        <h2>Attachments</h2>

        <label>
          <span>Attachment Name:</span>
          <input
            :disabled="disabled || !file"
            id="admin-template-filename"
            pattern=".+\.docx$"
            requried
            type="text"
            v-model="filename"
          />
        </label>

        <label>
          <span>Date:</span>
          <input
            :disabled="disabled || !file"
            id="admin-template-created-at"
            readonly
            type="text"
            :value="
              createdAt && formatTimestamp(createdAt, { format: FORMAT_DEFAULT_DATE_TIME })
            "
          />
        </label>

        <section class="attachment-actions">
          <icon-button
            class="icon-download-template"
            :disabled="!template.id"
            name="download"
            title="Download Template"
            @click="handleDownload"
          />

          <icon-button
            class="icon-upload-template"
            :disabled="disabled"
            name="upload"
            title="Upload Template"
            @click="$refs.file.click()"
          />

          <input
            accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            :disabled="disabled"
            hidden
            ref="file"
            type="file"
            @change="handleUpload"
          />
        </section>
      </section>
    </fieldset>

    <details class="variables" open>
      <summary>
        <h2>Associated Tags</h2>
      </summary>

      <table>
        <colgroup>
          <col width="40%" />
          <col width="60%" />
        </colgroup>
        <thead>
          <tr></tr>
          <tr>
            <th>Tag</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="variable in variables" :key="variable.id">
            <td>
              <pre class="variable-id">{{ `\{\{${variable.id}\}\}` }}</pre>
            </td>
            <td>
              <span class="variable-description" v-html="formatDescription(variable.description)"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </details>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-template-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && template.id"
      >
        Delete
      </button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-template-save"
        type="submit"
        v-if="canSave"
      >
        Save
      </button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-template-cancel"
        type="reset"
        v-if="canCancel"
      >
        Cancel
      </button>
    </footer>
  </form>
</template>

<script>
import {
  deleteTemplate,
  downloadTemplate,
  getTemplate,
  getTemplates,
  getTemplateTypes,
  saveTemplate,
} from "../../api/TemplatesAPI";

export default {
  data() {
    return {
      createdAt: undefined,
      file: undefined,
      filename: undefined,
      name: undefined,
      typeId: undefined,
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    template: {
      type: Object,
      default: () => ({}),
    },
    types: {
      type: Array,
      default: null,
    },
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_TEMPLATES");
    },
    canSave() {
      return this.can({
        permissions: ["CREATE_TEMPLATES", "UPDATE_TEMPLATES"],
        matchAny: true,
      });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.template?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.user?.id
          ? this.can("UPDATE_TEMPLATES")
          : this.can("CREATE_TEMPLATES"))
      );
    },
    variables() {
      return (
        this.types.find((type) => type.id === this.typeId)?.variables ?? []
      );
    },
  },

  methods: {
    addOverflowTitles(observed = false) {
      this.$el.querySelectorAll('.variable-id, .variable-description').forEach(el => {
        if (!observed) {
          el.title?.trim() && (el.dataset.title = el.title);
        }

        if (el.scrollWidth > el.offsetWidth) {
          const title = el.title;
          title?.trim() && el.setAttribute('data-tite', title);
          el.setAttribute('title', el.innerHTML.replaceAll(/<br[^>]*>([^\r\n]+)/g, ' ($1)').replaceAll(/<[^>]*>/g, ''));
        } else if (el.dataset.title) {
          el.setAttribute('title', el.dataset.title);
          delete el.dataset.title;
        } else {
          el.removeAttribute('title');
        }
      })
    },
    formatDescription(description) {
      return description.replace(
        /(Date|Date\/Time|Time(?:zone)?): (.*?) \(ex: .*?\)/,
        (_, type, format) =>
          `${type}: ${format}<br/><pre>ex: ${this.formatTimestamp(
            Date.now(),
            { format }
          )}</pre>`
      );
    },
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (!confirm("Are you sure you want to delete this template?")) {
        return;
      }

      await deleteTemplate(this.template);
      this.$emit("delete", this.template);
    },
    async handleDownload() {
      try {
        await downloadTemplate(this.template.id);
      } catch (error) {
        this.showErrors(error, "Templates");
      }
    },
    async handleSubmit() {
      try {
        const template = {
          ...this.template,
          filename: this.filename,
          name: this.name,
          type: { id: this.typeId },
        };

        const response = await saveTemplate(template, this.file);
        this.file = undefined;
        this.$emit("submit", { ...template, ...response });
      } catch (e) {
        this.showErrors(e, "A template");
      }
    },
    handleUpload(e) {
      this.file = event.target.files[0];
      this.createdAt = Date.now();
      this.filename = this.file.name;
      this.$refs.file.value = '';
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return (
        this.file ||
        this.filename !== this.template?.filename ||
        this.name !== this.template?.name ||
        this.typeId !== this.template?.type?.id
      );
    },
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    template: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.createdAt = to?.createdAt;
          this.filename = to?.filename;
          this.name = to?.name;
          this.typeId = to?.type?.id;
        }

        !this.disabled && this.autofocus();
      },
    },
  },

  mounted() {
    this.addOverflowTitles();
    this.observer = new ResizeObserver(this.addOverflowTitles);
    this.observer.observe(this.$el.querySelector('.variables table'));
  },

  beforeDestroy() {
    this.observer?.disconnect();
  }
};
</script>

<style>
.admin-templates-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: minmax(20em, 1fr) 4fr minmax(30em, 1.5fr);
}

.admin-templates-form > fieldset > label {
  grid-column: 1;
}

.admin-templates-form .attachment {
  display: grid;
  grid-column: 3;
  grid-gap: 0 1em;
  grid-row: 1 / 2;
  grid-template-columns: 1fr 1fr 5em;
}

.admin-templates-form .attachment h2 {
  grid-column: span 3;
}

.admin-templates-form .attachment input {
  transform: translateX(-0.25em);
}

.admin-templates-form .attachment input:not(:focus) {
  background: transparent;
  border-color: transparent;
}

.admin-templates-form .attachment .attachment-actions {
  align-items: flex-end;
  display: flex;
  justify-content: space-evenly;
}

.admin-templates-form .variables {
  grid-column: 2 / span 3;
  grid-row: 3;
  margin-top: 2em;
}

.admin-templates-form .variables h2 {
  display: inline;
}

.admin-templates-form .variables table {
  font-size: 0.85em;
  table-layout: fixed;
  width: 100%;
}

.admin-templates-form .variables table * {
  vertical-align: top;
}

.admin-templates-form .variables table tbody tr:hover {
  background: var(--primary-light-gray);
  cursor: default;
}

.admin-templates-form .variables .variable-description {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.admin-templates-form > footer {
  grid-column: span 4;
  margin: 0;
  padding: 0.5em 0 0 0;
}

@media (max-width: 1091px) {
  .admin-templates-form {
    display: flex;
    flex-direction: column;
  }

  .admin-templates-form > * + *,
  .admin-templates-form > fieldset > * + *,
  .admin-templates-form .variables {
    margin-top: 1em;
  }
}
</style>