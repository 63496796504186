<template>
  <table class="striped admin-service-subcategories-table">
    <caption v-html="caption" v-if="caption" />
    <colgroup>
      <col width="50%" />
      <col width="50%" />
    </colgroup>
    <thead>
      <tr>
        <th>Subcategory</th>
        <th>Category</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="subcategory in paginatedSubcategories"
        :key="subcategory.id"
        :class="selectedId === subcategory.id ? 'selected' : 'selectable'"
        @click="handleSelect(subcategory)"
      >
        <td>{{ subcategory.name }}</td>
        <td>{{ (subcategory.category || {}).name }}</td>
      </tr>
      <tr v-if="subcategories.length === 0">
        <td class="no-records" colspan="2">
          There are currently no service subcategories in the system.
        </td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls" colspan="2">
          <icon-button
            :disabled="!hasPrevPage"
            name="prev"
            @click="goToPrevPage"
          />

          <icon-button
            :disabled="!hasNextPage"
            name="next"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("subcategories")],

  props: {
    caption: {
      type: String,
      default: undefined,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
    subcategories: {
      type: Array,
    },
  },

  methods: {
    handleSelect(subcategory) {
      this.$emit("select", subcategory);
    },
  },
};
</script>