<template>
  <section id="admin-tools">
    <content-header title="Tools" />

    <section class="admin-links">
      <router-link
        v-for="(link, index) in links"
        :key="index"
        class="admin-link"
        :to="link.to"
      >
        <img :alt="link.icon.replace(/.*?\/([^/]+)$/, '$1')" :src="link.icon" />
        {{ link.name }}
      </router-link>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    search: {
      type: String,
      default: "",
    },
  },

  computed: {
    links() {
      return [
        this.hasAdmin("USERS") && {
          name: "User & Organizations",
          to: { name: "Admin: Users" },
          icon: require("../../../assets/icons/user.svg"),
        },
        (this.hasAdmin("EQUIPMENT_CATEGORIES") ||
          this.hasAdmin("EQUIPMENT_SUBCATEGORIES") ||
          this.hasAdmin("MANUFACTURERS") ||
          this.hasAdmin("VENDORS") ||
          this.hasAdmin("EQUIPMENT_ITEMS") ||
          this.hasAdmin("EQUIPMENT_PARTS") ||
          this.hasAdmin("EQUIPMENT_ACCESSORIES")) && {
          name: "Equipment",
          to: { name: "Admin: Equipment" },
          icon: require("../../../assets/icons/equipment.svg"),
        },
        this.hasAdmin("FACILITIES") && {
          name: "Facilities & Insurance",
          to: { name: "Admin: Facilities" },
          icon: require("../../../assets/icons/facility.svg"),
        },
        (this.hasAdmin("PROGRAMS") ||
          this.hasAdmin("PROJECTS") ||
          this.hasAdmin("EQUIPMENT_TYPES")) && {
          name: "Programs",
          to: { name: "Admin: Programs" },
          icon: require("../../../assets/icons/program.svg"),
        },
        this.hasAdmin("TEMPLATES") && {
          name: "Templates",
          to: { name: "Admin: Templates" },
          icon: require("../../../assets/icons/template.svg"),
        },
        this.hasAdmin("BILLING_CODES") && {
          name: "Billing Codes",
          to: { name: "Admin: Billing Codes" },
          icon: require("../../../assets/icons/billing-code-h.svg"),
        },
        (this.hasAdmin("LOAN_TYPES") ||
          this.hasAdmin("SERVICE_CATEGORIES") ||
          this.hasAdmin("SERVICE_SUBCATEORIES")) && {
          name: "Loans & Services",
          to: { name: "Admin: Loans & Services" },
          icon: require("../../../assets/icons/loan.svg"), // TODO: Update this icon
        },
        this.hasAdmin("REFERRAL_AGENTS") && {
          name: "Referral Agents",
          to: { name: "Admin: Referral Agents" },
          icon: require("../../../assets/icons/user.svg"), // TODO: Update this icon
        },
        this.hasAdmin("HOLIDAYS") && {
          name: "Holidays",
          to: { name: "Admin: Holidays" },
          icon: require("../../../assets/icons/holiday.svg"), // TODO: Update this icon
        },
      ].filter((v) => v);
    },
  },
};
</script>

<style>
#admin-tools .admin-links {
  --stamp-size: 11em;
  display: grid;
  font-size: 1.4em;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(var(--stamp-size), 1fr));
  justify-content: center;
  text-align: center;
}

#admin-tools .admin-links .admin-link {
  align-items: center;
  background-image: url("../../../assets/stamp.svg#default");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: var(--primary-black);
  display: flex;
  flex-direction: column;
  font-weight: 500;
  height: var(--stamp-size);
  justify-content: flex-end;
  text-decoration: none;
  text-transform: uppercase;
  padding: 2em 1.33em;
  width: var(--stamp-size);
}

#admin-tools .admin-links .admin-link img {
  filter: var(--filter-primary-red);
  height: 3.5em;
  justify-self: center;
  margin-bottom: 1em;
}

#admin-tools .admin-links .admin-link:hover,
#admin-tools .admin-links .admin-link:active,
#admin-tools .admin-links .admin-link:focus {
  background-image: url("../../../assets/stamp.svg#hover");
  color: var(--primary-white);
}

#admin-tools .admin-links .admin-link:hover img,
#admin-tools .admin-links .admin-link:active img,
#admin-tools .admin-links .admin-link:focus img {
  filter: var(--filter-primary-white);
}

#admin-tools .admin-links .admin-link-disabled,
#admin-tools .admin-links .admin-link-disabled:hover,
#admin-tools .admin-links .admin-link-disabled:active,
#admin-tools .admin-links .admin-link-disabled:focus {
  background-image: url("../../../assets/stamp.svg#disabled");
  color: var(--disabled-font-color);
}

#admin-tools .admin-links .admin-link-disabled img,
#admin-tools .admin-links .admin-link-disabled:hover img,
#admin-tools .admin-links .admin-link-disabled:active img,
#admin-tools .admin-links .admin-link-disabled:focus img {
  filter: var(--filter-disabled-font-color);
}
</style>