<template>
  <table class="striped admin-holidays-table">
    <caption v-html="caption" v-if="caption" />
    <colgroup>
      <col width="25%" />
      <col width="25%" />
      <col width="50%" />
    </colgroup>
    <thead>
      <tr>
        <th>Date</th>
        <th>Name</th>
        <th>Description</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="holiday in paginatedHolidays"
        :key="holiday.id"
        :class="[
          selectedId === holiday.id ? 'selected' : 'selectable',
          compareDates(new Date(holiday.date), Date.now()) > 0
            ? 'past-event'
            : '',
        ]"
        @click="handleSelect(holiday)"
      >
        <td>{{ formatTimestamp(holiday.date) }}</td>
        <td>{{ holiday.name }}</td>
        <td>{{ holiday.description }}</td>
      </tr>
      <tr v-if="holidays.length === 0">
        <td class="no-records" colspan="3">
          There are currently no holidays in the system.
        </td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls" colspan="3">
          <icon-button
            name="prev"
            :disabled="!hasPrevPage"
            @click="goToPrevPage"
          />

          <icon-button
            name="next"
            :disabled="!hasNextPage"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("sortedHolidays", "paginatedHolidays")],

  props: {
    caption: {
      type: String,
      default: undefined,
    },
    holidays: {
      type: Array,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    sortedHolidays() {
      const now = Date.now();

      return this.holidays.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        if (
          this.compareDates(dateA, now) > 0 &&
          this.compareDates(dateB, now) <= 0
        )
          return 1;
        else if (
          this.compareDates(dateA, now) <= 0 &&
          this.compareDates(dateB, now) > 0
        )
          return -1;

        return this.compareDates(dateA, dateB);
      });
    },
  },

  methods: {
    compareDates(dateA, dateB) {
      return 0 - (dateA > dateB) + (dateA < dateB);
    },
    handleSelect(holiday) {
      this.$emit("select", holiday);
    },
  },
};
</script>

<style scoped>
.past-event {
  text-decoration: line-through;
}
</style>