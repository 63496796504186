<template>
  <component
    class="search-form"
    :is="tag"
    v-on="listeners"
    @keypress="handleKeyPress"
    @submit.prevent="handleSubmit"
  >
    <fieldset>
      <label class="search-label">
        <span>{{ getStaticName($store.getters.user, "Search", "Recherche") }}</span>

        <span class="search-input">
          <input
            class="search-field"
            :disabled="disabled"
            :id="inputId"
            type="search"
            :value="phrase"
            @input="handleInput"
            @search="handleSearch"
          />

          <i class="search-icon" @click="handleClick" />
        </span>
      </label>
    </fieldset>

    <slot />
  </component>
</template>

<script>
const searchIcon = require("../../assets/search.svg");

export default {
  data() {
    return {
      active: false,
      focus: false,
      phrase: this.value,
    };
  },

  props: {
    debounce: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      default: null,
    },
    tag: {
      type: String,
      default: "section",
    },
    value: {
      type: String,
      default: "",
    },
  },

  computed: {
    listeners() {
      const listeners = { ...this.$listeners };
      delete listeners.keypress;
      delete listeners.input;
      delete listeners.submit;
      return listeners;
    },
    searchIcon() {
      return `${searchIcon}#default`;
    },
  },

  methods: {
    handleClick(event) {
      event.preventDefault();

      this.timeout && clearTimeout(this.timeout);
      this.$emit("input", this.phrase);

      let form = this.$el;
      const button = document.createElement("button");
      button.style = "display:none";
      button.type = "submit";

      if (this.tag !== "form") {
        form = document.createElement("form");
        form.style = "display:none";

        this.$el
          .querySelectorAll("input, select, textarea")
          .forEach((el) => form.appendChild(el.cloneNode()));

        form.addEventListener("submit", this.handleSubmit);
        document.body.appendChild(form);
      }

      form.appendChild(button);
      button.click();
      button.remove();
      form === this.$el || form.remove();
    },
    handleKeyPress(event) {
      event.charCode === 13 && this.handleClick(event);
    },
    handleInput(event) {
      this.timeout && clearTimeout(this.timeout);
      this.phrase = event.target.value;

      this.timeout = setTimeout(() => {
        this.$emit("change", this.phrase);
        this.$emit("input", this.phrase);
      }, Math.max(this.debounce, 0));
    },
    handleSearch(event) {
      this.timeout && clearTimeout(this.timeout);
      this.phrase = event.target.value;
      this.$emit("change", this.phrase);
      this.$emit("input", this.phrase);
    },
    handleSubmit(event) {
      event.preventDefault();
      this.$emit("submit", event);
    },
  },
};
</script>

<style>
.search-form > fieldset {
  border: none;
  display: contents;
}

.search-label {
  color: var(--primary-red);
  display: flex;
  flex-direction: column;
  font-style: italic;
}

.search-input {
  display: inline-flex;
  position: relative;
}

.search-input .search-field {
  flex: 1;
  padding-right: 1.814em;
  width: 100%;
}

.search-input .search-field,
.search-input .search-field:hover,
.search-input .search-field:active,
.search-input .search-field:focus,
.search-input .search-field:disabled {
  background-image: none;
}

.search-input .search-icon {
  background-image: url("../../assets/search.svg#default");
  background-position: center center;
  background-size: 1em;
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  flex: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.814em;
}

.search-input .search-field:hover + .search-icon {
  background-image: url("../../assets/search.svg#hover");
}

.search-input .search-field:active + .search-icon,
.search-input .search-field:focus + .search-icon {
  background-image: url("../../assets/search.svg#active");
}

.search-input .search-field:disabled + .search-icon {
  background-image: url("../../assets/search.svg#disabled");
}
</style>