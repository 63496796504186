import { get, post, put } from './http';

export const getSession = async () =>
  await get('/api/session');

export const login = async creds =>
  await post('/api/login', creds, { raw: true });

export const logout = async () => {
  get('/api/logout', { raw: true });
};

export const recoverUsername = async data =>
  await post('/api/usernames/forgotten', data, { raw: true });

export const requestReset = async data =>
  await post('/api/passwords/forgotten', {
    url: `${new URL(`${process.env.VUE_APP_API_PATH.replace(/(.)?\/+$/g, '$1')}/reset-password`, window.location.origin)}/{{token}}`,
    ...data
  }, { raw: true });

export const resetPassword = async data =>
  await (data?.token
    ? put('/api/passwords/forgotten', data, { raw: true })
    : put('/api/passwords/reset', data, { raw: true }));

export const updatePassword = async data =>
  await put('/api/passwords/update', data, { raw: true });