<template>
  <table class="striped admin-referral-agents-table">
    <caption v-html="caption" v-if="caption" />
    <colgroup>
      <col width="32px" />
      <col width="25%" />
      <col width="25%" />
      <col width="25%" />
      <col width="25%" />
    </colgroup>
    <thead>
      <tr>
        <th></th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Email</th>
        <th>Phone Number</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="referralAgent in paginatedReferralAgents"
        :key="referralAgent.id"
        :class="selectedId === referralAgent.id ? 'selected' : 'selectable'"
        @click="handleSelect(referralAgent)"
      >
        <td>
          <img
            class="user-indicator"
            src="../../assets/icons/user.svg"
            title="This referral agent is linked to a user account"
            v-if="referralAgent.isUser"
          />
        </td>
        <td>{{ referralAgent.firstName }}</td>
        <td>{{ referralAgent.lastName }}</td>
        <td>{{ getEmailAddress(referralAgent).address }}</td>
        <td>
          {{ formatPhoneNumber(getPhoneNumber(referralAgent).number)
          }}{{
            getPhoneNumber(referralAgent).extension
              ? ` ext. ${getPhoneNumber(referralAgent).extension}`
              : ""
          }}
        </td>
      </tr>
      <tr v-if="referralAgents.length === 0">
        <td class="no-records" colspan="5">
          There are currently no referral agents in the system.
        </td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls" colspan="5">
          <icon-button
            name="prev"
            :disabled="!hasPrevPage"
            @click="goToPrevPage"
          />

          <icon-button
            name="next"
            :disabled="!hasNextPage"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("referralAgents")],

  props: {
    caption: {
      type: String,
      default: undefined,
    },
    referralAgents: {
      type: Array,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
  },

  methods: {
    getEmailAddress(referralAgent) {
      return referralAgent?.emailAddresses?.find((a) => a.primary) ?? {};
    },
    getPhoneNumber(referralAgent) {
      return referralAgent?.phoneNumbers?.slice(-1)?.[0] ?? {};
    },
    handleSelect(referralAgent) {
      this.$emit("select", referralAgent);
    },
  },
};
</script>

<style scoped>
.user-indicator {
  aspect-ratio: 1;
  background-color: #545454;
  border-radius: 50%;
  filter: invert(1);
  height: 1em;
  padding: 2px;
  vertical-align: middle;
}

.selected .user-indicator {
  background-color: #18e2cd;
}
</style>