<template>
  <section class="admin-templates-page">
    <content-header :prev="previousRoute" :title="title" />

    <template v-if="!template">
      <search-form class="template-search" :debounce="200" v-model="search">
        <icon-button
          class="template-create-button"
          name="add-template"
          title="Create Template"
          @click="handleCreate"
          v-if="can('CREATE_TEMPLATES')"
        />
      </search-form>

      <TemplatesTable
        :page-size="20"
        :selected-id="template && template.id"
        :templates="filteredTemplates"
        @select="handleSelect"
      />
    </template>

    <TemplatesForm
      :template.sync="template"
      :types="templateTypes"
      @delete="handleDelete"
      @reset="handleCancel"
      @submit="handleSave"
      v-else
    />
  </section>
</template>


<script>
import TemplatesTable from "../../../components/admin/TemplatesTable";
import TemplatesForm from "../../../components/admin/TemplatesForm";

import {
  getTemplate,
  getTemplates,
  getTemplateTypes,
} from "../../../api/TemplatesAPI";

export default {
  components: {
    TemplatesTable,
    TemplatesForm,
  },

  data() {
    return {
      _previousRoute: undefined,
      search: "",
      template: undefined,
      templates: [],
      templateTypes: [],
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined,
    },
  },

  computed: {
    filteredTemplates() {
      const regex = this.getFilterRegex(this.search);

      return this.templates.filter((template) => {
        const templateType = this.templateTypes.find(
          (type) => type.id === template?.type?.id
        );

        return regex.test(
          [
            template?.name ?? "",
            template?.filename ?? "",
            template?.type?.name ?? "",
            template?.type?.description ?? "",
            ...(!templateType
              ? []
              : templateType.variables.map((variable) =>
                  [
                    variable.id ?? "",
                    variable.name ?? "",
                    variable.description ?? "",
                  ].join(0x1f)
                )),
          ].join(0x1f)
        );
      });
    },
    previousRoute() {
      return (
        this.$data._previousRoute ??
        (this.template ? { name: "Admin: Templates" } : { name: "Admin" })
      );
    },
    title() {
      return this.template?.id
        ? `Edit Template - ${this.template.name}`
        : this.template
        ? "Create Template"
        : "Templates";
    },
  },

  methods: {
    handleCancel() {
      this.$router.push({
        ...this.$route,
        params: { ...this.$route.params, id: undefined },
      });
    },
    handleCreate() {
      this.$router.push({
        ...this.$route,
        params: { ...this.$route.params, id: "new" },
      });
    },
    handleDelete(template) {
      this.templates = this.templates.filter((t) => t?.id !== template?.id);
      this.template?.id === template?.id && (this.template = undefined);
    },
    handleSave(template) {
      this.templates = [
        ...this.templates.filter((e) => e?.id !== template?.id),
        template,
      ].sort(this.sortBy("name", "id"));

      const created = !/^\d+$/.test(this.template?.id);
      this.template = { ...template };

      if (created) {
        this.handleSelect(template);
        this.showAlert(`Your template has been created successfully!`);
      } else {
        this.showAlert(`Your template has been updated successfully!`);
      }
    },
    handleSelect(template) {
      this.$nextTick(() => {
        this.$router.push({
          ...this.$route,
          params: { ...this.$route.params, id: template?.id },
        });
      });
    },
  },

  watch: {
    async id(to, from) {
      if (to === from) {
        return;
      }

      if (!to) {
        this.template = undefined;
        this.templates = (await getTemplates()).sort(this.sortBy('name', 'id'));
      } else {
        if (to !== "new") {
          try {
            this.template = {
              id: to,
              ...this.templates?.find((e) => e.id === to),
            };
          } catch (e) {
            this.handleSelect();
          }
        } else {
          this.template = {};
        }
      }
    },
  },

  async created() {
    if (this.id) {
      try {
        this.template = { id: this.id };
        this.template = this.id === "new" ? {} : await getTemplate(this.id);
      } catch (e) {
        this.handleSelect();
      }
    }

    [this.templates, this.templateTypes] = await Promise.all([
      getTemplates().then((templates) =>
        templates.sort(this.sortBy("name", "id"))
      ),
      getTemplateTypes().then((types) => types.sort(this.sortBy("name", "id"))),
    ]);
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$data._previousRoute =
        vm.id && from.name === "Admin" ? from : undefined;
    });
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },
};
</script>

<style>
.admin-templates-page {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "header search"
    "table table"
    "form form";
}

.admin-templates-page > .template-list {
  display: contents;
}

.admin-templates-page .content-header {
  align-items: center;
  grid-area: header;
}

.admin-templates-page .template-search {
  align-items: flex-end;
  display: flex;
  font-size: 0.8rem;
  font-weight: normal;
  grid-area: search;
  margin: 0 0 0.585rem auto;
  padding: 0;
}

.admin-templates-page .template-search label {
  color: var(--primary-red);
  margin-right: 0.33em;
}

.admin-templates-page .template-create-button {
  align-self: flex-end;
  font-size: 0.755rem;
  grid-area: add;
}

.admin-templates-page .admin-templates-table {
  grid-area: table;
}

.admin-templates-page .admin-templates-form {
  grid-area: form;
}

/* 
.templates-page .content-header {
  align-items: center;
  grid-area: header;
}

.table {
  grid-area: form;
}

.templates-page .templates-search {
  align-items: flex-end;
  display: flex;
  font-size: 0.8rem;
  font-weight: normal;
  grid-area: search;
  margin: 0 0 0.585rem auto;
  padding: 0;
}

.templates-page .templates-search label {
  color: var(--primary-red);
  margin-right: 0.33em;
}

.templates-page .templates-create-button {
  align-self: flex-end;
  font-size: 0.755rem;
  grid-area: add;
}

.templates-page .templates-table {
  grid-area: form;
}

.templates-page .templates-form {
  grid-area: form;
}

.template-search {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.8rem;
  font-weight: normal;
  grid-area: search;
  margin: 0 0 0.585rem auto;
  padding: 0;
}

.templates-search .icon-add-template i {
  height: 1.3em;
  width: 1.3em;
}

.search-label {
  margin-right: 1em;
}

.templates-search {
  display: flex;
  align-items: flex-end;
} */
</style>