<template>
  <div>
    <div class="table-space-between">
      <caption
        v-html="caption"
      />
      <div class="control-filter">
        <div class="date-filter">
          <label>
            Start Date
            <br />
            <date-input class="date-input" v-model="startDateFilter" />
          </label>

          <label>
            End Date
            <br />
            <date-input class="date-input" v-model="endDateFilter" />
          </label>

          <button @click="resetFilterDates">Clear</button>
        </div>
        <search-form v-model="search" class="invoice-search" />
      </div>

      <div class="table-overflow">
        <table class="striped invoice">
          <thead>
            <tr>
              <th class="invoice-table-header invoice-pick-checkbox-header">
                <input
                  type="checkbox"
                  :checked="isAllSelected"
                  @click="handleSelectAll"
                />
              </th>
              <th
                class="invoice-table-header invoice-status-header"
              >
                Client Number
              </th>
              <th
                class="invoice-table-header invoice-status-header"
              >
                Client Name
              </th>

              <th class="invoice-table-header invoice-invoice-number-header">
                invoice no
              </th>

              <th class="invoice-table-header invoice-approval-number-header">
                approval no
              </th>

              <th
                class="invoice-table-header invoice-program-header"
                @click="sortByProgram"
              >
                program
              </th>

              <th
                class="invoice-table-header invoice-invoiced-date-header"
              >
                total
              </th>

              <th class="invoice-table-header invoice-invoiced-date-header">
                invoiced date
              </th>
            </tr>
          </thead>
          <tbody ref="items">
            <InvoiceRow
              v-for="(invoice, index) in paginatedInvoices"
              :key="invoice.id"
              :invoice="invoice"
              :invoice-equip-number="equipmentNumbersCollected"
              :invoice-programs="invoicePrograms"
              :invoice-codes="invoiceCodes"
              :index="index"
              :status="status"
              table-type="bulkInvoicing"
              @update:invoice="handleInvoiceUpdate($event, index)"
            />
          </tbody>
          <tfoot>
            <tr>
              <td
                class="page-controls"
                :colspan="11"
              >
                <button
                  v-if="
                    can('READ_TEMPLATES')
                  "
                  :disabled="!itemsArray.find((item) => item.selected)"
                  type="button"
                  class="export-button"
                  @click="handleToggleTemplates(true)"
                >
                  Templates
                </button>

                <icon-button
                  :disabled="!hasPrevPage"
                  id="invoice-notes-page-prev"
                  name="prev"
                  @click="goToPrevPage"
                />

                <icon-button
                  :disabled="!hasNextPage"
                  id="invoice-notes-page-next"
                  name="next"
                  @click="goToNextPage"
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <popup
      class="invoicing-template-popup"
      :modal="true"
      ref="templateModal"
      @close="handleToggleTemplates(false)"
      v-if="templateModal"
    >
      <TemplateSelector
        :templates="filteredTemplates"
        @cancel="handleToggleTemplates(false)"
        @submit="handleSubmitTemplates"
      />
    </popup>
  </div>
</template>

<script>
import { pagination } from "../../mixins/pagination";

import {
  generateDocuments,
  updateInvoice,
  updateGeneratedInvoices,
  postCsvInvoiceExport,
  postSage50InvoiceExport,
} from "../../api/InvoiceAPI";

import { getTemplates } from "../../api/TemplatesAPI";

import InvoiceRow from "./InvoiceRow";

import TemplateSelector from "../templates/TemplateSelector";

export default {
  mixins: [pagination("filteredInvoices")],

  data() {
    return {
      endDateFilter: "",
      // we will use this array to store data we need to display and will fill it depend on the type of the table we are at
      itemsArray: [],
      search: "",
      startDateFilter: "",
      selectItem: false,
      isCheckAll: false,
      templateModal: false,
      sort: 0,
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined,
    },
    caption: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
    invoicePrograms: {
      type: Array,
    },
    invoiceCodes: {
      type: Array,
    },
    status: {
      type: String,
      default: undefined,
    },
    itemsInvoices: {
      type: Array,
    }
  },

  computed: {
    invoicesTemplateGeneration() {
      return this.itemsArray.filter((item) => item.selected).map((a) => a.id);
    },
    equipmentNumbersCollected() {
      return Object.values(
        this.itemsArray
          .reduce(
            (equipment, invoice) => [
              ...equipment,
              ...(invoice.items?.map(
                (item) =>
                  item?.loan?.equipment ??
                  item.service?.equipment ??
                  item?.equipment
              ) ?? []),
            ],
            []
          )
          .reduce((equip, item) => {
            return item ? { ...equip, [item.id]: item } : equip;
          }, {})
      );
    },
    filteredInvoices() {
      const startDateFilterMatched = this.startDateFilter.length > 0 ? this.startDateFilter.match(/\d+-\d+-\d+/)[0] : this.startDateFilter;
      const endDateFilterMatched = this.endDateFilter.length > 0 ? this.endDateFilter.match(/\d+-\d+-\d+/)[0] : this.endDateFilter;

      const startDate =
        startDateFilterMatched &&
        new Date(`${startDateFilterMatched} 00:00:00`).toJSON();

      const endDate =
        endDateFilterMatched &&
        new Date(`${endDateFilterMatched} 23:59:59`).toJSON();

      if (!/\S/.test(this.search) && !startDate && !endDate) {
        return this.itemsArray;
      }

      const regex = this.getFilterRegex(this.search);

      return this.itemsArray.filter(
        (invoice) =>
          (!startDate || invoice?.createdAt >= startDate) &&
          (!endDate || invoice?.createdAt <= endDate) &&
          regex.test(
            [
              (invoice?.client?.clientNumber) ||
                "",
              (this.getFullName(invoice?.client)) ||
                "",
              invoice?.invoiceNumber ?? "",
              invoice?.approvalNumber ?? "",
              invoice?.program?.name ?? "",
            ].join(String.fromCharCode(0x1f))
          )
      );
    },
    // Checking if we selected ALL invoices that user curently search for and if so check the master checkbox
    isAllSelected() {
      return (
        this.filteredInvoices.length > 0 &&
        !this.filteredInvoices.find((i) => !i.selected)
      );
    },
    filteredTemplates() {
      return this.templates.filter((t) => t.type.name == "Client Invoice");
    },
  },

  methods: {
    async handleSubmitTemplates(selectedTemplates) {
      try {
        await updateGeneratedInvoices(this.invoicesTemplateGeneration);
        
        await generateDocuments({
          invoiceIds: this.invoicesTemplateGeneration,
          templateIds: selectedTemplates,
        });

        this.handleToggleTemplates(false);
        this.$emit("handleBulkTemplatesUpdate");
      } catch (e) {
        this.showErrors(e);
      }
    },
    async handleToggleTemplates(templateModal) {
      this.templates = this.templates ?? (await getTemplates());
      const selectedInvoices = this.itemsArray.filter((invoice => invoice.selected));

      if(templateModal && this.checkTemplateNonGenerated(selectedInvoices)) {
        if(confirm(`Are you sure you would like to generate ${selectedInvoices.length > 1 ? "Invoices for these Clients? These Invoices will be moved to the Invoice History." : "an Invoice for this Client? This Invoice will be moved to the Invoice History."}`)) {
          this.templateModal = templateModal ?? !this.templateModal;
          this.templateModal &&
          this.$nextTick(() => this.$refs.templateModal.$el.scrollIntoView());
        }
      } else {
        this.templateModal = templateModal ?? !this.templateModal;
        this.templateModal &&
          this.$nextTick(() => this.$refs.templateModal.$el.scrollIntoView());
      }
    },
    resetFilterDates() {
      this.startDateFilter = "";
      this.endDateFilter = "";
    },
    sortByProgram() {
      switch (this.sort) {
        case 0:
          this.itemsArray.sort(
            (a, b) =>
              a?.program?.name?.localeCompare(b?.program?.name, undefined, {
                sensitivity: "base",
              }) ||
              a?.invoiceNumber?.replace(/^\D*(\d+).*/, "$1") -
                b?.invoiceNumber?.replace(/^\D*(\d+).*/, "$1")
          );

          this.sort = 1;
          break;

        case 1:
          this.itemsArray.sort(
            (a, b) =>
              b?.program?.name?.localeCompare(a?.program?.name, undefined, {
                sensitivity: "base",
              }) ||
              a?.invoiceNumber?.replace(/^\D*(\d+).*/, "$1") -
                b?.invoiceNumber?.replace(/^\D*(\d+).*/, "$1")
          );

          this.sort = 2;
          break;

        default:
          this.itemsArray.sort(
            (a, b) =>
              a?.invoiceNumber?.replace(/^\D*(\d+).*/, "$1") -
              b?.invoiceNumber?.replace(/^\D*(\d+).*/, "$1")
          );

          this.sort = 0;
          break;
      }
    },
    validateFields(array) {
      if (array.filter((i) => !i.approvalNumber).length > 0) {
        alert(
          "Please double check items you want to select to make sure they are all filled with data"
        );
        return false;
      }
    },
    handleSelectAll(e) {
      if (!this.checkValidity(this.$refs.items)) {
        if (!this.validateFields(this.itemsArray.filter((i) => i.selected))) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      this.filteredInvoices.forEach((i) => (i.selected = e.target.checked));
    },
    handleInvoiceUpdate(invoice, index) {
      const targetIndex = this.itemsArray.findIndex(
        (invoice) => invoice === this.paginatedInvoices[index]
      );

      targetIndex >= 0 && this.itemsArray.splice(targetIndex, 1, invoice);
    },
    async updateInvoice() {
      try {
        const results = await Promise.allSettled(
          this.itemsArray.filter((item) => item.selected).map(updateInvoice)
        );

        const invoices = [...this.itemsInvoices];

        results.forEach((res) => {
          if (res.status !== "fulfilled") {
            return;
          }

          const invoice = res.value;

          invoices.splice(
            invoices.findIndex((inv) => inv.id === invoice.id),
            1,
            invoice
          );
        });

        this.$emit("update:items-invoices", invoices);
      } catch (e) {
        this.showErrors(e, "Invoice");
      }
    },
  },

  watch: {
    itemsInvoices: {
      immediate: true,
      handler(to, from) {
        this.itemsArray =
          JSON.parse(JSON.stringify(to))?.map((i) => ({
            ...i,
            approvalNumber: i?.approvalNumber ?? "",
            items: i.items ?? null,
            selected: false,
          })) ?? [];
      },
    }
  },
  components: {
    InvoiceRow,
    TemplateSelector,
  },
};
</script>

<style>
.invoice-notes {
  border-collapse: collapse;
  display: grid;
  grid-template-columns: 6em 12em 1fr 2em;
  margin: 0;
  padding: 0;
  width: 100%;
}

.invoice-notes tbody,
.invoice-notes thead,
.invoice-notes tfoot,
.invoice-notes tr {
  display: contents;
}

.invoice-notes button {
  height: 2em;
  vertical-align: top;
  width: 2em;
}

.invoice-notes thead th {
  background: var(--primary-red);
  color: var(--primary-white);
  text-align: left;
  text-transform: uppercase;
}

.invoice-notes thead th,
.invoice-notes tbody td {
  margin: 0;
  padding: 0.2em 0.2em;
}

.invoice-notes tbody td {
  vertical-align: top;
}

.invoice-notes tbody td.invoice-note-date,
.invoice-notes tbody td.invoice-note-author {
  color: var(--primary-red);
}

.invoice-notes tbody td.invoice-note-message textarea {
  background: transparent;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  height: 3em;
  resize: vertical;
  width: 100%;
}

.invoice-notes
  tbody
  td.invoice-note-message
  textarea::-webkit-input-placeholder {
  color: var(--primary-medium-gray);
  opacity: 1;
}

.invoice-notes tbody td.invoice-note-message textarea::placeholder {
  color: var(--primary-medium-gray);
  opacity: 1;
}

.invoice-notes tbody tr:nth-child(2n + 1) td {
  background: var(--primary-light-gray);
}

.invoice-notes tbody td button {
  height: 1.33em;
  width: 1.33em;
}

.invoice-notes tbody td button > i {
  font-size: 0.4em;
}

.invoice-notes tfoot td {
  grid-column: span 2;
  padding-top: 0.4em;
}

.invoice-notes .invoice-note-date {
  width: 6em;
}

.invoice-notes .invoice-note-author {
  width: 12em;
}

.invoice-notes .invoice-note-remove {
  text-align: right;
  width: 2em;
}

.invoice-table-header {
  text-transform: uppercase;
}

.invoice-table-control-buttons {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.invoice-search {
  align-self: flex-end;
}

.table-space-between {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  overflow-y: hidden;
}

.date-filter {
  display: flex;
  justify-content: space-evenly;
}

.control-filter {
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
}

.date-filter .date-input {
  margin-right: 2em;
}

.export-button {
  margin-right: 2em;
}

caption {
  font-weight: bold;
  padding-bottom: 0.2em;
  text-align: left;
}

.invoice tfoot td > * {
  vertical-align: middle;
}

.popup.invoicing-template-popup {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>