<template>
  <form
    autocomplete="off"
    class="admin-programs-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Acronym</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-program-acronym"
          required
          type="text"
          v-model.trim="acronym"
        />
      </label>

      <label>
        <span class="required">Program / Association</span>

        <input
          :disabled="disabled"
          id="admin-program-name"
          required
          type="text"
          v-model.trim="name"
        />
      </label>

      <label>
        <span>Vendor Number</span>

        <input
          :disabled="disabled"
          id="admin-program-vendor-number"
          type="text"
          v-model.trim="vendorNumber"
        />
      </label>

      <label>
        <span>Invoice Number Prefix</span>

        <input
          :disabled="disabled"
          id="admin-program-vendor-invoice-number-prefix"
          type="text"
          v-model.trim="invoiceNumberPrefix"
        />
      </label>

      <label class="invoices-require-approval">
        <span>Invoices Require Approval</span>

        <input
          :disabled="disabled"
          id="admin-program-vendor-invoice-requires-approval"
          type="checkbox"
          v-model.trim="invoiceRequiresApproval"
        />
      </label>

      <EquipmentManagerTool
        :selectedId="program.id"
        :collection="users"
        :disabled="disabled"
        :equipmentManagers="equipmentManagers"
        :mappingPrograms="false"
        @handleManagerUpdate="handleManagerUpdate"
      />
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-program-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && program.id"
      >Delete</button>

      <button :disabled="!isSaveEnabled" id="admin-program-save" type="submit" v-if="canSave">Save</button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-program-cancel"
        type="reset"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </form>
</template>

<script>
import EquipmentManagerTool from "../../components/admin/EquipmentManagerTool";

import { deleteProgram, saveProgram } from "../../api/ProgramAPI";
import { updateByProgramId } from "../../api/EquipmentManagerAPI";

export default {
  data() {
    return {
      acronym: undefined,
      invoiceNumberPrefix: undefined,
      invoiceRequiresApproval: undefined,
      name: undefined,
      vendorNumber: undefined,
      managerMapping: undefined,
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    program: {
      type: Object,
    },
    users: {
      type: Array,
    },
    equipmentManagers: {
      type: Array
    }
  },
  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_PROGRAMS");
    },
    canSave() {
      return this.can({
        permissions: ["CREATE_PROGRAMS", "UPDATE_PROGRAMS"],
        matchAny: true,
      });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.program?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.program?.id
          ? this.can("UPDATE_PROGRAMS")
          : this.can("CREATE_PROGRAMS"))
      );
    },
  },

  methods: {
    handleManagerUpdate(userCollection){
        this.managerMapping = userCollection;
    },
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (!confirm("Are you sure you want to delete this program?")) {
        return;
      }

      await deleteProgram(this.program);
      this.$emit("delete", this.program);
    },
    async handleSave() {
      try {
        if(this.program.id != null) {
          await updateByProgramId(this.program.id, this.managerMapping);
        }

        this.$emit(
          "save",
          await saveProgram({
            ...this.program,
            acronym: this.acronym,
            invoiceNumberPrefix: this.invoiceNumberPrefix,
            invoiceRequiresApproval: this.invoiceRequiresApproval,
            name: this.name,
            vendorNumber: this.vendorNumber,
          })
        );
      } catch (e) {
        this.showErrors(e, "A program");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return (
        this.acronym !== this.program?.acronym ||
        this.invoiceNumberPrefix !== this.program?.invoiceNumberPrefix ||
        this.invoiceRequiresApproval !==
          this.program?.invoiceRequiresApproval ||
        this.name !== this.program?.name ||
        this.vendorNumber !== this.program?.vendorNumber
      );
    },
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    program: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.acronym = to?.acronym;
          this.invoiceNumberPrefix = to?.invoiceNumberPrefix;
          this.invoiceRequiresApproval = to?.invoiceRequiresApproval;
          this.name = to?.name;
          this.vendorNumber = to?.vendorNumber;
        }

        !this.disabled && this.autofocus();
      },
    },
  },
  components: {
    EquipmentManagerTool,
  }
};
</script>

<style>
.admin-programs-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.admin-programs-form label.invoices-require-approval {
  justify-self: flex-start;
}

.admin-programs-form input[type="checkbox" i] {
  margin-top: 5px;
}

.admin-programs-form > footer {
  grid-column: span 3;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>