<template>
  <section class="indirect-services-page">
    <content-header
      :prev="showBack && { name: 'Home' }"
      tag="h1"
      title="Indirect Services"
    />

    <search-form
      class="indirect-services-search"
      :debounce="200"
      v-model="search"
    >
      <icon-button name="add-indirect-service" @click="handleCreate" />
    </search-form>

    <IndirectServicesTable
      class="indirect-services-table"
      :indirect-services="filteredIndirectServices"
      :selected-id="indirectService.id"
      @select="handleSelect"
    />

    <section class="indirect-services-form-wrapper">
      <content-header
        class="indirect-services-form-header"
        tag="h2"
        :title="
          indirectService.id
            ? 'Edit Indirect Service'
            : 'Create Indirect Service'
        "
      />

      <span class="indirect-services-form-tab tab-left tab-left-active">
        <img
          alt="Indirect Services"
          src="../../../assets/icons/indirect-service.svg"
        />
      </span>

      <IndirectServicesForm
        class="indirect-services-form"
        :disabled="indirectService.creator === undefined"
        :indirect-service="indirectService"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </section>
</template>

<script>
import IndirectServicesTable from "../../../components/indirect-services/IndirectServicesTable";
import IndirectServicesForm from "../../../components/indirect-services/IndirectServicesForm";

import { getIndirectServices } from "../../../api/IndirectServiceAPI";

export default {
  data() {
    return {
      indirectService: {},
      indirectServices: [],
      search: "",
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    filteredIndirectServices() {
      const regex = this.getFilterRegex(this.search);

      return this.indirectServices.filter((service) =>
        regex.test(
          [
            this.getUserName(service?.creator),
            ...(service?.notes?.map((note) =>
              [note?.message ?? "", this.getUserName(note?.author)].join(
                String.fromCharCode(0x1f)
              )
            ) ?? []),
          ].join(String.fromCharCode(0x1f))
        )
      );
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.indirectService = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.indirectService = {
          creator: {
            ...this.$store.getters.user,
            permissions: undefined,
            roles: undefined,
          },
          notes: [
            {
              author: {
                ...this.$store.getters.user,
                permissions: undefined,
                roles: undefined,
              },
              createdAt: new Date().toJSON(),
            },
          ],
          createdAt: new Date().toJSON(),
        };
      }
    },
    handleDelete(indirectService) {
      this.indirectServices = this.indirectServices.filter(
        (s) => s?.id !== indirectService?.id
      );

      this.indirectService = {};
    },
    handleSave(indirectService) {
      const created = !this.indirectService.id;

      this.indirectServices = [
        ...this.indirectServices.filter((s) => s?.id !== indirectService?.id),
        indirectService,
      ].sort(this.sortBy("-createdAt", "-id"));

      this.handleSelect(created ? indirectService : undefined, true);

      this.showAlert(
        `Your indirect service has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(indirectService, force = false) {
      if (force || this.confirmIfDirty()) {
        this.indirectService = {
          ...this.indirectServices.find((s) => s?.id === indirectService?.id),
        };
      }
    },
  },

  watch: {
    "indirectService.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, id: to },
          });
        });
    },
  },

  async created() {
    this.indirectServices = await getIndirectServices();
    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    IndirectServicesTable,
    IndirectServicesForm,
  },
};
</script>

<style>
.indirect-services-page {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "header   search"
    "table    table"
    "form     form";
}

.indirect-services-page > .content-header {
  grid-area: header;
}

.indirect-services-page .indirect-services-search {
  align-items: flex-end;
  display: flex;
  font-size: 0.8rem;
  font-weight: normal;
  grid-area: search;
  margin: 0 0 0.585rem auto;
  padding: 0;
}

.indirect-services-page .indirect-services-search label {
  color: var(--primary-red);
  margin-right: 0.33em;
}

.indirect-services-page .indirect-services-search .icon-button {
  font-size: 0.755rem;
}

.indirect-services-page .indirect-services-table {
  grid-area: table;
}

.indirect-services-page .indirect-services-form-wrapper {
  align-items: flex-start;
  display: grid;
  grid-area: form;
  grid-template-areas:
    ".     header"
    "tab   form";
  grid-template-columns: 4em 1fr;
  margin-top: 2em;
}

.indirect-services-page .indirect-services-form-header {
  grid-area: header;
  margin: 0;
  position: initial;
  top: initial;
}

.indirect-services-page .indirect-services-form-wrapper .tab-left {
  grid-area: tab;
  justify-self: flex-end;
  padding-left: 12%;
}

.indirect-services-page .indirect-services-form-tab img {
  filter: var(--filter-primary-white);
  max-height: 3em;
  max-width: 2.5em;
}

.indirect-services-page .indirect-services-form {
  border-color: var(--primary-medium-gray);
  border-style: solid;
  border-width: 1px;
  grid-area: form;
  padding: 1em;
}
</style>