<template>
  <section id="search-results">
    <h2>Your Search Results</h2>

    <slot />

    <section class="search-results search-results-empty" v-if="!hasResults">
      No search results were found
    </section>

    <section class="search-results" v-else>
      <template v-for="row in paginatedResults">
        <ClientCard
          :key="`client-${row.id}`"
          :client="row"
          :expanded.sync="expanded.clients[row.id]"
          @delete="handleDeleteClient"
          v-if="clients.includes(row)"
        />

        <EquipmentCard
          :key="`equipment-${row.id}`"
          :equipment="row"
          :expanded.sync="expanded.equipment[row.id]"
          @delete="handleDeleteEquipment"
          v-if="equipment.includes(row)"
        />

        <ReferralAgentCard
          :key="`referral-agent-${row.id}`"
          :expanded.sync="expanded.referralAgents[row.id]"
          :referral-agent="row"
          @delete="handleDeleteReferralAgent"
          v-if="referralAgents.includes(row)"
        />

        <InvoiceCard
          :key="`invoice-${row.id}`"
          :expanded.sync="expanded.invoices[row.id]"
          :invoice="row"
          @delete="handleDeleteInvoice"
          v-if="invoices.includes(row)"
        />
      </template>
    </section>

    <footer class="page-controls" v-if="hasPages">
      <icon-button :disabled="!hasPrevPage" name="prev" @click="goToPrevPage" />
      <icon-button :disabled="!hasNextPage" name="next" @click="goToNextPage" />
    </footer>
  </section>
</template>

<script>
import { pagination } from "../../mixins/pagination";

import ClientCard from "../clients/ClientCard";
import EquipmentCard from "../equipment/EquipmentCard";
import InvoiceCard from "../invoices/InvoiceCard";
import ReferralAgentCard from "../referral-agents/ReferralAgentCard";

export default {
  components: {
    ClientCard,
    EquipmentCard,
    ReferralAgentCard,
    InvoiceCard,
  },

  mixins: [pagination("combinedResults", "paginatedResults")],

  data() {
    return {
      clients: [],
      equipment: [],
      expanded: {
        clients: {},
        equipment: {},
        invoices: {},
        referralAgents: {},
      },
      invoices: [],
      referralAgents: [],
    };
  },

  props: {
    results: {
      type: Object,
      default: () => ({}),
    },
    pageCount: {
      type: Number,
      default: 1
    },
    pageNumber: {
      type: Number,
      default: 1
    }
  },

  computed: {
    combinedResults() {
      return [
        ...this.clients,
        ...this.equipment,
        ...this.invoices,
        ...this.referralAgents,
      ];
    },
    hasResults() {
      return this.combinedResults.length > 0;
    },
    hasNextPage() {
      return this.pageNumber < this.pageCount;
    },
    hasPages() {
      return this.pageCount > 1;
    },
    hasPrevPage() {
      return this.pageNumber > 1;
    },
  },

  methods: {
    getCardType(row) {
      return this.clients.includes(row)
        ? "ClientCard"
        : this.equipment.includes(row)
        ? "EquipmentCard"
        : this.referralAgents.includes(row)
        ? "ReferralAgentCard"
        : this.invoices.includes(row)
        ? "InvoiceCard"
        : "div";
    },
    handleDeleteClient(client) {
      const currentPage = this.page.current;
      this.clients = this.clients.filter((c) => c.id !== client?.id);
      this.$emit("update:results", { ...this.results, clients: this.clients });

      delete this.expanded.clients[client.id];
      this.$nextTick(() => (this.page.current = currentPage));
    },
    handleDeleteEquipment(equipment) {
      const currentPage = this.page.current;
      this.equipment = this.equipment.filter((e) => e.id !== equipment?.id);

      this.$emit("update:results", {
        ...this.results,
        equipment: this.equipment,
      });

      delete this.expanded.equipment[client.id];
      this.$nextTick(() => (this.page.current = currentPage));
    },
    handleDeleteInvoice(invoice) {
      const currentPage = this.page.current;
      this.invoices = this.invoices.filter((i) => i.id !== invoice?.id);

      this.$emit("update:results", {
        ...this.results,
        invoices: this.invoices,
      });

      delete this.expanded.invoices[client.id];
      this.$nextTick(() => (this.page.current = currentPage));
    },
    handleDeleteReferralAgent(referralAgent) {
      const currentPage = this.page.current;
      this.referralAgents = this.referralAgents.filter(
        (r) => r.id !== referralAgent?.id
      );
      this.$emit("update:results", {
        ...this.results,
        referralAgents: this.referralAgents,
      });

      delete this.expanded.referralAgents[client.id];
      this.$nextTick(() => (this.page.current = currentPage));
    },
    goToFirstPage() {
      this.$emit("page-change", 1);
    },
    goToLastPage() {
      this.$emit("page-change", this.pageCount);
    },
    goToNextPage() {
      this.$emit("page-change", Math.min(this.pageNumber + 1, this.pageCount));
    },
    goToPrevPage() {
      this.$emit("page-change", Math.max(this.pageNumber - 1, 1));
    },
  },

  watch: {
    "results.records.clients": {
      immediate: true,
      handler(to) {
        to === this.clients || (this.expanded.clients = {});
        this.clients = Array.isArray(to) ? to : [];
      },
    },
    "results.records.equipment": {
      immediate: true,
      handler(to) {
        to === this.equipment || (this.expanded.equipment = {});
        this.equipment = Array.isArray(to) ? to : [];
      },
    },
    "results.records.invoices": {
      immediate: true,
      handler(to) {
        to === this.invoices || (this.expanded.invoices = {});
        this.invoices = Array.isArray(to) ? to : [];
      },
    },
    "results.records.referralAgents": {
      immediate: true,
      handler(to) {
        to === this.referralAgents || (this.expanded.referralAgents = {});
        this.referralAgents = Array.isArray(to) ? to : [];
      },
    },
  },
};
</script>

<style>
#search-results {
  display: flex;
  flex-wrap: wrap;
}

#search-results > .search-results {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: 100%;
}

#search-results > .search-results ~ .search-results,
#search-results > .search-results > * + * {
  margin-top: 1em;
}

#search-results > footer {
  align-items: center;
  display: flex;
  font-size: 1.11em;
  justify-content: flex-end;
  margin-top: 1em;
  width: 100%;
}
</style>