<template>
  <div class="picture-mask pick-archived-picture-modal">
      <div class="picture-body">
        <div class="picture-close">
          <span v-on:click="closeArchivePicModal">X</span>
        </div>
        <div class="picture-header">
          <h2>Pick picture from archived</h2>
        </div>
        <div class="picture-remaining">
          <h3>{{3 - photosCount - selectedPhotos.length}} selections remaining (Total Selectable: {{3 - photosCount}})</h3>
        </div>
        <div class="picture-message">
          <div class="section-picture-main">
            <div
              class="no-image-text"
              v-if="!allPhotos"
            >Please put any picture into archive first picture</div>
              <UploadedImage
                v-for="(image, index) in allPhotos"
                v-bind:key="index"
                v-bind:value="index"
                :image="image"
                :index="index"
                :id="image.id"
                @imageClick="onImageClick"
                :class="checkIfSelected(image.id) ? 'upload-selected' : null"
              >
              </UploadedImage>
          </div>
        </div>
        <div class="picture-footer">
          <button type="button" @click="selectArchivedPhotos">Add Image(s)</button>
        </div>
      </div>
  </div>
</template>

<script>
import { cleanUrl } from "../../func";
import { getAllPhotos } from "../../api/EquipmentAPI";
import UploadedImage from "../common/UploadedImage";

export default {
  data() {
    return {
      selectedPhotos: [],
      allPhotos: [],
    };
  },
  props: {
    photosCount: 0
  },
  methods: {
    closeArchivePicModal() {
      this.$emit("closeArchivePicModal");
    },
    onImageClick(id) {
        const index = this.allPhotos.map(photo => photo.id).indexOf(id);

        if (!this.selectedPhotos.includes(index) && this.selectedPhotos.length < 3 - this.photosCount) {
          this.selectedPhotos.push(index);
        } else {
          this.selectedPhotos = this.selectedPhotos.filter(e => e !== index);
        }
    },
    checkIfSelected(id) {
      const index = this.allPhotos.map(photo => photo.id).indexOf(id);

      return this.selectedPhotos.includes(index);
    },
    async selectArchivedPhotos() {
      this.$emit("archivedSelectedPhotos", await Promise.all(
        this.selectedPhotos.map(async index => {
          const photo = this.allPhotos[index];
          const file = new File([await(await fetch(cleanUrl(photo.url))).blob()], photo.filename, { type: photo.contentType });
          file.creationDate = photo.createdAt;
          file.userAdded = this.getUserName(photo.creator);
          return file;
        })
      ));

      this.selectedPhotos = [];
    },
  },
  async mounted() {
    this.allPhotos = await getAllPhotos();
  },
  components: {
    UploadedImage,
  },
};
</script>

<style>
.pick-archived-picture-modal .picture-body {
  width: 60%;
  left: 20%;
  top: 10%;
  grid-template-rows: repeat(5, auto);
  grid-template-areas: "close" "header" "remaining" "message" "footer";
}

.pick-archived-picture-modal .picture-close {
  grid-area: close;
}

.pick-archived-picture-modal .picture-header {
  grid-area: header;
}

.pick-archived-picture-modal .picture-remaining {
  grid-area: remaining;
  justify-self: center;
}

.pick-archived-picture-modal .picture-message {
  grid-area: message;
  justify-self: center;
}

.pick-archived-picture-modal .picture-footer {
  grid-area: footer;
  justify-self: end;
}

.pick-archived-picture-modal .picture-message {
  overflow: auto;
  max-height: 500px;
}

.upload-selected {
  background-color: var(--primary-red);
  opacity:0.6;
}
</style>
