<template>
  <section class="equipment-on-hold-selector">
    <content-header tag="h2" title="Select A Client" />

    <search-form :debounce="200" v-model="search" @change="goToFirstPage" />

    <table class="striped">
      <colgroup>
        <col width="20%" />
        <col width="30%" />
        <col width="20%" />
        <col width="30%" />
      </colgroup>
      <thead>
        <tr>
          <th>Client Number</th>
          <th>Client Name</th>
          <th>Date of Birth</th>
          <th>Client Agency Number</th>
        </tr>
      </thead>
      <tbody>
        <tr class="selected" v-if="selected && !paginatedClients.includes(selected)">
          <td>{{ selected.clientNumber }}</td>
          <td>{{ getFullName(selected) }}</td>
          <td>{{ formatTimestamp(selected.birthDate, { convertTimezone: false }) }}</td>
          <td>{{ selected.fcsNumber }}</td>
        </tr>
        <tr
          v-for="client in paginatedClients"
          :key="client.id"
          :class="(selected === client) ? 'selected' : 'selectable'"
          @click="handleSelect(client)"
        >
          <td>{{ client.clientNumber }}</td>
          <td>{{ getFullName(client) }}</td>
          <td>{{ formatTimestamp(client.birthDate, { convertTimezone: false }) }}</td>
          <td>{{ client.fcsNumber }}</td>
        </tr>
      </tbody>
      <tfoot v-if="hasPages">
        <tr>
          <td class="page-controls" colspan="4">
            <icon-button :disabled="!hasPrevPage" name="prev" @click="goToPrevPage" />
            <icon-button :disabled="!hasNextPage" name="next" @click="goToNextPage" />
          </td>
        </tr>
      </tfoot>
    </table>

    <footer>
      <button :disabled="!selectedId" type="button" @click.prevent="handleClear">Make Available</button>

      <button
        :disabled="!selected || selected.id === selectedId"
        type="button"
        @click.prevent="handleSave"
      >Place On Hold</button>

      <button type="button" @click.prevent="handleClose">Cancel</button>
    </footer>
  </section>
</template>

<script>
import { pagination } from "../../mixins/pagination";

import { getClientSummaries } from "../../api/ClientAPI";

export default {
  mixins: [pagination("filteredClients")],

  data() {
    return {
      _clients: [],
      search: "",
      selected: undefined,
    };
  },

  props: {
    clients: {
      type: Array,
      default: null,
    },
    selectedId: {
      type: [Number, String],
      default: null,
    },
  },

  computed: {
    filteredClients() {
      if (!/\S/.test(this.search)) {
        return this.$data._clients;
      }

      const regex = this.getFilterRegex(this.search);

      return this.$data._clients.filter((client) =>
        regex.test(
          [
            client?.clientNumber ?? "",
            this.getFullName(client),
            client?.medicareNumber ?? "",
            client?.fcsNumber ?? "",
            ...(client?.addresses
              ?.filter((a) => a?.mailing)
              .slice(0, 1)
              .map((a) =>
                [
                  a.address1 ?? "",
                  a.address2 ?? "",
                  a.city?.name ?? "",
                  a.province?.abbrevivation ?? "",
                  a.postalCode ?? "",
                ].join(0x1f)
              ) ?? []),
            ...(client?.phoneNumbers
              ?.filter((p) => p?.type.id === 1)
              .slice(0, 1)
              .map((p) => p.number ?? "") ?? []),
          ].join(0x1f)
        )
      );
    },
  },

  methods: {
    handleClear() {
      if (
        !this.selectedId ||
        confirm(
          "Are you sure you want to mark this piece of equipment as available?"
        )
      ) {
        this.$emit("save");
      }
    },
    handleClose() {
      this.$emit("close");
    },
    handleSave() {
      if (!this.selected) {
        return this.showAlert(
          "You must select a client to place the equipment on hold for."
        );
      }

      this.$emit("save", this.selected);
    },
    handleSelect(client) {
      this.selected = this.selected === client ? null : client;
    },
  },

  watch: {
    clients: {
      immediate: true,
      async handler(to) {
        this.$data._clients = Array.isArray(to) ? to : await getClientSummaries();

        this.selected = this.$data._clients.find(
          (client) => client.id === +this.selectedId
        );
      },
    },
    selectedId: {
      immediate: true,
      handler(to) {
        this.selected = this.$data._clients.find((c) => c.id === +to);
      },
    },
  },
};
</script>

<style>
.equipment-on-hold-selector {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "header search"
    "table table"
    "footer footer";
}

.equipment-on-hold-selector > h2 {
  color: var(--primary-red);
}

.equipment-on-hold-selector > table,
.equipment-on-hold-selector > footer {
  grid-column: span 2;
}
</style>