import Vue from 'vue';
import VueRouter from 'vue-router';

import '../styles/global.css';
import { routeSilent } from '../func';
import store from '../store';

import LoginView from '../views/login/LoginView';
import Login from '../views/login/pages/Login';
import ForgotEmail from '../views/login/pages/ForgotEmail';
import ForgotPassword from '../views/login/pages/ForgotPassword';
import ResetPassword from '../views/login/pages/ResetPassword';

import HomeView from '../views/home/HomeView';
import Clients from '../views/home/pages/Clients';
import Search from '../views/home/pages/Search';
import Equipment from '../views/home/pages/Equipment';
import Reports from '../views/home/pages/Reports';
import IndirectServices from '../views/home/pages/IndirectServices';
import BulkInvoicing from '../views/home/pages/BulkInvoicing';

import AdminView from '../views/admin/AdminView';
import AdminTools from '../views/admin/pages/Tools';
import AdminUsers from '../views/admin/UsersView';
import AdminEquipmentView from '../views/admin/EquipmentView';
import AdminEquipmentCategories from '../views/admin/EquipmentCategoriesView';
import AdminManufacturers from '../views/admin/ManufacturersView';
import AdminEquipmentSpecifications from '../views/admin/EquipmentSpecificationsView';
import AdminEquipmentStatuses from '../views/admin/EquipmentStatusesView.vue';
import AdminFacilities from '../views/admin/FacilitiesView';
import AdminPrograms from '../views/admin/ProgramsView';
import AdminBillingCodes from '../views/admin/pages/BillingCodes';
import AdminTemplates from '../views/admin/pages/Templates';
import AdminLoansServicesView from '../views/admin/LoansServicesView';
import AdminLoans from '../views/admin/LoansView';
import AdminServiceCategories from '../views/admin/ServiceCategoriesView';
import AdminReferralAgents from '../views/admin/pages/ReferralAgents';
import AdminHolidays from '../views/admin/pages/Holidays';

Vue.use(VueRouter);

const can = (...args) =>
  store.getters['user/can'](...args);

const hasAdmin = (...args) =>
  store.getters['user/hasAdmin'](...args);

const routes = [
  {
    path: '/login',
    component: LoginView,
    children: [
      {
        name: 'Login',
        path: '/login',
        component: Login,
        meta: {
          requiresAuth: false,
        },
        beforeEnter: (_to, _from, next) => {
          store.dispatch('logout');
          next();
        },
      },
      {
        name: 'Forgot Email',
        path: '/forgot-email',
        component: ForgotEmail,
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: 'Forgot Password',
        path: '/forgot-password',
        component: ForgotPassword,
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: 'Reset Password',
        path: '/reset-password/:token?',
        component: ResetPassword,
        props: route => ({
          token: route.params.token,
          withToken: !!route.params.token || !store.state.api.token,
        }),
        meta: {
          requiresAuth: false,
        },
        beforeEnter: (to, _from, next) => {
          (!!to.params.token || !store.state.api.token) && store.commit('api/setPasswordExpired', true, { root: true });

          if (!to.params.withToken && !store.state.api.passwordExpired) {
            next(false);
          } else {
            next();
          }
        },
      },
      {
        name: 'Logout',
        path: '/logout',
        meta: {
          requiresAuth: false,
        },
        beforeEnter: async (_to, _from, next) => {
          await store.dispatch('logout');
          next({ name: 'Login', params: { skipDirtyCheck: true } });
        },
      },
    ]
  },

  {
    path: '/',
    component: HomeView,
    beforeEnter: (_to, _from, next) => {
      store.commit('setModal');
      next();
    },
    children: [
      {
        path: '/',
        redirect: {
          name: 'Home',
        },
      },
      {
        name: 'Home',
        path: '/home',
        component: Search,
        props: route => ({
          phrase: route.query.q,
          type: route.query.t,
        }),
        beforeEnter: (_to, from, next) => {
          if (!can({ permissions: ['READ_CLIENTS', 'READ_EQUIPMENT', 'READ_USERS', 'READ_INVOICES'], matchAny: true })) {
            if (!can({ permissions: ['READ_AVAILABLE_EQUIPMENT', 'READ_PROGRAM_EQUIPMENT', 'READ_AVAILABLE_PROGRAM_EQUIPMENT'], matchAny: true })) {
              next(from.name ? false : { name: 'Logout' });
            } else {
              next({ name: 'Equipment' });
            }
          } else {
            next();
          }
        }
      },
      {
        name: 'Clients',
        path: '/clients/:id?',
        component: Clients,
        props: route => ({
          id: /^new$/i.test(route.params.id ?? '') ? 'new' : (+route.params.id || undefined),
        }),
        beforeEnter: (to, from, next) => {
          if (!can('READ_CLIENTS')) {
            next(from.name ? false : { name: 'Home', replace: true });
          } else if (to.params.id === 'new' && !can('CREATE_CLIENTS')) {
            next({ ...to, params: { ...to.params, id: undefined } });
          } else {
            next();
          }
        },
      },
      {
        name: 'Equipment',
        path: '/equipment/:id?',
        component: Equipment,
        props: route => ({
          id: /^new$/i.test(route.params.id ?? '') ? 'new' : (+route.params.id || undefined),
        }),
        beforeEnter: (to, from, next) => {
          if (!can({ permissions: ['READ_EQUIPMENT', 'READ_AVAILABLE_EQUIPMENT', 'READ_PROGRAM_EQUIPMENT', 'READ_AVAILABLE_PROGRAM_EQUIPMENT'], matchAny: true })) {
            next(from.name ? false : { name: 'Home', replace: true });
          } else if (to.params.id === 'new' && !can('CREATE_EQUIPMENT')) {
            next({ ...to, params: { ...to.params, id: undefined } });
          } else {
            next();
          }
        },
      },
      {
        name: 'Reports',
        path: '/reports',
        component: Reports,
        beforeEnter: (to, from, next) => {
          if (!can('READ_REPORTS')) {
            next(from.name ? false : { name: 'Home', replace: true });
          } else {
            next();
          }
        },
      },
      {
        name: 'Indirect Services',
        path: '/indirect-services/:id?',
        component: IndirectServices,
        props: route => ({
          id: +route.params.id || undefined,
        }),
        beforeEnter: (to, from, next) => {
          // This ensures the content doesn't change behind the modal
          next(from.name ? false : { name: 'Home', replace: true });

          // This ensures the page is displayed as a modal
          if (can('READ_INDIRECT_SERVICES')) {
            to.params.id = +to.params.id || undefined;
            store.commit('setModal', to);
            routeSilent(to.path);
          }
        },
      },
      {
        name: 'Bulk Invoicing',
        path: '/bulk-invoicing',
        component: BulkInvoicing,

        beforeEnter: (to, from, next) => {
          // This ensures the content doesn't change behind the modal
          next(from.name ? false : { name: 'Home', replace: true });
          // This ensures the page is displayed as a modal
          if (can('READ_INVOICES')) {
            to.params.id = +to.params.id || undefined;
            store.commit('setModal', to);
            routeSilent(to.path);
          }
        },
      },
      {
        path: '/tools',
        component: AdminView,
        children: [
          {
            name: 'Admin',
            path: '/tools',
            component: AdminTools,
            meta: {
              full: true,
            },
            beforeEnter: (_to, from, next) => {
              if (
                !hasAdmin('USERS') &&
                !hasAdmin('ORGANIZATIONS') &&
                !hasAdmin('EQUIPMENT_CATEGORIES') &&
                !hasAdmin('EQUIPMENT_SUBCATEGORIES') &&
                !hasAdmin('MANUFACTURERS') &&
                !hasAdmin('VENDORS') &&
                !hasAdmin('EQUIPMENT_ITEMS') &&
                !hasAdmin('EQUIPMENT_PARTS') &&
                !hasAdmin('EQUIPMENT_ACCESORIES') &&
                !hasAdmin('EQUIPMENT_STATUSES') &&
                !hasAdmin('FACILITIES') &&
                !hasAdmin('INSURANCE_PROVIDERS') &&
                !hasAdmin('PROGRAMS') &&
                !hasAdmin('PROJECTS') &&
                !hasAdmin('EQUIPMENT_TYPES') &&
                !hasAdmin('TEMPLATES') &&
                !hasAdmin('BILLING_CODES') && 
                !hasAdmin("LOAN_TYPES") &&
                !hasAdmin("SERVICE_CATEGORIES") &&
                !hasAdmin("SERVICE_SUBCATEORIES") &&
                !hasAdmin("REFERRAL_AGENTS") &&
                !hasAdmin("HOLIDAYS")
              ) {
                next(from.name ? false : { name: 'Home', replace: true });
              } else {
                next();
              }
            }
          },
          {
            name: 'Admin: Users View',
            path: '/tools/:view(users|organizations)/:id?',
            component: AdminUsers,
            props: route => ({
              view: route.params.view,
              id: +route.params.id || undefined,
            }),
            meta: {
              full: true,
            },
            beforeEnter: (to, from, next) => {
              if (!hasAdmin('USERS') && !hasAdmin('ORGANIZATIONS')) {
                next(from.name ? false : { name: 'Admin', replace: true });
              } else if (to.params.view === 'users' && !hasAdmin('USERS')) {
                next(from.name ? false : { ...to, params: { view: 'organizations' }, replace: true });
              } else if (to.params.view === 'organizations' && !hasAdmin('ORGANIZATIONS')) {
                next(from.name ? false : { ...to, params: { view: 'users' }, replace: true });
              } else {
                next();
              }
            },
          },
          {
            name: 'Admin: Equipment',
            path: '/tools/equipment',
            component: AdminEquipmentView,
            redirect: () => {
              if (hasAdmin('EQUIPMENT_CATEGORIES') || hasAdmin('EQUIPMENT_SUBCATEGORIES') || hasAdmin('EQUIPMENT_ITEMS')) {
                return { name: 'Admin: Equipment Categories' };
              } else if (hasAdmin('MANUFACTURERS') || hasAdmin('VENDORS')) {
                return { name: 'Admin: Equipment Manufacturers' };
              } else if (hasAdmin('EQUIPMENT_PARTS') || hasAdmin('EQUIPMENT_ACCESSORIES')) {
                return { name: 'Admin: Equipment Specifications' };
              } else if (hasAdmin('EQUIPMENT_STATUSES')) {
                return { name: 'Admin: Equipment Statuses' };
              } else {
                return { name: 'Admin' };
              }
            },
            children: [
              {
                name: 'Admin: Categories View',
                path: '/tools/equipment/:view(categories|subcategories|items)/:id?',
                component: AdminEquipmentCategories,
                props: route => ({
                  view: route.params.view,
                  id: +route.params.id || undefined,
                }),
                meta: {
                  full: true,
                },
                beforeEnter: (to, from, next) => {
                  if (!hasAdmin('EQUIPMENT_CATEGORIES') && !hasAdmin('EQUIPMENT_SUBCATEGORIES') && !hasAdmin('EQUIPMENT_ITEMS')) {
                    next(from.name ? false : { name: 'Admin', replace: true });
                  } else if (to.params.view === 'categories' && !hasAdmin('EQUIPMENT_CATEGORIES')) {
                    next(from.name ? false : { ...to, params: { view: hasAdmin('EQUIPMENT_SUBCATEGORIES') ? 'subcategories' : 'items' }, replace: true });
                  } else if (to.params.view === 'subcategories' && !hasAdmin('EQUIPMENT_SUBCATEGORIES')) {
                    next(from.name ? false : { ...to, params: { view: hasAdmin('EQUIPMENT_CATEGORIES') ? 'categories' : 'items' }, replace: true });
                  } else if (to.params.view === 'items' && !hasAdmin('EQUIPMENT_ITEMS')) {
                    next(from.name ? false : { ...to, params: { view: hasAdmin('EQUIPMENT_CATEGORIES') ? 'categories' : 'subcategories' }, replace: true });
                  } else {
                    next();
                  }
                },
              },
              {
                name: 'Admin: Manufacturers View',
                path: '/tools/equipment/:view(manufacturers|suppliers)/:id?',
                component: AdminManufacturers,
                props: route => ({
                  view: route.params.view,
                  id: +route.params.id || undefined,
                }),
                meta: {
                  grid: false,
                  search: false,
                },
                beforeEnter: (to, from, next) => {
                  if (!hasAdmin('MANUFACTURERS') && !hasAdmin('VENDORS')) {
                    next(from.name ? false : { name: 'Admin', replace: true });
                  } else if (to.params.view === 'manufacturers' && !hasAdmin('MANUFACTURERS')) {
                    next(from.name ? false : { ...to, params: { view: 'suppliers' }, replace: true });
                  } else if (to.params.view === 'suppliers' && !hasAdmin('VENDORS')) {
                    next(from.name ? false : { ...to, params: { view: 'manufacturers' }, replace: true });
                  } else {
                    next();
                  }
                },
              },
              {
                name: 'Admin: Equipment Specifications View',
                path: '/tools/equipment/:view(specifications|accessories)/:id?',
                component: AdminEquipmentSpecifications,
                props: route => ({
                  view: route.params.view,
                  id: +route.params.id || undefined,
                }),
                meta: {
                  full: true,
                },
                beforeEnter: (to, from, next) => {
                  if (!hasAdmin('EQUIPMENT_PARTS') && !hasAdmin('EQUIPMENT_ACCESSORIES')) {
                    next(from.name ? false : { name: 'Admin', replace: true });
                  } else if (to.params.view === 'specifications' && !hasAdmin('EQUIPMENT_PARTS')) {
                    next(from.name ? false : { ...to, params: { view: 'accessories' }, replace: true });
                  } else if (to.params.view === 'accessories' && !hasAdmin('EQUIPMENT_ACCESSORIES')) {
                    next(from.name ? false : { ...to, params: { view: 'specifications' }, replace: true });
                  } else {
                    next();
                  }
                },
              },
              {
                name: 'Admin: Equipment Statuses View',
                path: '/tools/equipment/:view(statuses)/:id?',
                component: AdminEquipmentStatuses,
                props: route => ({
                  view: route.params.view,
                  id: +route.params.id || undefined,
                }),
                meta: {
                  full: true,
                },
                beforeEnter: (to, from, next) => {
                if (!hasAdmin('EQUIPMENT_STATUSES')){
                    next(from.name ? false : { name: 'Admin', replace: true });
                  } else {
                    next();
                  }
                }
              }
            ]
          },
          {
            name: 'Admin: Facilities View',
            path: '/tools/:view(facilities|insurance-providers)/:id?',
            component: AdminFacilities,
            props: route => ({
              view: route.params.view,
              id: +route.params.id || undefined,
            }),
            beforeEnter: (to, from, next) => {
              if (!hasAdmin('FACILITIES') && !hasAdmin('INSURANCE_PROVIDERS')) {
                next(from.name ? false : { name: 'Admin', replace: true });
              } else if (to.params.view === 'facilities' && !hasAdmin('FACILITIES')) {
                next(from.name ? false : { ...to, params: { view: 'insurance-providers' }, replace: true });
              } else if (to.params.view === 'insurance-providers' && !hasAdmin('INSURANCE_PROVIDERS')) {
                next(from.name ? false : { ...to, params: { view: 'facilities' }, replace: true });
              } else {
                next();
              }
            },
          },
          {
            name: 'Admin: Programs View',
            path: '/tools/:slug(equipment)?/:view(programs|projects|types)/:id?',
            component: AdminPrograms,
            props: route => ({
              slug: route.params.slug,
              view: route.params.view,
              id: +route.params.id || undefined,
            }),
            meta: {
              full: true,
            },
            beforeEnter: (to, from, next) => {
              // This will ensure that the paths are always: /tools/programs, /tools/projects, or /tools/equipment/types
              if (to.params.view === 'types' && to.params.slug !== 'equipment') {
                next({ ...to, params: { ...to.params, slug: 'equipment' } });
              } else if (to.params.view !== 'types' && to.params.slug) {
                next({ ...to, params: { ...to.params, slug: undefined } });
              }

              if (!hasAdmin('PROGRAMS') && !hasAdmin('PROJECTS') && !hasAdmin('EQUIPMENT_TYPES')) {
                next(from.name ? false : { name: 'Admin', replace: true });
              } else if (to.params.view === 'programs' && !hasAdmin('PROGRAMS')) {
                next(from.name ? false : { ...to, params: { view: hasAdmin('PROJECTS') ? 'projects' : 'types' }, replace: true });
              } else if (to.params.view === 'projects' && !hasAdmin('PROJECTS')) {
                next(from.name ? false : { ...to, params: { view: hasAdmin('PROGRAMS') ? 'programs' : 'types' }, replace: true });
              } else if (to.params.view === 'types' && !hasAdmin('EQUIPMENT_TYPES')) {
                next(from.name ? false : { ...to, params: { view: hasAdmin('PROGRAMS') ? 'programs' : 'projects' }, replace: true });
              } else {
                next();
              }
            },
          },
          {
            name: 'Admin: Templates',
            path: '/tools/templates/:id?',
            component: AdminTemplates,
            props: route => ({
              id: /^new$/i.test(route.params.id ?? '') ? 'new' : (+route.params.id || undefined),
            }),              
            beforeEnter: (to, from, next) => {
              if (!hasAdmin('TEMPLATES')) {
                next(from.name ? false : { name: 'Admin', replace: true });
              } else if (to.params.id === 'new' && !can('CREATE_TEMPLATES')) {
                next({ ...to, params: { ...to.params, id: undefined } });
              } else {
                next();
              }
            },
          },
          {
            name: 'Admin: Billing Codes',
            path: '/tools/billing-codes/:id?',
            component: AdminBillingCodes,
            props: route => ({
              id: +route.params.id || undefined,
            }),
            beforeEnter: (_to, from, next) => {
              if (!hasAdmin('BILLING_CODES')) {
                next(from.name ? false : { name: 'Admin', replace: true });
              } else {
                next();
              }
            },
          },
          {
            name: 'Admin: Loans & Services',
            path: '/tools/loans',
            alias: '/tools/services',
            component: AdminLoansServicesView,
            redirect: () => {
              if (hasAdmin('LOAN_TYPES')) {
                return { name: 'Admin: Loan Types' };
              } else if (hasAdmin('SERVICE_CATEGORIES')) {
                return { name: 'Admin: Service Categories' };
              } else if (hasAdmin('SERVICE_SUBCATEGORIES')) {
                return { name: 'Admin: Service Subcategories' };
              } else {
                return { name: 'Admin' };
              }
            },
            children: [
              {
                name: 'Admin: Loans View',
                path: '/tools/loans/:view(types)/:id?',
                component: AdminLoans,
                props: route => ({
                  view: route.params.view,
                  id: +route.params.id || undefined,
                }),
                meta: {
                  grid: false,
                  search: false,
                },
                beforeEnter: (to, from, next) => {
                  if (!hasAdmin('LOAN_TYPES')) {
                    next(from.name ? false : { name: 'Admin', replace: true });
                  } else {
                    next();
                  }
                },
              },
              {
                name: 'Admin: Services View',
                path: '/tools/services/:view(categories|subcategories)/:id?',
                component: AdminServiceCategories,
                props: route => ({
                  view: route.params.view,
                  id: +route.params.id || undefined,
                }),
                meta: {
                  grid: false,
                  search: false,
                },
                beforeEnter: (to, from, next) => {
                  if (!hasAdmin('SERVICE_CATEGORIES') && !hasAdmin('SERVICE_SUBCATEGORIES')) {
                    next(from.name ? false : { name: 'Admin', replace: true });
                  } else if (to.params.view === 'categories' && !hasAdmin('SERVICE_CATEGORIES')) {
                    next(from.name ? false : { ...to, params: { view: 'subcategories' }, replace: true });
                  } else if (to.params.view === 'subcategories' && !hasAdmin('SERVICE_SUBCATEGORIES')) {
                    next(from.name ? false : { ...to, params: { view: 'categories' }, replace: true });
                  } else {
                    next();
                  }
                },
              },
            ],
          },
          {
            name: 'Admin: Referral Agents',
            path: '/tools/referral-agents/:id?',
            component: AdminReferralAgents,
            props: route => ({
              id: +route.params.id || undefined,
            }),
            beforeEnter: (_to, from, next) => {
              if (!hasAdmin('REFERRAL_AGENTS')) {
                next(from.name ? false : { name: 'Admin', replace: true });
              } else {
                next();
              }
            },
          },
          {
            name: 'Admin: Holidays',
            path: '/tools/holidays/:id?',
            component: AdminHolidays,
            props: route => ({
              id: +route.params.id || undefined,
            }),
            beforeEnter: (_to, from, next) => {
              if (!hasAdmin('HOLIDAYS')) {
                next(from.name ? false : { name: 'Admin', replace: true });
              } else {
                next();
              }
            },
          },
          // REDIRECTS
          {
            name: 'Admin: Users',
            path: '/tools/users/:id?',
            redirect: '/tools/users/:id?'
          },
          {
            name: 'Admin: Organizations',
            path: '/tools/organizations/:id?',
            redirect: '/tools/organizations/:id?'
          },
          {
            name: 'Admin: Equipment Categories',
            path: '/tools/equipment/categories/:id?',
            redirect: '/tools/equipment/categories/:id?'
          },
          {
            name: 'Admin: Equipment Subcategories',
            path: '/tools/equipment/subcategories/:id?',
            redirect: '/tools/equipment/subcategories/:id?'
          },
          {
            name: 'Admin: Equipment Items',
            path: '/tools/equipment/items/:id?',
            redirect: '/tools/equipment/items/:id?'
          },
          {
            name: 'Admin: Equipment Manufacturers',
            path: '/tools/equipment/manufacturers/:id?',
            redirect: '/tools/equipment/manufacturers/:id?'
          },
          {
            name: 'Admin: Equipment Vendors',
            path: '/tools/equipment/suppliers/:id?',
            redirect: '/tools/equipment/suppliers/:id?'
          },
          {
            name: 'Admin: Equipment Specifications',
            path: '/tools/equipment/specifications/:id?',
            redirect: '/tools/equipment/specifications/:id?'
          },
          {
            name: 'Admin: Equipment Statuses',
            path: '/tools/equipment/statuses/:id?',
            redirect: '/tools/equipment/statuses/:id?'
          },
          {
            name: 'Admin: Wheelchair Accessories',
            path: '/tools/equipment/accessories/:id?',
            redirect: '/tools/equipment/accessories/:id?'
          },
          {
            name: 'Admin: Facilities',
            path: '/tools/facilities/:id?',
            redirect: '/tools/facilities/:id?'
          },
          {
            name: 'Admin: Insurance Providers',
            path: '/tools/insurance-providers/:id?',
            redirect: '/tools/insurance-providers/:id?'
          },
          {
            name: 'Admin: Programs',
            path: '/tools/programs/:id?',
            redirect: '/tools/programs/:id?'
          },
          {
            name: 'Admin: Projects',
            path: '/tools/projects/:id?',
            redirect: '/tools/projects/:id?'
          },
          {
            name: 'Admin: Equipment Types',
            path: '/tools/equipment/types/:id?',
            redirect: '/tools/equipment/types/:id?'
          },
          {
            name: 'Admin: Loan Types',
            path: '/tools/loans/types/:id?',
            redirect: '/tools/loans/types/:id?'
          },
          {
            name: 'Admin: Service Categories',
            path: '/tools/services/categories/:id?',
            redirect: '/tools/services/categories/:id?'
          },
          {
            name: 'Admin: Service Subcategories',
            path: '/tools/services/subcategories/:id?',
            redirect: '/tools/services/subcategories/:id?'
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.disabled) {
    next(false);
  } else if (to.name === 'Logout') {
    next();
  } else if (store.state.api.passwordExpired && to.name !== 'Reset Password') {
    const _next = from.query.next ?? to.query.next ?? to.fullPath;
    next({ name: 'Reset Password', query: { next: !_next.match(/^\/(home|login|logout)?$/) ? _next : undefined } });
  } else if (!store.state.api.token && to.meta.requiresAuth !== false && to.name !== 'Login') {
    const _next = from.query.next ?? to.query.next ?? to.fullPath;
    next({ name: 'Login', query: { next: !_next.match(/^\/(home|login|logout)?$/) ? _next : undefined } });
  } else {
    next();
  }
});

export default router;