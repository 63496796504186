import { del, get, post, put } from './http';

const API_PATH = '/api/indirect-services';

export const createIndirectService = async indirectService =>
    await post(API_PATH, indirectService);

export const deleteIndirectService = async indirectService =>
    await del(`${API_PATH}/${indirectService.id}`);

export const getIndirectServices = async () =>
    await get(API_PATH);

export const saveIndirectService = async indirectService =>
    await (indirectService.id ? updateIndirectService(indirectService) : createIndirectService(indirectService));

export const updateIndirectService = async indirectService =>
    await put(`${API_PATH}/${indirectService.id}`, indirectService);