<template>
  <table class="striped admin-loan-types-table">
    <caption v-html="caption" v-if="caption" />
    <thead>
      <tr>
        <th>Loan Type</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="loanType in paginatedLoanTypes"
        :key="loanType.id"
        :class="selectedId === loanType.id ? 'selected' : 'selectable'"
        @click="handleSelect(loanType)"
      >
        <td>{{ loanType.name }}</td>
      </tr>
      <tr v-if="loanTypes.length === 0">
        <td class="no-records">
          There are currently no loan types in the system.
        </td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls">
          <icon-button
            :disabled="!hasPrevPage"
            name="prev"
            @click="goToPrevPage"
          />

          <icon-button
            :disabled="!hasNextPage"
            name="next"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("loanTypes")],

  props: {
    caption: {
      type: String,
      default: undefined,
    },
    loanTypes: {
      type: Array,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
  },

  methods: {
    handleSelect(project) {
      this.$emit("select", project);
    },
  },
};
</script>