<template>
  <ContentWrapper
    :create-icon="can('CREATE_BILLING_CODES') ? 'add-billing-code' : null"
    :header-tag="headerTag"
    search-input-id="admin-billing-code-search-phrase"
    :show-back="showBack"
    title="Billing Codes"
    @create="handleCreate"
    @search="handleSearch"
  >
    <BillingCodesTable
      class="admin-table"
      :billing-codes="filteredBillingCodes"
      :selected-id="billingCode.id"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="billingCode.id ? `Edit - ${billingCode.code}` : 'Create Billing Code'"
      />

      <span class="admin-form-tab">
        <img alt="Billing Codes" src="../../../assets/icons/billing-code.svg" />
      </span>

      <BillingCodesForm
        class="admin-form"
        :disabled="billingCode.code === undefined"
        :billing-code="billingCode"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import BillingCodesTable from "../../../components/admin/BillingCodesTable";
import BillingCodesForm from "../../../components/admin/BillingCodesForm";

import { getBillingCodes } from "../../../api/BillingCodeAPI";

export default {
  data() {
    return {
      billingCode: {},
      _billingCodes: [],
      search: "",
    };
  },

  props: {
    billingCodes: {
      type: Array,
      default: undefined,
    },
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    filteredBillingCodes() {
      const regex = this.getFilterRegex(this.search);

      return this.$data._billingCodes.filter((c) =>
        regex.test(
          [
            c?.code ?? "",
            c?.description ?? "",
            c?.rate ?? "",
            c?.accountNumber ?? "",
          ].join(String.fromCharCode(0x1f))
        )
      );
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.billingCode = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.billingCode = {
          code: "",
          description: "",
          rate: "",
          accountNumber: "",
        };
      }
    },
    handleDelete(billingCode) {
      this.$data._billingCodes = this.$data._billingCodes.filter(
        (c) => c?.id !== billingCode?.id
      );

      this.billingCode = {};
    },
    handleSave(billingCode) {
      const created = !this.billingCode.id;

      this.$data._billingCodes = [
        ...this.$data._billingCodes.filter((c) => c?.id !== billingCode?.id),
        billingCode,
      ];

      this.handleSelect(created ? billingCode : undefined, true);

      this.showAlert(
        `Your billing code has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(billingCode, force = false) {
      if (force || this.confirmIfDirty()) {
        this.billingCode = {
          ...this.$data._billingCodes.find((c) => c?.id === billingCode?.id),
        };
      }
    },
  },

  watch: {
    "billingCode.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    async billingCodes(to, from) {
      to === this.$data._billingCodes ||
        (this.$data._billingCodes = to?.slice?.() ?? (await getBillingCodes()));
    },
    "$data._billingCodes"(to, from) {
      if (to !== from) {
        this.$data._billingCodes = to;
        this.$emit("update:billing-codes", this.$data._billingCodes);
      }
    },
  },

  async created() {
    this.$data._billingCodes =
      this.billingCodes?.slice?.() ?? (await getBillingCodes());

    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    BillingCodesTable,
    BillingCodesForm,
  },
};
</script>