<template>
  <input
    :pattern="pattern"
    placeholder="$0.00"
    type="text"
    :value="formattedValue"
    v-on="listeners"
    @blur="handleBlur"
    @input="handleInput"
    @invalid="handleInvalid"
  />
</template>

<script>
export default {
  data() {
    return {
      timeout: undefined
    };
  },

  props: {
    debounce: {
      type: Number,
      default: 400
    },
    pattern: {
      type: String,
      default: "^(-?\\$|\\$-?)?\\d+(\\.\\d{0,2})?$"
    },
    value: {
      type: [Number, String],
      default: ""
    }
  },

  computed: {
    formattedValue() {
      return /\d/.test(this.value)
        ? this.formatCurrency(this.value)
        : this.value;
    },
    listeners() {
      const listeners = { ...this.$listeners };
      delete listeners.blur;
      delete listeners.input;
      delete listeners.invalid;
      return listeners;
    }
  },

  methods: {
    handleBlur(event) {
      this.timeout && clearTimeout(this.timeout);
      this.$emit("input", this.sanitizeValue(event.target.value));
      this.$emit("blur", event);
    },
    handleInput(event) {
      event.target.setCustomValidity("");

      this.timeout && clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () => this.$emit("input", this.sanitizeValue(event.target.value)),
        Math.max(0, this.debounce)
      );
    },
    handleInvalid(event) {
      if (!event.target.validity.valid) {
        event.target.setCustomValidity("Please enter a valid currency amount");
      } else {
        event.target.setCustomValidity("");
      }

      this.$emit("invalid", event);
    },
    sanitizeValue(value) {
      return value
        .replace(/[^\d\.-]+/g, "")
        .replace(/(.)-.*/g, "$1")
        .replace(/(\.\d{0,2}).*/g, "$1");
    }
  }
};
</script>

<style>
.currency-input {
  display: inline-flex;
  width: auto;
}

.currency-label {
  align-items: center;
  background: var(--primary-light-gray);
  border-color: var(--primary-dark-gray);
  border-style: solid;
  border-width: 1px 0 1px 1px;
  color: var(--primary-dark-gray);
  display: flex;
  font-style: normal;
  justify-content: center;
  padding: 0 0.43em 0 0.33em;
}
</style>