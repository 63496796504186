<template>
  <SearchCard
    class="invoice-card"
    :expanded="expanded"
    @update:expanded="$emit('update:expanded', arguments[0])"
  >
    <template slot="tab-icon">
      <img src="../../assets/icons/invoice.svg" />
    </template>

    <template slot="actions">
      <router-link
        :to="{ name: 'Admin: Users', params: { id: user.id } }"
        v-if="false"
      >
        <icon-button name="edit" />
      </router-link>

      <icon-button
        name="archive"
        @click="handleArchive"
        v-if="can('DELETE_INVOICES')"
      />
    </template>

    <template slot="info">
      <label>
        Invoice Number
        <strong>{{ getInvoiceNumber(invoice) || "N/A" }}</strong>
      </label>

      <label>
        Client
        <strong>{{ getFullName(invoice.client) || "N/A" }}</strong>
      </label>

      <label>
        Program
        <strong>{{ (invoice.program || {}).name || "N/A" }}</strong>
      </label>

      <label>
        Amount
        <strong>{{
          invoice.numberOfItems > 0 ? formatCurrency(invoice.total) : "N/A"
        }}</strong>
      </label>

      <label>
        Invoice Date
        <strong>{{ formatTimestamp(invoice.createdAt) }}</strong>
      </label>
    </template>

    <template slot="expanded-info">
      <label>
        Status
        <strong>{{
          invoice.approvalNumber || !(invoice.program || {}).invoiceRequiresApproval
            ? "Approved"
            : "Awaiting Approval"
        }}</strong>
      </label>

      <label>
        Approval Number
        <strong>{{ invoice.approvalNumber || "N/A" }}</strong>
      </label>

      <label>
        Number of Line Items
        <strong>{{ invoice.numberOfItems }}</strong>
      </label>
    </template>
  </SearchCard>
</template>

<script>
import SearchCard from "../search/SearchCard";
import { deleteInvoice } from "../../api/InvoiceAPI";

export default {
  components: {
    SearchCard,
  },

  props: {
    expanded: {
      type: Boolean,
      default: null,
    },
    invoice: {
      type: Object,
    },
  },

  methods: {
    async handleArchive() {
      if (!confirm("Are you sure you want to archive this invoice?")) {
        return;
      }

      try {
        await deleteInvoice(this.invoice);
        this.$emit("delete", this.invoice);
        this.showAlert("The invoice has been successfully archived!");
      } catch (e) {
        this.showErrors(e);
      }
    },
  },
};
</script>

<style>
.invoice-card .search-card-inner .search-info {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
</style>