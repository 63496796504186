import { del, get, post, put } from './http';

const API_PATH = '/api/referral-agents';

export const createReferralAgentUser = async (referralAgent, user) =>
    await put(`${API_PATH}/${referralAgent.id}/user`, {
        url: new URL(process.env.BASE_URL, window.location.origin),
        ...user,
    });

export const createReferralAgent = async referralAgent =>
    await post(API_PATH, { url: new URL(process.env.BASE_URL, window.location.origin), ...referralAgent })

export const deleteReferralAgent = async referralAgent =>
    await del(`${API_PATH}/${referralAgent.id}`);

export const getReferralAgent = async id =>
    await get(`${API_PATH}/${id}`);

export const getReferralAgents = async () =>
    await get(API_PATH);

export const saveReferralAgent = async referralAgent =>
    await (referralAgent.id ? updateReferralAgent(referralAgent) : createReferralAgent(referralAgent));

export const updateReferralAgent = async referralAgent =>
    await put(`${API_PATH}/${referralAgent.id}`, referralAgent);