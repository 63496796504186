<template>
  <section class="search-card">
    <section :class="['tab-left', isExpanded && 'tab-left-active', 'search-card-tab']">
      <slot name="tab-icon">
        <img src="../../assets/icons/search.svg" />
      </slot>
    </section>

    <section class="search-card-inner">
      <aside class="search-actions">
        <slot name="actions" />

        <icon-button
          class="search-card-expand-collapse"
          :name="isExpanded ? 'collapse' : 'expand'"
          @click="handleExpandCollapse"
          v-if="canExpand"
        />
      </aside>

      <section class="search-info">
        <slot name="info" />
        <slot name="expanded-info" v-if="isExpanded" />
      </section>
    </section>
  </section>
</template>

<script>
export default {
  data() {
    return {
      _expanded: this.expanded,
    };
  },

  props: {
    expanded: {
      type: Boolean,
    },
  },

  computed: {
    canExpand() {
      return this.$slots["expanded-info"]?.length > 0;
    },
    isExpanded() {
      return this.expanded ?? this.$data._expanded;
    },
  },

  methods: {
    handleExpandCollapse() {
      this.$data._expanded = !this.$data._expanded;
      this.$emit("update:expanded", this.$data._expanded);
    },
  },

  watch: {
    expanded(to, from) {
      this.$data._expanded = to ?? false;
    },
  },
};
</script>

<style>
.search-card {
  align-self: flex-end;
  display: flex;
  padding-left: 2em;
  width: 100%;
}

.search-card .search-card-tab {
  background-image: radial-gradient(
    circle at 100% 4em,
    var(--tab-color) 3.9em,
    transparent 4em
  );
  height: 8em;
  padding-right: 0.7em;
  width: 4em;
}

.search-card .search-card-tab img {
  width: 2em;
}

.search-card .search-card-tab:not(.tab-left-active) img {
  filter: var(--filter-primary-black);
}

.search-card .search-card-inner {
  border-color: var(--primary-light-gray);
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  justify-content: stretch;
  padding: 1em;
}

.search-card .search-card-inner .search-actions {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 1em;
  width: auto;
}

.search-card .search-card-inner .search-actions .icon-button {
  font-size: 0.75em;
}

.search-card .search-card-inner .search-actions .icon-button:not(:first-child) {
  margin-top: 0.33em;
}

.search-card
  .search-card-inner
  .search-actions
  .icon-button.search-card-expand-collapse {
  margin-top: auto;
}

.search-card .search-card-inner .search-info {
  flex: 1;
  display: grid;
  gap: 1em 0.1em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.search-card .search-card-inner .search-info label {
  display: flex;
  flex-direction: column;
  font-size: 0.95em;
}
</style>