<template>
  <form
    autocomplete="off"
    class="admin-users-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">First Name</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-user-first-name"
          required
          type="text"
          v-model.trim="firstName"
        />
      </label>

      <label>
        <span class="required">Last Name</span>

        <input
          :disabled="disabled"
          id="admin-user-last-name"
          required
          type="text"
          v-model.trim="lastName"
        />
      </label>

      <label>
        <span>Phone Number</span>

        <phone-number-input
          :disabled="disabled"
          id="admin-user-home-phone-number"
          v-model="phoneNumber.number"
        />
      </label>

      <label>
        <span>Extension</span>

        <input
          :disabled="disabled"
          id="admin-user-home-phone-extension"
          pattern="^\d*$"
          type="text"
          v-model.number="phoneNumber.extension"
        />
      </label>

      <label>
        <span class="required">Email</span>

        <email-address-input
          :disabled="disabled"
          id="admin-user-email-address"
          required
          v-model.trim="username"
        />
      </label>

      <label>
        <span class="required">Organization</span>

        <select
          :disabled="disabled"
          id="admin-user-organization"
          required
          v-model.number="organization.id"
        >
          <option disabled></option>

          <option
            v-for="organization in filterOptions(
              organizations,
              user.organization
            )"
            :key="organization.id"
            :value="organization.id"
          >
            {{ organization.name }}
          </option>
        </select>
      </label>

      <label>
        <span class="required">Permissions</span>

        <select
          :disabled="disabled"
          id="admin-user-role"
          required
          v-model="role.id"
          v-if="!isReferralAgent"
        >
          <option disabled></option>

          <option v-for="role in filteredRoles" :key="role.id" :value="role.id">{{ role.title }}</option>
        </select>
        
        <select
          disabled
          id="admin-user-role"
          required
          v-else
        >
          <option disabled selected>Referral Agent</option>
        </select>
      </label>

      <label class="admin-user-referral-agent-label">
        <span>Referral Agent</span>

        <input
          :disabled="disabled"
          id="admin-user-is-referral-agent"
          type="checkbox"
          v-model="isReferralAgent"
        />
      </label>
      
      <EquipmentManagerTool
        :selectedId="user.id"
        :collection="programs"
        :disabled="disabled"
        :equipmentManagers="equipmentManagers"
        :mappingPrograms="true"
        @handleManagerUpdate="handleManagerUpdate"
      />
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-user-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && user.id"
      >
        Delete
      </button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-user-save"
        type="submit"
        v-if="canSave"
      >
        Save
      </button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-user-cancel"
        type="reset"
        v-if="canCancel"
      >
        Cancel
      </button>
    </footer>
  </form>
</template>

<script>
import { deleteUser, saveUser } from "../../api/UserAPI";
import EquipmentManagerTool from "../../components/admin/EquipmentManagerTool";
import { updateByClientId } from "../../api/EquipmentManagerAPI";

export default {
  data() {
    return {
      firstName: undefined,
      lastName: undefined,
      phoneNumber: {},
      username: undefined,
      organization: {},
      role: {},
      isReferralAgent: undefined,
      managerMapping: undefined,
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    organizations: {
      type: Array,
    },
    roles: {
      type: Array,
    },
    user: {
      type: Object,
    },
    programs: {
      type: Array,
    },
    equipmentManagers: {
      type: Array
    }
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_USERS");
    },
    canSave() {
      return this.can({
        permissions: ["CREATE_USERS", "UPDATE_USERS"],
        matchAny: true,
      });
    },
    filteredRoles() {
      return this.filterOptions(
        this.roles.filter((r) => r.code !== "REFERRAL_AGENT"),
        this.user.role
      );
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.user?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.user?.id ? this.can("UPDATE_USERS") : this.can("CREATE_USERS"))
      );
    },
  },

  methods: {
    handleManagerUpdate(programCollection){
      this.managerMapping = programCollection;
    },
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (!confirm("Are you sure you want to delete this user?")) {
        return;
      }

      await deleteUser(this.user);
      this.$emit("delete", this.user);
    },
    async handleSave() {
      try {
        if(this.user.id != null){
          await updateByClientId(this.user.id, this.managerMapping);
        }

        this.$emit(
          "save",
          await saveUser({
            ...this.user,
            username: this.username,
            firstName: this.firstName,
            lastName: this.lastName,
            role: {
              ...(this.isReferralAgent
                ? this.roles.find((r) => r.code === "REFERRAL_AGENT")
                : this.role),
            },
            organization: { ...this.organization },
            emailAddresses: [
              {
                ...this.user?.emailAddresses?.find((e) => e.primary),
                address: this.username,
                primary: true,
              },
              ...(this.user?.emailAddresses?.filter((e) => !e.primary) ?? []),
            ].filter((e) => e?.address?.trim()?.length > 0),
            phoneNumbers: [
              { ...this.phoneNumber, type: { id: 1 } },
              ...(this.user?.phoneNumbers?.filter(
                (p) => p?.id !== this.phoneNumber.id
              ) ?? []),
            ].filter((p) => p?.number?.trim()?.length > 0),
          })
        );
      } catch (e) {
        this.showErrors(e, "A user");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      if (
        this.username !== this.user?.username ||
        this.firstName !== this.user?.firstName ||
        this.lastName !== this.user?.lastName ||
        this.role.id !== this.user?.role?.id ||
        this.organization.id !== this.user?.organization?.id ||
        this.isReferralAgent !== this.user?.isReferralAgent
      ) {
        return true;
      }

      const phoneNumber = this.user?.phoneNumbers?.slice(-1)?.[0] ?? {};

      return (
        this.phoneNumber.number !== phoneNumber?.number ||
        this.phoneNumber.extension !== phoneNumber?.extension
      );
    },
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    user: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.username = to?.username;
          this.firstName = to?.firstName;
          this.lastName = to?.lastName;
          this.phoneNumber = { ...to?.phoneNumbers?.slice(-1)?.[0] };
          this.role = { ...to?.role };
          this.organization = { ...to?.organization };
          this.isReferralAgent = to?.isReferralAgent;
        }

        !this.disabled && this.autofocus();
      },
    },
  },

  components: {
    EquipmentManagerTool,
  }
};
</script>

<style>
.admin-users-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.admin-users-form .admin-user-referral-agent-label {
  align-items: center;
}

.admin-users-form > footer {
  grid-column: span 4;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>