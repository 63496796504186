<template>
  <ContentWrapper
    :create-icon="can('CREATE_ORGANIZATIONS') ? 'add-organization' : null"
    :header-tag="headerTag"
    search-input-id="admin-organization-search-phrase"
    :show-back="showBack"
    title="Organizations"
    @create="handleCreate"
    @search="handleSearch"
  >
    <OrganizationsTable
      class="admin-table"
      :organizations="filteredOrganizations"
      :selected-id="organization.id"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="organization.id ? `Edit - ${organization.name}` : 'Create Organization'"
      />

      <span class="admin-form-tab">
        <img alt="Organizations" src="../../../assets/icons/program.svg" />
      </span>

      <OrganizationsForm
        class="admin-form"
        :disabled="organization.name == undefined"
        :organization="organization"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import OrganizationsTable from "../../../components/admin/OrganizationsTable";
import OrganizationsForm from "../../../components/admin/OrganizationsForm";

import { getOrganizations } from "../../../api/OrganizationAPI";

export default {
  data() {
    return {
      organization: {},
      _organizations: [],
      search: "",
    };
  },

  props: {
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    organizations: {
      type: Array,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    filteredOrganizations() {
      const regex = this.getFilterRegex(this.search);
      return this.$data._organizations.filter((o) => regex.test(o?.name ?? ""));
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.organization = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.organization = { name: "" };
      }
    },
    handleDelete(organization) {
      this.$data._organizations = this.$data._organizations.filter(
        (p) => p?.id !== organization?.id
      );

      this.organization = {};
    },
    handleSave(organization) {
      const created = !this.organization.id;

      this.$data._organizations = [
        ...this.$data._organizations.filter((p) => p?.id !== organization?.id),
        organization,
      ];

      this.handleSelect(created ? organization : undefined, true);

      this.showAlert(
        `Your organization has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(organization, force = false) {
      if (force || this.confirmIfDirty()) {
        this.organization = {
          ...this.$data._organizations.find((p) => p?.id === organization?.id),
        };
      }
    },
  },

  watch: {
    "$data._organizations"(to, from) {
      if (to !== from) {
        this.$data._organizations = to.sort(this.sortBy("name", "id"));
        this.$emit("update:organizations", this.$data._organizations);
      }
    },
    "organization.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    async organizations(to, from) {
      to === this.$data._organizations ||
        (this.$data._organizations =
          to?.slice?.() ?? (await getOrganizations()));
    },
  },

  async created() {
    this.$data._organizations =
      this.organizations?.slice?.() ?? (await getOrganizations());
    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    OrganizationsTable,
    OrganizationsForm,
  },
};
</script>