<template>
  <form
    autocomplete="off"
    class="admin-manufacturers-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Name</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-manufacturer-name"
          required
          type="text"
          v-model.trim="name"
        />
      </label>
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-manufacturer-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && manufacturer.id"
      >Delete</button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-manufacturer-save"
        type="submit"
        v-if="canSave"
      >Save</button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-manufacturer-cancel"
        type="reset"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </form>
</template>

<script>
import {
  deleteManufacturer,
  saveManufacturer
} from "../../api/ManufacturerAPI";

export default {
  data() {
    return {
      name: undefined
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    manufacturer: {
      type: Object
    }
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_MANUFACTURERS");
    },
    canSave() {
      return this.can({ permissions: ["CREATE_MANUFACTURERS", "UPDATE_MANUFACTURERS"], matchAny: true });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.manufacturer?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.manufacturer?.id
          ? this.can("UPDATE_MANUFACTURERS")
          : this.can("CREATE_MANUFACTURERS"))
      );
    }
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (!confirm("Are you sure you want to delete this manufacturer?")) {
        return;
      }

      await deleteManufacturer(this.manufacturer);
      this.$emit("delete", this.manufacturer);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveManufacturer({
            ...this.manufacturer,
            name: this.name
          })
        );
      } catch (e) {
        this.showErrors(e, "A manufacturer");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return this.name !== this.manufacturer?.name;
    }
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    manufacturer: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.name = to?.name;
        }

        !this.disabled && this.autofocus();
      }
    }
  }
};
</script>

<style>
.admin-manufacturers-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.admin-manufacturers-form > footer {
  grid-column: span 2;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>