<template>
  <dialog class="loader" open>
    <progress class="loader-progress" max="100" min="0" :value="value" />
  </dialog>
</template>

<script>
export default {
  props: {
    hideScrollbar: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 50,
    },
  },

  methods: {
    cancelEvent(e) {
      e.preventDefault();
    },
  },

  mounted() {
    this.style = document.createElement("style");
    this.style.type = "text/css";
    this.style.textContent = "*,*:active,*:focus,*:hover{cursor:progress}";

    this.hideScrollbar &&
      (this.style.textContent += `body{scrollbar-width:none;padding-right:${
        window.innerWidth - document.body.clientWidth
      }px}body::-webkit-scrollbar{min-width:0;width:0}`);

    document.body.appendChild(this.style);
  },

  beforeDestroy() {
    this.style?.remove();
  },
};
</script>

<style scoped>
.loader {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  position: fixed;
  user-select: none;
  width: 100vw;
  z-index: 999999;
}

.loader-progress {
  appearance: none;
  background: var(--primary-medium-gray);
  height: 0.4rem;
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.loader-progress::-webkit-progress-bar {
  background: var(--primary-medium-gray);
}

.loader-progress::-webkit-progress-value {
  background: #e71d30;
  background: linear-gradient(90deg, #e71d30 0%, #37c719 100vw);
  transition: width 400ms linear;
}

.loader-progress::-moz-progress-bar {
  background: #e71d30;
  background: linear-gradient(90deg, #e71d30 0%, #37c719 100vw);
  transition: width 400ms linear;
}
</style>