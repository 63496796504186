import { del, get, post, put } from './http';

const API_PATH = '/api/projects';

export const createProject = async project =>
    await post(API_PATH, project);

export const deleteProject = async project =>
    await del(`${API_PATH}/${project.id}`);

export const getProjects = async () =>
    await get(API_PATH);

export const saveProject = async project =>
    await (project.id ? updateProject(project) : createProject(project));

export const updateProject = async project =>
    await put(`${API_PATH}/${project.id}`, project);