<template>
  <PartialView>
    <EquipmentSpecifications
      ref="specifications"
      class="admin-content-partial"
      header-tag="h3"
      :id="specificationId"
      :route-params="{view: 'specifications'}"
      :show-back="false"
      v-if="hasAdmin('EQUIPMENT_PARTS')"
    />

    <WheelchairAccessories
      ref="accessories"
      class="admin-content-partial"
      header-tag="h3"
      :id="accessoryId"
      :route-params="{view: 'accessories'}"
      :show-back="false"
      v-if="hasAdmin('EQUIPMENT_ACCESSORIES')"
    />
  </PartialView>
</template>

<script>
import PartialView from "./PartialView";
import EquipmentSpecifications from "./pages/EquipmentSpecifications";
import WheelchairAccessories from "./pages/WheelchairAccessories";

export default {
  data() {
    return {
      accessoryId: this.view === "accessories" ? this.id : undefined,
      specificationId: this.view === "specifications" ? this.id : undefined,
    };
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined,
    },
    view: {
      type: String,
      default: "specifications",
    },
  },

  methods: {
    isDirty() {
      return false;
    },
  },

  mounted() {
    this.$refs.specifications &&
      (this.$refs.specifications.$el.id = "admin-equipment-specifications");

    this.$refs.accessories &&
      (this.$refs.accessories.$el.id = "admin-equipment-accessories");
  },

  beforeRouteUpdate(to, from, next) {
    to.params.view === "specifications" &&
      (this.specificationId = +to.params.id || undefined);

    to.params.view === "accessories" &&
      (this.accessoryId = +to.params.id || undefined);

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (
      !to.params.skipDirtyCheck &&
      Object.values(this.$refs).find((r) => r?.isDirty?.()) &&
      !this.confirmDiscard()
    ) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    PartialView,
    EquipmentSpecifications,
    WheelchairAccessories,
  },
};
</script>