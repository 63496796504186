import { sortBy } from '../../func';

const API_PATH = '/api/genders';

export const genders = {
    namespaced: true,
    state: {
        genders: null,
    },
    mutations: {
        setData(state, value) {
            state.genders = value;
        },
    },
    actions: {
        clearGenders({ commit }) {
            commit('setData', null);
        },
        async createGender({ dispatch, rootGetters }, gender) {
            return rootGetters['user/can']('CREATE_GENDERS')
                ? await dispatch('api/post', { url: API_PATH, data: gender }, { root: true })
                : null;
        },
        async deleteGender({ dispatch, rootGetters }, gender) {
            return rootGetters['user/can']('DELETE_GENDERS')
                ? await dispatch('api/delete', `${API_PATH}/${gender.id}`, { root: true })
                : null;
        },
        async getGenders({ commit, dispatch, rootGetters, state }) {
            if (!state.genders) {
                commit('setData', []);

                rootGetters['user/can']('READ_GENDERS') &&
                    commit('setData', (await dispatch('api/get', API_PATH, { root: true })).sort(sortBy('name', 'id')));
            }

            return state.genders;
        },
        async saveGender({ dispatch }, gender) {
            return await dispatch(gender.id ? 'updateGender' : 'createGender', gender);
        },
        async updateGender({ dispatch, rootGetters }, gender) {
            return rootGetters['user/can']('UPDATE_GENDERS')
                ? await dispatch('api/put', { url: `${API_PATH}/${gender.id}`, data: gender }, { root: true })
                : null;
        },
        logout({ dispatch }) {
            dispatch('clearGenders');
        },
    }
};

export default genders;