<template>
  <section class="template-selector">
    <slot name="header" />

    <section>
      <content-header>Templates</content-header>
      <search-form :debounce="200" v-model="filter" />

      <table class="striped">
        <colgroup>
          <col width="1%" />
          <col width="49.5%" />
          <col width="49.5%" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <input
                :checked="isAllSelected"
                type="checkbox"
                @click="handleSelectAll"
              />
            </th>
            <th>Template Name</th>
            <th>Template Type</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="template in paginatedTemplates"
            :key="template.id"
            :class="
              selectedTemplates.includes(template.id)
                ? 'selected'
                : 'selectable'
            "
            @click="handleSelect(template)"
          >
            <td>
              <input
                :checked="selectedTemplates.includes(template.id)"
                type="checkbox"
              />
            </td>
            <td>{{ template.name }}</td>
            <td>{{ template.type.name }}</td>
          </tr>
        </tbody>
        <tfoot v-if="hasPages">
          <tr>
            <td class="page-controls">
              <icon-button
                :disabled="!hasPrevPage"
                name="prev"
                @click="goToPrevPage"
              />

              <icon-button
                :disabled="!hasNextPage"
                name="next"
                @click="goToNextPage"
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </section>

    <slot name="embedded" />

    <footer>
      <button type="button" @click="handleSubmit">Generate Documents</button>
      <button type="button" @click="handleCancel">Cancel</button>
    </footer>
  </section>
</template>
    
<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("filteredTemplates")],

  props: {
    templates: {
      type: Array,
    },
    type: {
      type: String,
    },
  },

  data() {
    return {
      filter: "",
      selectedTemplates: [],
    };
  },

  computed: {
    filteredTemplates() {
      const regex = this.getFilterRegex(this.filter);

      return (
        !/\S/.test(this.filter)
          ? this.templates.slice()
          : this.templates.filter(
              (template) =>
                template?.id &&
                regex.test(
                  [template?.name ?? "", template?.type?.name ?? ""].join(
                    String.fromCharCode(0x1f)
                  )
                )
            )
      ).sort(this.sortBy("name", "id"));
    },
    isAllSelected() {
      return (
        this.selectedTemplates.length &&
        !this.paginatedTemplates.find(
          (template) => !this.selectedTemplates.includes(template.id)
        )
      );
    },
  },

  methods: {
    handleCancel() {
      this.filter = "";
      this.selectedTemplates = [];
      this.$emit("cancel");
    },
    handleSelectAll() {
      this.selectedTemplates = this.isAllSelected
        ? this.selectedTemplates.filter(
            (id) =>
              !this.paginatedTemplates.find((template) => template.id === id)
          )
        : [
            ...this.selectedTemplates,
            ...this.paginatedTemplates.map((template) => template.id),
          ].filter((v, i, a) => a.indexOf(v) === i);
    },
    handleSelect(template) {
      this.selectedTemplates = this.selectedTemplates.includes(template.id)
        ? this.selectedTemplates.filter((id) => id != template.id)
        : [...this.selectedTemplates, template.id].filter(
            (v, i, a) => a.indexOf(v) === i
          );
    },
    handleSubmit() {
      this.$emit("submit", this.selectedTemplates);
    },
  },
};
</script>
    
<style scoped>
.template-selector {
  display: grid;
  grid-template-columns: 1fr auto;
}

.template-selector > section {
  display: contents;
}

.template-selector > * + *:before {
  content: " ";
  grid-column: span 2;
  height: 2rem;
}

.template-selector > section > table,
.template-selector > footer {
  grid-column: span 2;
}

.template-selector > section > table tfoot td > :first-child {
  margin-right: auto;
}

.template-selector .content-header {
  grid-area: unset;
}

.template-selector > footer {
  align-items: center;
  display: flex;
  font-size: 1.11em;
  font-weight: bold;
  justify-content: flex-end;
  outline: none;
  margin: 2em 0 0;
  padding: 0.25em 0 0;
  text-transform: uppercase;
  width: 100%;
}

.template-selector > footer > :not(:first-child) {
  margin-left: 0.5em;
}
</style>
