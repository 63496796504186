<template>
  <form
    autocomplete="off"
    class="admin-equipment-subcategories-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Name</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-equipment-subcategory-name"
          required
          type="text"
          v-model.trim="name"
        />
      </label>

      <label>
        <span>Name Fr</span>

        <input
          :disabled="disabled"
          id="admin-equipment-subcategory-name-fr"
          type="text"
          v-model.trim="name_fr"
        />
      </label>

      <label>
        <span class="required">Category</span>

        <select
          :disabled="disabled"
          id="admin-equipment-subcategory-category"
          required
          v-model.number="category.id"
        >
          <option disabled></option>

          <option
            v-for="category in filterOptions(categories, subcategory.category)"
            :key="category.id"
            :value="category.id"
          >{{ category.name }}</option>
        </select>
      </label>
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-equipment-subcategory-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && subcategory.id"
      >Delete</button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-equipment-subcategory-save"
        type="submit"
        v-if="canSave"
      >Save</button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-equipment-subcategory-cancel"
        type="reset"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </form>
</template>

<script>
import {
  deleteEquipmentSubcategory,
  saveEquipmentSubcategory
} from "../../api/EquipmentAPI";

export default {
  data() {
    return {
      name: undefined,
      name_fr: undefined,
      category: {}
    };
  },

  props: {
    categories: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    subcategory: {
      type: Object
    }
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_EQUIPMENT_SUBCATEGORIES");
    },
    canSave() {
      return this.can({
        permissions: [
          "CREATE_EQUIPMENT_SUBCATEGORIES",
          "UPDATE_EQUIPMENT_SUBCATEGORIES"
        ],
        matchAny: true
      });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return (
        !this.disabled && this.subcategory?.id && !this.subcategory?.protected
      );
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.subcategory?.id
          ? this.can("UPDATE_EQUIPMENT_SUBCATEGORIES")
          : this.can("CREATE_EQUIPMENT_SUBCATEGORIES"))
      );
    }
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (
        !confirm("Are you sure you want to delete this equipment subcategory?")
      ) {
        return;
      }

      await deleteEquipmentSubcategory(this.subcategory);
      this.$emit("delete", this.subcategory);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveEquipmentSubcategory({
            ...this.subcategory,
            name: this.name,
            name_fr: this.name_fr,
            category: this.category
          })
        );
      } catch (e) {
        this.showErrors(e, "An equipment subcategory");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return (
        this.name !== this.subcategory?.name ||
        this.name_fr !== this.subcategory?.name_fr ||
        this.category.id !== this.subcategory?.category?.id
      );
    }
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    subcategory: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.name = to?.name;
          this.name_fr = to?.name_fr;
          this.category = { ...to?.category };
        }

        !this.disabled && this.autofocus();
      }
    }
  }
};
</script>

<style>
.admin-equipment-subcategories-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.admin-equipment-subcategories-form > footer {
  grid-column: span 2;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>