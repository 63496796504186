<template>
  <section class="search-page">
    <h2 class="search-header" v-if="!results.records">Welcome to Easter Seals!</h2>

    <search-form
      class="search-form"
      :phrase="currentPhrase"
      :type="currentType"
      @search="handleSearch"
      v-if="!results.records"
    />

    <SearchResults class="search-results" 
    :results="results"  
    :page-count="results.page.last" 
    :page-number="results.page.current"
    @page-change="handlePageChange"
    v-else>
      <search-form class="search-form" :phrase="currentPhrase" :type="currentType" @search="handleSearch" />
    </SearchResults>
  </section>
</template>

<script>
import { scrollPosition } from "../../../mixins/scrollPosition";
import store from "../../../store";

import SearchForm from "../../../components/search/SearchForm";
import SearchResults from "../../../components/search/SearchResults";

import { search } from "../../../api/SearchAPI";
import { getPaginatedEquipment } from "../../../api/EquipmentAPI";
import { getPaginatedClient } from "../../../api/ClientAPI";

export default {
  mixins: [scrollPosition],
  data() {
    return {
      _phrase: undefined,
      _timeout: undefined,
      _type: undefined,
      results: {
        totalRecordCount: 0,
        records: undefined,
        page: {
          size: 20,
          current: 1,
          first: 1,
          last: 1,
        }
      },
    };
  },

  props: {
    phrase: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "clients",
    },
  },

  methods: {
    async handlePageChange(pageNumber) {
      const parsedType = this.$data._type;
      
      if(parsedType == "equipment" || parsedType == "clients") {
        await this.loadResults({ page: pageNumber });
      } else {
        this.results.page.current = pageNumber;
        this.setScrollPosition();
      }
    },
    async loadResults({size, page, phrase, type}) {
      if(!this.canSearch(phrase, type)) {
        this.results.records = null;
      } else {
        const parsedType = type ?? this.$data._type;

        if(parsedType == "equipment") {
          this.results = await getPaginatedEquipment({
            size: size ?? this.results.page.size,
            page: page ?? this.results.page.current,
            phrase: phrase ?? this.phrase
          });

          this.results = {...this.results, "records": {"equipment": this.results.records}};

          this.setScrollPosition();
        } else if(parsedType == "clients") {
          this.results = await getPaginatedClient({
            size: size ?? this.results.page.size,
            page: page ?? this.results.page.current,
            phrase: phrase ?? this.phrase
          });

          this.results = {...this.results, "records": {"clients": this.results.records}};

          this.setScrollPosition();
        } else {
          const records = (await search(phrase ?? this.$data_phrase, type ?? this.$data._type))?.results;
          const key = records ? Object.keys(records)[0] : null;
          
          const page = {
            size: 20,
            current: page ?? 1,
            first: 1,
            last: key ? Math.ceil(records[key].length / 20) : 1,
          }
          const totalRecordCount = key ? records[key].length : 0;
          this.results = {"totalRecordCount": totalRecordCount, "page":{...page}, "records": records};
        }
      }
    },
    async handleSearch({ phrase, type } = {}) {
      this.$data._phrase = phrase;
      this.$data._type = type;
      await this.loadResults({ page: 1, type: type, phrase: phrase });

      const newQuery = { q: phrase, t: type };

      if (this.$route.query.q !== newQuery.q || this.$route.query.t !== newQuery.t) {
        this.$router.push({
          ...this.$route,
          query: newQuery,
        });
      }
    },
    async search() {
        this.loadResults({ page: 1 })
    },
    canSearch(phrase, type) {
      return (type ?? this.$data._type) && (phrase ?? this.$data._phrase);
    }
  },
  computed: {
    currentPhrase() {
      return this.$data._phrase;
    },
    currentType() {
      return this.$data._type;
    }
  },
  watch: {
    phrase(to, from) {
      if (to !== this.$data._phrase) {
        this.$data._phrase = to;
        this.$data._timeout && clearTimeout(this.$data._timeout);
        this.$data._timeout = setTimeout(this.search, 200);
      }
    },
    type(to, from) {
      if (to !== this.$data._type) {
        this.$data._type = to;
        this.$data._timeout && clearTimeout(this.$data._timeout);
        this.$data._timeout = setTimeout(this.search, 200);
      }
    },
  },

  async beforeRouteEnter(to, from, next) {
    const { q: phrase, t: type } = to.query;
    
    const queryString = window.location.search;

    await new Promise(function fn(resolve) {
      Array.isArray(store.getters.user.permissions)
        ? resolve()
        : setTimeout(fn, 100, resolve);
    });

    next(async (vm) => {
      // For some reason, window.location.search gets cleared when we hit this point. This fixes that.
      window.history.replaceState(
        null,
        document.title,
        `${window.location.href}${queryString}`
      );

      vm.$data._phrase = phrase;
      vm.$data._type = type;
      await vm.loadResults({page: 1, phrase: phrase, type: type});
    });
  },


  beforeRouteUpdate(to, from, next) {
    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    this.abortRequests();
    next();
  },

  components: {
    SearchForm,
    SearchResults,
  },
};
</script>

<style>
.search-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>

<style scoped>
.search-header {
  margin: 20% 0 0;
}

.search-form {
  margin: 0 0 20%;
  width: 50%;
}

.search-results {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.search-results .search-form {
  margin: 0;
  width: 40%;
}
</style>