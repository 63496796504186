<template>
  <form
    autocomplete="off"
    class="admin-equipment-types-form"
    @reset.prevent="handleCancel"
    @submit.prevent="handleSave"
  >
    <fieldset>
      <label>
        <span class="required">Equipment Prefix</span>

        <input
          autofocus
          :disabled="disabled"
          id="admin-equipment-type-code"
          maxlength="1"
          minlength="1"
          name="code"
          required
          type="text"
          v-model.trim="code"
        />
      </label>

      <label>
        <span class="required">Description</span>

        <input
          :disabled="disabled"
          id="admin-equipment-type-description"
          name="description"
          required
          type="text"
          v-model.trim="description"
        />
      </label>

      <label>
        <span class="required">Program</span>

        <select
          :disabled="disabled"
          id="admin-equipment-type-program"
          name="program"
          required
          v-model.number="program.id"
        >
          <option disabled></option>

          <option
            v-for="program in filterOptions(programs, type.program)"
            :key="program.id"
            :value="program.id"
          >{{ program.name }}</option>
        </select>
      </label>

      <label>
        <span>Comments</span>

        <input
          :disabled="disabled"
          id="admin-equipment-type-comment"
          name="comments"
          type="text"
          v-model.trim="comment"
        />
      </label>
    </fieldset>

    <footer>
      <button
        :disabled="!isDeleteEnabled"
        id="admin-equipment-type-delete"
        type="button"
        @click="handleDelete"
        v-if="canDelete && type.id"
      >Delete</button>

      <button
        :disabled="!isSaveEnabled"
        id="admin-equipment-type-save"
        type="submit"
        v-if="canSave"
      >Save</button>

      <button
        :disabled="!isCancelEnabled"
        id="admin-equipment-type-cancel"
        type="reset"
        v-if="canCancel"
      >Cancel</button>
    </footer>
  </form>
</template>

<script>
import { deleteEquipmentType, saveEquipmentType } from "../../api/EquipmentAPI";

export default {
  data() {
    return {
      code: undefined,
      description: undefined,
      program: {},
      comment: undefined
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    programs: {
      type: Array
    },
    type: {
      type: Object
    }
  },

  computed: {
    canCancel() {
      return true;
    },
    canDelete() {
      return this.can("DELETE_EQUIPMENT_TYPES");
    },
    canSave() {
      return this.can({ permissions: ["CREATE_EQUIPMENT_TYPES", "UPDATE_EQUIPMENT_TYPES"], matchAny: true });
    },
    isCancelEnabled() {
      return !this.disabled;
    },
    isDeleteEnabled() {
      return !this.disabled && this.type?.id;
    },
    isSaveEnabled() {
      return (
        !this.disabled &&
        (this.type?.id
          ? this.can("UPDATE_EQUIPMENT_TYPES")
          : this.can("CREATE_EQUIPMENT_TYPES"))
      );
    }
  },

  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    async handleDelete() {
      if (!confirm("Are you sure you want to delete this equipment type?")) {
        return;
      }

      await deleteEquipmentType(this.type);
      this.$emit("delete", this.type);
    },
    async handleSave() {
      try {
        this.$emit(
          "save",
          await saveEquipmentType({
            ...this.type,
            code: this.code,
            description: this.description,
            program: this.program,
            comment: this.comment
          })
        );
      } catch (e) {
        this.showErrors(e, "An equipment type");
      }
    },
    isDirty() {
      if (this.disabled) {
        return false;
      }

      return (
        this.code !== this.type?.code ||
        this.description !== this.type?.description ||
        this.program.id !== this.type?.program?.id ||
        this.comment !== this.type?.comment
      );
    }
  },

  watch: {
    disabled(to, from) {
      !to && from && this.autofocus();
    },
    type: {
      immediate: true,
      handler(to, from) {
        if (to !== from) {
          this.code = to?.code;
          this.description = to?.description;
          this.program = { ...to?.program };
          this.comment = to?.comment;
        }

        !this.disabled && this.autofocus();
      }
    }
  }
};
</script>

<style>
.admin-equipment-types-form {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.admin-equipment-types-form > footer {
  grid-column: span 4;
  margin: 0;
  padding: 0.5em 0 0 0;
}
</style>