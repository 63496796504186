import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { formatTimestamp, routeSilent, sortBy } from './func';

const discardMessage = 'You have unsaved changes that will be lost. Would you like to continue?';

Vue.mixin({
  computed: {
    ...mapGetters('user', ['can', 'hasAdmin']),
    FORMAT_DEFAULT_DATE() {
      return formatTimestamp.FORMAT_DEFAULT_DATE;
    },
    FORMAT_DEFAULT_DATE_TIME() {
      return formatTimestamp.FORMAT_DEFAULT_DATE_TIME;
    },
    FORMAT_DEFAULT_TIME() {
      return formatTimestamp.FORMAT_DEFAULT_TIME;
    },
  },

  methods: {
    /** Inherited from './func' */
    formatTimestamp,
    routeSilent,
    sortBy,

    /** API **/
    ...mapActions(['api/abort']),
    abortRequests() {
      this['api/abort']();
    },

    /** ALERTS / ERRORS **/
    confirmDiscard() {
      return this.showConfirm(discardMessage);
    },
    confirmIfDirty(el = this) {
      return el.isDirty() ? el.confirmDiscard() : true;
    },
    confirmOnUnload(e) {
      if (this.isDirty()) {
        return e.returnValue = discardMessage;
      }
    },
    hideAlert() {
    },
    hideConfirm() {
    },
    hideError() {
    },
    isDirty() {
      return this.$children?.reduce((dirty, child) => dirty || child.isDirty(), false);
    },
    showAlert(message) {
      return alert(message);
    },
    showConfirm(message) {
      return confirm(message);
    },
    showError(message) {
      return this.showAlert(message);
    },
    checkTemplateNonGenerated(invoices) {
      return invoices.length > 0 && (invoices.filter(invoice => !invoice.templateGenerated).length > 0)
    },

    /** DOM **/
    autofocus() {
      this.$nextTick(() => {
        this?.$el?.querySelector?.("[autofocus]:not(:disabled)")?.focus?.();
      });
    },
    checkValidity(...els) {
      els.length || (els = [this.$el]);

      for (const el of els) {
        el?.setCustomValidity?.('');

        if (el?.reportValidity?.() === false) {
          return false;
        }

        for (const input of el?.querySelectorAll?.("input, select, textarea") ?? []) {
          input?.setCustomValidity?.('');

          if (input.reportValidity?.() === false) {
            return false;
          }
        }
      }

      return true;
    },
    filterOptions(list = [], selected) {
      return selected?.id && !list.find(opt => opt.id === selected?.id) ? [...list, selected] : [...list];
    },
    async showErrors(e, desc = 'An entity') {
      if (e?.message?.match(/409 Conflict/) || e?.status === 409) {
        const errors = e.getFieldErrors && Object.entries(await e.getFieldErrors());

        if (errors?.length > 0 && errors.reduce((reported, [key, value]) => {
          const id = key.replace(
            /([a-z])([A-Z])/g,
            (_, a, b) => `${a}-${b.toLowerCase()}`
          );

          const el = this.$el.querySelector(`#${id}, #${key}, [name="${id}" i], [name="${key}" i]`);
          const label = el?.parentNode?.firstElementChild;

          if (el) {
            el.setCustomValidity(`${desc} with ${label?.textContent?.toLowerCase() ?? key} ${value} already exists`);
            el.reportValidity();
            return reported = (reported ?? true) && true;
          }

          return false;
        }, null)) {
          return;
        }
      }

      const error = e.getError && await e.getError();

      if (error?.message?.match(/^Validation failed/)) {

        if (error?.errors?.length > 0) {
          this.showAlert(`The following validation errors have occurred:\n   - ${error.errors.join('\n   - ')}\n\nPlease double-check your form and try submitting again.`);
        } else {
          this.showAlert('A validation error has occurred. Please double-check your form and try submitting it again.');
        }

        return;
      }

      if (e?.message?.match(/400 Bad Request/) || e?.status === 400) {
        this.showAlert(`${error?.message?.trim() ?? ''}.`.replace(/(^\.+|\.{2,})$/, '') || 'An unexpected error has occurred. Please contact the system administrator.');
        return;
      }

      this.showAlert('An unexpected error has occurred. Please contact the system administrator.');
      throw e;
    },

    /** FORMATTING **/
    cleanUrl(url) {
      if (/^[^;/]*:?\/\//.test(url)) {
        return url;
      }

      return `${process.env.VUE_APP_API_PATH?.replace(/(.)\/+$/, '$1') ?? ''}${url}`.replace(/\/+/g, '/');
    },
    formatCurrency(value) {
      if ((value ?? false) === false) {
        return value;
      }

      return `\$${new Number(value).toFixed(2)}`;
    },
    formatDate(value, { format, ...params } = {}) {
      return formatTimestamp(value, {
        format: format ?? this.$store.getters.user?.dateFormat,
        ...params
      });
    },
    formatDateTime(value, { format, ...params } = {}) {
      return formatTimestamp(value, {
        format: format ?? this.$store.getters.user?.dateTimeFormat,
        ...params
      });
    },
    formatHTML(value) {
      return value.replace(/(\r?\n|\r\n?)/g, "<br/>$1");
    },
    formatMedicareNumber(value) {
      if ((value ?? false) === false) {
        return value;
      }

      return (value.toString().replace(/\D+/gi, "").match(/(.{1,3})/g) ?? [value]).join(" ");
    },
    formatPhoneNumber(value) {
      if ((value ?? false) === false) {
        return value;
      }

      const number = value.toString().replace(/\D+/g, "");

      if (number.length > 10) {
        return number.replace(/^(\d*)(\d{3})(\d{3})(\d{4})$/, "$1 ($2) $3-$4");
      } else if (number.length >= 6) {
        return number.replace(/^(\d{3})(\d{3})(\d*)$/, "($1) $2-$3");
      } else if (number.length >= 3) {
        return number.replace(/^(\d{3})(\d*)$/, "($1) $2");
      } else if (number.length > 0) {
        return `(${number}`;
      } else {
        return number;
      }
    },
    formatPostalCode(value) {
      if ((value ?? false) === false) {
        return value;
      }

      return (value.toString().replace(/[^A-Z0-9]+/gi, "").match(/(.{1,3})/g) ?? [value]).join(" ");
    },
    formatTime(value, { format, ...params } = {}) {
      return formatTimestamp(value, {
        format: format ?? this.$store.getters.user?.timeFormat,
        ...params
      });
    },
    formatTimestamp(value, { format, ...params } = {}) {
      return formatTimestamp(value, {
        format: format ?? this.$store.getters.user?.dateFormat,
        ...params
      });
    },
    getEquipmentNumber(equipment) {
      return `${equipment?.type?.code ?? ''}${equipment?.equipmentNumber ?? ''}${equipment?.equipmentNumberSuffix ?? ''}`;
    },
    getEquipmentStatus(equipment) {
      return equipment?.deleted
        ? "Deleted"
        : equipment?.reserved
          ? "Reserved"
          : equipment?.onLoan
            ? "On Loan"
            : equipment?.onHold
              ? "On Hold"
              : equipment?.status
                ? equipment?.status?.name
                : equipment?.lost
                  ? "Lost"
                  : "Available";
    },
    getStaticName(user, englishString, frenchString) {
      const languageId = user.language?.id;
      const isUserRA = user.roles?.includes("REFERRAL_AGENT");

      if (languageId === 1) {
        return englishString;
      }

      return (isUserRA && languageId === 2) ? frenchString : englishString;
    },
    getName(object, user) {
      const languageId = user.language?.id;
      const isUserRA = user.roles?.includes("REFERRAL_AGENT");

      if (languageId === 1 || (languageId === 2 && !object?.name_fr)) {
        return object?.name;
      }

      return (isUserRA && languageId === 2) ? object?.name_fr : object?.name;
    },
    isOnHold(equipment) {
      return equipment?.deleted
        ? false
        : equipment?.onLoan
          ? false
          : equipment?.onHold;
    },
    isAvailable(equipment) {
      return equipment?.deleted
        ? false
        : equipment?.reserved
          ? false
          : equipment?.onLoan
            ? false
            : equipment?.onHold
              ? false
              : equipment?.status
                ? equipment?.status?.name
                : equipment?.lost
                  ? false
                  : true
    },
    getFilterRegex(phrase) {
      const substrings = [];
      const substringEncapsulator = String.fromCharCode(0x1f);

      phrase = phrase?.trim().replace(/(["'`])(.*?)\1/g, (_, __, substring) => {
        substrings.push(substring);
        return `${substringEncapsulator}${substrings.length - 1}${substringEncapsulator}`;
      });

      return new RegExp(phrase?.split(/\s+/).map(term => {
        const negative = term.charAt(0) === '-';
        term = term.replace(/^[+-]/, '');

        const value = term.charAt(0) === substringEncapsulator && term.charAt(term.length - 1) === substringEncapsulator
          ? substrings[parseInt(term.substring(1, term.length - 1))]
          : term;

        return `(?${negative ? '!' : '='}.*?${value
          .replace(/([\\?])/g, '\\$1')
          .replace(/([^a-zA-Z0-9\s\?])/g, '$1?')
          .replace(/([\(\)\{\}\[\]])/g, '\\$1')
          .replace(/\s+/g, '\\s*')})`;
      }).join('') ?? '', 'i');
    },
    getFullName(info) {
      return `${info?.firstName ?? ''} ${info?.lastName ?? ''}`.trim();
    },
    getInvoiceNumber(invoice) {
      return `${invoice?.program?.invoiceNumberPrefix ?? ''}${invoice.invoiceNumber}`;
    },
    getUserName(user) {
      return (this.getFullName(user) || user?.username) ?? '';
    },
  },

  mounted() {
    this.autofocus();
  },
});