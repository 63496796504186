import { toggleUserLanguage } from "../api/UserAPI";

const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
const DEFAULT_TIME_FORMAT = 'hh:mm A';

const DEFAULT_USER_DATA = {
    dateFormat: DEFAULT_DATE_FORMAT,
    dateTimeFormat: `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`,
    timeFormat: DEFAULT_TIME_FORMAT,
};

export const user = {
    namespaced: true,
    state: {
        user: {
            ...DEFAULT_USER_DATA,
            ...JSON.parse(localStorage.getItem('easter-seals.user') ?? '{}'),
        },
    },
    getters: {
        can: state => permission => {
            const {
                matchAny = false,
                permissions = []
            } = Array.isArray(permission)
                    ? { permissions: permission }
                    : typeof permission === 'object'
                        ? permission
                        : { permissions: [permission] };

            return matchAny
                ? !!permissions.find(permission => state.user?.permissions?.includes(permission))
                : !permissions.find(permission => !state.user?.permissions?.includes(permission))
        },
        hasAdmin: (_, getters) => permission =>
            getters.can(`READ_${permission}`) && getters.can({ permissions: [`CREATE_${permission}`, `UPDATE_${permission}`, `DELETE_${permission}`], matchAny: true }),
    },
    mutations: {
        setData(state, data) {
            state.user = { ...DEFAULT_USER_DATA, ...data };
            data ? localStorage.setItem('easter-seals.user', JSON.stringify(data)) : localStorage.removeItem('easter-seals.user');
        }
    },
    actions: {
        clearSession({ commit }) {
            commit('setData');
        },
        async getSession({ commit, dispatch, rootState }) {
            rootState.api.token && !rootState.api.passwordExpired &&
                commit('setData', await dispatch('api/get', '/api/session', { root: true }));
        },
        async login({ dispatch }) {
            await dispatch('getSession');
        },
        logout({ commit }) {
            commit('setData');
        },
        async resetPassword({ dispatch }) {
            await dispatch('getSession');
        },
        async toggleUserLanguage({ dispatch, rootState }) {
            const newLangId = rootState.user.user.language.id === 1 ? 2 : 1;
            await dispatch('api/put', `/api/users/language/${newLangId}`, { root: true });
            await dispatch('getSession');
        }
    }
}

export default user;