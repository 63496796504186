<template>
    <PartialView>
        <EquipmentStatuses 
            ref="statuses"
            class="admin-content-partial"
            header-tag="h3"
            :id="statusId"
            :route-params="{view: 'statuses'}"
            :show-back="false"
            @update:statuses="handleUpdateStatuses"
            v-if="hasAdmin('EQUIPMENT_STATUSES')"
        />
    </PartialView>
</template>

<script>
import PartialView from "./PartialView";
import EquipmentStatuses from "./pages/EquipmentStatuses";

export default {
    components: {
        PartialView,
        EquipmentStatuses
    },

    data() {
        return {
            statusId: this.view === "statuses" ? this.id : undefined,
            statuses: undefined
        };
    },

    props: {
        id: {
            type: [Number, String],
            default: undefined,
        },
        view: {
            type: String,
            default: "statuses",
        },
    },

    methods: {
        handleUpdateStatuses(statuses) {
            this.statuses = statuses;
        },
        isDirty() {
            return false;
        },
    },

    mounted() {
        this.$refs.statuses &&
        (this.$refs.statuses.$el.id = "admin-equipment-statuses");
    },

    beforeRouteUpdate(to, from, next) {
        to.params.view === "statuses" &&
        (this.statusId = +to.params.id || undefined);

        this.abortRequests();
        next();
    },

    beforeRouteLeave(to, from, next) {
    if (
      !to.params.skipDirtyCheck &&
      Object.values(this.$refs).find((r) => r?.isDirty?.()) &&
      !this.confirmDiscard()
    ) {
      return next(false);
    }

    this.abortRequests();
    next();
  },
}
</script>