<template>
  <table class="striped indirect-services-notes">
    <caption v-html="caption" v-if="caption" />
    <thead>
      <tr>
        <th class="indirect-services-note-date">Date</th>
        <th class="indirect-services-note-author">User</th>
        <th class="indirect-services-note-message">Notes</th>
        <th class="indirect-services-note-remove"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(note, index) in paginatedNotes" :key="index">
        <td class="indirect-services-note-date">
          {{ formatTimestamp(note.createdAt) }}
        </td>
        <td class="indirect-services-note-author">
          {{ getUserName(note.author) }}
        </td>
        <td
          class="indirect-services-note-message-text"
          v-html="formatHTML(note.message)"
          v-if="note.id"
        ></td>
        <td class="indirect-services-note-message-input" v-if="!note.id">
          <textarea
            :id="`indirect-services-note-${index}-message`"
            placeholder="add note here..."
            required
            v-model.trim="note.message"
          />
        </td>
        <td class="indirect-services-note-remove">
          <icon-button
            :id="`indirect-services-note-${index}-remove`"
            name="remove"
            @click="handleRemove(note)"
            v-if="canRemove"
          />
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td class="page-controls" colspan="4">
          <icon-button
            :disabled="disabled"
            id="indirect-services-notes-add"
            name="add"
            @click="handleAdd"
            v-if="canAdd"
          />

          <icon-button
            :disabled="!hasPrevPage"
            id="indirect-services-notes-page-prev"
            name="prev"
            @click="goToPrevPage"
          />

          <icon-button
            :disabled="!hasNextPage"
            id="indirect-services-notes-page-next"
            name="next"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("notes")],

  props: {
    canAdd: {
      type: Boolean,
      default: true,
    },
    canRemove: {
      type: Boolean,
      default: true,
    },
    caption: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    notes: {
      type: Array,
    },
  },

  methods: {
    handleAdd() {
      this.canAdd &&
        this.$emit("update:notes", [
          {
            author: {
              ...this.$store.getters.user,
              permissions: undefined,
              roles: undefined,
            },
            createdAt: new Date().toJSON(),
          },
          ...this.notes,
        ]);
    },
    handleRemove(note) {
      this.canRemove &&
        this.$emit(
          "update:notes",
          this.notes.filter((n) => n !== note)
        );
    },
  },
};
</script>

<style>
.indirect-services-notes {
  border-collapse: collapse;
  display: grid;
  grid-template-columns: 6em 12em 1fr 2em;
  margin: 0;
  padding: 0;
  width: 100%;
}

.indirect-services-notes tbody,
.indirect-services-notes thead,
.indirect-services-notes tfoot,
.indirect-services-notes tr {
  display: contents;
}

.indirect-services-notes button {
  height: 2em;
  vertical-align: top;
  width: 2em;
}

.indirect-services-notes thead th {
  background: var(--primary-red);
  color: var(--primary-white);
  text-align: left;
  text-transform: uppercase;
}

.indirect-services-notes thead th,
.indirect-services-notes tbody td {
  margin: 0;
  padding: 0.2em 0.2em;
}

.indirect-services-notes tbody td {
  vertical-align: top;
}

.indirect-services-notes tbody td.indirect-services-note-date,
.indirect-services-notes tbody td.indirect-services-note-author {
  color: var(--primary-red);
}

.indirect-services-notes tbody td.indirect-services-note-message-text {
  max-height: 6em;
  overflow: auto;
}

.indirect-services-notes
  tbody
  td.indirect-services-note-message-input
  textarea {
  background: transparent;
  border: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  height: 3em;
  resize: vertical;
  width: 100%;
}

.indirect-services-notes
  tbody
  td.indirect-services-note-message-input
  textarea::-webkit-input-placeholder {
  color: var(--primary-medium-gray);
  opacity: 1;
}

.indirect-services-notes
  tbody
  td.indirect-services-note-message-input
  textarea::placeholder {
  color: var(--primary-medium-gray);
  opacity: 1;
}

.indirect-services-notes tbody tr:nth-child(2n + 1) td {
  background: var(--primary-light-gray);
}

.indirect-services-notes tbody td button {
  height: 1.33em;
  width: 1.33em;
}

.indirect-services-notes tbody td button > i {
  font-size: 0.4em;
}

.indirect-services-notes tfoot td {
  grid-column: span 4;
  padding-top: 0.4em;
}

.indirect-services-notes .indirect-services-note-date {
  width: 6em;
}

.indirect-services-notes .indirect-services-note-author {
  width: 12em;
}

.indirect-services-notes .indirect-services-note-remove {
  text-align: right;
  width: 2em;
}
</style>