import scrollPosition, { ScrollPosition } from "./scrollPosition";

export const pagination = (inputProp, outputProp) => {
    if (typeof inputProp !== 'string') {
        throw new TypeError(`inputProp required type string but got ${typeof inputProp}`);
    }

    if (inputProp.trim().length < 1) {
        throw new TypeError('inputProp required non-empty string');
    }

    const sanitizedInputProp = inputProp.replace(/^(filtered|paginated)/i, '');
    outputProp = outputProp ?? `paginated${sanitizedInputProp.charAt(0).toUpperCase()}${sanitizedInputProp.substr(1)}`;

    if (typeof outputProp !== 'string') {
        throw new TypeError(`outputProp required type string but got ${typeof outputProp}`);
    }

    if (outputProp.trim().length < 1) {
        throw new TypeError('outputProp required non-empty string');
    }

    return {
        mixins: [scrollPosition],
        data() {
            const size = Math.max(0, this.pageSize) ?? 'Infinity';
            const count = Math.ceil(this[inputProp]?.length / size) || 1;

            return {
                page: {
                    count,
                    current: Math.max(1, Math.min(count, this.pageNumber)),
                    size,
                },
            };
        },

        props: {
            pageNumber: {
                type: Number,
                default: 1,
            },
            pageSize: {
                type: Number,
                default: 20,
            },
        },

        computed: {
            hasNextPage() {
                return Array.isArray(this[inputProp]) && (this.page.current < this.page.count);
            },
            hasPages() {
                return Array.isArray(this[inputProp]) && (this[inputProp].length > this.page.size);
            },
            hasPrevPage() {
                return Array.isArray(this[inputProp]) && (this.page.current > 1);
            },
            [outputProp]() {
                const start = this.page.size * (this.page.current - 1);

                return Array.isArray(this[inputProp])
                    ? this[inputProp].slice(start, start + this.page.size)
                    : this[inputProp];
            },
        },

        methods: {
            goToFirstPage() {
                this.page.current = 1;
                this.setScrollPosition();
            },
            goToLastPage() {
                this.page.current = this.page.count;
                this.setScrollPosition();
            },
            goToNextPage() {
                this.page.current++;
                this.setScrollPosition();
            },
            goToPrevPage() {
                this.page.current--;
                this.setScrollPosition();
            },
        },

        watch: {
            'page.current'(to) {
                this.page.current = Math.max(1, Math.min(this.page.count, to));
            },
            'page.size'(to) {
                this.page.size = Math.max(0, to) ?? 'Infinity';
                this.page.count = Math.ceil(this[inputProp].length / this.page.size) || 1;
            },
            pageNumber(to) {
                this.page.current = to;
            },
            pageSize(to) {
                this.page.size = Math.max(0, to) ?? 'Infinity';
            },
            [inputProp]: {
                immediate: true,
                handler(to) {
                    this.page.count = Math.ceil(to.length / this.page.size) || 1;
                    this.page.current = Math.min(this.page.current, this.page.count);
                }
            }
        },
    };
};

export default pagination;