import { sortBy } from '../../func';

const API_PATH = '/api/counties';

export const counties = {
    namespaced: true,
    state: {
        counties: null,
    },
    mutations: {
        setData(state, value) {
            state.counties = value;
        },
    },
    actions: {
        clearCounties({ commit }) {
            commit('setData', null);
        },
        async createCounty({ dispatch, rootGetters }, county) {
            return rootGetters['user/can']('CREATE_COUNTIES')
                ? await dispatch('api/post', { url: API_PATH, data: county }, { root: true })
                : null;
        },
        async deleteCounty({ dispatch, rootGetters }, county) {
            return rootGetters['user/can']('DELETE_COUNTIES')
                ? await dispatch('api/delete', `${API_PATH}/${county.id}`, { root: true })
                : null;
        },
        async getCounties({ commit, dispatch, rootGetters, state }) {
            if (!state.counties) {
                commit('setData', []);

                rootGetters['user/can']('READ_COUNTIES') &&
                    commit('setData', (await dispatch('api/get', API_PATH, { root: true })).sort(sortBy('name', 'id')));
            }

            return state.counties;
        },
        async saveCounty({ dispatch }, county) {
            return await dispatch(county.id ? 'updateCounty' : 'createCounty', county);
        },
        async updateCounty({ dispatch, rootGetters }, county) {
            return rootGetters['user/can']('UPDATE_COUNTIES')
                ? await dispatch('api/put', { url: `${API_PATH}/${county.id}`, data: county }, { root: true })
                : null;
        },
        logout({ dispatch }) {
            dispatch('clearCounties');
        },
    }
};

export default counties;