<template>
  <ContentWrapper
    :create-icon="can('CREATE_EQUIPMENT_SUBCATEGORIES') ? 'add-equipment-subcategory' : null"
    :header-tag="headerTag"
    search-input-id="admin-equipment-subcategory-search-phrase"
    :show-back="showBack"
    title="Equipment Subcategories"
    @create="handleCreate"
    @search="handleSearch"
  >
    <EquipmentSubcategoriesTable
      class="admin-table"
      :selected-id="subcategory.id"
      :subcategories="filteredSubcategories"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="subcategory.id ? `Edit - ${subcategory.name}` : 'Create Subcategory'"
      />

      <span class="admin-form-tab">
        <img alt="Equipment Subcategories" src="../../../assets/icons/equipment-category.svg" />
      </span>

      <EquipmentSubcategoriesForm
        class="admin-form"
        :disabled="subcategory.name === undefined"
        :categories="$data._categories"
        :subcategory="subcategory"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import EquipmentSubcategoriesTable from "../../../components/admin/EquipmentSubcategoriesTable";
import EquipmentSubcategoriesForm from "../../../components/admin/EquipmentSubcategoriesForm";

import {
  getEquipmentCategories,
  getEquipmentSubcategories,
} from "../../../api/EquipmentAPI";

export default {
  data() {
    return {
      _categories: [],
      search: "",
      _subcategories: [],
      subcategory: {},
    };
  },

  props: {
    categories: {
      type: Array,
      default: undefined,
    },
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    subcategories: {
      type: Array,
      default: undefined,
    },
  },

  computed: {
    filteredSubcategories() {
      const regex = this.getFilterRegex(this.search);
      return this.$data._subcategories.filter((s) => regex.test(s?.name ?? ""));
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.subcategory = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.subcategory = {
          name: "",
          name_fr: null,
        };
      }
    },
    handleDelete(subcategory) {
      this.$data._subcategories = this.$data._subcategories.filter(
        (s) => s?.id !== subcategory?.id
      );

      this.subcategory = {};
    },
    handleSave(subcategory) {
      const created = !this.subcategory.id;

      this.$data._subcategories = [
        ...this.$data._subcategories.filter((s) => s?.id !== subcategory?.id),
        subcategory,
      ];

      this.handleSelect(created ? subcategory : undefined, true);

      this.showAlert(
        `Your equipment subcategory has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(subcategory, force = false) {
      if (force || this.confirmIfDirty()) {
        this.subcategory = {
          ...this.$data._subcategories.find((s) => s?.id === subcategory?.id),
        };
      }
    },
  },

  watch: {
    categories: {
      immediate: true,
      async handler(to, from) {
        this.$data._categories =
          to?.slice?.() ?? (await getEquipmentCategories());
      },
    },
    "$data._categories"(to, from) {
      if (to !== from) {
        this.$data._categories = to.sort(this.sortBy("name", "id"));
        const map = to.reduce((map, prog) => ({ ...map, [prog.id]: prog }), {});

        this.$data._subcategories.forEach(
          (sub) => (sub.category = map[sub.category?.id] ?? {})
        );
      }
    },
    "$data._subcategories"(to, from) {
      if (to !== from) {
        this.$data._subcategories = to.sort(this.sortBy("name", "id"));
        this.$emit("update:subcategories", this.$data._subcategories);
      }
    },
    async subcategories(to, from) {
      to === this.$data._subcategories ||
        (this.$data._subcategories =
          to?.slice?.() ?? (await getEquipmentSubcategories()));
    },
    "subcategory.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
  },

  async created() {
    this.$data._subcategories =
      this.subcategories?.slice?.() ?? (await getEquipmentSubcategories());

    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    EquipmentSubcategoriesTable,
    EquipmentSubcategoriesForm,
  },
};
</script>