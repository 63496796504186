<template>
  <table class="striped admin-equipment-types-table">
    <caption v-html="caption" v-if="caption" />
    <colgroup>
      <col width="25%" />
      <col width="25%" />
      <col width="25%" />
      <col width="25%" />
    </colgroup>
    <thead>
      <tr>
        <th>Equipment Prefix</th>
        <th>Description</th>
        <th>Program</th>
        <th>Comments</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="type in paginatedTypes"
        :key="type.id"
        :class="selectedId === type.id ? 'selected' : 'selectable'"
        @click="handleSelect(type)"
      >
        <td>{{ type.code }}</td>
        <td>{{ type.description }}</td>
        <td>{{ (type.program || {}).name }}</td>
        <td>{{ type.comment }}</td>
      </tr>
      <tr v-if="types.length === 0">
        <td class="no-records" colspan="4">
          There are currently no equipment types in the system.
        </td>
      </tr>
    </tbody>
    <tfoot v-if="hasPages">
      <tr>
        <td class="page-controls" colspan="4">
          <icon-button
            :disabled="!hasPrevPage"
            name="prev"
            @click="goToPrevPage"
          />

          <icon-button
            :disabled="!hasNextPage"
            name="next"
            @click="goToNextPage"
          />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { pagination } from "../../mixins/pagination";

export default {
  mixins: [pagination("types")],

  props: {
    caption: {
      type: String,
      default: undefined,
    },
    selectedId: {
      type: Number,
      default: -1,
    },
    types: {
      type: Array,
    },
  },

  methods: {
    handleSelect(type) {
      this.$emit("select", type);
    },
  },
};
</script>