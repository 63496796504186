<template>
  <ContentWrapper
    :create-icon="can('CREATE_EQUIPMENT_TYPES') ? 'add-equipment-type' : null"
    :header-tag="headerTag"
    search-input-id="admin-equipment-type-search-phrase"
    :show-back="showBack"
    title="Equipment Types"
    @create="handleCreate"
    @search="handleSearch"
  >
    <EquipmentTypesTable
      class="admin-table"
      :selected-id="type.id"
      :types="filteredTypes"
      @select="handleSelect"
    />

    <section class="admin-form-wrapper">
      <content-header
        class="admin-form-header"
        tag="h2"
        :title="type.id ? `Edit - ${type.code}` : 'Create Equipment Type'"
      />

      <span class="admin-form-tab">
        <img alt="Equipment Types" src="../../../assets/icons/equipment.svg" />
      </span>

      <EquipmentTypesForm
        class="admin-form"
        :disabled="type.code === undefined"
        :programs="$data._programs"
        :type="type"
        @cancel="handleCancel"
        @delete="handleDelete"
        @save="handleSave"
      />
    </section>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "./ContentWrapper";
import EquipmentTypesTable from "../../../components/admin/EquipmentTypesTable";
import EquipmentTypesForm from "../../../components/admin/EquipmentTypesForm";

import { getEquipmentTypes } from "../../../api/EquipmentAPI";
import { getPrograms } from "../../../api/ProgramAPI";

export default {
  data() {
    return {
      _programs: [],
      search: "",
      type: {},
      _types: [],
    };
  },

  props: {
    headerTag: {
      type: String,
      default: undefined,
    },
    id: {
      type: [Number, String],
      default: undefined,
    },
    programs: {
      type: Array,
      default: undefined,
    },
    routeParams: {
      type: Object,
      default: undefined,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    types: {
      type: Array,
      default: undefined,
    },
  },

  computed: {
    filteredTypes() {
      const regex = this.getFilterRegex(this.search);

      return this.$data._types.filter((t) =>
        regex.test(
          [
            t?.code ?? "",
            t?.description ?? "",
            t?.program?.name ?? "",
            t?.comments ?? "",
          ].join(String.fromCharCode(0x1f))
        )
      );
    },
  },

  methods: {
    handleCancel() {
      if (this.confirmIfDirty()) {
        this.type = {};
      }
    },
    handleCreate() {
      if (this.confirmIfDirty()) {
        this.type = { code: "" };
      }
    },
    handleDelete(type) {
      this.$data._types = this.$data._types.filter((t) => t?.id !== type?.id);
      this.type = {};
    },
    handleSave(type) {
      const created = !this.type.id;

      this.$data._types = [
        ...this.$data._types.filter((t) => t?.id !== type?.id),
        type,
      ];

      this.handleSelect(created ? type : undefined, true);

      this.showAlert(
        `Your equipment type has been ${
          created ? "created" : "updated"
        } successfully!`
      );
    },
    handleSearch(phrase) {
      this.search = phrase;
    },
    handleSelect(type, force = false) {
      if (force || this.confirmIfDirty()) {
        this.type = {
          ...this.$data._types.find((t) => t?.id === type?.id),
        };
      }
    },
  },

  watch: {
    "$data._programs"(to, from) {
      if (to !== from) {
        this.$data._programs = to.sort(this.sortBy("name", "id"));
        const map = to.reduce((map, prog) => ({ ...map, [prog.id]: prog }), {});

        this.$data._types.forEach(
          (type) => (type.program = map[type.program?.id] ?? {})
        );
      }
    },
    "$data._types"(to, from) {
      if (to !== from) {
        this.$data._types = to.sort(this.sortBy("code"));
        this.$emit("update:types", this.$data._types);
      }
    },
    programs: {
      immediate: true,
      async handler(to, from) {
        this.$data._programs = to?.slice?.() ?? (await getPrograms());
      },
    },
    "type.id"(to, from) {
      to === this.id ||
        this.$nextTick(() => {
          this.$router.push({
            ...this.$route,
            params: { ...this.$route.params, ...this.routeParams, id: to },
          });
        });
    },
    async types(to, from) {
      to === this.$data._types ||
        (this.$data._types = to?.slice?.() ?? (await getEquipmentTypes()));
    },
  },

  async created() {
    this.$data._types = this.types?.slice?.() ?? (await getEquipmentTypes());
    this.handleSelect(this);
  },

  beforeRouteUpdate(to, from, next) {
    if (!(this?.confirmIfDirty?.() ?? true)) {
      return next(false);
    }

    this.abortRequests();
    next();
  },

  beforeRouteLeave(to, from, next) {
    if (!to.params.skipDirtyCheck && !(this?.confirmIfDirty?.() ?? true)) {
      next(false);
    }

    this.abortRequests();
    next();
  },

  components: {
    ContentWrapper,
    EquipmentTypesTable,
    EquipmentTypesForm,
  },
};
</script>