<template>
  <section class="reports-page" ref="page">
    <iframe allowtransparency class="metabase" ref="iframe" :src="dashboardUrl" />
  </section>
</template>

<script>
import * as jose from "jose";

function getToken() {
  return new jose.SignJWT({
    resource: { dashboard: +process.env.VUE_APP_METABASE_DASHBOARD_ID },
    params: {}
  })
  .setProtectedHeader({ "alg": "HS256", "typ": "JWT" })
  .setExpirationTime(Math.round(Date.now() / 1000) + 10 * 60)
  .setIssuedAt()
  .sign(new TextEncoder().encode(process.env.VUE_APP_METABASE_SECRET));
}

export default {
  data() {
    return {
      dashboardUrl: "",
    };
  },

  methods: {
    getHeight() {
      return Math.max(
        this.$refs.iframe?.contentWindow?.document?.body?.scrollHeight ?? 0,
        this.$refs.iframe?.contentWindow?.document?.body?.offsetHeight ?? 0
      );
    },
    resizeHeight() {
      const height = this.getHeight();
      height && (this.$refs.iframe.style.height = `${height}px`);
    },
  },

  watch: {
    dashboardUrl : {
      immediate: true,
      async handler(to, from) {
        this.$data.dashboardUrl = `${process.env.VUE_APP_METABASE_PATH?.replace(/(.)\/+$/, "$1") ?? ""}/embed/dashboard/${await getToken()}#bordered=false&titled=false`
      }
    }
  },

  mounted() {
    // this.$watch() doesn't detect changes, so we'll work around it using setInterval()
    let height = Math.max(
      this.$refs.iframe?.contentWindow?.document?.body?.scrollHeight,
      this.$refs.iframe?.contentWindow?.document?.body?.offsetHeight
    );

    const iv = setInterval(() => {
      const _height = this.getHeight();

      if (height !== _height) {
        height = _height;
        this.resizeHeight();
      }
    }, 250);

    this.metabaseWatcher = () => clearInterval(iv);
    window.addEventListener("resize", this.resizeHeight);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resizeIframe);
    this.metabaseWatcher?.();
  },
};
</script>

<style>
.reports-page {
  padding: 0;
}

.metabase {
  border: 0;
  height: calc(100vh - 14.33rem);
  margin: 0;
  padding: 0;
  width: 100%;
}
</style>