<template>
  <div class="contents-div">
    <tr :class="['invoice-item-wrapper', hidden && 'hidden']">
      <td
        class="invoice-pick-checkbox-data"
      >
        <input v-model="invoice.selected" type="checkbox" @click="handleSelect" />
      </td>
      <td
        class="invoice-status-data"
        v-if="tableType == 'bulkInvoicing'"
        @click="showItems"
      >
        <label>
          <router-link
            class="client-link"
            target="_blank"
            :to="{ name: 'Clients', params: { id: invoice.client.id} }"
            v-if="
              can({
                permissions: ['READ_CLIENTS'],
                matchAny: true,
              })
            "
          >
            <span
              >{{ invoice.client.clientNumber }}
              <small :title="`View Client ${invoice.client.clientNumber}`"
                ><icon name="launch" /></small
            ></span>
          </router-link>
          <template v-else>
            {{ invoice.client.clientNumber }}
          </template>
        </label>
      </td>
      <td
        class="invoice-status-data"
        v-if="tableType == 'bulkInvoicing'"
        @click="showItems"
      >{{ getFullName(invoice.client) || "N/A" }}</td>

      <td class="invoice-number-data" @click="showItems">{{ invoice.invoiceNumber }}</td>

      <td class="invoice-approval-number" v-if="tableType == 'invoiceNoAppNumber'">
        <input
          type="text"
          v-model="invoice.approvalNumber"
          placeholder="Enter Approval No"
          required
        />
      </td>

      <td
        class="invoice-approval-number"
        v-if="
          (tableType == 'completedInvoices' && invoice.approvalNumber) ||
          tableType == 'bulkInvoicing'
        "
      >
        <span>{{ invoice.approvalNumber }}</span>
      </td>

      <td
        class="invoice-approval-number"
        v-if="tableType == 'completedInvoices' && !invoice.approvalNumber"
      >
        <span>Approval Number is not required</span>
      </td>

      <td @click="showItems">
        <select :disabled="tableType === 'completedInvoices'" id="invoice-program-select" v-model.number="invoice.program.id" required>
          <option v-for="ip in invoicePrograms" :key="ip.id" :value="ip.id">{{ ip.name }}</option>
        </select>
      </td>

      <td
        class="invoice-status-data"
        v-if="tableType == 'bulkInvoicing'"
        @click="showItems"
      >{{ formatCurrency(invoice.total) }}</td>

      <td
        class="invoice-status"
        @click="showItems"
      >{{ formatTimestamp(invoice.createdAt) }}</td>
    </tr>
    <tr>
      <td :colspan="tableType == 'bulkInvoicing' ? 8 : 5">
        <table class="invoice-inner-table" v-if="toggleItemInfo">
          <tr class="inner-table-header">
            <th class="invoice-table-header inner-table-td">equipment no</th>
            <th class="invoice-table-header inner-table-td">item</th>
            <th class="invoice-table-header inner-table-td">code</th>
            <th class="invoice-table-header inner-table-td">fee</th>
            <th class="invoice-table-header inner-table-td">note</th>
          </tr>

          <tr v-for="(item, i) in invoice.items" :key="i">
            <td class="inner-table-td" v-if="item.equipment">
              <select id="invoice-equip-number-data" v-model.number="item.equipment.id" disabled>
                <option
                  v-for="ip in invoiceEquipNumber"
                  :key="`index-${ip.id}`"
                  :value="ip.id"
                >{{ getEquipmentNumber(ip) }}</option>
              </select>
            </td>
            <td class="inner-table-td" v-else-if="item.loan">
              <select
                id="invoice-equip-number-data"
                v-model.number="item.loan.equipment.id"
                disabled
              >
                <option
                  v-for="ip in invoiceEquipNumber"
                  :key="`index-${ip.id}`"
                  :value="ip.id"
                >{{ getEquipmentNumber(ip) }}</option>
              </select>
            </td>
            <td class="inner-table-td" v-else-if="item.service">
              <select
                id="invoice-equip-number-data"
                v-model.number="item.service.equipment.id"
                disabled
              >
                <option
                  v-for="ip in invoiceEquipNumber"
                  :key="`index-${ip.id}`"
                  :value="ip.id"
                >{{ getEquipmentNumber(ip) }}</option>
              </select>
            </td>
            <td class="inner-table-td" v-else>
              <select id="invoice-equip-number-data" disabled>
                <option></option>
                <option
                  v-for="ip in invoiceEquipNumber"
                  :key="`index-${ip.id}`"
                  :value="ip.id"
                >{{ getEquipmentNumber(ip) }}</option>
              </select>
            </td>
            <td class="invoice-status-data inner-table-td" v-if="item.equipment">
              {{
              invoiceEquipNumber.find((e) => e.id == item.equipment.id).item
              .name
              }}
            </td>
            <td class="invoice-status-data inner-table-td" v-else-if="item.loan">
              {{
              invoiceEquipNumber.find((e) => e.id == item.loan.equipment.id)
              .item.name
              }}
            </td>
            <td class="invoice-status-data inner-table-td" v-else-if="item.service">
              <span v-if="item.service.equipment">
                {{
                invoiceEquipNumber.find(
                (e) => e.id == item.service.equipment.id
                ).item.name
                }}
              </span>
              <span v-else>
                {{
                "--"
                }}
              </span>
            </td>
            <td class="invoice-status-data inner-table-td" v-else>{{ "--" }}</td>
            <td class="inner-table-td">
              <select
                id="invoice-code-select"
                v-model.number="item.billingCode.id"
                disabled
                required
              >
                <option v-for="ip in invoiceCodes" :key="ip.id" :value="ip.id">{{ ip.code }}</option>
              </select>
            </td>
            <td
              id="invoice-item-fee"
              class="inner-table-td"
            >{{ `${formatCurrency(item.fee)}` }}</td>
            <td class="invoice-program-data inner-table-td">
              <textarea disabled :id="`invoice-note-${i}-message`" :value="item.description" />
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </div>
</template>

<script>
import { getInvoiceItems } from "../../api/InvoiceAPI";

export default {
  data() {
    return {
      approvalNumber: "",

      innerItem: "",

      toggleItemInfo: false
    };
  },
  props: {
    invoice: {
      type: Object,
      default: {}
    },
    invoicePrograms: {
      type: Array
    },
    invoiceCodes: {
      type: Array
    },
    index: {
      type: Number
    },
    invoiceEquipNumber: {
      type: Array
    },
    tableType: {
      type: String,
      default: undefined
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    billingCode() {
      if (this.invoiceCodes && this.billingCode) {
        return (this.billingDescription = this.invoiceCodes.find(
          e => e.id == this.billingCode
        )?.description);
      }
    }
  },
  methods: {
    showItems() {
      if (!this.toggleItemInfo && !this.invoice.items) {
        this.invoice.items = [];
        getInvoiceItems(this.invoice.id).then(
          items => (this.invoice.items = items)
        );
      }

      this.toggleItemInfo = !this.toggleItemInfo;
    },
    handleSelect(e) {
      if (!this.checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      this.$emit("update:invoice", {
        ...this.invoice,
        selected: e.target.checked
      });
    }
  },
  watch: {
    invoice: {
      immediate: true,
      handler(value) {
        this.equipmentId = value.id;
      }
    }
  }
};
</script>

<style scoped>
textarea {
  resize: none;
  width: 80%;
}
.contents-div {
  display: contents;
}

.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}
.invoice-table-header {
  text-transform: uppercase;
}

.inner-table-td {
  text-align: center;
}

.inner-table-header {
  background-color: var(--primary-red);
  color: var(--primary-white);
}

.invoice-inner-table {
  border: 1px solid black;
}

.client-link {
    all: unset;
    cursor: pointer;
    display: contents;
}

.client-link small {
  vertical-align: middle;
}

.hidden {
  height: 0;
  margin: 0;
  padding: 0;
  display: none;
}

.hidden + tr > td {
  height: 0;
  margin: 0;
  padding: 0;
}
</style>