import { del, get, post, put } from './http';

const API_PATH = '/api/services';

export const createService = async service =>
    await post(API_PATH, service);

export const deleteService = async service =>
    await del(`${API_PATH}/${service.id}`);

export const getService = async id =>
    await get(`${API_PATH}/${id}`)

export const getServices = async () =>
    await get(API_PATH);

export const saveService = async service =>
    await (service.id ? updateService(service) : createService(service));

export const updateService = async service =>
    await put(`${API_PATH}/${service.id}`, service);



/** Service Categories **/
const CATEGORY_API_PATH = `${API_PATH}/categories`;

export const createServiceCategory = async category =>
    await post(CATEGORY_API_PATH, category);

export const deleteServiceCategory = async category =>
    await del(`${CATEGORY_API_PATH}/${category.id}`);

export const getServiceCategories = async () =>
    await get(CATEGORY_API_PATH);

export const saveServiceCategory = async category =>
    await (category.id ? updateServiceCategory(category) : createServiceCategory(category));

export const updateServiceCategory = async category =>
    await put(`${CATEGORY_API_PATH}/${category.id}`, category);



/** Service Subcategories **/
const SUBCATEGORY_API_PATH = `${API_PATH}/subcategories`;

export const createServiceSubcategory = async subcategory =>
    await post(SUBCATEGORY_API_PATH, subcategory);

export const deleteServiceSubcategory = async subcategory =>
    await del(`${SUBCATEGORY_API_PATH}/${subcategory.id}`);

export const getServiceSubcategories = async () =>
    await get(SUBCATEGORY_API_PATH);

export const saveServiceSubcategory = async subcategory =>
    await (subcategory.id ? updateServiceSubcategory(subcategory) : createServiceSubcategory(subcategory));

export const updateServiceSubcategory = async subcategory =>
    await put(`${SUBCATEGORY_API_PATH}/${subcategory.id}`, subcategory);