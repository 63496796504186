<template>
  <component :is="tag" :class="className" v-on="$listeners" />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    tag: {
      type: String,
      default: "i",
    },
  },

  computed: {
    className() {
      return ["icon", ...(this.name?.split(/[\s-_]+/g) ?? [])]
        .map((s) => s.toLowerCase())
        .join("-");
    },
  },
};
</script>

<style scoped>
i {
  background-color: currentColor;
  display: inline-block;
  height: 1.6em;
  mask-position: center;
  mask-repeat: no-repeat;
  width: 1.6em;
}

.icon-add,
.icon-expand {
  mask-image: url("../../assets/icons/expand.svg");
}

.icon-add-billing-code {
  mask-image: url("../../assets/icons/billing-code-add.svg");
}

.icon-add-calendar,
.icon-add-holiday {
  mask-image: url("../../assets/icons/calendar-add.svg");
}

.icon-add-client {
  mask-image: url("../../assets/icons/client-add.svg");
}

.icon-add-equipment,
.icon-add-equipment-type {
  mask-image: url("../../assets/icons/equipment-add.svg");
}

.icon-add-equipment-accessory,
.icon-add-equipment-specification {
  mask-image: url("../../assets/icons/equipment-specification-add.svg");
}

.icon-add-equipment-category,
.icon-add-equipment-subcategory {
  mask-image: url("../../assets/icons/equipment-category-add.svg");
}

.icon-add-equipment-item {
  mask-image: url("../../assets/icons/equipment-item-add.svg");
}

.icon-add-equipment-statuses {
  mask-image: url("../../assets/icons/equipment-status-add.svg");
}

.icon-add-indirect-service {
  mask-image: url("../../assets/icons/indirect-service-add.svg");
}

.icon-add-facility {
  mask-image: url("../../assets/icons/facility-add.svg");
}

.icon-add-loan {
  mask-image: url("../../assets/icons/loan-add.svg");
}

.icon-add-manufacturer,
.icon-add-vendor {
  mask-image: url("../../assets/icons/manufacturer-add.svg");
}

.icon-add-program {
  mask-image: url("../../assets/icons/program-add.svg");
}

.icon-add-organization {
  mask-image: url("../../assets/icons/facility-add.svg");
}

.icon-add-pic {
  mask-image: url("../../assets/home/cameraAdd.svg");
}

.icon-add-project {
  mask-image: url("../../assets/icons/program-add.svg");
}

.icon-add-referral-agent,
.icon-add-user {
  mask-image: url("../../assets/icons/user-add.svg");
}

.icon-add-repair,
.icon-add-service {
  mask-image: url("../../assets/icons/service-add.svg");
}

.icon-add-template {
  mask-image: url("../../assets/icons/add-template.svg");
}

.icon-archive {
  mask-image: url("../../assets/icons/archive.svg");
}

.icon-close {
  mask-image: url("../../assets/icons/close.svg");
}

.icon-collapse {
  mask-image: url("../../assets/icons/collapse.svg");
}

.icon-delete,
.icon-remove {
  mask-image: url("../../assets/icons/remove.svg");
}

.icon-download {
  mask-image: url("../../assets/icons/download.svg");
}

.icon-edit {
  mask-image: url("../../assets/icons/edit.svg");
}

.icon-invoice {
  mask-image: url("../../assets/icons/invoice.svg");
}

.icon-launch {
  mask-image: url("../../assets/icons/launch.svg");
}

.icon-next {
  mask-image: url("../../assets/icons/next.svg");
}

.icon-prev {
  mask-image: url("../../assets/icons/prev.svg");
}

.icon-print {
  mask-image: url("../../assets/icons/print.svg");
}

.icon-sort {
  mask-image: url("../../assets/icons/dragsort.svg");
}

.icon-upload {
  mask-image: url("../../assets/icons/upload.svg");
}
</style>