import { del, get, post, put } from './http';
import { sortBy } from '../func';

const API_PATH = '/api/billing-codes';

export const createBillingCode = async billingCode =>
    await post(API_PATH, billingCode);

export const deleteBillingCode = async billingCode =>
    await del(`${API_PATH}/${billingCode.id}`);

export const getBillingCodes = async () =>
    (await get(API_PATH)).sort(sortBy('code', 'description'));

export const saveBillingCode = async billingCode =>
    await (billingCode.id ? updateBillingCode(billingCode) : createBillingCode(billingCode));

export const updateBillingCode = async billingCode =>
    await put(`${API_PATH}/${billingCode.id}`, billingCode);